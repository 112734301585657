import React, { useEffect, useState } from 'react';
import logo from './../../assets/img/upload.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useForm, SubmitHandler, SubmitErrorHandler, useFieldArray } from 'react-hook-form';
import { MSG_STAFF_ID_PROOF_TYPE_ERROR } from '../../config/messages';
interface IProps {
  contentTitle: string;
  label: string;
  url: string;
  onUpload: (e: any) => void;
  defaultImage?: BinaryType | null | undefined;
  isSignature?: boolean;
  isIdProof?: boolean;
  imgId?: string;
}

const ImageBoxMaidProfile: React.FC<IProps> = ({ contentTitle, label, url, defaultImage, onUpload, isSignature, isIdProof, imgId }) => {
  const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer | null>('');
  useEffect(() => {
    if (defaultImage !== undefined) {
      setSelectedImage(defaultImage);
      console.log(selectedImage,defaultImage);
      
    }
  }, [defaultImage]);

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    console.log(event, "aa");

    const e = (event.target as HTMLInputElement).files![0];
    if (e.type === 'image/png' || e.type === 'image/jpg' || e.type === 'image/jpeg') {
      const reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onload = () => {
        setSelectedImage(reader.result);
        onUpload(reader.result);
      };
    }
    else {
      toast.error(MSG_STAFF_ID_PROOF_TYPE_ERROR, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }); 
    }

    // if (e.type === 'image/png' || e.type === 'image/jpg' || e.type === 'image/jpeg' || e.type === 'image/svg') {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(e);
    //   reader.onload = () => {
    //     setSelectedImage(reader.result);
    //     onUpload(reader.result);
    //   };
    // } else {
    //   // TODO ALERT!
    // }
  };
  
  return (
    <>
      <label htmlFor={imgId ? "file-input" + imgId : "file-input"} className="div-label font-bold">
        <div className="">{contentTitle}</div>
        <div className={isSignature ? "image-box-signature margin-top10" : isIdProof ? "image-box-idproof margin-top10" : "image-box  margin-top10"}>
          {selectedImage && (
            <img className="center_normal" src={`${selectedImage}`} alt="Upload_Photo" />
          )}
          {!selectedImage && (
            <>
              <img className="center_small" src={logo} alt="Upload_Photo" />
            </>
          )}
          <input
            type="file"
            accept="image/png, image/jpeg" // "image/*"
            className="hide"
            id={imgId ? "file-input" + imgId : "file-input"}
            onChange={handleUpload}
          />
        </div>
      </label>
      <div className="upload-photo-label">{label}</div>
      
    </>
  );
};

export default ImageBoxMaidProfile;
