export const setTokens = (authData: any) => {
  // localStorage.setItem('user', JSON.stringify(authRes.user));
  localStorage.setItem('token', authData.access);
  localStorage.setItem('refreshToken', authData.refresh); 
  localStorage.setItem('adminUserId', authData.userId);
  localStorage.setItem('adminUserName', authData.fullName);  
  localStorage.setItem('adminUserRole', authData.role);
};

export const removeTokens = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('token');
  localStorage.removeItem('selectedHotelId');
  localStorage.removeItem('adminUserId');
  localStorage.removeItem('adminUserRole'); 
  localStorage.removeItem('adminUserName');  
};

export const getAccessToken = () => localStorage.getItem('token');
export const getUser = () => localStorage.getItem('user');
export const setUser = (user: any) => localStorage.setItem('user', JSON.stringify(user));
export const getRefreshToken = () => localStorage.getItem('refresh_token');
export const getAdminUserRole = () => localStorage.getItem('adminUserRole');

// ******************************************************************
// ***************** FOR STAFF LOGIN - 28JULY2022 ******************** 
// ******************************************************************

export const setMaidTokens = (authData: any) => {
  localStorage.setItem('maidToken', authData.access);
  localStorage.setItem('maidRefreshToken', authData.refresh);
};
export const setMaidPhone = (authData: any) => {
  localStorage.setItem('maidPhone', authData);
};
export const setMaidId = (decode: any) => localStorage.setItem('maidId', decode.user_id);

export const getMaidId = () => localStorage.getItem('maidId');
export const getMaidPhone = () => localStorage.getItem('maidPhone');
export const getMaidAccessToken = () => localStorage.getItem('maidToken');
export const getMaidRefreshToken = () => localStorage.getItem('maidRefreshToken');

export const removeMaidTokens = () => {
  localStorage.removeItem('maid');
  localStorage.removeItem('maidAccessToken');
  localStorage.removeItem('maidRefreshToken');
  localStorage.removeItem('maidToken');
  localStorage.removeItem('maidPhone');
};

// **********************************************************************
// ***************** TO STORE AND FETCH SELECTED HOTEL ****************** 
// **********************************************************************

export const setSelectedHotelId = (hotelId: any) => localStorage.setItem('selectedHotelId', hotelId); 
export const getSelectedHotelId = () => localStorage.getItem('selectedHotelId');



// ******************************************************************
// ***************** FOR HOTEL LOGIN - 09 SEP 2022 ****************** 
// ******************************************************************

export const setHotelTokens = (authData: any) => {
  localStorage.setItem('hotelToken', authData.access);
  localStorage.setItem('hotelRefreshToken', authData.refresh);
};

export const setHotelId = (decode: any) => localStorage.setItem('hotelId', decode.user_id);

export const getHotelId = () => localStorage.getItem('hotelId');
export const getHotelAccessToken = () => localStorage.getItem('hotelToken');
export const getHotelRefreshToken = () => localStorage.getItem('hotelRefreshToken');

export const removeHotelTokens = () => {
  localStorage.removeItem('hotel');
  localStorage.removeItem('hotelToken');
  localStorage.removeItem('hotelRefreshToken');
  localStorage.removeItem('hotelPhone');
};



