import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
// import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// import Modal from '@mui/material/Modal';
// import Box from '@mui/material/Box';
// import { useHistory } from 'react-router-dom';
// import { Store } from 'react-notifications-component';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import Select, { Options } from 'react-select';
import { Store } from 'react-notifications-component';

import Table from '../../components/Table';
import { selectHotelUserListDataSlice, setDataLoading } from './hotelUsersListDataSlice';
// import { IAdminAssociate, IAdminAssociateListRequestData, IAdminAssociateCreate, IAdminAssociateModify, IAdminAssociateUsername } from '../../models/adminAssociateInterfaces';
import {
  fetchAdminAssociates, createAdminAssociate, modifyAdminAssociate, statusUpdateAdminAssociate, resetAssocUserPassword, AdminAssociateUsernameSearch, fetchHotelUsers, deleteAdminAssociate, resetHotelUserPassword
} from '../../services/adminAssociateService';
import { adminAssociatesListFields, HotelUserListFields } from '../../config/tables';
// import { LBL_ASSOCIATES, LBL_ASSOCIATE, LBL_USER_ROLE_ADMIN_SUPERADMIN, LBL_USER_ROLE_ADMIN_ADMIN, LBL_HOTEL_ASSOCIATES, LBL_USER_ROLE_HOTEL_USER, LBL_USER_ROLE_HOTEL_MANAGER, LBL_HOTEL_ASSOCIATE } from '../../config/common_labels';
import { getAdminUserRole } from '../../services/localStorage';
// import { checkPagePermission, checkActionPermission, PERM_ADD_USER, PERM_EDIT_USER } from '../../config/permissions';

import { notification_shown_time_success, notification_shown_time_failed, USER_ROLE_HOTEL_USER, USER_ROLE_ADMIN_SUPERADMIN, USER_ROLE_ADMIN_ADMIN, notification_shown_time_failed4, USER_ROLE_HOTEL_MANAGER } from '../../config/env';

// import { Autocomplete, Checkbox, TextField } from '@mui/material';
// import { selectMaidListHotelFilterSlice } from '../MaidList/maidListHotelFilterSlice';
import { fetchHotelsNameId } from '../../services/hotelService';
import SearchBar from '../../components/SearchBar';
import Pagination from '../../components/Pagination';
import { selectHotelUserListPaginationSlice, setCurrentPage } from './hotelUsersListPaginationSlice';
import { changeSearch } from './hotelUsersListSearchSlice';
import { selectAdminAssociatesAddEditSlice } from '../AdminAssociates/adminAssociatesAddEditSlice';
import { IHotelUserListRequestData } from '../../models/hotelUsersListInterfaces';
// import { IHotelAssociateCreate, IHotelAssociateModify } from '../../models/hotelAssociateInterfaces';
import { IHotelForm } from '../../models/hotelsInterfaces';
// import { createHotelAssociate, modifyHotelAssociate } from '../../services/hotelAssociateService';
// import { MSG_CONFIRM_RESET_PASSWORD } from '../../config/messages';
import ModalBox from '../../components/ModalBox';
import { selectHotelAssociatesAddEditSlice } from '../HotelForm/hotelAssociatesAddEditSlice';
import { LBL_HOTEL_ASSOCIATE } from '../../config/common_labels';

export const HotelUserList = () => {
  // const { allHotelOpts } = useAppSelector(selectMaidListHotelFilterSlice);
  let adminUserRole = getAdminUserRole();
  let hotelUserListFields = HotelUserListFields;
  // IF ADMIN USER - NO EDIT AND DELETE OPTIONS
  if (adminUserRole === USER_ROLE_ADMIN_ADMIN.toString()) {
    hotelUserListFields = hotelUserListFields.filter(item =>item.type != 'delete_user_button'); 
  }

  const [listFetchAdminAssociatesPromise, setListFetchAdminAssociatesPromise] = useState<Array<any>>([]);
  const { associates, totalSize, loading, assocUserloading, associatesUser } = useAppSelector(selectHotelUserListDataSlice);
  const { currentPage } = useAppSelector(selectHotelUserListPaginationSlice);
  const { addEditLoading } = useAppSelector(selectHotelAssociatesAddEditSlice);
  const { deleteUserLoading } = useAppSelector(selectAdminAssociatesAddEditSlice);

  const dispatch = useAppDispatch();
  // const history = useHistory();
console.log(associates,"associates");

  const [editId, setEditId] = useState(0);
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [username, setUsername] = useState('');
  // const [mobile, setMobile] = useState('');
  // const [position, setPosition] = useState('');
  // const [hotelListObj, setHotelListObj] = useState([]);
  // const [hotelList, setHotelList] = useState([]);
  // const [role, setRole] = useState(USER_ROLE_HOTEL_USER);
  // const [usernameError, setUsernameError] = useState('required');
  // const [modalHeader, setModalHeader] = useState('');
  // const [emailVerified, setEmailVerified] = useState(false);
  const [isActive, setIsActive] = useState(false);
  // const [isPrimaryUser, setIsPrimaryUser] = useState(false);
  // const [roleDisabled, setRoleDisabled] = useState(false);
  // const [needUserMerge, setNeedUserMerge] = useState(false);
  const [modalData, setModalData] = useState<any>();

  const [searchDisabled, setSearchDisabled] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState("all");
  const [searchTypeOption, setSearchTypeOption] = useState({value: 'all', label: 'All'});

  const [displaySearch, setDisplaySearch] = useState<string>('');
  const [applySearch, setApplySearch] = useState(0);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    setValue,
    clearErrors,
    setError,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IHotelForm>();

  // let adminUserId = localStorage.getItem('adminUserId');

  const fetchall = (): void => {

    let sVal = displaySearch;
    if (displaySearch.length > 2) {
      dispatch(setDataLoading());
      changeSearch(displaySearch);
    }
    sVal = displaySearch;
    listFetchAdminAssociatesPromise.forEach((p) => {
      p.abort();
    });

    const req: IHotelUserListRequestData = {
      page: currentPage,
      searchType: searchType,
      searchText: encodeURIComponent(searchText.trim()),
    }; 

    const promise = dispatch(fetchHotelUsers(req));
    setListFetchAdminAssociatesPromise([promise]);

  };

  React.useEffect(() => {
    if(deleteUserLoading=='deleted') {
      fetchall();
    }
  }, [deleteUserLoading]);

  React.useEffect(() => {
    if (addEditLoading == 'succeeded') {
      handleClose();
      fetchall();
    }
    else if (addEditLoading == 'statusUpdated') {
      fetchall();
    }
    else if (addEditLoading == 'statusUpdateFailed') {
      setIsActive(!isActive);
    }
  }, [addEditLoading]);

  React.useEffect(() => {
    // dispatch(fetchHotelsNameId(''))
    fetchall();
  }, [dispatch, currentPage, applySearch]);


  const onClickable = (id: number, name: string): void => {
    if(name=='deleteUser') {
      Store.addNotification({
        title: 'Refund',
        id: 'deleteUser-modal',
        content: (
            <div className="refund-modal">
                <div className="refund-flex-center page-heading">
                    <h4 >Delete {LBL_HOTEL_ASSOCIATE}</h4>
                </div>
                <div className="refund-flex-center">
                    Are you sure you want to delete this {LBL_HOTEL_ASSOCIATE.toLowerCase()}?
                </div>
                <div className="refund-margin"></div>
                <div className="refund-flex-center">
                    <button
                        type="button"
                        className="primary"
                        onClick={() => Store.removeNotification('deleteUser-modal')}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="rounded-btn danger margin-left-15px"
                        onClick={() => {
                            Store.removeNotification('deleteUser-modal');
                            dispatch(deleteAdminAssociate(id));
                          }
                        }
                    >
                        Delete
                    </button>
                </div>
            </div>
          ),
          type: 'default',
          container: 'center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
              duration: 1000000000,
              click: false,
              touch: false,
          },
      });
    }
    else {
      setEditId(id);
      const assocDet = associates.find(item => item.id == id);
      console.log('assocDet: ', assocDet);
      setModalData(assocDet)
      handleOpen();
    }
    
  };


  // useEffect(() => {

  //   if (assocUserloading == "succeeded" && associatesUser) {
  //     if (associatesUser?.firstName && associatesUser?.firstName.trim().length > 0) {
  //       setFirstName(associatesUser.firstName);
  //     }
  //     if (associatesUser?.lastName && associatesUser?.lastName.trim().length > 0) {
  //       setLastName(associatesUser.lastName);
  //     }
  //     if (associatesUser?.role && associatesUser?.role > 0) {
  //       setRole(associatesUser.role);
  //     }
  //     if (associatesUser?.mobile && associatesUser?.mobile.trim().length > 0) {
  //       setMobile(associatesUser.mobile);
  //     }
  //     if (associatesUser?.position && associatesUser?.position.trim().length > 0) {
  //       setPosition(associatesUser.position);
  //     }
  //   }

  // }, [assocUserloading])

  // const updateAssocMobile = (value: string) => {
  //   setMobile(value);
  //   setValue('mobile', value);
  //   clearErrors('mobile');
  // };

  // const onSetHotelList = (value: any) => {
  //   setHotelListObj(value)
  //   setHotelList(value.map((item: any) => parseInt(item.value, 10)));
  // };

  // const onAssocSaveSubmitted = () => {
  //   if (firstName && firstName.trim().length > 0 && lastName && lastName.trim().length > 0 && username && username.trim().length > 0) {
  //     var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //     if (username.match(validRegex)) {
  //       if (editId == 0 && hotelListObj && hotelListObj.length > 0) {
  //         var newAssoc: IHotelAssociateCreate = {
  //           hotelId: "",
  //           firstName: firstName,
  //           lastName: lastName,
  //           mobile: mobile && mobile.trim().length > 0 ? mobile.search('\\+') < 0 ? '+' + mobile : mobile : '',
  //           username: username,
  //           position: position,
  //           role: role,
  //           hotelList: hotelList,
  //           isActive: true,
  //         };
  //         dispatch(createHotelAssociate(newAssoc));
  //       }
  //       else if (editId > 0) {
  //         var assocData: IHotelAssociateModify = {
  //           userId: editId,
  //           hotelId: "",
  //           firstName: firstName,
  //           lastName: lastName,
  //           mobile: mobile && mobile.trim().length > 0 ? mobile.search('\\+') < 0 ? '+' + mobile : mobile : '',
  //           username: username,
  //           position: position,
  //           isActive: isActive,
  //           role: role,
  //           hotelList: hotelList,
  //         };
  //         dispatch(modifyHotelAssociate(assocData));
  //       }

  //     }
  //   }
  //   else {
  //     return false;
  //   }
  // };

  // const OnSetUsername = (value: any) => {
  //   setUsername(value)
  //   let pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //   if (pattern.test(value)) {

  //     console.log("2");
  //     handleAssociateUsername(value)
  //   }
  // };

  // const handleAssociateUsername = (e: any) => {
  //   var newAssoc: IAdminAssociateUsername = {
  //     username: encodeURIComponent(e),
  //   };
  //   dispatch(AdminAssociateUsernameSearch(newAssoc));
  // };

  React.useEffect(() => {
    if (searchType == "all") {
      setSearchDisabled(true)
      setSearchText("")
      setDisplaySearch("")
    } else {
      setSearchDisabled(false)
    }
  }, [searchType]);

  const onResendEmail = (username: string) => {
    if (username) {
      dispatch(resetHotelUserPassword(encodeURIComponent(username)));
    }
  };
  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };

  const acceptSearch = (e: any): void => {
    setDisplaySearch(e.target.value as string);
    setSearchText(e.target.value as string);
    if(e.target.value.trim().length==0) {
      // setSearchTypeOption({value: 'all', label: 'All'}); 
      // onSearchButton(''); 
    }; 
  };

  const onSearchButton = (e: any) => {
    e.preventDefault();
    acceptPage(1);
    setApplySearch(applySearch + 1);
  }

  const handleChangeSearchType = (e: any): void => {
    setSearchType(e.value);
    if(e.value=='all') {
      setSearchTypeOption({value: 'all', label: 'All'});
    } 
    else if(e.value=='name') {
      setSearchTypeOption({value: 'name', label: 'Name'});
    }
    else if(e.value=='username') {
      setSearchTypeOption({value: 'username', label: 'Email'});
    }
    else if(e.value=='hotel') {
      setSearchTypeOption({value: 'hotel', label: 'Hotel Name'});
    }
    else if(e.value=='mobile') {
      setSearchTypeOption({value: 'mobile', label: 'Mobile'});
    } 
  };

  useEffect(() => {
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
  }, [])

  function selectBoxOnFocus() {
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-grey');
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-light-grey');
  }
  function selectBoxOnBlur() {
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-grey');
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
  }

    
  return (
    <>
    <div className="header-content-container">
      <div className="box">
        <div style={{ display: "flex", justifyContent: "left", gap: "17px" }}>

          {/* <select name='selectType' id='selectType' className={'min-width-300'} value={searchType} onChange={(e: any) => setSearchType(e.target.value)}>
            <option value="all">All</option>
            <option value="name">Name</option>
            <option value="username">Email</option>
            <option value="hotel">Hotel Name</option>
            <option value="mobile">Mobile</option>
          </select> */}
          <Select
              onChange={handleChangeSearchType}
              name="selectType"
              className="div-left min-width-300 margin-top2"
              classNamePrefix="react-select-maids"
              options={[ 
                {value: 'all', label: 'All'},
                {value: 'name', label: 'Name'},
                {value: 'username', label: 'Email'},
                {value: 'hotel', label: 'Hotel Name'},
                {value: 'mobile', label: 'Mobile'}
              ]}
              value={searchTypeOption}
              onFocus={selectBoxOnFocus}
              onBlur={selectBoxOnBlur}
            />
          <SearchBar
            searchDisabled={searchDisabled}
            onSearch={acceptSearch}
            currentSearch={displaySearch}
            place_holder="&#xf002; Search "
            searchButton={true}
            onSearchButton={onSearchButton} />
        </div>


        <div className="content-box table-wrapper">
          <Table
            titles={hotelUserListFields}
            data={Array.isArray(associates) ? associates : []}
            isLoading={loading === `loading` ? true : false}
            onClickable={onClickable}
            currentPage={0}
            onResendEmail={onResendEmail}
            showDeleteActionAlways={'yes'}
          />

          <div className="justify-right">
            <div className="pagination">
              <Pagination
                isTableLoading={loading === `loading` ? true : false}
                totalCount={totalSize}
                currentPage={currentPage}
                setCurrentPage={acceptPage}
                pageSize={20}
                siblingCount={2}
              />
            </div>
          </div>

        </div>
      </div>
      </div>
     



      <ModalBox
        open={open}
        close={handleClose}
        editId={editId}
        assocDetails={modalData}     
       />

    </>
  );
};
