import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { useHistory } from 'react-router-dom';
import Select, { Options } from 'react-select';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { getTerms, saveTerms } from '../../services/termsServices';
import { selectGetTermsDataSlice } from './termsSlice';
import ReactLoading from 'react-loading';
import { Store } from 'react-notifications-component';
import { LBL_MAID, LBL_TERMS, LBL_MAIDS, LBL_T_AND_C } from '../../config/common_labels';
import { USER_GROUP_MAIDS, USER_GROUP_GUESTS } from '../../config/env';
import { ITerms } from '../../models/termsInterfaces';

export const Terms = (props: any) => {
  const history = useHistory(); 

  const { termsLoading, terms } = useAppSelector(selectGetTermsDataSlice);
  const [termsGroupId, setTermsGroupId] = useState(USER_GROUP_MAIDS.toString());
  const [selectedTermsOption, setSelectedTermsOption] = useState({ value: USER_GROUP_MAIDS.toString(), label: LBL_MAIDS });
  const [isNewTerms, setIsNewTerms] = useState(false);
  const [newTermsText, setNewTermsText] = useState('');

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getTerms(termsGroupId));
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
  }, []);

  useEffect(() => {
    if (termsLoading == 'succeeded') {
      setNewTermsText(terms.conditionText);
      setIsNewTerms(false);
    }
  }, [termsLoading]);

  function selectBoxOnFocus() {
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-grey');
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-light-grey');
  }
  function selectBoxOnBlur() {
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-grey');
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
  }

  const handleTermsChange = (e: any): void => {
    setTermsGroupId(e.value);
    setSelectedTermsOption(e);
    dispatch(getTerms(e.value));
    setIsNewTerms(false); 
  };
  
  const allTermsOptions  = [
    {value: USER_GROUP_MAIDS.toString(), label: LBL_MAIDS},
    {value: USER_GROUP_GUESTS.toString(), label: ' Users who pay tips'}
  ]

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'blockquote', 'strike'],
    ],
  };

  const handleChange = (e: any) => {
    setNewTermsText(e);
  }

  const modifyTerms = (e: any) => {
    var newTerms: ITerms = {
      group: termsGroupId ? Number(termsGroupId) : 0,
      conditionText: newTermsText,
      currentTerms: isNewTerms,
    };
    dispatch(saveTerms(newTerms));
  }

  return (
    <>

      {termsLoading === 'loading' && (
        <div className="table-loader">
          <ReactLoading className="loader-center" color={'#2f427c'} type={'bars'} width={'3vw'} />
        </div>
      )}
      {termsLoading != 'loading' && (
        <>

          <div className="header-content-container">
            <div className="box">
              <div className="row">
                <div className="col maid-list-container">
                  <div className="hotel-select-bar">
                    <h4 className="page-heading div-left maid-list-title padding-top6">{LBL_T_AND_C} for </h4>
                    <Select
                      onChange={handleTermsChange}
                      name="hotelName"
                      onFocus={selectBoxOnFocus}
                      onBlur={selectBoxOnBlur}
                      className="margin-left-15px div-left"
                      classNamePrefix="react-select-maids"
                      options={allTermsOptions}
                      value={selectedTermsOption}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content_container">
            <div className="box pb-5">
              <form autoComplete="off">
                <div className="row padding-20-30px">
                  <div className="col-12">

                    <div className="row  mb-10px">
                      <div className="mb-10px ">
                        {/* <div className="mb-10px">
                      <label className="div-label font-bold">Terms &amp; Conditions</label>
                    </div> */}
                        <div className="mb-10px">
                          <ReactQuill theme="snow"
                            value={newTermsText}
                            onChange={handleChange}
                            modules={modules}
                            readOnly={false}
                            className="w-100perc"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="col-8 div-left flex">
                      {termsGroupId!=USER_GROUP_GUESTS.toString() &&  <><input
                        name="chk_newTerms"
                        id="chk_newTerms"
                        type="checkbox"
                        checked={isNewTerms}
                        onChange={e => setIsNewTerms(e.target.checked)}
                        className="activeCheckbox mt-5px"
                      /> <label htmlFor='chk_newTerms' className='div-label font-bold padding-bottom-5px mt-7px'> Save as new {LBL_T_AND_C}</label>
                      </>}
                    </div>
                    <div className="col-4 div-right">
                      <button type="button" className="primary div-right" onClick={e => modifyTerms(e)} > Save </button>
                    </div>
                  </div>

                </div>

              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};
