import React from 'react';
import { useHistory } from 'react-router-dom';
import { setSelectedHotelId } from '../../services/localStorage';

interface IProps {
  title: string;
  value: string;
  storeId?:number;
}

const UrlTableField: React.FC<IProps> = ({ title, value, storeId }) => {
  const history = useHistory();

  const onClickUrl = (): void => {
    if(storeId && storeId > 0) { 
      setSelectedHotelId(storeId);
    }
    history.push(value);
  };
  return (
    <>
      <a className="url-field" onClick={onClickUrl}>
        {title} 
      </a>
    </>
  );
};

export default UrlTableField;
