import React, { FC } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { PrivateRoute } from './components/PrivateRoute';
import { PageLayout } from './pages/PageLayout';
import { TipForm } from './pages/Tip';
import { StripeWrapper } from './pages/Stripe';
import { Success } from './pages/Success';

import { history } from './helpers/history';
import { setIntercept } from './services/apiService';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import { MaidRegister } from './pages/MaidRegister';
import { BasePage } from './pages/Base';
import { HotelAdminHotelForm } from './pages/HotelAdminHotelForm';
import { HotelAddSuccess } from './pages/HotelAddSuccess';
import { Privacypolicy } from './pages/privacyPolicy';

import { MaidLogin } from './pages/MaidLogin/MaidLogin'; 
import { MaidOTP } from './pages/MaidLogin/MaidOTP';
import { MaidProfile } from './pages/MaidLogin/MaidProfile';
import { StaffTerms } from './pages/MaidLogin/StaffTerms'; 
import { PaymentLog } from './pages/PaymentLog';

import { HotelLogin } from './pages/HotelLogin/HotelLogin'; 
import ResetPassword from './pages/AdminResetPassword/adminResetPassword';
import urlNotFound from './pages/404/404';
import MaidTerms from './pages/MaidRegister/Terms';

import { LBL_URL_MAIDS } from './config/common_labels';
import { EmailLog } from './pages/EmailLog';

export const App: FC = () => {
  React.useEffect(() => {
    setIntercept(history);
    if (process.env.NODE_ENV != 'development') {
      console.log = () => {};
    }
  }, []);

  return (
    <Router history={history}>
      <ReactNotifications />
      <Switch>
        <Route exact path="/  " component={Privacypolicy} />
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/tip/:id" component={TipForm} />
        <Route exact path="/pay" component={StripeWrapper} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/register/:id" component={MaidRegister} />
        <Route exact path="/new-hotel" component={HotelAdminHotelForm} />
        <Route exact path="/hotel-success" component={HotelAddSuccess} />

        {/* STAFF LOGIN PAGES */}
        <Route exact path={"/"+LBL_URL_MAIDS} component={MaidLogin} />
        <Route exact path={"/"+LBL_URL_MAIDS+"/"} component={MaidLogin} />
        <Route exact path={"/"+LBL_URL_MAIDS+"/otp"} component={MaidOTP} />
        <Route exact path={"/"+LBL_URL_MAIDS+"/profile"} component={MaidProfile} />
        <Route exact path={"/"+LBL_URL_MAIDS+"/profile/"} component={MaidProfile} />
        <Route exact path="/payment/log" component={PaymentLog} />
        <Route exact path="/email/log/" component={EmailLog} />
        {/* USED IN STAFF REGISTER PAGE  */}
        <Route exact path={"/"+LBL_URL_MAIDS+"-terms"} component={MaidTerms} />
        {/* USED IN STAFF LOGIN PAGE - 'Maid' CHANGED TO 'Staff' IN NOV2022 ASPER CLIENT REQUEST */}
        <Route exact path={"/"+LBL_URL_MAIDS+"/terms"} component={StaffTerms} />

        {/* HOTEL LOGIN PAGE */}
        {/* <Route exact path="/hotel" component={HotelLogin} /> */}
        {/* ADMIN ASSOCUSERS RESET PASSWORD */}
        <Route exact path="/resetpassword/" component={ ResetPassword}/>

        {/* STATUS CODE PAGES */}
        <Route exact path="/invalid/not-found" component={urlNotFound} />


        <PrivateRoute exact path="/">
          <Redirect to={{ pathname: '/' }} />
        </PrivateRoute>
        <PrivateRoute path="/v1" component={PageLayout} />
      </Switch>
    </Router>
  );
};
