import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Store } from 'react-notifications-component';
import { useForm } from 'react-hook-form';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { selectMaidListHotelFilterSlice } from '../../pages/MaidList/maidListHotelFilterSlice';
import { getAdminUserRole } from '../../services/localStorage';

import { MSG_CONFIRM_HOTEL_USER_DEACTIVATE, MSG_CONFIRM_HOTEL_USER_DEACTIVATE_HEADER, MSG_CONFIRM_RESET_PASSWORD } from '../../config/messages';
import { adminAssociatesListFields } from '../../config/tables';
import { USER_ROLE_ADMIN_ADMIN, USER_ROLE_HOTEL_MANAGER, USER_ROLE_HOTEL_USER } from '../../config/env';
import { LBL_HOTEL_ASSOCIATE, LBL_USER_ROLE_HOTEL_MANAGER, LBL_USER_ROLE_HOTEL_USER } from '../../config/common_labels';

import { selectHotelUserListDataSlice } from '../../pages/HotelUserList/hotelUsersListDataSlice';

import { IHotelForm } from '../../models/hotelsInterfaces';
import { IAdminAssociateUsername, IHotelAssociateCreate, IHotelAssociateModify } from '../../models/hotelAssociateInterfaces';

import { resetAssocUserPassword, resetHotelUserPassword } from '../../services/adminAssociateService';
import { fetchHotelsNameId } from '../../services/hotelService';
import { AdminAssociateUsernameSearch, createHotelAssociate, modifyHotelAssociate, statusUpdateHotelAssociate } from '../../services/hotelAssociateService';

interface IProps {
  open: boolean;
  close: () => void;
  assocDetails: any;
  editId: number;
  hotelId?: number;
  onAdd?: boolean;
  isHotelActive?: string; 
}

const ModalBox: React.FC<IProps> = (props) => {

  const { allHotelOpts, allHotelOptsInactive } = useAppSelector(selectMaidListHotelFilterSlice);
  let adminUserRole = getAdminUserRole();
  let userListFields = adminAssociatesListFields;
  if (adminUserRole === USER_ROLE_ADMIN_ADMIN.toString()) {
    userListFields[0].clickable = false;
  }
  const { assocUserloading, associatesUser } = useAppSelector(selectHotelUserListDataSlice); 

  const dispatch = useAppDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [mobile, setMobile] = useState('');
  const [position, setPosition] = useState('');
  const [hotelListObj, setHotelListObj] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [role, setRole] = useState(USER_ROLE_HOTEL_USER);
  const [usernameError, setUsernameError] = useState('required');
  const [modalHeader, setModalHeader] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [modalopen, setModalOpen] = useState(false);
  const [stateAllHotelOptsInactive, setStateAllHotelOptsInactive] = useState(allHotelOptsInactive);

  const onResetEmail = (username: string) => {
    if (username && username.length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        Store.addNotification({
          title: 'Reset Password',
          id: 'resetpwd-modal',
          content: (
            <div className="resetpwd-modal">
              <div className="resetpwd-flex-center page-heading">
                <h4>Confirm Reset Password</h4>
              </div>
              <div className="resetpwd-flex-center">
                {MSG_CONFIRM_RESET_PASSWORD + ' of ' + username + '?'}
              </div>
              <div className="resetpwd-margin"></div>
              <div className="resetpwd-flex-center">
                <button
                  type="button"
                  className="primary"
                  onClick={() => Store.removeNotification('resetpwd-modal')}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="primary margin-left-15px"
                  onClick={() => {
                    dispatch(resetHotelUserPassword(encodeURIComponent(username)))
                    Store.removeNotification('resetpwd-modal')
                    // props.close()
                  }
                  }
                >
                  Reset Password
                </button>
              </div>
            </div>
          ),
          type: 'default',
          container: 'center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 1000000000,
            click: false,
            touch: false,
          },
        });
      }
    }
  };

  const {
    setValue,
    clearErrors,
    setError,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IHotelForm>();

  const resetAssociate = () => {
    setFirstName('');
    setLastName('');
    setUsername('');
    setMobile('');
    setPosition('');
    setUsernameError('required');
    setEmailVerified(false);
    setIsActive(false);
    setRole(USER_ROLE_HOTEL_USER);
    setHotelList([]);
    setHotelListObj([]);
  }


  React.useEffect(() => {
    if (username && username.length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        setUsernameError('');
      }
      else {
        setUsernameError('required');
      }
    }
    else {
      setUsernameError('required');
    }
  }, [username]);

  React.useEffect(() => {
    dispatch(fetchHotelsNameId(''))
    
    if (props.onAdd) {
      resetAssociate();
      setModalHeader('Add ' + LBL_HOTEL_ASSOCIATE);
      props.open;
      let arr: any = []
      let arr2: any = []
      allHotelOptsInactive.map((d: any) => { arr.push(d) });
      
      arr.filter((d: any) => d.value == props.hotelId ? arr2.push(d) : null);
      console.log('M arr2: ', arr2); 
      setHotelListObj(arr2);
      setHotelList(arr2.map((item: any) => parseInt(item.value, 10)));
    }
    else{
      let assocDet = props.assocDetails;
      setFirstName(assocDet?.firstName ? assocDet.firstName : '');
      setLastName(assocDet?.lastName ? assocDet.lastName : '');
      setUsername(assocDet?.username ? assocDet.username : '');
      setMobile(assocDet?.mobile ? assocDet.mobile : '');
      setPosition(assocDet?.position ? assocDet.position : '');
      setRole(assocDet?.role ? assocDet.role : USER_ROLE_HOTEL_USER);
      setEmailVerified(assocDet?.emailVerified ? assocDet.emailVerified : false);
      setIsActive(assocDet?.isActive ? assocDet.isActive : false);
      setUsernameError('');
      setModalHeader('Edit ' + LBL_HOTEL_ASSOCIATE);
      console.log('assocDet: ',assocDet); 
      let arr: any = []
      let arr2: any = []
      allHotelOptsInactive.map((d: any) => { arr.push(d) });

      setStateAllHotelOptsInactive([]); 

      if (assocDet?.userHotelIds) {
        console.log('assocDet?.userHotelIds: '); 
        assocDet?.userHotelIds.map((data: any) => {
          arr.filter((d: any) => d.value == data ? arr2.push(d) : null)
        })
      }
      else if (assocDet?.userHotels) {
        console.log('assocDet?.userHotels: '); 
        // assocDet?.userHotels.filter((item: any) => (
        //   item.isActive == true)).map((data: any) => {
        //     arr.filter((d: any) => d.value == data.id ? arr2.push(d) : null)
        //   })

        assocDet?.userHotels.filter((item: any) => (
          item.isActive!='')).map((data: any) => {
            arr.filter((d: any) => d.value == data.id ? arr2.push(d) : null)
          }); 
      }
      
      setHotelListObj(arr2);
      console.log('Marr2: ',JSON.stringify(arr2)); 
      setHotelList(arr2.map((item: any) => parseInt(item.value, 10))); 
      setStateAllHotelOptsInactive(arr);
    }

  }, [props.assocDetails,props.onAdd]);



  useEffect(() => {

    if (assocUserloading == "succeeded" && associatesUser) {
      if (associatesUser?.firstName && associatesUser?.firstName.trim().length > 0) {
        setFirstName(associatesUser.firstName);
      }
      if (associatesUser?.lastName && associatesUser?.lastName.trim().length > 0) {
        setLastName(associatesUser.lastName);
      }
      if (associatesUser?.role && associatesUser?.role > 0) {
        setRole(associatesUser.role);
      }
      if (associatesUser?.mobile && associatesUser?.mobile.trim().length > 0) {
        setMobile(associatesUser.mobile);
      }
      if (associatesUser?.position && associatesUser?.position.trim().length > 0) {
        setPosition(associatesUser.position);
      }
    }

  }, [assocUserloading])

  const updateAssocMobile = (value: string) => {
    setMobile(value);
    setValue('mobile', value);
    clearErrors('mobile');
  };

  const onSetHotelList = (value: any) => {
    console.log(value); 
    setHotelListObj(value)
    setHotelList(value.map((item: any) => parseInt(item.value, 10)));
  };

  const onAssocSaveSubmitted = () => {
    if (firstName && firstName.trim().length > 0 && lastName && lastName.trim().length > 0 && username && username.trim().length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        if (props.editId == 0 && hotelListObj && hotelListObj.length > 0) {
          var newAssoc: IHotelAssociateCreate = {
            hotelId: props.hotelId,
            firstName: firstName,
            lastName: lastName,
            mobile: mobile && mobile.trim().length > 0 ? mobile.search('\\+') < 0 ? '+' + mobile : mobile : '',
            username: username,
            position: position,
            role: role,
            hotelList: hotelList,
            isActive: true,
          };
          dispatch(createHotelAssociate(newAssoc));
        }
        else if (props.editId > 0) {
          var assocData: IHotelAssociateModify = {
            userId: props.editId,
            hotelId: props.hotelId,
            firstName: firstName,
            lastName: lastName,
            mobile: mobile && mobile.trim().length > 0 ? mobile.search('\\+') < 0 ? '+' + mobile : mobile : '',
            username: username,
            position: position,
            isActive: isActive,
            role: role,
            hotelList: hotelList,
          };
          dispatch(modifyHotelAssociate(assocData));
        }

      }
    }
    else {
      return false;
    }
  };

  const OnSetUsername = (value: any) => {
    setUsername(value)
    let pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (pattern.test(value)) {

      console.log("2");
      handleAssociateUsername(value)
    }
  };

  const handleAssociateUsername = (e: any) => {
    var newAssoc: IAdminAssociateUsername = {
      username: encodeURIComponent(e),
    };
    dispatch(AdminAssociateUsernameSearch(newAssoc));
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const changeStatus = (actionStr: string) => {
    if(actionStr=='deactivate') {
      setModalOpen(true);
    }
    else if(actionStr=='activate') { 
      updateUserStatus();  
    }
  }

  const updateUserStatus = () => { 
      props.close(); 
      dispatch(statusUpdateHotelAssociate(props.editId)); 
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    bgcolor: 'background.paper',
    border: '1px solid #d0d0d0',
    boxShadow: 24,
    p: 4,
  };


  return (
    <>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby={modalHeader}
        aria-describedby=""
      >
        <Box sx={style}>
          <div className="associate-modal div-center">

            <div className='row col-12 margin-bottom20px'>
              <div className="row col-12 page-heading div-center">
                <div className="div-center "><h4 className='font-merri font-bold'>{modalHeader}</h4></div>
              </div>

              <div className='row margin-top5'>
                <div className='col-6 div-label font-bold'>
                  Username (Email)
                  <input
                    type="text"
                    name={'username'}
                    className={usernameError && usernameError.trim().length > 0 ? "w-100px input-error" : "w-100px"}
                    placeholder="Enter Username here"
                    onChange={(e) => OnSetUsername(e.target.value)}
                    required
                    value={username}
                  />
                  {emailVerified && <div className='div-right resetpwd-text' onClick={(e) => onResetEmail(username)}>Reset Password</div>}
                </div>
                <div className='col-6 div-label font-bold'>
                  Mobile
                  <PhoneInput
                    inputProps={{
                      name: `mobile`,
                      required: true,
                      autoFocus: false,
                    }}
                    inputClass="tel-input"
                    onlyCountries={['us', 'in']}
                    country={'us'}
                    value={mobile}
                    onChange={(code) => updateAssocMobile(code)}
                  />
                  {emailVerified && <div className='div-right resetpwd-text' >&nbsp;</div>}
                </div>

              </div>
              <div className='row margin-top20'>
                <div className='col-6 div-label font-bold'>
                  First Name
                  <input
                    type="text"
                    name={'firstName'}
                    className={firstName && firstName.trim().length > 0 ? "w-100px" : "w-100px input-error"}
                    placeholder="Enter First Name here"
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    value={firstName}
                  />
                </div>
                <div className='col-6 div-label font-bold'>
                  Last Name
                  <input
                    type="text"
                    name={'lastName'}
                    className={lastName && lastName.trim().length > 0 ? "w-100px" : "w-100px input-error"}
                    placeholder="Enter Last Name here"
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    value={lastName}
                  />
                </div>
              </div>
              <div className='row margin-top20'>
                <div className='col-6 div-label font-bold'>
                  Position
                  <input
                    type="text"
                    name={'position'}
                    className="w-100px"
                    placeholder="Enter Position here"
                    onChange={(e) => setPosition(e.target.value)}
                    value={position}
                  />
                </div>
                <div className='col-6 div-label font-bold'>
                  Role
                  <select name="role" id="role" className='w-100px' value={role} defaultValue={USER_ROLE_HOTEL_USER} onChange={(e: any) => setRole(e.target.value)}>
                    <option value={USER_ROLE_HOTEL_USER}>{LBL_USER_ROLE_HOTEL_USER} </option>
                    <option value={USER_ROLE_HOTEL_MANAGER}>{LBL_USER_ROLE_HOTEL_MANAGER}</option>
                  </select>

                </div>
              </div>


              <div className='row margin-top20'>
                <div className=' div-label font-bold col-12'>
                  Hotel List
                  <div className={hotelListObj && hotelListObj.length > 0 ? "autocomplete" : (props.editId == 0 ? "input-error autocomplete" : "autocomplete")}>
                    <Autocomplete
                      sx={{ height: 'auto' }}
                      multiple
                      limitTags={3}
                      disableCloseOnSelect={true}
                      id="multiple-limit-tags"
                      options={stateAllHotelOptsInactive}
                      className="select_checkbox"
                      getOptionLabel={(option) => option.label}
                      // defaultValue={[allHotelOpts[0]]}
                      value={hotelListObj}
                      onChange={(e: any, newValue: any) => { onSetHotelList(newValue) }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}><Checkbox style={{ marginRight: 8 }} checked={selected} />{option.label}</li>
                      )}
                      renderInput={(params) => props.editId > 0 ? (
                        <TextField {...params} size="small" />
                      ) : (<TextField {...params} size="small" required />)}
                    />
                  </div>
                </div>
              </div>

              <div className='row margin-top30'>
                <div className='col-7 div-left'>
                  {/* {emailVerified === true && props.editId > 0 && <>
                    <label>
                      <div className='div-left'>
                        <input
                          type="checkbox"
                          checked={isActive}
                          onChange={e => changeStatus(e.target.checked)}
                          className="activeCheckbox"
                        /></div> <span > Active</span>
                    </label></>} */}
                    {emailVerified === true && props.editId > 0 && isActive && 
                    <button
                      type="button"
                      className="bg-danger  rounded-btn"
                      onClick={e => changeStatus('deactivate')}
                    >
                      Deactivate User
                    </button>}
                    {emailVerified === true && props.editId > 0 && !isActive && 
                    <button
                      type="button"
                      className="bg-success text-white rounded-btn"
                      onClick={e => changeStatus('activate')}
                    >
                      Activate User
                    </button>}
                </div>
                <div className='col-5 div-right'>
                  <button
                    type="button"
                    className="primary div-right margin-left-10px"
                    onClick={() => onAssocSaveSubmitted()}
                  > &nbsp; Save &nbsp; </button>
                  <button
                    type="button"
                    className="primary div-right margin-right-15px"
                    onClick={props.close}
                  >Cancel</button>
                </div>
              </div>
              <div className='row margin-top120'>&nbsp;</div>
            </div>
          </div>
        </Box>
      </Modal>

      <Dialog
            open={modalopen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <div className='refund-flex-center'>{MSG_CONFIRM_HOTEL_USER_DEACTIVATE_HEADER}</div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              <div className='refund-flex-center'>{MSG_CONFIRM_HOTEL_USER_DEACTIVATE}</div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button className='primary' onClick={()=>{handleClose(); }}>No</Button>
              <Button className='primary' onClick={()=>{handleClose(); updateUserStatus(); }} autoFocus>
                Yes
              </Button> */}
              <button
                    type="button"
                    className="primary margin-right-15px"
                    onClick={()=>{handleClose(); }}
                  >No</button>
              <button
                    type="button"
                    className="primary margin-right-15px"
                    onClick={()=>{handleClose(); updateUserStatus(); }}
                  >Yes</button>    
            </DialogActions>
          </Dialog>
    </>
  );
};

export default ModalBox;
