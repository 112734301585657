import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm, SubmitHandler, SubmitErrorHandler, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactLoading from 'react-loading';
import dayjs from 'dayjs';
var QRCode = require('qrcode.react');
import copy from 'copy-to-clipboard';
import { Store } from 'react-notifications-component';

import AddPageHeader from '../../components/AddPageHeader';
import ImageBox from '../../components/ImageBox';

import { IHotelForm, IHotelId } from '../../models/hotelsInterfaces';
import {
  fetchHotel,
  updateHotel,
  createHotelHotelAdmin,
  activateHotel,
  deactivateHotel,
  fetchConfig,
  fetchHotelAdminHotelConfig,
  getHotelQrCode,
  getHotelAdminsettings,
} from '../../services/hotelService';
import { getPdfFile } from '../../services/apiService';

import {
  selectHotelFormDataSlice,
  setMobile,
  setAlternative,
  setHotelForm,
  setNewHotelCreated,
} from './hotelFormDataSlice';

import {
  notification_shown_time_success,
  server_url_var,
  tip_server_url_var,
  TIP_AMT_MAX_VALUE,
  SMS_MAX_LENGTH,
  TIP_FACT_HEADER_MAX_LENGTH,
  TIP_FACT_BODY_MAX_LENGTH,
  APP_NAME,
  ROOM_NUMBER_VALUE,
  ROOM_NAME_VALUE,
  GOOGLE_RECAPTCHA_KEY,
  HOTEL_CODE_MAX_LENGTH,
  PAYOUT_HOTEL,
  PAYOUT_STAFF,
  DEFAULT_CURRENCY,
  DEFAULT_COUNTRY,
  COMPANY_DOC_TYPE_IRS_LETTER_147C_ID,
  COMPANY_DOC_TYPE_IRS_LETTER_147C,
  COMPANY_DOC_TYPE_IRS_SS4_LETTER_ID,
  COMPANY_DOC_TYPE_IRS_SS4_LETTER,
} from '../../config/env';

import { selectHotelSettingsFormDataSlice } from '../Settings/AddSettings';
import {
  MSG_INVALID_PHONE_NUMBER,
  MSG_LINK_COPIED,
  MSG_ENTER_VALID_EMAIL,
  MSG_INVALID_AMOUNT,
  MSG_STAFF_ID_PROOF_TYPE_ERROR,
} from '../../config/messages';
import {
  LBL_MAID,
  LBL_MAIDS,
  LBL_ROOM_NAME,
  LBL_ROOM_NUMBER,
  LBL_PAYOUT_HOTEL,
  LBL_PAYOUT_STAFF,
} from '../../config/common_labels';

export const HotelAdminHotelForm = (props: any) => {
  const history = useHistory();
  const [title, setTitle] = useState<string>('Add New Hotel');
  const [subTitle, setSubTitle] = useState<string>('Add New Hotel');
  const [selectedImage, setSelectedImage] = useState<BinaryType | null | undefined>(null);
  const { hotelForm, loading, newHotelCreated } = useAppSelector(selectHotelFormDataSlice);
  const { hotelSettingsForm, newSettingsAdded } = useAppSelector(selectHotelSettingsFormDataSlice);
  const [selectedMobile, setSelectedMobile] = useState('');
  const [selectedAlternative, setSelectedAlternative] = useState('');
  const dispatch = useAppDispatch();

  const [payout, setPayout] = useState(false); //FOR BANK ACC DISPLAY
  const [saveClickCount, setSaveClickCount] = useState(0);

  const [verificationDocumentImg, setVerificationDocumentImg] = useState('');
  const [checkDisable, setCheckDisable] = useState<boolean>(false);

  const [primaryMobileNumber, setPrimaryMobileNumber] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaValue, setCaptchaValue] = useState<string>('');
  let required = 'This field is required.';

  function onCaptchaChange(val: any) {
    setCaptchaValue(val);
    setCaptchaVerified(true);
  }

  const checkMandatory = (e: any, fieldName: string) => {
    if (fieldName === 'payout') {
      if (e.target.value != PAYOUT_HOTEL) {
        setPayout(false);
      } else {
        setPayout(true);
      }
    }
  };

  const fileValidation = (e: any) => {
    setVerificationDocumentImg(e);
  };

  useEffect(() => {
    dispatch(setHotelForm({} as IHotelForm));
    // reset form with user data
    if (props.match.params.id != undefined) {
      dispatch(fetchHotel(props.match.params.id));
      dispatch(getHotelAdminsettings(1));
    } else {
      dispatch(fetchHotelAdminHotelConfig());
      dispatch(getHotelAdminsettings(1));
    }
    return function cleanup() {
      dispatch(setHotelForm({} as IHotelForm));
      reset(hotelForm);
    };
  }, [dispatch]);

  useEffect(() => {
    setValue(`welcomeMessage`, hotelSettingsForm?.welcomeMessage);
    setValue(`googleReviewLink`, hotelSettingsForm?.googleReviewLink);
    setValue(`tripAdvisorLink`, hotelSettingsForm?.tripAdvisorLink);

    setValue(`tip_1`, hotelSettingsForm?.tip_1);
    setValue(`tip_2`, hotelSettingsForm?.tip_2);
    setValue(`tip_3`, hotelSettingsForm?.tip_3);
    setValue(`tip_4`, hotelSettingsForm?.tip_4);
    setValue(`tip_5`, hotelSettingsForm?.tip_5);

    setValue(`tippingFactHeader_1`, hotelSettingsForm?.tippingFactHeader_1);
    setValue(`tippingFactHeader_2`, hotelSettingsForm?.tippingFactHeader_2);
    setValue(`tippingFactHeader_3`, hotelSettingsForm?.tippingFactHeader_3);
    setValue(`tippingFactHeader_4`, hotelSettingsForm?.tippingFactHeader_4);
    setValue(`tippingFactHeader_5`, hotelSettingsForm?.tippingFactHeader_5);

    setValue(`tippingFactBody_1`, hotelSettingsForm?.tippingFactBody_1);
    setValue(`tippingFactBody_2`, hotelSettingsForm?.tippingFactBody_2);
    setValue(`tippingFactBody_3`, hotelSettingsForm?.tippingFactBody_3);
    setValue(`tippingFactBody_4`, hotelSettingsForm?.tippingFactBody_4);
    setValue(`tippingFactBody_5`, hotelSettingsForm?.tippingFactBody_5);

    setValue(
      `paymentCommission`,
      hotelSettingsForm?.defaultPaymentCommission ? hotelSettingsForm.defaultPaymentCommission : 0
    );
  }, [hotelSettingsForm]);

  // This effect is used to handle a redirect when a hotel is created successfully
  React.useEffect(() => {
    if (newHotelCreated === true) {
      setTitle(hotelForm?.hotelName);
      setSelectedMobile(hotelForm?.mobileNumber);
      setSelectedAlternative(hotelForm?.alternativeMobileNumber);
      setSubTitle(`Hotel List/${hotelForm?.hotelName}`);
      history.replace('/hotel-success/');
      return function cleanup() {
        dispatch(setNewHotelCreated(false));
      };
    }
  }, [newHotelCreated]);

  const {
    setValue,
    getValues,
    clearErrors,
    setError,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IHotelForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  useEffect(() => {
    if (fields.length === 0) {
      incContacts();
    }
  }, [fields]);

  useEffect(() => {
    if (props.match.params.id != undefined) {
      reset(hotelForm);
      setSelectedImage(hotelForm?.image);
      setVerificationDocumentImg(
        hotelForm?.verificationDocument
          ? `data:image/png;base64,${hotelForm?.verificationDocument}`
          : ''
      );
      setCheckDisable(hotelForm?.documentVerified ? hotelForm?.documentVerified : false);
      if (hotelForm == undefined || hotelForm.contacts == undefined || hotelForm.contacts == null) {
        append({}, { shouldFocus: false });
      }
      setTitle(hotelForm?.hotelName);
      setSelectedMobile(hotelForm?.mobileNumber);
      setSelectedAlternative(hotelForm?.alternativeMobileNumber);
      setSubTitle(`Hotel List/${hotelForm?.hotelName}`);
    } else {
      // setValue(`paymentCommission`, hotelForm?.defaultPaymentCommission);
      if (hotelSettingsForm?.defaultPaymentCommission) {
        setValue(
          `paymentCommission`,
          hotelSettingsForm?.defaultPaymentCommission
            ? hotelSettingsForm.defaultPaymentCommission
            : 0
        );
      }
    }

    setValue(`payout`, hotelForm?.payout ? hotelForm?.payout : PAYOUT_STAFF);

    if (!hotelForm?.payout) {
      setPayout(false);
    } else if (hotelForm?.payout && hotelForm?.payout != PAYOUT_HOTEL) {
      setPayout(false);
    } else {
      setPayout(true);
    }

    setValue(`accountHolderName`, hotelForm?.bankDetails?.accountHolderName);
    setValue(
      `country`,
      hotelForm?.bankDetails?.country ? hotelForm?.bankDetails?.country : DEFAULT_COUNTRY
    );
    setValue(`accountNumber`, hotelForm?.bankDetails?.accountNumber);
    setValue(`bankName`, hotelForm?.bankDetails?.bankName);
    setValue(`routingNumber`, hotelForm?.bankDetails?.routingNumber);

    setValue(`companyName`, hotelForm?.companyName);
    setValue(`companyTaxId`, hotelForm?.companyTaxId);
    
  }, [hotelForm]);

  const onErrorSubmit: SubmitErrorHandler<IHotelForm> = (data) => {
    if (selectedMobile === '') {
      setError('mobileNumber', {
        type: 'manual',
        message: MSG_INVALID_PHONE_NUMBER,
      });
    }
    // if (selectedAlternative === '') {
    //   setError('alternativeMobileNumber', {
    //     type: 'manual',
    //     message: MSG_INVALID_PHONE_NUMBER,
    //   });
    // }
  };

  const updateFieldMobile = (value: string) => {
    setSelectedMobile(value);
    clearErrors('mobileNumber');
  };

  const updateFieldAltMobile = (value: string) => {
    setSelectedAlternative(value);
    clearErrors('alternativeMobileNumber');
  };

  const updateIndexMobileField = (index: number, value: string) => {
    setValue(`contacts.${index}.mobileNumber`, value);
  };

  const getIndexMobilenumber = (index: number): string => {
    if (
      hotelForm == undefined ||
      hotelForm.contacts == undefined ||
      hotelForm.contacts == null ||
      hotelForm.contacts.length == 0
    ) {
      return '';
    }
    if (hotelForm.contacts.length >= index) {
      if (hotelForm.contacts[index] == undefined) {
        return '';
      }
      return hotelForm.contacts[index].mobileNumber;
    }
    return '';
  };

  const onSubmit: SubmitHandler<IHotelForm> = (data) => {
    console.log(data, 'admin');

    if (!captchaVerified || !captchaValue || captchaValue.length == 0) {
      return;
    }

    const idsToRemove: number[] = [];
    if (selectedMobile.trim() === '') {
      setError('mobileNumber', {
        type: 'manual',
        message: MSG_INVALID_PHONE_NUMBER,
      });
      return;
    }
    // if (selectedAlternative === '') {
    //   setError('alternativeMobileNumber', {
    //     type: 'manual',
    //     message: MSG_INVALID_PHONE_NUMBER,
    //   });
    //   return;
    // }
    (data.currency = hotelForm?.currency ? hotelForm.currency : DEFAULT_CURRENCY),
      (data.mobile = primaryMobileNumber);
    data.image = selectedImage;
    data.verificationDocument = verificationDocumentImg;
    data.mobileNumber = selectedMobile;
    data.alternativeMobileNumber = selectedAlternative;
    data.paymentCommission = hotelSettingsForm?.defaultPaymentCommission
      ? hotelSettingsForm.defaultPaymentCommission
      : 0;
    if (data?.contacts != undefined) {
      data.contacts.map((contact, i) => {
        const isEmpty = !Object.values(contact).some((x) => x !== null && x !== '');
        if (isEmpty) {
          idsToRemove.push(i);
        }
      });
      idsToRemove.map((id) => {
        if (data?.contacts != undefined) {
          data.contacts = data.contacts.filter((contact, index) => {
            return index != id;
          });
        }
      });
    }
    if (props.match.params.id === undefined) {
      data.hotel_user_active = false;
      dispatch(createHotelHotelAdmin(data));
    } else {
      data.id = props.match.params.id;
      dispatch(updateHotel(data));
    }
  };

  const onSaveSubmitted = (): void => {
    setSaveClickCount(saveClickCount + 1);
    if (loading != 'loading') {
      handleSubmit(onSubmit, onErrorSubmit)();
    }
  };

  const onCancelSubmitted = (): void => {
    history.push('/v1/hotels');
  };

  const incContacts = (): void => {
    append(
      {
        firstName: '',
        lastName: '',
        position: '',
        addressLineOne: '',
        addressLineTwo: '',
        zip: '',
        mobileNumber: '',
        city: '',
        state: '',
        email: '',
      },
      { shouldFocus: false }
    );
  };

  const decContacts = (index: number): void => {
    remove(index);
  };

  const onImageUploadSubmitted = (e: any): void => {
    setSelectedImage(e);
  };

  const onActivateHotel = (): void => {
    var req: IHotelId = {
      id: props.match.params.id,
    };
    dispatch(activateHotel(req));
  };

  const onDeactivateHotel = (): void => {
    var req: IHotelId = {
      id: props.match.params.id,
    };
    dispatch(deactivateHotel(req));
  };

  const copyLink = (): void => {
    copy(server_url_var + '/register/' + hotelForm.hotelCodeId + '/');
    Store.addNotification({
      title: APP_NAME,
      message: MSG_LINK_COPIED,
      type: 'success',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: notification_shown_time_success,
        onScreen: true,
        pauseOnHover: true,
      },
    });
  };

  const handleHotelCodeValidation = (e: any) => {
    e.currentTarget.value = e.currentTarget.value.replace(/[^a-z0-9]/gi, '').toUpperCase();
  };

  const changeHotelCode = (e: any) => {
    let HotelName = e.currentTarget.value.replace(/[^a-z0-9]/gi, '').toUpperCase();
    if (hotelForm.hotelCode == '' || hotelForm.hotelCode == undefined) {
      setValue('hotelCode', HotelName);
    }
  };

  return (
    <div className="content_container">
      {loading != 'loading' && (
        <AddPageHeader
          contentTitle={title}
          subTitle={''}
          onSave={onSaveSubmitted}
          onCancel={onCancelSubmitted}
          appLogo={true}
          smallHeader={true}
        />
      )}
      {loading === 'loading' && (
        <div className="table-loader">
          <ReactLoading className="loader-center" color={'#2f427c'} type={'bars'} width={'3vw'} />
        </div>
      )}
      {loading != 'loading' && (
        <div className="box pb-5">
          <form autoComplete="off">
            <div className="row top">
              <div className="col-1">&nbsp;</div>
              <div className="col-10">
                <div className="row">
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">
                        Hotel Name<span className="danger">*</span>
                      </label>
                    </div>
                    <div className="mb-10px">
                      <input
                        autoFocus
                        {...register('hotelName', { required })}
                        required
                        type="text"
                        name="hotelName"
                        className={errors.hotelName ? 'input-error w-100px' : 'w-100px'}
                        // onChange={(e) => changeHotelCode(e)}
                        placeholder="Enter hotel name here"
                      />
                    </div>
                    {errors.hotelName && <span className="danger">{errors.hotelName.message}</span>}
                  </div>
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      {/* <label className="div-label font-bold">
                        Hotel Code <span className="danger">*</span>
                      </label> */}
                      <label className="div-label font-bold">
                        Address Line 1 <span className="danger">*</span>
                      </label>
                    </div>
                    <div className="mb-10px">
                      {/* <input
                        {...register('hotelCode', { required })}
                        type="text"
                        name="hotelCode"
                        className={errors.hotelCode ? 'input-error w-100px' : 'w-100px'}
                        placeholder="Code"
                        onChange={(e) => handleHotelCodeValidation(e)}
                        maxLength={HOTEL_CODE_MAX_LENGTH}
                      /> */}
                       <input
                          {...register('addressLineOne', { required })}
                          type="text"
                          name="addressLineOne"
                          className={errors.addressLineOne ? 'input-error w-100px' : 'w-100px'}
                          placeholder="Enter address line 1 here"
                        />
                    </div>
                    {/* {errors.hotelCode && <span className="danger">{errors.hotelCode.message}</span>} */}
                    {errors.addressLineOne && (
                      <span className="danger">{errors.addressLineOne.message}</span>
                    )}
                  </div>

                  <div>
                    <div className="row ">
                      {/* <div className="col-6 form-group">
                        <div className="mb-10px">
                          
                        </div>
                        <div className="mb-10px">
                         
                        </div>
                        
                      </div> */}

                      <div className="col-6  form-group">
                        <div className="mb-10px">
                          <label className="div-label font-bold">Address Line 2</label>
                        </div>
                        <div className="mb-10px">
                          <input
                            {...register('addressLineTwo')}
                            type="text"
                            name="addressLineTwo"
                            className="w-100px"
                            placeholder="Enter address line 2 here"
                          />
                        </div>
                      </div>

                      <div className="col-6  form-group">
                        <div className="mb-10px">
                          <label className="div-label font-bold">
                            City<span className="danger">*</span>
                          </label>
                        </div>
                        <div className="mb-10px">
                          <input
                            {...register('city', { required })}
                            type="text"
                            name="city"
                            className={errors.city ? 'input-error w-100px' : 'w-100px'}
                            placeholder="Enter City here"
                          />
                        </div>
                        {errors.city && <span className="danger">{errors.city.message}</span>}
                      </div>


                    </div>
                  </div>
                  <div>
                    <div className="row">
                      
                      <div className="col-6 form-group">
                        <div className="mb-10px">
                          <label className="div-label font-bold">
                            State<span className="danger">*</span>
                          </label>
                        </div>
                        <div className="mb-10px">
                          <input
                            {...register('state', { required })}
                            type="text"
                            name="state"
                            className={errors.state ? 'input-error w-100px' : 'w-100px'}
                            placeholder="Enter State here"
                          />
                        </div>
                        {errors.state && <span className="danger">{errors.state.message}</span>}
                      </div>
                      <div className="col-6 form-group">
                        <div className="mb-10px">
                          <label className="div-label font-bold">
                            Zip<span className="danger">*</span>
                          </label>
                        </div>
                        <div className="mb-10px">
                          <input
                            {...register('zip', { required })}
                            type="text"
                            name="zip"
                            className={errors.zip ? 'input-error w-100px' : 'w-100px'}
                            placeholder="Enter Zip here"
                          />
                        </div>
                        {errors.zip && <span className="danger">{errors.zip.message}</span>}
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row">
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">
                        Phone Number<span className="danger">*</span>
                      </label>
                    </div>
                    <div className="mb-10px">
                      <PhoneInput
                        inputProps={{
                          name: 'mobileNumberPhoneInput',
                          required: true,
                          autoFocus: false,
                        }}
                        // containerClass={errors.mobileNumber?"input-error w-100px":"w-100px" }
                        containerClass="w-100px"
                        inputClass="tel-input"
                        onlyCountries={['us']}
                        country={'us'}
                        value={selectedMobile}
                        onChange={(code) => updateFieldMobile(code)}
                      />
                    </div>
                    {errors.mobileNumber && (
                      <span className="danger">{errors.mobileNumber.message}</span>
                    )}
                  </div>
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Alternate Phone Number</label>
                    </div>
                    <div className="mb-10px">
                      <PhoneInput
                        inputProps={{
                          name: 'alternativeMobileNumberPhoneInput',
                          required: false,
                          autoFocus: false,
                        }}
                        inputClass="tel-input"
                        onlyCountries={['us']}
                        country={'us'}
                        value={selectedAlternative}
                        onChange={(code) => updateFieldAltMobile(code)}
                      />
                      {/* {errors.alternativeMobileNumber && (
                        <span className="danger">{errors.alternativeMobileNumber.message}</span>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">
                        Communication Email<span className="danger">*</span>
                      </label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('communicationEmail', {
                          required,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: MSG_ENTER_VALID_EMAIL,
                          },
                        })}
                        type="email"
                        name="communicationEmail"
                        className={errors.communicationEmail ? 'input-error w-100px' : 'w-100px'}
                        placeholder="Enter email"
                      />
                    </div>
                    {errors.communicationEmail && (
                      <span className="danger">{errors.communicationEmail.message}</span>
                    )}
                  </div>
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Payout</label>
                    </div>
                    <div className="mb-10px">
                      <select
                        id="payout"
                        className={'w-100px'}
                        defaultValue={PAYOUT_STAFF}
                        {...register('payout', {
                          required: 'Required.',
                          onChange: (e) => checkMandatory(e, 'payout'),
                        })}
                      >
                        <option value={PAYOUT_STAFF}>{LBL_PAYOUT_STAFF}</option>
                        <option value={PAYOUT_HOTEL}>{LBL_PAYOUT_HOTEL} </option>
                        {/* <option value={PAYOUT_TOVA}>{LBL_PAYOUT_TOVA}</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row hide">
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">
                        Ask for additional processing fee
                      </label>
                    </div>
                    <div className="mb-10px">
                      {/* <select name="tipIncludeProcessFee" id="tipIncludeProcessFee" className='w-100px' value={active} defaultValue={"Yes"} onChange={(e: any) => setActive(e.target.value)}>  */}
                      <select
                        id="tipIncludeProcessFee"
                        className="w-100px"
                        defaultValue={'false'}
                        {...register('tipIncludeProcessFee', { required })}
                      >
                        <option value={'false'}>No</option>
                        <option value={'true'}>Yes </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-3 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Room Label</label>
                    </div>
                    <div className="mb-10px">
                      <select
                        id="roomLabel"
                        className="w-100px"
                        defaultValue={ROOM_NUMBER_VALUE}
                        {...register('roomLabel', { required })}
                      >
                        <option value={ROOM_NUMBER_VALUE}>{LBL_ROOM_NUMBER}</option>
                        <option value={ROOM_NAME_VALUE}>{LBL_ROOM_NAME} </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-3 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Mandatory</label>
                    </div>
                    <div className="mb-10px">
                      <select
                        id="roomLabelMandatory"
                        className="w-100px"
                        defaultValue={'false'}
                        {...register('roomLabelMandatory', { required })}
                      >
                        <option value={'false'}>No</option>
                        <option value={'true'}>Yes </option>
                      </select>
                    </div>
                  </div>
                </div>

                {props.match.params.id != undefined && (
                  <>
                    <div className="mb-10px">
                      <label className="div-label font-bold">{LBL_MAID + ' invite link'}</label>
                    </div>
                    <div className="row top">
                      <div className="col-10 mt-10 form-group">
                        <div className="blue-backGround pointer-cursor" onClick={copyLink}>
                          <p className="mb-0">
                            Click here to copy the link for inviting {LBL_MAIDS} to this hotel.
                          </p>
                          {/* <p className="mb-0">{APP_NAME} App</p> */}
                        </div>
                        {/* <div className="height-between"></div> */}
                      </div>
                      <div className="col-10 form-group">
                        <div className="downoad-qr">
                          <div className="mb-10px">
                            <label className="div-label font-bold">QR Code for Tipping</label>
                          </div>
                        </div>

                        <div className="">
                          <div className="">
                            <div className="image-box-qrcode">
                              {hotelForm != null &&
                                hotelForm != undefined &&
                                hotelForm.hotelCodeId != undefined && (
                                  <QRCode
                                    value={`${tip_server_url_var}/tip/${hotelForm.hotelCodeId}/`}
                                  />
                                )}
                            </div>
                          </div>
                          <div className="mb-10px ">
                            {hotelForm != null &&
                              hotelForm != undefined &&
                              hotelForm.hotelQrUrl != undefined && (
                                <>
                                  <a
                                    href={hotelForm.hotelQrUrl}
                                    download={`${hotelForm.hotelCodeId}.pdf`}
                                  >
                                    <button
                                      type="button"
                                      className="primary text-white mt-10px ml-40px"
                                    >
                                      Download QR code
                                    </button>
                                  </a>
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* <div>
                  <div>
                    <label className='div-label font-bold mb-2'>Hotel Logo</label>
                  </div>
                  <DropzoneArea
                    dropzoneText="Click or drag a file to this area to upload"
                    filesLimit={1}
                    onChange={(files) => console.log('Files:', files)}
                  />
                </div> */}

                <ImageBox
                  contentTitle={'Hotel Logo'}
                  id={'Hotel Logo'}
                  label={''}
                  url={''}
                  defaultImage={selectedImage}
                  onUpload={onImageUploadSubmitted}
                />

                {/* PRIMARY-USER-START */}
                <div className="row mt-20px">
                  <div className="col-8 ">
                    <div className="">
                      <h5 className="div-label font-bold">
                        <div className="heading">Primary User for Hotel Login (Optional)</div>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="p-10">
                  <div className="row">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Username (Email)</th>
                          <th>Phone</th>
                          <th>Position</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <input
                              {...register(`first_name`)}
                              type="text"
                              name={`first_name`}
                              className="w-100px"
                              placeholder="Enter First Name here"
                            />

                            {errors.first_name && <span className="danger">Required</span>}
                          </td>
                          <td>
                            <input
                              {...register(`last_name`)}
                              type="text"
                              name={`last_name`}
                              className="w-100px"
                              placeholder="Enter Last Name here"
                            />
                            {errors.last_name && <span className="danger">Required</span>}
                          </td>

                          <td className="display-column">
                            <input
                              {...register(`username`, {
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                  message: MSG_ENTER_VALID_EMAIL,
                                },
                              })}
                              type="text"
                              name={`username`}
                              className="w-100px"
                              placeholder="Enter Email here"
                            />
                            {errors.username && (
                              <span className="danger">Enter a valid e-mail address</span>
                            )}
                          </td>
                          <td>
                            <PhoneInput
                              inputProps={{
                                name: `primaryMobileNumber`,
                              }}
                              inputClass="tel-input"
                              onlyCountries={['us']}
                              country={'us'}
                              value={primaryMobileNumber}
                              onChange={(e) => setPrimaryMobileNumber(e)}
                            />
                            <input
                              value={primaryMobileNumber}
                              onChange={(e) => setPrimaryMobileNumber(e.target.value)}
                              type="text"
                              name={`primaryMobileNumber`}
                              className="w-100px hide"
                              placeholder="Enter Mobile here"
                            />
                          </td>
                          <td>
                            <input
                              {...register(`position` as const)}
                              type="text"
                              name={`position`}
                              className="w-100px"
                              placeholder="Enter Position here"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* PRIMARY-USER-END */}

                {/* ADDITIONAL-CONTACTS-START */}
                <div className="row">
                  {payout && (
                    <>
                      <div className="row mt-20px">
                        <div className="col-3 ">
                          <div className="">
                            <h5 className="div-label font-bold">
                              <div className="heading">Bank Details</div>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <table className="table mx-2">
                          <thead>
                            <tr>
                              <th>Bank Account Payee Name</th>
                              <th>Country</th>
                              <th>Bank Account number</th>
                              <th>Bank Name</th>
                              <th>ABA/ ACH Routing Code</th>
                              <th>Legal Company Name</th>
                              <th>Company Tax ID</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>
                                <input
                                  {...register('accountHolderName', { required: 'Required.' })}
                                  name="accountHolderName"
                                  placeholder="Account Holder Name"
                                  type="text"
                                  className={'w-100px'}
                                ></input>
                                {errors.accountHolderName && (
                                  <span className="danger">{errors.accountHolderName.message}</span>
                                )}
                              </td>
                              <td>
                                <input
                                  {...register('country', { required: 'Required.' })}
                                  name="country"
                                  value={DEFAULT_COUNTRY}
                                  type="text"
                                  className={'w-100px'}
                                  readOnly
                                ></input>
                                {errors.country && (
                                  <span className="danger">{errors.country.message}</span>
                                )}
                              </td>
                              <td>
                                <input
                                  {...register('accountNumber', { required: 'Required.' })}
                                  name="accountNumber"
                                  type="text"
                                  placeholder="Account Number"
                                  className={'w-100px'}
                                  maxLength={35}
                                ></input>
                                {errors.accountNumber && (
                                  <span className="danger">{errors.accountNumber.message}</span>
                                )}
                              </td>
                              <td>
                                <input
                                  {...register('bankName', { required: 'Required.' })}
                                  name="bankName"
                                  placeholder="Account Number"
                                  type="text"
                                  className={'w-100px'}
                                ></input>
                                {errors.bankName && (
                                  <span className="danger">{errors.bankName.message}</span>
                                )}
                              </td>
                              <td>
                                <input
                                  {...register('routingNumber', { required: 'Required.' })}
                                  name="routingNumber"
                                  type="text"
                                  placeholder="Routing Number"
                                  className={'w-100px'}
                                  maxLength={9}
                                ></input>
                                {errors.routingNumber && (
                                  <span className="danger">{errors.routingNumber.message}</span>
                                )}
                              </td>
                              <td> 
                              <input
                                {...register('companyName', { required: 'Required.' })}
                                name="companyName"
                                type="text"
                                className={'w-100px'}
                              ></input>
                              {errors.companyName && (
                                <span className="danger">{errors.companyName.message}</span>
                              )}
                            </td>
                            <td>
                              <input
                                {...register('companyTaxId')}
                                name="companyTaxId"
                                type="text"
                                className={'w-100px'}
                                maxLength={9}
                              ></input>
                              {errors.companyTaxId && (
                                <span className="danger">{errors.companyTaxId.message}</span>
                              )}
                            </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="row mb-4 gap-3">
                        <div className="col-4">
                          <div className="mt-20px">
                            <h5 className="div-label font-bold">
                              <div className="heading">ID Proof Document Type</div>
                            </h5>
                          </div>
                          <div className="mb-10px ">
                            <select
                              disabled={checkDisable}
                              id="documentTypeId"
                              className={'w-100px'}
                              {...register('documentTypeId')}
                            >
                              <option value={0}>Select</option>
                              <option value={COMPANY_DOC_TYPE_IRS_LETTER_147C_ID}>
                                {COMPANY_DOC_TYPE_IRS_LETTER_147C}
                              </option>
                              <option value={COMPANY_DOC_TYPE_IRS_SS4_LETTER_ID}>
                                {COMPANY_DOC_TYPE_IRS_SS4_LETTER}{' '}
                              </option>
                            </select>
                          </div>
                          {errors.documentTypeId && (
                            <span className="danger">{errors.documentTypeId.message}</span>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4 ">
                        <div className="mt-20px">
                          <h5 className="div-label font-bold">
                            <div className="heading">Upload Document Image</div>
                          </h5>
                          <p className="div-label-small mb-0">{MSG_STAFF_ID_PROOF_TYPE_ERROR}</p>
                        </div>
                        <div className="mb-10px">
                          <ImageBox
                            label={''}
                            id={"verificationDocumentImg"}
                            url={''}
                            defaultImage={verificationDocumentImg}
                            onUpload={fileValidation}
                            checkDisable={checkDisable}
                          />
                        </div>
                        {errors.verificationDocument && (
                          <span className="danger">{errors.verificationDocument.message}</span>
                        )}
                      </div>
                    </>
                  )}

                  <div className="row">
                    <div className="col-3 ">
                      <div className="mb-10px">
                        <h5 className="div-label font-bold">
                          <div className="heading">Additional Contacts</div>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="margin-left-10px p-0">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Position</th>
                            <th>Address Line 1</th>
                            <th>Address Line 2</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Zip</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        {fields.map((field, index) => (
                          <>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    key={field.id}
                                    {...register(`contacts.${index}.firstName` as const)}
                                    type="text"
                                    name={`contacts.${index}.firstName`}
                                    className="w-100px"
                                    placeholder="Enter First Name here"
                                  />
                                </td>
                                <td>
                                  <input
                                    key={field.id}
                                    {...register(`contacts.${index}.lastName` as const)}
                                    type="text"
                                    name={`contacts.${index}.lastName`}
                                    className="w-100px"
                                    placeholder="Enter Last Name here"
                                  />
                                </td>
                                <td>
                                  <input
                                    key={field.id}
                                    {...register(`contacts.${index}.position` as const)}
                                    type="text"
                                    name={`contacts.${index}.position`}
                                    className="w-100px"
                                    placeholder="Enter Position here"
                                  />
                                </td>
                                <td>
                                  <input
                                    key={field.id}
                                    {...register(`contacts.${index}.addressLineOne` as const)}
                                    type="text"
                                    name={`contacts.${index}.addressLineOne`}
                                    className="w-100px"
                                    placeholder="Enter address line 1 here"
                                  />
                                </td>
                                <td>
                                  <input
                                    key={field.id}
                                    {...register(`contacts.${index}.addressLineTwo` as const)}
                                    type="text"
                                    name={`contacts.${index}.addressLineTwo`}
                                    className="w-100px"
                                    placeholder="Enter address line 2 here"
                                  />
                                </td>
                                <td>
                                  <input
                                    key={field.id}
                                    {...register(`contacts.${index}.city` as const)}
                                    type="text"
                                    name={`contacts.${index}.city`}
                                    className="w-100px"
                                    placeholder="Enter City here"
                                  />
                                </td>
                                <td>
                                  <input
                                    key={field.id}
                                    {...register(`contacts.${index}.state` as const)}
                                    type="text"
                                    name={`contacts.${index}.state`}
                                    className="w-100px"
                                    placeholder="Enter State here"
                                  />
                                </td>
                                <td>
                                  <input
                                    key={field.id}
                                    {...register(`contacts.${index}.zip` as const)}
                                    type="text"
                                    name={`contacts.${index}.zip`}
                                    className="w-100px"
                                    placeholder="Enter Zip here"
                                  />
                                </td>
                                <td>
                                  <PhoneInput
                                    inputProps={{
                                      name: `contacts.${index}.mobileNumberPhoneField`,
                                      required: true,
                                      autoFocus: false,
                                    }}
                                    inputClass="tel-input"
                                    onlyCountries={['us']}
                                    country={'us'}
                                    value={getIndexMobilenumber(index)}
                                    onChange={(code) => updateIndexMobileField(index, code)}
                                  />
                                  <input
                                    key={field.id}
                                    {...register(`contacts.${index}.mobileNumber` as const)}
                                    type="text"
                                    name={`contacts.${index}.mobileNumber`}
                                    className="w-100px hide"
                                    placeholder="Enter Mobile here"
                                  />
                                </td>
                                <td>
                                  <input
                                    key={field.id}
                                    {...register(`contacts.${index}.email` as const, {
                                      pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: MSG_ENTER_VALID_EMAIL,
                                      },
                                    })}
                                    type="text"
                                    name={`contacts.${index}.email`}
                                    className="w-100px"
                                    placeholder="Enter Email here"
                                  />
                                  {errors.contacts?.[index]?.email && (
                                    <span className="danger">Enter a valid e-mail address</span>
                                  )}
                                </td>
                                <td>
                                  <span className="btn-flex-row">
                                    <button
                                      type="button"
                                      className=""
                                      onClick={() => decContacts(index)}
                                    >
                                      <span className="font-bold danger"> x </span>
                                    </button>
                                    {index + 1 === fields.length && index < 3 && (
                                      <button
                                        type="button"
                                        className="primary ml-10px"
                                        onClick={incContacts}
                                      >
                                        <span className="font-bold"> + </span>
                                      </button>
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ))}
                      </table>
                    </div>
                  </div>
                  {/* ADDITIONAL-CONTACTS-END */}

                  <div className="row hide">
                    <div className="col-12 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">
                          {LBL_MAID + ' Welcome Message (SMS)'}
                        </label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('welcomeMessage', { maxLength: SMS_MAX_LENGTH })}
                          type="text"
                          name="welcomeMessage"
                          style={{ width: '100%', maxWidth: 1000 }}
                          className="maxw1000"
                          placeholder="Welcome Message"
                          maxLength={SMS_MAX_LENGTH}
                        />
                      </div>
                      {errors.welcomeMessage && (
                        <p className="danger">{errors.welcomeMessage.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Google review link</label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('googleReviewLink')}
                          type="text"
                          name="googleReviewLink"
                          style={{ width: '100%', maxWidth: 1000 }}
                          className="maxw1000"
                          placeholder="Google review link"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 form-group">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tripadvisor review link</label>
                      </div>
                      <div className="mb-10px">
                        <input
                          {...register('tripAdvisorLink')}
                          type="text"
                          name="tripAdvisorLink"
                          style={{ width: '100%', maxWidth: 1000 }}
                          className="maxw1000"
                          placeholder="Tripadvisor review link"
                        />
                      </div>
                    </div>
                  </div>

                  {/* TIPS-START */}
                  <div className="row hide">
                    <div className="col-1 form-group min-width-125">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tip 1 </label>
                      </div>
                      <div className="mb-10px">
                        <div className="input-icons">
                          <i className="fa fa-dollar hotel_icon"></i>
                          <input
                            {...register('tip_1', {
                              min: 0,
                              max: TIP_AMT_MAX_VALUE,
                              pattern: {
                                value: /^[.0-9]*$/,
                                message: MSG_INVALID_AMOUNT,
                              },
                            })}
                            type="number"
                            name="tip_1"
                            className="w-100px tipinp"
                            placeholder="Tip 1"
                            min={0}
                            max={TIP_AMT_MAX_VALUE}
                          />
                        </div>
                        {errors.tip_1 && <span className="danger">{errors.tip_1.message}</span>}
                      </div>
                    </div>

                    <div className="col-1 form-group ml-10px min-width-125">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tip 2 </label>
                      </div>
                      <div className="mb-10px">
                        <div className="input-icons">
                          <i className="fa fa-dollar hotel_icon"></i>
                          <input
                            {...register('tip_2', {
                              min: 0,
                              max: TIP_AMT_MAX_VALUE,
                              pattern: {
                                value: /^[.0-9]*$/,
                                message: MSG_INVALID_AMOUNT,
                              },
                            })}
                            type="number"
                            name="tip_2"
                            className="w-100px tipinp"
                            placeholder="Tip 2"
                            min={0}
                            max={TIP_AMT_MAX_VALUE}
                          />
                        </div>
                        {errors.tip_2 && <span className="danger">{errors.tip_2.message}</span>}
                      </div>
                    </div>

                    <div className="col-1 form-group ml-10px min-width-125">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tip 3 </label>
                      </div>
                      <div className="mb-10px">
                        <div className="input-icons">
                          <i className="fa fa-dollar hotel_icon"></i>
                          <input
                            {...register('tip_3', {
                              min: 0,
                              max: TIP_AMT_MAX_VALUE,
                              pattern: {
                                value: /^[.0-9]*$/,
                                message: MSG_INVALID_AMOUNT,
                              },
                            })}
                            type="number"
                            name="tip_3"
                            className="w-100px tipinp"
                            placeholder="Tip 3"
                            min={0}
                            max={TIP_AMT_MAX_VALUE}
                          />
                        </div>
                        {errors.tip_3 && <span className="danger">{errors.tip_3.message}</span>}
                      </div>
                    </div>

                    <div className="col-1 form-group ml-10px min-width-125">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tip 4 </label>
                      </div>
                      <div className="mb-10px">
                        <div className="input-icons">
                          <i className="fa fa-dollar hotel_icon"></i>
                          <input
                            {...register('tip_4', {
                              min: 0,
                              max: TIP_AMT_MAX_VALUE,
                              pattern: {
                                value: /^[.0-9]*$/,
                                message: MSG_INVALID_AMOUNT,
                              },
                            })}
                            type="number"
                            name="tip_4"
                            className="w-100px tipinp"
                            placeholder="Tip 4"
                            min={0}
                            max={TIP_AMT_MAX_VALUE}
                          />
                        </div>
                        {errors.tip_4 && <span className="danger">{errors.tip_4.message}</span>}
                      </div>
                    </div>

                    <div className="col-1 form-group ml-10px min-width-125">
                      <div className="mb-10px">
                        <label className="div-label font-bold">Tip 5 </label>
                      </div>
                      <div className="mb-10px">
                        <div className="input-icons">
                          <i className="fa fa-dollar hotel_icon"></i>
                          <input
                            {...register('tip_5', {
                              min: 0,
                              max: TIP_AMT_MAX_VALUE,
                              pattern: {
                                value: /^[.0-9]*$/,
                                message: MSG_INVALID_AMOUNT,
                              },
                            })}
                            type="number"
                            name="tip_5"
                            className="w-100px tipinp"
                            placeholder="Tip 5"
                            min={0}
                            max={TIP_AMT_MAX_VALUE}
                          />
                        </div>
                        {errors.tip_5 && <span className="danger">{errors.tip_5.message}</span>}
                      </div>
                    </div>

                    <div className="mb-10px">
                      <h5 className="div-label font-bold">
                        <div className="heading">Tipping Facts</div>
                      </h5>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-6 form-group mb-43">
                        <div className="mb-10px">
                          <label className="div-label font-bold">Tipping Fact Header 1</label>
                        </div>
                        <div className="mb-10px">
                          <input
                            {...register('tippingFactHeader_1', {
                              maxLength: TIP_FACT_HEADER_MAX_LENGTH,
                            })}
                            type="text"
                            name="tippingFactHeader_1"
                            className="w-100px"
                            placeholder="Tipping Fact Header 1"
                            maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                          />
                        </div>
                        {errors.tippingFactHeader_1 && (
                          <span className="danger">{errors.tippingFactHeader_1.message}</span>
                        )}
                      </div>

                      <div className="col-6 form-group">
                        <div className="mb-10px">
                          <label className="div-label font-bold">Tipping Fact body 1</label>
                        </div>
                        <div className="mb-10px">
                          <textarea
                            {...register('tippingFactBody_1', {
                              maxLength: TIP_FACT_BODY_MAX_LENGTH,
                            })}
                            rows={2}
                            name="tippingFactBody_1"
                            className="w-100px"
                            placeholder="Tipping Fact Header 1"
                            maxLength={TIP_FACT_BODY_MAX_LENGTH}
                          />
                        </div>
                        {errors.tippingFactBody_1 && (
                          <span className="danger">{errors.tippingFactBody_1.message}</span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 form-group  mb-43">
                        <div className="mb-10px">
                          <label className="div-label font-bold">Tipping Fact Header 2</label>
                        </div>
                        <div className="mb-10px">
                          <input
                            {...register('tippingFactHeader_2', {
                              maxLength: TIP_FACT_HEADER_MAX_LENGTH,
                            })}
                            type="text"
                            name="tippingFactHeader_2"
                            className="w-100px"
                            placeholder="Tipping Fact Header 2"
                            maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                          />
                        </div>
                        {errors.tippingFactHeader_2 && (
                          <span className="danger">{errors.tippingFactHeader_2.message}</span>
                        )}
                      </div>
                      <div className="col-6 form-group">
                        <div className="mb-10px">
                          <label className="div-label font-bold">Tipping Fact body 2</label>
                        </div>
                        <div className="mb-10px">
                          <textarea
                            {...register('tippingFactBody_2', {
                              maxLength: TIP_FACT_BODY_MAX_LENGTH,
                            })}
                            rows={2}
                            name="tippingFactBody_2"
                            className="w-100px"
                            placeholder="Tipping Fact body 2"
                            maxLength={TIP_FACT_BODY_MAX_LENGTH}
                          />
                        </div>
                        {errors.tippingFactBody_2 && (
                          <span className="danger">{errors.tippingFactBody_2.message}</span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 form-group  mb-43">
                        <div className="mb-10px">
                          <label className="div-label font-bold">Tipping Fact Header 3</label>
                        </div>
                        <div className="mb-10px">
                          <input
                            {...register('tippingFactHeader_3', {
                              maxLength: TIP_FACT_HEADER_MAX_LENGTH,
                            })}
                            type="text"
                            name="tippingFactHeader_3"
                            className="w-100px"
                            placeholder="Tipping Fact Header 3"
                            maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                          />
                        </div>
                        {errors.tippingFactHeader_3 && (
                          <span className="danger">{errors.tippingFactHeader_3.message}</span>
                        )}
                      </div>
                      <div className="col-6 form-group">
                        <div className="mb-10px">
                          <label className="div-label font-bold">Tipping Fact body 3</label>
                        </div>
                        <div className="mb-10px">
                          <textarea
                            {...register('tippingFactBody_3', {
                              maxLength: TIP_FACT_BODY_MAX_LENGTH,
                            })}
                            rows={2}
                            name="tippingFactBody_3"
                            className="w-100px"
                            placeholder="Tipping Fact body 3"
                            maxLength={TIP_FACT_BODY_MAX_LENGTH}
                          />
                        </div>
                        {errors.tippingFactBody_3 && (
                          <span className="danger">{errors.tippingFactBody_3.message}</span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 form-group  mb-43">
                        <div className="mb-10px">
                          <label className="div-label font-bold">Tipping Fact Header 4</label>
                        </div>
                        <div className="mb-10px">
                          <input
                            {...register('tippingFactHeader_4', {
                              maxLength: TIP_FACT_HEADER_MAX_LENGTH,
                            })}
                            type="text"
                            name="tippingFactHeader_4"
                            className="w-100px"
                            placeholder="Tipping Fact Header 4"
                            maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                          />
                        </div>
                        {errors.tippingFactHeader_4 && (
                          <span className="danger">{errors.tippingFactHeader_4.message}</span>
                        )}
                      </div>
                      <div className="col-6 form-group">
                        <div className="mb-10px">
                          <label className="div-label font-bold">Tipping Fact body 4</label>
                        </div>
                        <div className="mb-10px">
                          <textarea
                            {...register('tippingFactBody_4', {
                              maxLength: TIP_FACT_BODY_MAX_LENGTH,
                            })}
                            rows={2}
                            name="tippingFactBody_4"
                            className="w-100px"
                            placeholder="Tipping Fact body 4"
                            maxLength={TIP_FACT_BODY_MAX_LENGTH}
                          />
                        </div>
                        {errors.tippingFactBody_4 && (
                          <span className="danger">{errors.tippingFactBody_4.message}</span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 form-group mb-43">
                        <div className="mb-10px">
                          <label className="div-label font-bold">Tipping Fact Header 5</label>
                        </div>
                        <div className="mb-10px">
                          <input
                            {...register('tippingFactHeader_5', {
                              maxLength: TIP_FACT_HEADER_MAX_LENGTH,
                            })}
                            type="text"
                            name="tippingFactHeader_5"
                            className="w-100px"
                            placeholder="Tipping Fact Header 5"
                            maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                          />
                        </div>
                        {errors.tippingFactHeader_5 && (
                          <span className="danger">{errors.tippingFactHeader_5.message}</span>
                        )}
                      </div>
                      <div className="col-6 form-group">
                        <div className="mb-10px">
                          <label className="div-label font-bold">Tipping Fact body 5</label>
                        </div>
                        <div className="mb-10px">
                          <textarea
                            {...register('tippingFactBody_5', {
                              maxLength: TIP_FACT_BODY_MAX_LENGTH,
                            })}
                            rows={2}
                            name="tippingFactBody_5"
                            className="w-100px"
                            placeholder="Tipping Fact body 5"
                            maxLength={TIP_FACT_BODY_MAX_LENGTH}
                          />
                        </div>
                        {errors.tippingFactBody_5 && (
                          <span className="danger">{errors.tippingFactBody_5.message}</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      className={
                        captchaVerified === true && captchaValue && captchaValue.length > 0
                          ? 'div-captcha'
                          : saveClickCount > 0
                          ? 'div-captcha red-border'
                          : 'div-captcha'
                      }
                    >
                      <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_KEY} onChange={onCaptchaChange} />
                    </div>

                    <div className="mt-3 mb-30px div-right">
                      <button
                        type="button"
                        className="primary div-right margin-top18"
                        onClick={onSaveSubmitted}
                      >
                        Save Details
                      </button>
                    </div>

                    <div className="mt-3">
                      {hotelForm != null &&
                        hotelForm != undefined &&
                        hotelForm.isActive != undefined &&
                        hotelForm.isActive == 'YES' && (
                          <button type="button" className="bg-danger " onClick={onDeactivateHotel}>
                            Deactivate Hotel
                          </button>
                        )}
                      {hotelForm != null &&
                        hotelForm != undefined &&
                        hotelForm.isActive != undefined &&
                        hotelForm.isActive == 'NO' && (
                          <button
                            type="button"
                            className="bg-success text-white"
                            onClick={onActivateHotel}
                          >
                            Activate Hotel
                          </button>
                        )}
                    </div>

                    <label>
                      {hotelForm != null &&
                        hotelForm != undefined &&
                        hotelForm.creationDate != undefined && (
                          <>Created on {dayjs(hotelForm.creationDate).format('MMMM DD, YYYY')}</>
                        )}
                    </label>
                  </div>
                </div>
                {/* TIPS-END */}
              </div>
              <div className="col-1">&nbsp;</div>

              {/* <div className="col-6 form-group">&nbsp;</div> */}

              {/* <div className="col-4 v-top">
                <ImageBox
                  contentTitle={'Hotel Logo'}
                  label={''}
                  url={''}
                  defaultImage={selectedImage}
                  onUpload={onImageUploadSubmitted}
                />
                <div className='small text-center min-width-400'>(Square image preferred)</div>
              </div> */}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
