import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import ReactLoading from 'react-loading';

import { changeSearch, selectHotelListSearchSlice } from './hotelListSearchSlice';
import {
  setCurrentPage,
  setTotalPage,
  setStartLoading,
  setStopLoading,
  selectHotelListPaginationSlice,
} from './hotelListPaginationSlice';
import SearchBar from '../../components/SearchBar';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { selectHotelListDataSlice, setDataLoading } from './hotelListDataSlice';
import { IHotel, IHotelFilterRequestData, IHotelListRequestData } from '../../models/hotelsInterfaces';
import {
  fetchHotels,
  importHotels,
  exportHotels,
  getHotelSample,
  fetchFilterHotels,
} from '../../services/hotelService';
import { ITableHeader } from '../../models/genericInterfaces';
import { hotelListFields } from '../../config/tables';
import { useHistory } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import logo from '../../assets/img/csv.png';
import logo1 from '../../assets/img/csv-black.png';
import { notification_shown_time_success, notification_shown_time_failed, APP_NAME } from '../../config/env';
import { MSG_FILEIMPORT_INVALID_HEADER, MSG_HOTEL_IMPORT_SUCCESS, MSG_NO_RECORDS_IMPORTED,  MSG_HOTEL_IMPORT_FAILED, MSG_INVALID_INPUT_FILE } from '../../config/messages';
import { LBL_RESET_FILTER } from '../../config/common_labels';

export const HotelList = () => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [listFetchHotelPromise, setListFetchHotelPromise] = useState<Array<any>>([]);
  const [displaySearch, setDisplaySearch] = useState<string>('');
  const { searchValue } = useAppSelector(selectHotelListSearchSlice);
  const { currentPage } = useAppSelector(selectHotelListPaginationSlice);
  const { hotels, totalSize, loading } = useAppSelector(selectHotelListDataSlice);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [applySearch, setApplySearch] = useState(0);
  const [filterSideBar, setFilterSideBar] = useState(false);
  const [address, setAddress] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [active, setActive] = useState("Yes");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [applyFilter, setApplyFilter] = useState(0);
  const [fetchWithFilter, setFetchWithFilter] = useState(false);
  const [sortField, setSortField] = useState('hotel_name');
  const [logoState, setLogoState] = useState(true);

  const [importLoading, setImportLoading] = useState('');

  const handleFilterHotel = () => {
    setFilterSideBar(!filterSideBar)
  }

  const closeFilterDetails = () => {
    setFilterSideBar(false)
  }

  const handleApplyFilter = (e : any) => {
    e.preventDefault(); 
    setFetchWithFilter(true);
    setApplyFilter((applyFilter + 1));
    acceptPage(1);
    closeFilterDetails()
    setDisplaySearch(filterSearch)
  }

  const handleRestFilter = () => {
    setAddress("")
    setFilterSearch("")
    setActive("Yes")
    setfromDate("")
    setToDate("")
    setFetchWithFilter(false)
    setApplyFilter((applyFilter + 1))
    setDisplaySearch('')
  }

  const onNavigate = (value: string): void => {
    history.push(value);
  };

  const fetchall = (): void => {
    if (fetchWithFilter) {
      dispatch(setDataLoading());
      const req: IHotelFilterRequestData = {
        page: currentPage,
        search: filterSearch.trim(),
        address: address,
        active: active,
        added_from_date: fromDate,
        added_to_date: toDate,
        sort: sortField
      };
      const promise = dispatch(fetchFilterHotels(req));
      setListFetchHotelPromise([promise]);

    } else if (sortField != "") {
      const req: IHotelFilterRequestData = {
        page: currentPage,
        search: filterSearch.trim(),
        address: address,
        active: active,
        added_from_date: fromDate,
        added_to_date: toDate,
        sort: sortField
      };
      const promise = dispatch(fetchFilterHotels(req));
      setListFetchHotelPromise([promise]);
    }

    else {
      let sVal = displaySearch;
      if (displaySearch.length > 2) {
        dispatch(setDataLoading());
        changeSearch(displaySearch);
      }
      sVal = displaySearch;
      listFetchHotelPromise.forEach((p) => {
        p.abort();
      });
      const req: IHotelListRequestData = {
        page: currentPage,
        search: sVal.trim(), 
        active: active,
        address: address,
        added_from_date: fromDate,
        added_to_date: toDate,
        sort: sortField
      };
      const promise = dispatch(fetchHotels(req));
      setListFetchHotelPromise([promise]);
    }
  };

  React.useEffect(() => {
    fetchall();    
  }, [dispatch, applySearch, currentPage, applyFilter, sortField]); // displaySearch

  const onSearchButton = (e:any) => {
    e.preventDefault(); 
    setFetchWithFilter(true)
    acceptPage(1);
    setApplySearch(applySearch + 1);
    setFilterSearch(displaySearch)
  };

  const acceptSearch = (e: any): void => {
    setDisplaySearch(e.target.value as string);
  };

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };

  const handleSort = (sortField: string) => {
    console.log(sortField); 
    setSortField(sortField=='hotel_code'?'hotel_code_id':sortField=='-hotel_code'?'-hotel_code_id':sortField); 
  };

  const onImport = (): void => {
    inputFile?.current?.click();
  };

  const onExport = (): void => {
    const req: IHotelListRequestData = {
      page: currentPage,
      search: displaySearch,
      active: active,
      address: address,
      added_from_date: fromDate,
      added_to_date: toDate,
      sort: sortField
    };
    exportHotels(req);
  };

  const postHotels = (e: any): void => {
    (async () => {
      setImportLoading('loading'); 
      const uploadStatus = await importHotels(e.target.files[0]);

      if (uploadStatus?.status === 200) {
        setImportLoading(''); 
        fetchall();
        let msgTitle = APP_NAME;
        let msgText = MSG_HOTEL_IMPORT_SUCCESS;
        let dismisObj = {
          duration: notification_shown_time_success,
          onScreen: true,
          click: true,
          showIcon: false
        }
        let notImpCount = uploadStatus?.data?.notImported ? uploadStatus.data.notImported.length : 0;
        let totCount = uploadStatus?.data?.totalCount ? uploadStatus.data.totalCount : 0;
        let impCount = totCount - notImpCount;
        msgText = MSG_HOTEL_IMPORT_SUCCESS+' '+ impCount + '/' + totCount + ' records imported. ';

        if (impCount > 0 && totCount === impCount) {
          Store.addNotification({
            title: msgTitle,
            message: msgText,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: notification_shown_time_success,
              onScreen: true,
              click: false,
              showIcon: false
            }
          });
        }
        else if (totCount === notImpCount) {
          msgText = MSG_NO_RECORDS_IMPORTED;
          Store.addNotification({
            title: msgTitle,
            message: msgText,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: notification_shown_time_success,
              onScreen: true,
              click: false,
              showIcon: false
            }
          });
        }
        else if (notImpCount > 0) {
          let rowText = uploadStatus.data.notImported.length === 1 ? ' Row ' : ' Rows ';
          msgText = impCount + '/' + totCount + ' records imported. ' + rowText + uploadStatus.data.notImported.join(',') + ' not imported.';
          Store.addNotification({
            title: msgTitle,
            message: msgText,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 0,
              onScreen: true,
              click: true,
              showIcon: true
            },
          });
        }
      }
      else {
        setImportLoading(''); 
        let msgTitle = APP_NAME;
        let msgText = MSG_HOTEL_IMPORT_FAILED; 
        if (uploadStatus.response.status === 400) {
          msgTitle = APP_NAME;
          msgText = MSG_INVALID_INPUT_FILE;
          if (uploadStatus?.response?.data?.message && uploadStatus?.response?.data.message === 'invalid_headings') {
            msgText = MSG_FILEIMPORT_INVALID_HEADER;
          }

          Store.addNotification({
            title: msgTitle,
            message: msgText,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 0,
              onScreen: true,
              click: true,
              showIcon: true
            }
          });
        }
      }
      inputFile!.current!.value = '';
      setImportLoading(''); 
    })();
  };

  const onClickable = (id: number, name: string): void => {
    history.push('edit-hotel/' + id);
  };

  const downloadSample = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    getHotelSample();
  };

  const checkDate = (dateValue:any,calledFrom:string) => { 
    let arrTmp = dateValue.split('-'); 
    if(arrTmp.length==3 && arrTmp[0].length > 4) {
      if(calledFrom=='fromDate') { 
        setfromDate(arrTmp[0].substring(0,4)+'-'+arrTmp[1]+'-'+arrTmp[2]); 
      }
      else if(calledFrom=='toDate') { 
        setToDate(arrTmp[0].substring(0,4)+'-'+arrTmp[1]+'-'+arrTmp[2]); 
      }
    }
    else {
      if(calledFrom=='fromDate') { 
        setfromDate(dateValue); 
      }
      else if(calledFrom=='toDate') { 
        setToDate(dateValue); 
      }
    }
  }

  return (
    <>
      <div className="header-content-container">
      <div className="box">

        <div className="justify-space-between">
          <div className="justify-left">
            <SearchBar onSearch={acceptSearch} currentSearch={displaySearch} place_holder="&#xf002; Search hotel name or code" searchButton={true} onSearchButton={onSearchButton} />
            <div className='circlediv '>
              <div className={`circle pointer  ${fetchWithFilter ? "bluebackGround" : null}`} onClick={handleFilterHotel}>
                <i className="fa  fa-filter" ></i>
              </div>
            </div>
            <span className='mt-10px ml-9px link-resent-m' onClick={handleRestFilter}>{LBL_RESET_FILTER}</span>
          </div>
          <div className="justify-end">
            <input
              type="file"
              onChange={postHotels}
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
            />
            <button type="button" className="primary csv-button"
            onMouseEnter={()=>setLogoState(false)}
            onMouseLeave={()=>setLogoState(true)}
             onClick={downloadSample} title="CSV template for importing hotels.">
              <img
                src={logoState ? logo : logo1}
                className="info-sample invert-filter"
                title="CSV template for importing hotels."
              ></img>
            </button>
            <button type="button" className="primary margin-left-15px" onClick={() => onImport()}>
              Import Hotels
            </button>
            <button type="button" className="primary  margin-left-15px" onClick={() => onExport()}>
              Export Hotels
            </button>
            <button
              type="button"
              className="primary margin-left-15px"
              onClick={() => onNavigate('add-hotel')}
            >
              Add Hotel
            </button>
          </div>
        </div>

        {importLoading === 'loading' && (
          <div className="table-loader">
            <ReactLoading className="loader-center" color={'#2f427c'} type={'bars'} width={'3vw'} />
          </div>
        )}

        {importLoading !== 'loading' &&
        <div className="content-box table-wrapper">
          <Table
            handleSort={handleSort}
            titles={hotelListFields}
            data={hotels}
            isLoading={loading === `loading` ? true : false}
            onClickable={onClickable}
            currentPage={0}
            sortedFieldName={sortField}
          />
          <div className="justify-right">
            <div className="pagination">
              <Pagination
                isTableLoading={loading === `loading` ? true : false}
                totalCount={totalSize}
                currentPage={currentPage}
                setCurrentPage={acceptPage}
                pageSize={20}
                siblingCount={2}
              />
            </div>
          </div>
        </div>}
      </div>

      <div id="filterHotelDetails" className={`sidenav ${filterSideBar ? "show_sidenav" : "hide"}`} >
        <div className="box">
          <div className="row">
            <div className="col">
              <label className="h5 page-heading">Filter Hotel Details</label>
              <span className="closebtn"
                onClick={closeFilterDetails}
              >
                ×
              </span>
            </div>
          </div>

          <form autoComplete="off" onSubmit={e => handleApplyFilter(e)} >

            <div className='box border  mt-20px'>

              <div className=''>
                <label className="div-label font-bold mt-20px ">Hotel Name/Code</label>
                <div className="mb-2">
                  <input
                    value={filterSearch}
                    name="address"
                    type="text"
                    onChange={(e: any) => setFilterSearch(e.target.value)}
                    className="w-100px max-width-100"
                  ></input>
                </div>
              </div>

              <div className='hide'>
                <label className="div-label font-bold mt-20px ">Address</label>
                <div className="mb-2">
                  <input
                    value={address}
                    name="address"
                    type="text"
                    onChange={(e: any) => setAddress(e.target.value)}
                    className="w-100px"
                  ></input>
                </div>
              </div>

              <div className=''>
                <label htmlFor='activeDetail' className="div-label font-bold mt-20px ">Active</label>
                <div className="mb-2">
                  <select name="active" id="activeDetail" className='w-100px' value={active} defaultValue={"Yes"} onChange={(e: any) => setActive(e.target.value)}>
                    <option value="All">All </option>
                    <option value="Yes">Yes </option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>

              <div className="mb-20px flex" >
                <div className='display-column w-100px'>
                  <label className="div-label font-bold mt-20px ">Added Date From</label>
                  <input
                    value={fromDate}
                    name="From_Date"
                    placeholder="From Date"
                    type="date"
                    className='w-100px'
                    onChange={(e) => checkDate(e.target.value,'fromDate')}
                    maxLength={10}
                  ></input>
                </div>
                <div className='display-column w-100px ml-27px'>
                  <label className="div-label font-bold mt-20px ">Date To</label>
                  <input
                    value={toDate}
                    min={fromDate}
                    placeholder="To Date"
                    className='w-100px'
                    name="To_Date"
                    type="date"
                    onChange={(e) => checkDate(e.target.value,'toDate')}
                  ></input>
                </div>
              </div>

              <div className='mb-43 justify-right'>
                <button type="submit" className="primary mt-30px" onClick={e => handleApplyFilter(e)}>
                  Apply
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
      </div>
    </>
  );
};
