import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authenticateMaidPhone, verifyMaidPhoneOTP } from '../../services/maidAuthenticationService';
import { Store } from 'react-notifications-component';
import jwt_decode from 'jwt-decode'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { setMaidTokens, setMaidPhone, setMaidId } from '../../services/localStorage';
import { RootState } from '../../store';
import { useHistory } from 'react-router-dom';
import { notification_shown_time_success } from '../../config/env';
import { MSG_VERIFY_CODE_SENT_TO } from '../../config/messages';

export interface IAuthentication {
  isProcessingRequest: boolean;
  accessToken?: string;
  maidPhone?: string;
}

const initialState: IAuthentication = { isProcessingRequest: false };

export const maidAuthenticationSlice = createSlice({
  name: 'maidAuthentication',
  initialState,
  reducers: {
    start: (state) => {
      return {
        ...state,
        isProcessingRequest: true,
      };
    },
    success: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        maidPhone: action.payload,
        isProcessingRequest: false,
      };
    },
    error: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isProcessingRequest: false,
      };
    },
  },
});

export const authenticateMaid = (maidPhone: string) => async (dispatch: any) => {
  dispatch(start());
  try {
    const authData = await authenticateMaidPhone(maidPhone); // authData IS BLANK, AN OMPTY OBJECT COMING FROM SERVER
    console.log('authenticateMaidPhone:: authData:',JSON.stringify(authData));
    if(!authData || authData==undefined || authData=='undefined') {
      return false;
    }
    else {
      setMaidPhone(maidPhone);
      dispatch(success(maidPhone));
      toast.success(MSG_VERIFY_CODE_SENT_TO+maidPhone, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: MSG_VERIFY_CODE_SENT_TO+maidPhone,
      //   type: 'success',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
      // setMaidTokens(authData);
    }
    
  } catch (err) {
    // dispatch(error(err));
  }
};

export const verifyOTP = (maidPhone: string, maidOtp: string) => async (dispatch: any) => {
  dispatch(start());
  try {
    const authData = await verifyMaidPhoneOTP(maidPhone, maidOtp); 
    let dataAccess = authData.access;
    let decoded = jwt_decode(dataAccess);  
    dispatch(success(authData));
    setMaidId(decoded); 
    setMaidTokens(authData);
  } catch (err) {
    // dispatch(error(err));
  }
};

// Action creators are generated for each case reducer function
export const { start, success, error } = maidAuthenticationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const maidSelectAuthentication = (state: RootState) => state.authentication;

export const maidAuthenticationReducer = maidAuthenticationSlice.reducer;
