import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { Autocomplete, Checkbox, TextField } from '@mui/material';

import { changeSearch, selectGuestPaymentsSearchSlice } from './guestPaymentsSearchSlice';
import {
  setCurrentPage,
  setTotalPage,
  setStartLoading,
  setStopLoading,
  selectGuestPaymentsPaginationSlice,
} from './guestPaymentsPaginationSlice';
import SearchBar from '../../components/SearchBar';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import {
  selectGuestPaymentsDataSlice,
  setFilterState,
  setFilterFrom,
  setFilterTo,
  resetGuestPaymentsSummary,
  resetGuestPayments,
} from './guestPaymentsDataSlice';

import {
  setguestPayments,
  selectGuestRefundSlice,
  selectGuestRefundReducer,
} from './guestRefundSlice';
import { IGuestPaymentsRequestData } from '../../models/guestPaymentsInterfaces';
import {
  fetchPayments,
  fetchPaymentsSummary,
  exportGuestPayments,
} from '../../services/guestPaymentService';
import { guestPaymentsFields } from '../../config/tables';
import {
  selectGuestPaymentsHotelFilterSlice,
  changeHotelFilter,
} from './guestPaymentHotelFilterSlice';
import { fetchHotelsNameId } from '../../services/maidPaymentsService';
import DateFilterComponent from '../../components/DateFilterComponent';
import dayjs from 'dayjs';
import { performRefund } from '../../services/guestPaymentService';
import { getLowerDateRange, getNextDateRange } from '../../helpers/dateOps';
import Select, { Options } from 'react-select';
import { Store } from 'react-notifications-component';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  LBL_EXPORT_TIPS,
  LBL_TIPS_OF,
  LBL_HOTEL_INACTIVE,
  LBL_INCLUDE_INACTIVE_HOTELS,
} from '../../config/common_labels';
import { setSelectedHotelId, getSelectedHotelId } from '../../services/localStorage';
import { PAYOUT_STAFF, STRIPE_COMMISION_STRING } from '../../config/env';
import { IAutocompleteSelectOptionType } from '../../models/maidsInterfaces';
import MultiSelect from '../../components/MultiSelect';

export const GuestPayments = (props: any) => {
  const { searchValue } = useAppSelector(selectGuestPaymentsSearchSlice);
  const { currentPage, isLoading } = useAppSelector(selectGuestPaymentsPaginationSlice);
  const { curHotelOPt, allHotelOpts, allHotelOptsInactive, isHotelActive, hotelNameIds } =
    useAppSelector(selectGuestPaymentsHotelFilterSlice);
  const { refundLoading } = useAppSelector(selectGuestRefundSlice);
  const {
    guestPayments,
    guestPaymentsSummary,
    totalSize,
    loading,
    guestPaymentsLoading, 
    filterState,
    filterFrom,
    filterTo
  } = useAppSelector(selectGuestPaymentsDataSlice);

  const dispatch = useAppDispatch();

  const [applySearch, setApplySearch] = useState(0);
  const [localStorageHotelId, setLocalStorageHotelId] = useState(getSelectedHotelId());

  const [inactiveHotel, setInactiveHotel] = useState(false);
  const [activeHotel, setactiveHotel] = useState<IAutocompleteSelectOptionType[]>([]);

  // const [hotelListObj, setHotelListObj] = useState(curHotelOPt);
  const [hotelListObj, setHotelListObj] = useState<IAutocompleteSelectOptionType[]>([]);
  const [hotelList, setHotelList] = useState<string[]>([]); // useState<number[]>([]);
  const [paymentFields, setPaymentFields] = useState(guestPaymentsFields);

  const [multiSearchText, setMultiSearchText] = useState('');
  const [currHotelIds, setCurrHotelIds] = useState<string>(''); 
  const [searchDetailsChanged, setSearchDetailsChanged] = useState(true); 

  React.useEffect(() => { 
    if(guestPaymentsLoading!='loading' && guestPaymentsLoading!='idle') {
      setSearchDetailsChanged(false); 
    }
  }, [guestPaymentsLoading]);  

  const fetchPayDetails = () => {
    if (hotelList.length <= 1) {
      let arrFields = guestPaymentsFields.filter((item) => item.title != 'hotelName');
      setPaymentFields(arrFields);
    } else {
      setPaymentFields(guestPaymentsFields);
    }

    let hIds = hotelList.join(',').replace('all,','');
    if (searchDetailsChanged || (hotelList.length > 0 && hIds!=currHotelIds)) {
      const hasAllOption = hotelList.some((hotel) => hotel === 'all');
      let hotelsList =
        hasAllOption && inactiveHotel
          ? 'all'
          : hasAllOption && !inactiveHotel
          ? 'active_all'
          : hIds;
      if(hotelsList && hotelsList.length > 0) {
        const req: IGuestPaymentsRequestData = {
          page: currentPage,
          hotel: hotelsList,
          start: encodeURIComponent(filterFrom),
          end: encodeURIComponent(filterTo),
          search: searchValue.trim(),
        };
        dispatch(fetchPayments(req));
        dispatch(fetchPaymentsSummary(req));
        setCurrHotelIds(hIds); 
      }
    } else if (hotelList.length==0) { 
      dispatch(
        resetGuestPaymentsSummary({
          paymentCommission: '',
          total: 0,
          totalCommission: 0,
          totalCommissionCurrency: '',
          totalCurrency: '',
        })
      );
      dispatch(resetGuestPayments([]));
      setCurrHotelIds(''); 
    }
  };

  // React.useEffect(() => {
  //     if (curValue !== 0) {
  //         setHotelListObj([]);
  //         setHotelList([]);
  //         let arr: any = []
  //         let arr2: any = []
  //         allHotelOptsInactive.map((d: any) => { arr.push(d) });

  //         arr.filter((d: any) => d.value == curValue ? arr2.push(d) : null);
  //         console.log('arr2: ', arr2);
  //         setHotelListObj(arr2);
  //         setHotelList(arr2.map((item: any) => parseInt(item.value, 10)));
  //     }
  // }, [curValue]);

  React.useEffect(() => {
    fetchPayDetails();
  }, [applySearch, currentPage, filterState, hotelList]);

  React.useEffect(() => {
    setSearchDetailsChanged(true); 
  }, [filterFrom, filterTo]);

  React.useEffect(() => {
    fetchPayDetails();
  }, [searchDetailsChanged]);

  React.useEffect(() => {
    if (refundLoading == 'success') {
      fetchPayDetails();
    }
  }, [refundLoading]);

  const onSearchButton = (e: any) => {
    e.preventDefault();
    acceptPage(1);
    setApplySearch(applySearch + 1);
  };

  const acceptSearch = (e: any): void => {
    dispatch(changeSearch(e.target.value));
  };

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };

  const setStartDate = (e: Date): void => {
    dispatch(setFilterFrom(dayjs(e).format(`YYYY-MM-DDTHH:mm:ssZZ`)));
  };
  const setEndDate = (e: Date): void => {
    dispatch(setFilterTo(dayjs(e).format(`YYYY-MM-DDT23:59:59ZZ`)));
  };

  const setLess = (): void => {
    let dateRange = getLowerDateRange(filterFrom, filterTo, 'guest');
    dispatch(setFilterFrom(dateRange[0]));
    dispatch(setFilterTo(dateRange[1]));
  };

  const setMore = (): void => {
    let dateRange = getNextDateRange(filterFrom, filterTo, 'guest');
    dispatch(setFilterFrom(dateRange[0]));
    dispatch(setFilterTo(dateRange[1]));
  };

  const dateFilter = (): JSX.Element => {
    if (filterState === 'transDate') {
      return (
        <div className="div-left" id="transDate">
          <div className="div-left">
            <div className="div-left margin-left-15px pay-period">From</div>
          </div>
          <div className="div-left  margin-left-5px pointer-cursor">
            <DateFilterComponent
              defaultDate={dayjs(filterFrom).toDate()}
              setDate={setStartDate}
              format={`YYYY-MM-DDTHH:mm:ssZZ`}
              page="guest"
              type="start"
            />
          </div>
          <div className="div-left">
            <div className="div-left margin-left-15px pay-period">To</div>
          </div>
          <div className="div-left  margin-left-5px pointer-cursor">
            <DateFilterComponent
              defaultDate={dayjs(filterTo).toDate()}
              minDate={filterFrom}
              setDate={setEndDate}
              format={`YYYY-MM-DDTHH:mm:ssZZ`}
              page="guest"
              type="end"
            />
          </div>
        </div>
      );
    }
    return <span />;
  };

  const payPeriodFilter = (): JSX.Element => {
    if (filterState === 'payPeriod') {
      return (
        <div className="div-left" id="payPeriod">
          {loading != 'loading' ? <div onClick={() => setLess()} className="box-data div-left pointer-cursor"> &lt; </div> : <div className="box-data div-left "> &lt; </div>}
          <div className="box-data  div-left">{dayjs(filterFrom).format('MMM DD, YYYY')}</div>
          <div className="box-data  div-left">{dayjs(filterTo).format('MMM DD, YYYY')}</div>
          {loading != 'loading' ? 
            <div onClick={() => setMore()} className="box-data div-left pointer-cursor"> &gt; </div>
            : <div className="box-data div-left"> &gt; </div>
          }
        </div>
      );
    }
    return <span />;
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    acceptPage(1);
    dispatch(setFilterState(e.target.value));
  };

  const handleHotelFilterChange = (e: any): void => {
    acceptPage(1);
    dispatch(changeHotelFilter(e.value));
    setSelectedHotelId(e.value);
    if (e.active) {
      setLocalStorageHotelId(e.value);
    }
  };

  const onExport = (): void => {
    const req: IGuestPaymentsRequestData = {
      page: currentPage,
      hotel: hotelList.length > 0 ? hotelList.join() : '0',
      start: encodeURIComponent(filterFrom),
      end: encodeURIComponent(filterTo),
      search: searchValue.trim(),
    };
    exportGuestPayments(req);
  };

  const onClickable = (id: number, name: string): void => {
    if (name === `Refund`) {
      let gp = guestPayments.find((option) => option.id === id);
      if (gp != undefined) {
        if (gp.refunded == false) {
          Store.addNotification({
            title: 'Refund',
            id: 'refund-modal',
            content: (
              <div className="refund-modal">
                <div className="refund-flex-center page-heading">
                  <h4>Perform Refund</h4>
                </div>
                <div className="refund-flex-center">
                  Are you sure you want to refund {getSymbolFromCurrency(gp.amountCurrency)}
                  {gp.amount} to {gp.customerName}?
                </div>
                <div className="refund-margin"></div>
                <div className="refund-flex-center">
                  <button
                    type="button"
                    className="primary "
                    onClick={() => Store.removeNotification('refund-modal')}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="primary margin-left-15px"
                    onClick={() => dispatch(performRefund(id))}
                  >
                    Refund
                  </button>
                </div>
              </div>
            ),
            type: 'default',
            container: 'center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 1000000000,
              click: false,
              touch: false,
            },
          });
        }
      }
      // dispatch(setguestPayments(id));
      // dispatch(performRefund(id));
    }
  };

  // useEffect(() => {
  //     document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
  // }, [])

  // function selectBoxOnFocus() {
  //     document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-grey');
  //     document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-light-grey');
  // }

  // function selectBoxOnBlur() {
  //     document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-grey');
  //     document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
  // }
  const colorStyles = {
    option: (styles: any, data: any) => ({
      ...styles,
      color: data.data.active ? '' : 'red',
    }),
  };

  const setIncludePayout = (incPayout:boolean) => {
    console.log(incPayout); 
  }

  return (
    <>
      <div className="header-content-container">
        <div className="box">
          <div className="row">
            <div className="col-12">
              <h4 className="page-heading div-left ml-5px padding-top6">{LBL_TIPS_OF}</h4>
              <div className="div-left col-3 ml-10px">
                {/* <Autocomplete 
                                onBlur={e => onSetMultiSearchText('')}
                                sx={{ height: 'auto' }}
                                multiple
                                limitTags={1}
                                disableCloseOnSelect={true} 
                                id="multiple-limit-tags"
                                options={inactiveHotel ? allHotelOptsInactive : activeHotel}
                                className="select_checkbox border-one"
                                getOptionLabel={(option) => option.label}
                                // getOptionDisabled={(option) =>
                                //   option === activeHotel[0] || option === activeHotel[2]
                                // }
                                // defaultValue={[allHotelOpts[0]]}
                                value={hotelListObj}
                                inputValue={multiSearchText}
                                onChange={(e: any, newValue: any) => {
                                  onSetHotelList(e, newValue);
                                }} 
                                isOptionEqualToValue={(option, value) => option.label === value.label} 
                                renderOption={(props, option, { selected }) => (
                                  <li {...props} >
                                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                    {option.label}
                                  </li>
                                )}
                                renderInput={(params) =>
                                  props.editId > 0 ? (
                                    <TextField {...params} size="small" onChange={e => onSetMultiSearchText(e.target.value)} />
                                  ) : (
                                    <TextField {...params} size="small" required  onChange={e => onSetMultiSearchText(e.target.value)}  />
                                  )
                                }
                              />  */}
                              <MultiSelect 
                                getHotellist={setHotelList} 
                                getIncludeInactiveStatus={setInactiveHotel} 
                                getIncludePayoutStatus={setIncludePayout}
                                includePayout={false} 
                              />

                {/* <Select
                                    onChange={handleHotelFilterChange}
                                    name="hotelName"
                                    onFocus={selectBoxOnFocus}
                                    onBlur={selectBoxOnBlur}
                                    className="margin-left-15px div-left "
                                    classNamePrefix="react-select-maids"
                                    options={allHotelOpts}
                                    value={curHotelOPt}
                                    isSearchable={true}
                                    styles={colorStyles}
                                /> */}
{/* 
                <div className="my-2 ">
                  <input
                    type="checkbox"
                    id="chk"
                    onChange={(e) => setInactiveHotel(e.target.checked)}
                    checked={inactiveHotel}
                    className=""
                  />{' '}
                  <label className="div-label-small" htmlFor="chk">
                    {LBL_INCLUDE_INACTIVE_HOTELS}
                  </label>
                </div> */}
              </div>

              <div className="div-left margin-left-15px">
                <div className="div-left">
                  {/* <div className="div-left margin-right-15px pay-period">Pay Period </div> */}
                  <select
                    value={filterState}
                    name="payPeriod"
                    className="div-left box-data sel-box min-width-170"
                    onChange={(e) => handlePeriodChange(e)}
                  >
                    <option value="payPeriod">Pay Period</option>
                    <option value="transDate">Transaction Date</option>
                  </select>
                </div>

                {payPeriodFilter()}

                {dateFilter()}
              </div>

              {/* {!isHotelActive && (
                <div className="div-right warning-text margin-top-m-10">{LBL_HOTEL_INACTIVE}</div>
              )} */}
            </div>
            <div className="col-12">
              <div className="page-sub-heading col-3 div-left margin-left-15px">
                <div>Total Tip Received</div>
                <div className="page-sub-data">
                  <h4>
                    {getSymbolFromCurrency(guestPaymentsSummary.totalCurrency || 'usd')}{' '}
                    {Number(guestPaymentsSummary.total).toFixed(2)}
                  </h4>
                </div>
              </div>

              {hotelList.length == 1 && (
                <div className="page-sub-heading col-3 div-left margin-left-15px">
                  <div>Commission Percentage</div>
                  <div className="page-sub-data">
                    <h4>
                      {Number(guestPaymentsSummary.paymentCommission).toFixed(0) +
                        '% ' +
                        STRIPE_COMMISION_STRING}
                    </h4>
                  </div>
                </div>
              )}

              <div className="page-sub-heading col-3 div-left margin-left-15px">
                <div>Commission Collected </div>
                <div className="page-sub-data">
                  <h4>
                    {getSymbolFromCurrency(guestPaymentsSummary.totalCommissionCurrency || 'usd')}{' '}
                    {Number(guestPaymentsSummary.totalCommission).toFixed(2)}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-content-container pt-0">
        <div className="box">
          <div className="justify-space-between">
            <SearchBar
              onSearch={acceptSearch}
              currentSearch={searchValue}
              place_holder="&#xf002; Search name"
              searchButton={true}
              onSearchButton={onSearchButton}
            />
            <div className="justify-end">
              {guestPayments && guestPayments.length > 0 && <button type="button" className="primary  margin-left-15px" onClick={onExport}>
                {LBL_EXPORT_TIPS}
                </button>}
            </div>
          </div>
          <div className="content-box table-wrapper">
            <Table
              titles={paymentFields}
              data={guestPayments}
              onClickable={onClickable}
              isLoading={loading === `loading` ? true : false}
              currentPage={0}
            />
            <div className="justify-right">
              <div className="pagination">
                {guestPayments && guestPayments.length > 0 && (
                  <Pagination
                    isTableLoading={loading === `loading` ? true : false}
                    totalCount={totalSize}
                    currentPage={currentPage}
                    setCurrentPage={acceptPage}
                    pageSize={20}
                    siblingCount={2}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
