import React, { useState, useEffect,useRef  } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm, useFormState } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useLocation, Link, useHistory } from "react-router-dom";
import { Store } from 'react-notifications-component';
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignaturePad from "react-signature-canvas";
import Select, { Options } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../helpers/hook';

import { IMaidProfile, IMaidProfileReload } from "../../models/maidProfileInterfaces";
import { getMaidPhone, removeMaidTokens, getMaidId } from "../../services/localStorage";
import { fetchMaidProfile, maidProfileUpdate, fetchMaidW9Form, createW9Form, fetchMaidIDProof, uploadMaidIDProof, deleteMaidIDProof } from '../../services/maidProfileService';
import { selectMaidProfileDataSlice, setReloadMaidProfileForIDProof, setReloadMaidProfileForW9Form, setMaidProfileW9FormCreate } from './maidProfileSlice';
import { setMaidProfileProperties } from './maidProfileEditSlice';

import logo from './../../assets/img/tova_stack.png';
import { DEFAULT_COUNTRY, DEFAULT_CURRENCY, APP_NAME, STAFF_PAYMENT_METHOD_BANK, STAFF_PAYMENT_METHOD_CHECK,    
    STAFF_DOC_TYPE_PASSOPORT_ID, STAFF_DOC_TYPE_PASSOPORT, 
    STAFF_DOC_TYPE_PASSOPORT_CARD_ID, STAFF_DOC_TYPE_PASSOPORT_CARD,
    STAFF_DOC_TYPE_DRIVER_LICENSE_ID, STAFF_DOC_TYPE_DRIVER_LICENSE,  
    STAFF_DOC_TYPE_STATE_ID_CARD_ID, STAFF_DOC_TYPE_STATE_ID_CARD, 
    STAFF_DOC_TYPE_GREEN_CARD_ID, STAFF_DOC_TYPE_GREEN_CARD,  
    STAFF_DOC_TYPE_BORDER_CROSSING_CARD_ID, STAFF_DOC_TYPE_BORDER_CROSSING_CARD,
    STAFF_DOC_TYPE_CHILD_ID_CARD_ID, STAFF_DOC_TYPE_CHILD_ID_CARD, 
    STAFF_DOC_TYPE_NYC_CARD_ID, STAFF_DOC_TYPE_NYC_CARD, 
    STAFF_DOC_TYPE_US_VISA_CARD_ID, STAFF_DOC_TYPE_US_VISA_CARD } from "../../config/env";
import ImageBox from '../../components/ImageBox';
import { MSG_INVALID_PHONE_NUMBER, MSG_ROUTING_9_DIGITS, MSG_CONFIRM_REM_ID_PROOF_IMAGE } from "../../config/messages";

import ImageBoxMaidProfile from "../../components/ImageBoxMaidProfile";
import { LBL_URL_MAIDS, LBL_STAFF_PAYMENT_METHOD_BANK, LBL_STAFF_PAYMENT_METHOD_CHECK, LBL_DOCS_VERIFIED_BY_STRIPE } from "../../config/common_labels";

export const MaidProfile = () => {
    let sigCanvas = useRef<any>({});
    const history = useHistory();
    const location = useLocation();
    const [maidProfileReload, setMaidProfileReload] = useState<number>(0);
    const [curMaid, setCurMaid] = useState<any>(getMaidId());
    const [selectedMobile, setSelectedMobile] = useState('');
    const [selectedAlternative, setSelectedAlternative] = useState('');
    const { maidProfile, reloadMaidProfileForIDProof, reloadMaidProfileForW9Form, maidProfileStatus, idProofRemovetatus, maidProfileIdProofUploadStatus, maidProfileW9FormStatus, maidProfileW9FormCreate } = useAppSelector(selectMaidProfileDataSlice); // idProofStatus, idProofs  


    const [idProofLoading, setIdProofLoading] = useState<boolean>(false);
    const [w9FormFile, setW9FormFile] = useState<string>('');
    const [maidProfileLoaded, setMaidProfileLoaded] = useState<boolean>(false);

    const [w9FormLoading, setW9FormLoading] = useState<boolean>(false);
    const [w9Signature, setW9Signature] = useState<BinaryType | null | undefined>(null);
    const [w9SSN, setW9SSN] = useState<string>('');

    const [idProofFiles, setIdProofFiles] = useState<{ image: string; order: number }[]>([]);

    const [newIDProofFile, setNewIDProofFile] = useState<BinaryType | null | undefined>(null);

    const [emailEmpty, setEmailEmpty] = useState(true);
    const [mobileEmpty, setMobileEmpty] = useState(true);
    const [addressLine1Empty, setAddressLine1Empty] = useState(true);
    const [cityEmpty, setCityEmpty] = useState(true);
    const [stateEmpty, setStateEmpty] = useState(true);
    const [zipEmpty, setZipEmpty] = useState(true);
    const [accHolderNameEmpty, setAccHolderNameEmpty] = useState(true);
    const [dobEmpty, setDobEmpty] = useState(true);
    const [countryEmpty, setCountryEmpty] = useState(false);
    const [ssnEmpty, setSsnEmpty] = useState(true);
    const [accNumberEmpty, setAccNumberEmpty] = useState(true);
    const [bankNameEmpty, setBankNameEmpty] = useState(true);
    const [routingEmpty, setRoutingEmpty] = useState(true);
    const [saveButtonClicked, setSaveButtonClicked] = useState(false);
    const [docVerified, setDocVerified] = useState(false); 
    const [disabledClass, setDisabledClass] = useState(''); 


    const paymentOptionBank  = {value: STAFF_PAYMENT_METHOD_BANK.toString(), label: LBL_STAFF_PAYMENT_METHOD_BANK}; 
    const paymentOptionCheck = {value: STAFF_PAYMENT_METHOD_CHECK.toString(), label: LBL_STAFF_PAYMENT_METHOD_CHECK};  

    const [selectedPaymentOption, setSelectedPaymentOption] = useState(paymentOptionBank);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(STAFF_PAYMENT_METHOD_BANK); 

    const idProofDefaultOption = {value: '0', label:'Select ID proof document type'}; 
    const [selectedIdProofOption, setSelectedIdProofOption] = useState(idProofDefaultOption);
    const [selectedIdProofId, setSelectedIdProofId] = useState(0);

    const allPaymentOptions  = [ paymentOptionBank, paymentOptionCheck ]; 

    const idProofDocTypes = [ idProofDefaultOption,
        {value: STAFF_DOC_TYPE_PASSOPORT_ID.toString(), label:STAFF_DOC_TYPE_PASSOPORT}, 
        {value: STAFF_DOC_TYPE_PASSOPORT_CARD_ID.toString(), label: STAFF_DOC_TYPE_PASSOPORT_CARD},
        {value: STAFF_DOC_TYPE_DRIVER_LICENSE_ID.toString(), label: STAFF_DOC_TYPE_DRIVER_LICENSE},
        {value: STAFF_DOC_TYPE_STATE_ID_CARD_ID.toString(), label: STAFF_DOC_TYPE_STATE_ID_CARD},
        {value: STAFF_DOC_TYPE_GREEN_CARD_ID.toString(), label: STAFF_DOC_TYPE_GREEN_CARD},
        {value: STAFF_DOC_TYPE_BORDER_CROSSING_CARD_ID.toString(), label: STAFF_DOC_TYPE_BORDER_CROSSING_CARD},
        {value: STAFF_DOC_TYPE_CHILD_ID_CARD_ID.toString(), label: STAFF_DOC_TYPE_CHILD_ID_CARD},
        {value: STAFF_DOC_TYPE_NYC_CARD_ID.toString(), label: STAFF_DOC_TYPE_NYC_CARD},
        {value: STAFF_DOC_TYPE_US_VISA_CARD_ID.toString(), label: STAFF_DOC_TYPE_US_VISA_CARD} ]; 

    const handlePaymentOptionChange = (e: any, calledFrom:string): void => {
        if(calledFrom=='paymentMethod') {
            setSelectedPaymentMethod(e.value); 
            setSelectedPaymentOption(e.value==STAFF_PAYMENT_METHOD_CHECK.toString() ? paymentOptionCheck : paymentOptionBank);
        }
        else if(calledFrom=='idProof') {
            console.log(e); 
            setSelectedIdProofId(e.value); 
            setSelectedIdProofOption(e);
        }
    };
    function selectBoxOnFocus(calledFrom:string) {
        if(calledFrom=='paymentMethod') {
            document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-grey');
            document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-light-grey');    
        }
        else if(calledFrom=='idProof') {
            document.getElementsByClassName('react-select-maids__single-value')[1].classList.add('color-grey');
            document.getElementsByClassName('react-select-maids__single-value')[1].classList.remove('color-light-grey');    
        }
    }
    function selectBoxOnBlur(calledFrom:string) {
        if(calledFrom=='paymentMethod') {
            document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-grey');
            document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
        }
        else if(calledFrom=='idProof') {
            document.getElementsByClassName('react-select-maids__single-value')[1].classList.remove('color-grey');
            document.getElementsByClassName('react-select-maids__single-value')[1].classList.add('color-light-grey');
        }
    }  



    const setMandatoryFields = (maidProfile: IMaidProfile) => {
        if (!maidProfile.email) { setEmailEmpty(true); }
        else { setEmailEmpty(false); }
        if (!maidProfile.mobileNumber) { setMobileEmpty(true); }
        else { setMobileEmpty(false); }
        if (!maidProfile.addressLine_1) { setAddressLine1Empty(true); }
        else { setAddressLine1Empty(false); }
        if (!maidProfile.city) { setCityEmpty(true); }
        else { setCityEmpty(false); }
        if (!maidProfile.state) { setStateEmpty(true); }
        else { setStateEmpty(false); }
        if (!maidProfile.zip) { setZipEmpty(true); }
        else { setZipEmpty(false); }
        if (!maidProfile.accountHolderName) { setAccHolderNameEmpty(true); }
        else { setAccHolderNameEmpty(false); }
        if (!maidProfile.dateOfBirth) { setDobEmpty(true); }
        else { setDobEmpty(false); }
        // if (!maidProfile.country) { setCountryEmpty(true); }
        // else { setCountryEmpty(false); }
        if (!maidProfile.ssn) { setSsnEmpty(true); }
        else { setSsnEmpty(false); }
        if (!maidProfile.accountNumber) { setAccNumberEmpty(true); }
        else { setAccNumberEmpty(false); }
        if (!maidProfile.bankName) { setBankNameEmpty(true); }
        else { setBankNameEmpty(false); }
        if (!maidProfile.routingNumber) { setRoutingEmpty(true); }
        else { setRoutingEmpty(false); }
    }

    const checkMandatory = (e: any, fieldName: string) => {
        if (fieldName === 'email') {
            if (e.target.value.trim().length === 0) { setEmailEmpty(true); }
            else { setEmailEmpty(false); }
        }
        else if (fieldName === 'addressLine_1') {
            if (e.target.value.trim().length === 0) { setAddressLine1Empty(true); }
            else { setAddressLine1Empty(false); }
        }
        else if (fieldName === 'city') {
            if (e.target.value.trim().length === 0) { setCityEmpty(true); }
            else { setCityEmpty(false); }
        }
        else if (fieldName === 'state') {
            if (e.target.value.trim().length === 0) { setStateEmpty(true); }
            else { setStateEmpty(false); }
        }
        else if (fieldName === 'zip') {
            if (e.target.value.trim().length === 0) { setZipEmpty(true); }
            else { setZipEmpty(false); }
        }
        else if (fieldName === 'accountHolderName') {
            if (e.target.value.trim().length === 0) { setAccHolderNameEmpty(true); }
            else { setAccHolderNameEmpty(false); }
        }
        else if (fieldName === 'dateOfBirth') {
            if (e.target.value.trim().length === 0) { setDobEmpty(true); }
            else { setDobEmpty(false); }
        }
        else if (fieldName === 'country') {
            if (e.target.value.trim().length === 0) { setCountryEmpty(true); }
            else { setCountryEmpty(false); }
        }
        else if (fieldName === 'ssn') {
            if (e.target.value.trim().length === 0) { setSsnEmpty(true); }
            else { setSsnEmpty(false); }
        }
        else if (fieldName === 'accountNumber') {
            if (e.target.value.trim().length === 0) { setAccNumberEmpty(true); }
            else { setAccNumberEmpty(false); }
        }
        else if (fieldName === 'bankName') {
            if (e.target.value.trim().length === 0) { setBankNameEmpty(true); }
            else { setBankNameEmpty(false); }
        }
        else if (fieldName === 'routingNumber') {
            if (e.target.value.trim().length === 0) { setRoutingEmpty(true); }
            else { setRoutingEmpty(false); }
        }

    }

    const onChangeW9SSN = (e: any) => {
        setW9SSN(e.target.value.trim())
    }

    const onW9SignatureSubmitted = (e: any): void => {
        setW9Signature(e);
    };

    const onNewIDProofFileSubmitted = (e: any): void => { 
        setNewIDProofFile(e);
        // setTimeout(() => {
        //     onUploadIDProof(); 
        // },750); 
    };

    const formatIntoPng = () => {
        if (sigCanvas.current) {
          const dataURL = sigCanvas.current.toDataURL();
          setW9Signature(sigCanvas.current.toDataURL());
          return dataURL;
        }
      };

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (newIDProofFile) {
            onUploadIDProof();
        }
    }, [newIDProofFile]);

    React.useEffect(() => {
        if(saveButtonClicked) {
            if(maidProfileStatus == 'succeeded') {
                if(w9Signature && w9SSN && w9SSN.length == 9) {
                    onGenerateW9Form(); 
                } 
            }
        }
    }, [maidProfileStatus]); 

    useEffect(() => {
        setMandatoryFields(maidProfile);
    }, [maidProfile]);

    useEffect(() => {
        dispatch(fetchMaidProfile(curMaid));
        getMaidIDProofData();
        setMaidProfileLoaded(true);
        getMaidW9FormData();
        dispatch(setMaidProfileProperties(maidProfile));
        setMandatoryFields(maidProfile);
    }, [location, maidProfileReload]);

    // RELOAD THE IDPROOF IMAGES OM UPLOAD OR REMOVAL 
    React.useEffect(() => {
        if (reloadMaidProfileForIDProof === true) {
            getMaidIDProofData();
            setNewIDProofFile(null);
            dispatch(setReloadMaidProfileForIDProof(false));
        }
    }, [reloadMaidProfileForIDProof]);

    // RELOAD THE W9 FORM ON GENERATE W9 FORM 
    // React.useEffect(() => {
    //     if (reloadMaidProfileForW9Form === true) {
    //         getMaidW9FormData();
    //         setW9Signature(null);
    //         dispatch(setReloadMaidProfileForW9Form(false));
    //     }
    // }, [reloadMaidProfileForW9Form]);

    React.useEffect(() => {
        if (maidProfileW9FormCreate?.w9) {
            setW9FormFile(maidProfileW9FormCreate.w9);
            setW9Signature(null);
            dispatch(setMaidProfileW9FormCreate({}));
        }
    }, [maidProfileW9FormCreate]);

    const getMaidIDProofData = (): void => {
        if (curMaid != 0) {
            setIdProofLoading(true);
            (async () => {
                const idProofData = await fetchMaidIDProof(curMaid);
                console.log('idProofData: ', idProofData)
                if (idProofData.status == 200) {
                    console.log('idProofData.data: ', idProofData.data)
                    setIdProofFiles(idProofData?.data?.data ? idProofData.data.data : []);
                    setIdProofLoading(false);
                    //   const link = document.createElement('a');
                    //   link.href = url;
                    //   link.setAttribute('download', 'w9' + '.pdf');
                    //   document.body.appendChild(link);
                    //   link.click();

                }
                else {
                    setIdProofFiles([]);
                    setIdProofLoading(false);
                }
            })();
        }
    };

    const getMaidW9FormData = (): void => {
        if (curMaid != 0) {
            setW9FormLoading(true); 
            (async () => {
                const w9Data = await fetchMaidW9Form(curMaid);
                if (w9Data?.status == 200) {
                    let url = w9Data.data['w9'];
                    setW9FormFile(url);
                    setW9FormLoading(false);
                }
                else {
                    setW9FormLoading(false);
                }
            })();
        }
    };

    const onGenerateW9Form = () => {
        let params = { id: curMaid, ssn: w9SSN, signature: w9Signature }
        if (curMaid != 0) {
            (async () => {
                dispatch(createW9Form(params));
                // if (w9FormData.status == 200) {
                //     let url = w9FormData.data['w9'];
                //     setW9FormFile(url);
                // }
            })();
        }
    }

    const onUploadIDProof = () => {
        let fileOrder = 0;
        let len = 1;
        let imageOrder0 = false, imageOrder1 = false, imageOrder2 = false, imageOrder3 = false;
        //let len = (idProofFiles && idProofFiles.length) ? idProofFiles.length : 1; 
        // if (idProofFiles && idProofFiles.length) {
        //     let tmpOrder = 0;
        //     idProofFiles.forEach(item => {
        //         if (item.order === 1) { imageOrder1 = true; }
        //         else if (item.order === 0) { imageOrder0 = true; }
        //         else if (item.order === 1) { imageOrder1 = true; }
        //         else if (item.order === 2) { imageOrder2 = true; }
        //         else if (item.order === 3) { imageOrder3 = true; }
        //         // if (item.order !== tmpOrder) {
        //         //     fileOrder = item.order;
        //         // }
        //         // tmpOrder++;
        //     })
        //     fileOrder = (!imageOrder0) ? 0 : (!imageOrder1) ? 1 : (!imageOrder2) ? 2 : (!imageOrder3) ? 3 : 0;
        //     len = fileOrder + 1;
        // }
        let name = 'image' + len;
        let fileName = 'image' + len + '.png';
        // let params = { id: curMaid, uri: newIDProofFile, name: name, fileName: fileName, type: 'image/png', order: fileOrder, idProofFilesLength: idProofFiles.length }
        let params = { id: curMaid, uri: newIDProofFile, name: name, fileName: fileName, type: 'image/png', docId: selectedIdProofId, idProofFilesLength: idProofFiles.length }
        if (curMaid != 0) {
            (async () => {
                dispatch(uploadMaidIDProof(params));
            })();
        }
    }

    const onRemoveIdProof = (order: number) => {
        Store.addNotification({
            title: 'Remove ID Proof',
            id: 'idproof-modal',
            content: (
                <div className="idproof-modal div-center">
                    <div className="page-heading">
                        <h4>Remove ID Proof</h4>
                    </div>
                    <div className="idproof-flex-center">
                        {MSG_CONFIRM_REM_ID_PROOF_IMAGE}
                    </div>
                    <div className="idproof-margin"></div>
                    <div className="idproof-flex-center">
                        <button
                            type="button"
                            className="primary"
                            onClick={() => removeIdProof(order)}
                        >
                            Remove
                        </button>
                        <button
                            type="button"
                            className="primary margin-left-15px"
                            onClick={() => Store.removeNotification('idproof-modal')}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            ),
            type: 'default',
            container: 'center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 1000000000,
                click: false,
                touch: false,
            },
        });
    }

    const removeIdProof = (order: number) => {
        Store.removeNotification('idproof-modal');
        let params = { id: curMaid, order: order }
        if (curMaid != 0) {
            (async () => {
                dispatch(deleteMaidIDProof(params));
            })();
        }
    }

    const updateFieldMobile = (value: string) => {
        setSelectedMobile(value);
        if (!value || value.trim().length === 0) {
            setMobileEmpty(true);
        }
        else {
            setMobileEmpty(false);
        }
        clearErrors('mobileNumber');
    };

    const updateFieldAltMobile = (value: string) => {
        setSelectedAlternative(value);
        clearErrors('alternativeMobileNumber');
    };

    const {
        setValue,
        clearErrors,
        setError,
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<IMaidProfile>();

    React.useEffect(() => {
        setDocVerified(maidProfile?.documentVerified ? true : false);  
        setDisabledClass(maidProfile?.documentVerified ? ' disabled ' : '');  
    }, [maidProfile.documentVerified]); 
    

    React.useEffect(() => {
        reset({
            id: maidProfile.id,
            mobileNumber: maidProfile.mobileNumber,
            alternativeMobileNumber: maidProfile.alternativeMobileNumber,
            email: maidProfile.email,
            addressLine_1: maidProfile.addressLine_1,
            addressLine_2: maidProfile.addressLine_2,
            city: maidProfile.city,
            state: maidProfile.state,
            zip: maidProfile.zip,
            accountHolderName: maidProfile.accountHolderName,
            dateOfBirth: maidProfile.dateOfBirth,
            country: maidProfile.country ? maidProfile.country : DEFAULT_COUNTRY,
            ssn: maidProfile.ssn,
            accountNumber: maidProfile.accountNumber,
            bankName: maidProfile.bankName,
            routingNumber: maidProfile.routingNumber,
            firstName: maidProfile.firstName,
            lastName: maidProfile.lastName,
            middleName: maidProfile.middleName,
            currency: maidProfile.currency ? maidProfile.currency : DEFAULT_CURRENCY,
            paymentMethod: maidProfile.paymentMethod ? maidProfile.paymentMethod : STAFF_PAYMENT_METHOD_BANK,
            documentVerified: maidProfile.documentVerified, 
            documentTypeId: maidProfile.documentTypeId,
        });
        setSelectedMobile(maidProfile.mobileNumber);
        setSelectedAlternative(maidProfile.alternativeMobileNumber);
        setSelectedPaymentMethod(maidProfile.paymentMethod ? maidProfile.paymentMethod : STAFF_PAYMENT_METHOD_BANK);
        setSelectedPaymentOption(maidProfile.paymentMethod==STAFF_PAYMENT_METHOD_CHECK ? paymentOptionCheck : paymentOptionBank);

        setSelectedIdProofId(maidProfile?.documentTypeId ? maidProfile?.documentTypeId : 0); 
        let docId = maidProfile?.documentTypeId ? maidProfile?.documentTypeId : 0;  
        const foundItem = idProofDocTypes.some(opt => opt.value == docId.toString());
        if(foundItem) {
            let selItem = idProofDocTypes.filter(opt => opt.value == docId.toString()); 
            console.log('selItem: ',selItem); 
            setSelectedIdProofOption(selItem[0]); 
        }
    }, [reset, maidProfile]);

    const onSubmit: SubmitHandler<IMaidProfile> = (data) => {
        if (selectedPaymentMethod==STAFF_PAYMENT_METHOD_BANK && data.routingNumber.trim().length != 9) {
            setError('routingNumber', {
                type: 'manual',
                message: MSG_ROUTING_9_DIGITS,
            });
            return;
        }

        if (selectedMobile === '') {
            setError('mobileNumber', {
                type: 'manual',
                message: MSG_INVALID_PHONE_NUMBER,
            });
            return;
        }
        if (selectedMobile.charAt(0) != '+') {
            data.mobileNumber = '+' + selectedMobile;
        } else {
            data.mobileNumber = selectedMobile;
        }

        if (selectedAlternative.trim().length == 0) {
            data.alternativeMobileNumber = '';
        }
        else if (selectedAlternative.charAt(0) != '+') {
            data.alternativeMobileNumber = '+' + selectedAlternative;
        } else {
            data.alternativeMobileNumber = selectedAlternative;
        }
        data.paymentMethod = selectedPaymentMethod; 
        data.documentTypeId = selectedIdProofId; 
        dispatch(maidProfileUpdate(data));
        setMaidProfileLoaded(false);
    };


    const onErrorSubmit: SubmitErrorHandler<IMaidProfile> = (data) => {
        if (selectedMobile === '') {
            setError('mobileNumber', {
                type: 'manual',
                message: MSG_INVALID_PHONE_NUMBER,
            });
        }
        // if (selectedAlternative === '') {
        //   setError('alternativeMobileNumber', {
        //     type: 'manual',
        //     message: MSG_INVALID_PHONE_NUMBER,
        //   });
        // }
    };

    const onSaveSubmitted = (): void => {
        setSaveButtonClicked(true); 
        handleSubmit(onSubmit, onErrorSubmit)();
    };

    function remTokens() {
        removeMaidTokens();
        history.push({
            pathname: '/'+LBL_URL_MAIDS,
        });
    }

    const showIdProof = (image: string): void => {
        Store.removeNotification('id-proof');
        // remove notification doesnt immediately remove it.
        var interval = setInterval(function () {
            let idcheck = document.getElementById('id-dialogue');
            if (typeof idcheck != 'undefined' && idcheck != null) {
            } else {
                Store.addNotification({
                    id: 'id-proof',
                    container: 'center',
                    animationIn: [],
                    animationOut: [],
                    dismiss: {
                        duration: 1000000000,
                        click: false,
                        touch: false,
                    },
                    content: () => (
                        <div id="id-dialogue" className="id-display-modal-main">
                            <div className="box">
                                <div className="row">
                                    <div className="col id-proof-disp">
                                        <label className="h5 page-heading">ID Proof</label>
                                        <span
                                            className="closebtn id-proof-image"
                                            onClick={() => {
                                                Store.removeNotification('id-proof');
                                            }}
                                        >
                                            ×
                                        </span>
                                    </div>
                                </div>
                                <div className="row t-align-center">
                                    <div className="col">
                                        <div>
                                            <img className="id-image" src={image} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                });
                clearInterval(interval);
            }
        }, 500);
    };

    let dobMax = ((new Date().getFullYear())-10).toString()+'-01-01'; 

    return (
        <div className="bg-white min-h-full">
            <header className="justify-center px-20 border-b shadow ">
                <div className="profile-container max-width-500">
                    <ToastContainer autoClose={5000} />
                    <div className="left">
                        <img className="small maid-profile-logo" src={logo} alt={APP_NAME} />  
                    </div> 
                    <div className="center margin-top20 margin-right-15px">
                        <span className=" font-bold h3 font-merri">My Profile</span>
                    </div>
                    <div className="right margin-top15 margin-right-15px">
                        <span className="div-label font-bold font-bold">&nbsp; &nbsp; &nbsp;</span>
                    </div>
                </div>
                
                {/* <div className="align-right margin-top15 margin-right-10px">
                    <button type={'button'} className='primary' onClick={remTokens}>Logout</button>
                </div> */}
            </header>
            <div className="align-center-common flex-col mb-4">
                
                <div className="box-p20-t10 border max-width-500 mt-25px ">
                    <div className="max-width-500 div-right">
                        <div className="div-right div-label cursor-pointer-link" onClick={remTokens}>
                            Logout
                        </div> 
                    </div> 
                    <form autoComplete="off" className="box-p20-t10 align-center-common flex-col">

                        <div className="max-width-500 mb-4">
                            <h3 className="font-merri font-bold">{(maidProfile?.firstName ? maidProfile.firstName : '' ) + ' ' + (maidProfile.middleName ? maidProfile.middleName : '') + ' ' + (maidProfile?.lastName ? maidProfile.lastName : '' )}</h3>
                            <div className="content_header">
                                <div className="downoad-qr align-center-common">
                                    <label className="div-label font-bold">Contact Details</label>
                                    {maidProfileStatus == 'loading' && (
                                        <ReactLoading className="" color={'#2f427c'} type={'bars'} width={'3vw'} />
                                    )}
                                    {maidProfileStatus != 'loading' && (<button type={'button'} className='primary' onClick={() => onSaveSubmitted()}>Save</button>)}
                                </div>
                            </div>
                            <div className="box border">
                                <label className="div-label font-bold">Email Address</label>
                                <div className="mb-2">
                                    <input
                                        {...register('email', { required: 'Required.', onChange: (e) => checkMandatory(e, 'email') })}
                                        name="email"
                                        type="email"
                                        autoFocus
                                        className={emailEmpty ? "input-error w-100px" : "w-100px"}
                                    ></input>
                                    {errors.email && <span className="danger">{errors.email.message}</span>}
                                </div>
                                <label className="div-label font-bold">Mobile Number</label>
                                <div className="mb-2">
                                    <PhoneInput
                                        inputProps={{
                                            name: 'mobileNumberPhoneInput',
                                            required: true,
                                            autoFocus: false,
                                        }}
                                        containerClass="w-100px"
                                        inputClass={mobileEmpty ? "input-error tel-input disabled" : "tel-input disabled"}
                                        onlyCountries={['us', 'in']}
                                        country={'us'}
                                        value={selectedMobile}
                                        onChange={(code) => updateFieldMobile(code)}
                                        disabled={true}
                                    />
                                    {errors.mobileNumber && (
                                        <span className="danger">{errors.mobileNumber.message}</span>
                                    )}
                                </div>

                                <label className="div-label font-bold">Alternative Mobile Number</label>
                                <div className="mb-2">
                                    <PhoneInput
                                        inputProps={{
                                            name: 'alternativeMobileNumberPhoneInput',
                                            required: false,
                                            autoFocus: false,
                                        }}
                                        inputClass="tel-input"
                                        onlyCountries={['us', 'in']}
                                        country={'us'}
                                        value={selectedAlternative}
                                        onChange={(code) => updateFieldAltMobile(code)}
                                    />
                                    {/* {errors.alternativeMobileNumber && (
                    <span className="danger">{errors.alternativeMobileNumber.message}</span>
                  )} */}
                                </div>
                            </div>
                        </div>

                        <div className="max-width-500 mb-4">
                            <div className="content_header">
                                <div className="downoad-qr">
                                    <label className="div-label font-bold">Address</label>
                                </div>
                            </div>
                            <div className="box border">
                                <label className="div-label font-bold">Address Line1</label>
                                <div className="mb-2">
                                    <input
                                        {...register('addressLine_1', { required: 'Required.', onChange: (e) => checkMandatory(e, 'addressLine_1') })}
                                        name="addressLine_1"
                                        type="text"
                                        className={addressLine1Empty ? "input-error w-100px" : "w-100px"}
                                    ></input>
                                    {errors.addressLine_1 && <span className="danger">{errors.addressLine_1.message}</span>}
                                </div>
                                <label className="div-label font-bold">Adress Line2</label>
                                <div className="mb-2">
                                    <input
                                        {...register('addressLine_2')}
                                        name="addressLine_2"
                                        type="text"
                                        className="w-100px"
                                    ></input>
                                    {errors.addressLine_2 && <span className="danger">{errors.addressLine_2.message}</span>}
                                </div>
                                <label className="div-label font-bold">City</label>
                                <div className="mb-2">
                                    <input
                                        {...register('city', { required: 'Required.', onChange: (e) => checkMandatory(e, 'city') })}
                                        name="city"
                                        type="text"
                                        className={cityEmpty ? "input-error w-100px" : "w-100px"}
                                    ></input>
                                    {errors.city && <span className="danger">{errors.city.message}</span>}
                                </div>
                                <label className="div-label font-bold">State</label>
                                <div className="mb-2">
                                    <input
                                        {...register('state', { required: 'Required.', onChange: (e) => checkMandatory(e, 'state') })}
                                        name="state"
                                        type="text"
                                        className={stateEmpty ? "input-error w-100px" : "w-100px"}
                                    ></input>
                                    {errors.state && <span className="danger">{errors.state.message}</span>}
                                </div>
                                <label className="div-label font-bold">Zip Code</label>
                                <div className="mb-2">
                                    <input
                                        {...register('zip', { required: 'Required.', onChange: (e) => checkMandatory(e, 'zip') })}
                                        name="zip"
                                        type="text"
                                        className={zipEmpty ? "input-error w-100px" : "w-100px"}
                                    ></input>
                                    {errors.zip && <span className="danger">{errors.zip.message}</span>}
                                </div>
                            </div>
                        </div>

                        <div className="max-width-500 mb-4">
                            <div className="content_header">
                                <div className="downoad-qr">
                                    <label className="div-label font-bold">Bank Details</label>
                                </div>
                            </div>


                            <div className="box border">
                                <label className="div-label font-bold">Payment Method</label>
                                {/* <label className="color-blue">Payment Method</label> */}
                                <div className="mb-3">
                                    <Select
                                        onChange={e => handlePaymentOptionChange(e,'paymentMethod')}
                                        name="paymentMethod"
                                        onFocus={e => selectBoxOnFocus('paymentMethod')} 
                                        onBlur={e => selectBoxOnBlur('paymentMethod')} 
                                        className={disabledClass+' maxSelWidth'}
                                        classNamePrefix="react-select-maids"
                                        options={allPaymentOptions}
                                        value={selectedPaymentOption}
                                        isSearchable={true}
                                        isDisabled={(docVerified && selectedPaymentMethod==STAFF_PAYMENT_METHOD_BANK)?true:false}
                                    />
                                </div>
                                {selectedPaymentMethod==STAFF_PAYMENT_METHOD_BANK && ( <>
                                    <label className="div-label font-bold">Bank Account Payee Name</label>
                                <div className="mb-2">
                                    <input
                                        {...register('accountHolderName', { required: 'Required.', onChange: (e) => checkMandatory(e, 'accountHolderName') })}
                                        name="accountHolderName"
                                        type="text"
                                        className={accHolderNameEmpty ? (disabledClass+" input-error w-100px") : (disabledClass+" w-100px")}
                                        disabled={docVerified?true:false}
                                    ></input>
                                    {errors.accountHolderName && <span className="danger">{errors.accountHolderName.message}</span>}
                                </div>

                                <label className="div-label font-bold">Date of Birth</label>
                                <div className="mb-2">
                                    <input
                                        type="date"
                                        {...register('dateOfBirth', { maxLength: 10, required: 'Required.', onChange: (e) => checkMandatory(e, 'dateOfBirth') })}
                                        className={dobEmpty ? ("input-error w-100px "+disabledClass) : ("w-100px "+disabledClass)}
                                        maxLength={10}
                                        max={dobMax}
                                        disabled={docVerified?true:false}
                                    />
                                    {errors.dateOfBirth && <span className="danger">{errors.dateOfBirth.message}</span>}
                                </div>

                                <label className="div-label font-bold">Country</label>
                                <div className="mb-2">
                                    <input
                                        {...register('country', { required: 'Required.', onChange: (e) => checkMandatory(e, 'country') })}
                                        name="country"
                                        type="text"
                                        className={countryEmpty ? "disabled input-error w-100px" : "disabled w-100px"}
                                        disabled
                                    ></input>
                                    {errors.country && <span className="danger">{errors.country.message}</span>}
                                </div>

                                <label className="div-label font-bold">Last 4 digits of social security number</label>
                                <div className="mb-2">
                                    <input
                                        {...register('ssn', { required: 'Required.', onChange: (e) => checkMandatory(e, 'ssn') })}
                                        name="ssn"
                                        type="password"
                                        className={ssnEmpty ? ("input-error w-100px "+disabledClass) : ("w-100px "+disabledClass)}
                                        maxLength={4}
                                        disabled={docVerified?true:false}
                                    ></input>
                                    {errors.ssn && <span className="danger">{errors.ssn.message}</span>}
                                </div>

                                <label className="div-label font-bold">Bank Account number</label>
                                <div className="mb-2">
                                    <input
                                        {...register('accountNumber', { required: 'Required.', onChange: (e) => checkMandatory(e, 'accountNumber') })}
                                        name="accountNumber"
                                        type="text"
                                        className={accNumberEmpty ? ("input-error w-100px "+disabledClass) : ("w-100px "+disabledClass)}
                                        maxLength={35}
                                        disabled={docVerified?true:false}
                                    ></input>
                                    {errors.accountNumber && <span className="danger">{errors.accountNumber.message}</span>}

                                </div>
                                {/* <label className="div-label font-bold">Re Enter Account number</label>
                                    <div className="mb-2">
                                        <input
                                            {...register('reaccountNumber', { required: 'Required.' })}
                                            name="reaccountNumber"
                                            type="text"
                                            className={("w-100px "+disabledClass)}
                                        ></input>
                                        {errors.reaccountNumber && <span className="danger">{errors.reaccountNumber.message}</span>}
                                    </div> */}
                                <label className="div-label font-bold">Bank Name</label>
                                <div className="mb-2">
                                    <input
                                        {...register('bankName', { required: 'Required.', onChange: (e) => checkMandatory(e, 'bankName') })}
                                        name="bankName"
                                        type="text"
                                        className={bankNameEmpty ? ("input-error w-100px "+disabledClass) : ("w-100px "+disabledClass)} 
                                        disabled={docVerified?true:false}
                                    ></input>
                                    {errors.bankName && <span className="danger">{errors.bankName.message}</span>}
                                </div>

                                <label className="div-label font-bold">ABA/ ACH Routing Code</label>
                                <div className="mb-2">
                                    <input
                                        {...register('routingNumber', { required: 'Required.', onChange: (e) => checkMandatory(e, 'routingNumber') })}
                                        name="routingNumber"
                                        type="text"
                                        className={routingEmpty ? ("input-error w-100px "+disabledClass) : ("w-100px "+disabledClass)}
                                        maxLength={9}
                                        disabled={docVerified?true:false}
                                    ></input>
                                    {errors.routingNumber && <span className="danger">{errors.routingNumber.message}</span>}
                                </div>
                                </>

                                )}
                                
                                {/* <button type="button" className="primary" onClick={() => onSaveSubmitted()}>
                            Save Details
                        </button>
                        */}
                            </div>
                            <div>
                            </div>
                            {/* <div className="justify-right">
                        <button type={'button'} className='primary mt-33' onClick={() => onSaveSubmitted()}>Save</button>
                    </div> */}
                        </div>



                        {/* <div>{JSON.stringify(maidProfile.isAdminApproved)}</div>
                        <div>{JSON.stringify(idProofFiles)}</div> */}

                        {/* {((!maidProfile.isAdminApproved) || (maidProfile.isAdminApproved && idProofFiles && idProofFiles.length > 0)) && <> */}
                        {idProofFiles && <>
                            <div className="max-width-500">
                                <div className="content_header">
                                    <div className="downoad-qr">
                                        <label className="div-label font-bold">ID Proof</label>
                                    </div>
                                </div>
                                <div className="box border">

                                <label className="div-label">
                                    <div className="font-bold">ID Proof Type</div>
                                </label>
                                <div className="mb-2">
                                    <Select
                                        onChange={e => handlePaymentOptionChange(e,'idProof')}
                                        name="idProof"
                                        onFocus={e => selectBoxOnFocus('idProof')}
                                        onBlur={e => selectBoxOnBlur('idProof')}
                                        className="maxSelWidth"
                                        classNamePrefix="react-select-maids"
                                        options={idProofDocTypes}
                                        value={selectedIdProofOption}
                                        isSearchable={true}
                                        isDisabled={(docVerified && selectedPaymentMethod==STAFF_PAYMENT_METHOD_BANK)?true:false}
                                    />
                                    {/* {errors.accountNumber && <span className="danger">{errors.accountNumber.message}</span>} */}

                                </div>

                                    {idProofLoading === true && (
                                        <ReactLoading className="" color={'#2f427c'} type={'bars'} width={'3vw'} />
                                    )}
                                    {idProofRemovetatus == 'loading' && (
                                        <ReactLoading className="" color={'#2f427c'} type={'bars'} width={'3vw'} />
                                    )}

                                    {!idProofLoading && idProofRemovetatus != 'loading' && idProofFiles && idProofFiles.length > 0 && (
                                        idProofFiles.map((imageData, i) => (
                                            <>
                                                {/* <img
                                                    key={i}
                                                    className="image-idproof"
                                                    onClick={() => showIdProof(imageData.image)}
                                                    id="maidIdProof"
                                                    src={`${imageData.image}`}
                                                    alt="ID Proof"
                                                /> */}
                                                <img
                                                    key={i}
                                                    className="image-idproof"
                                                    id="maidIdProof"
                                                    src={`${imageData.image}`}
                                                    alt="ID Proof"
                                                />
                                                {!docVerified && <div className="danger div-right mb-10px link-text-u" onClick={(e) => onRemoveIdProof(imageData.order)} >Remove</div>}
                                                <div className="mt-20px">&nbsp;</div>
                                            </>
                                        ))
                                    )}

                                    {!idProofLoading && idProofRemovetatus != 'loading' && idProofFiles && idProofFiles.length > 0 && <div className="mb-2">
                                        <div className="">
                                        <label className="div-label">{LBL_DOCS_VERIFIED_BY_STRIPE}</label>
                                        </div>
                                        <div className="mb-2">
                                        <label className=" font-bold">{docVerified ? 'Yes' : 'No'}</label>
                                        </div>
                                    </div>}

                                    {selectedIdProofId > 0 && idProofFiles && idProofFiles.length < 1  && <>
                                        {!idProofLoading && (<label className="div-label">
                                            <div className="font-bold">Upload ID proof Image</div>
                                            <div className="small">  (only .png or .jpg files are supported.)</div>
                                        </label>)}
                                        {/* maidProfileIdProofUploadStatus */}
                                        {maidProfileIdProofUploadStatus == 'loading' && (
                                            <ReactLoading className="" color={'#2f427c'} type={'bars'} width={'3vw'} />
                                        )}
                                        {(!idProofLoading) && (maidProfileIdProofUploadStatus != 'loading') && (
                                            <div className="mb-2 div-flex-box">
                                                <ImageBoxMaidProfile
                                                    contentTitle={''}
                                                    label={''}
                                                    url={''}
                                                    defaultImage={newIDProofFile}
                                                    onUpload={(e) => onNewIDProofFileSubmitted(e)}
                                                    isIdProof={true}
                                                    imgId="idProof"
                                                />
                                            </div>
                                        )}
                                        {/* <div className="mb-4">
                                                {newIDProofFile && <div className="div-right  generate-w9" onClick={(e) => onUploadIDProof()}>Upload ID proof</div>}
                                            </div> */}
                                    </>}

                                </div>
                                {/* <div className="justify-right">
                                    {maidProfileStatus == 'loading' && (
                                        <ReactLoading className="" color={'#2f427c'} type={'bars'} width={'3vw'} />
                                    )}
                                    {maidProfileStatus != 'loading' && (<button type={'button'} className='primary mt-33' onClick={() => onSaveSubmitted()}>Save</button>)}
                                </div> */}
                            </div>
                        </>}

                        <div className="max-width-500 mb-4 mt-33">
                            <div className="content_header">
                                <div className="downoad-qr">
                                    <label className="div-label font-bold font-bold">W9 Form</label>
                                </div>
                            </div>
                            <div className="box border">
                                {maidProfileW9FormStatus == 'loading' && (
                                    <ReactLoading className="" color={'#2f427c'} type={'bars'} width={'3vw'} />
                                )}
                                {maidProfileW9FormStatus != 'loading' && (
                                    <>
                                        <label className="div-label font-bold">Social security number</label>
                                        <div className="mb-2">
                                            <input
                                                name="socialsecuritynumber"
                                                type="password"
                                                className={w9SSN && w9SSN.length > 0 &&  w9SSN.length !== 9 ? "input-error w-100px" : "w-100px"}
                                                maxLength={9}
                                                onChange={(e) => { onChangeW9SSN(e) }}
                                                value={w9SSN}
                                            ></input>
                                        </div>
                                        <label className="div-label font-bold">Signature</label>
                                        <div className="clearcan" onClick={()=>sigCanvas.current.clear()}>
                                        Clear
                                        </div>
                                        <div className="mb-2 div-flex-box">
                                            {/* <ImageBox
                                                contentTitle={''}
                                                label={''}
                                                url={''}
                                                defaultImage={w9Signature}
                                                onUpload={(e) => onW9SignatureSubmitted(e)}
                                                isSignature={true}
                                            /> */}
                                            <SignaturePad
                                                ref={sigCanvas}
                                                onEnd={() => formatIntoPng()}
                                                penColor="black"
                                                canvasProps={{
                                                    width: 315,
                                                    height: 200,
                                                    style: { border: "1px solid black",borderRadius:5 },
                                                }}
                                            />
                                        </div>

                                        {/* <label className="div-label font-bold">Signature</label>
                                        <div className="mb-2 div-flex-box">
                                            <SignaturePad canvasProps={{width: 400, height: 200, className: 'sigCanvas'}}  />
                                            
                                        </div> */}

                                        

                                        <div className="mb-4">
                                            {w9FormLoading === true && (
                                                <ReactLoading className="" color={'#2f427c'} type={'bars'} width={'3vw'} />
                                            )}
                                            {!w9FormLoading && w9FormFile && w9FormFile.length > 2 && <div className="div-left "><Link to={{ pathname: w9FormFile }} target="_blank" >Download W9 Form</Link></div>}

                                            {/* {w9Signature && w9SSN && w9SSN.length == 9 && <div className="div-right  generate-w9" onClick={(e) => onGenerateW9Form()}>Generate W9 Form</div>} */}
                                        </div>
                                    </>)}

                                    <div className="justify-right">
                                    {maidProfileStatus == 'loading' && (
                                        <ReactLoading className="" color={'#2f427c'} type={'bars'} width={'3vw'} />
                                    )}
                                    {maidProfileStatus != 'loading' && (<button type={'button'} className='primary mt-33' onClick={() => onSaveSubmitted()}>Save</button>)}
                                </div>
                            </div>
                        </div>

                    </form>


                </div>

            </div>
            <div>&nbsp;</div>
        </div>
    )
}