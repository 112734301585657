import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotel, IHotelDefaultConfig, IHotelSettingsForm } from '../../models/hotelsInterfaces';
import {
  fetchHotel,
  fetchConfig,
  updateHotel,
  createHotelNew,
  getHotelsettings,
  getHotelAdminsettings,
  addHotelsettings,
  activateHotel,
  deactivateHotel,
} from '../../services/hotelService';
import { IGenericResponse, IPagination } from '../../services/interfaces';
import { Store } from 'react-notifications-component';
import { APP_NAME, notification_shown_time_success } from '../../config/env';
import { MSG_HOTEL_DETAILS_SAVED, MSG_SETTINGS_UPDATED, MSG_SETTINGS_UPDATION_FAILED } from '../../config/messages';



export interface DataState {
  hotelSettingsForm: IHotelSettingsForm;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  newSettingsAdded: boolean;
}

const initialState: DataState = {
  hotelSettingsForm: {} as IHotelSettingsForm,
  loading: 'idle',
  error: '',
  newSettingsAdded: false,
};

export const hotelSettingsFormDataSlice = createSlice({
  name: 'hotelFormSettingsData',
  initialState,
  reducers: {
    setHotelSettingsForm: (state, action: PayloadAction<any>) => {
      state.hotelSettingsForm = action.payload;
    },
  
    setNewSettingsAdded: (state, action: PayloadAction<boolean>) => {
      state.newSettingsAdded = action.payload;
    },
  },
  extraReducers: (builder) => {
    // from admin settings
    builder.addCase(getHotelsettings.pending, (state) => {
      state.hotelSettingsForm = {} as IHotelSettingsForm;
      state.loading = 'loading';
    });
    builder.addCase(getHotelsettings.fulfilled, (state, action) => {
      const m = action.payload as IHotelSettingsForm;
      state.hotelSettingsForm = m;
      state.loading = 'succeeded';
    });
    builder.addCase(getHotelsettings.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.hotelSettingsForm = {} as IHotelSettingsForm;
    });
    builder.addCase(getHotelAdminsettings.pending, (state) => {
      state.hotelSettingsForm = {} as IHotelSettingsForm;
      state.loading = 'loading';
    });
    builder.addCase(getHotelAdminsettings.fulfilled, (state, action) => {
      const m = action.payload as IHotelSettingsForm;
      state.hotelSettingsForm = m;
      state.loading = 'succeeded';
    });
    builder.addCase(getHotelAdminsettings.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.hotelSettingsForm = {} as IHotelSettingsForm;
    });
    builder.addCase(fetchConfig.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      const m = action.payload as IHotelDefaultConfig;
      state.loading = 'succeeded';
    });
    builder.addCase(fetchConfig.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });
    builder.addCase(addHotelsettings.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(addHotelsettings.fulfilled, (state, action) => {
      const m = action.payload as IHotelSettingsForm;
      state.hotelSettingsForm = m;
      state.loading = 'succeeded';
      if(!action.payload) {
        Store.addNotification({
          title: APP_NAME,
          message: MSG_SETTINGS_UPDATION_FAILED,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
      else {
        Store.addNotification({
          title: APP_NAME,
          message: MSG_SETTINGS_UPDATED,
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
      
    });
    builder.addCase(addHotelsettings.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      Store.addNotification({
        title: APP_NAME,
        message: MSG_SETTINGS_UPDATION_FAILED,
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      // state.error = action.error.message;
    });
    builder.addCase(createHotelNew.pending, (state, { payload }: any) => {
      state.loading = 'loading';
      // state.error = action.error.message;
    });
    builder.addCase(createHotelNew.fulfilled, (state, action) => {
      const m = action.payload as IHotelSettingsForm;
      state.hotelSettingsForm = m;
      state.loading = 'succeeded';
      Store.addNotification({
        title: APP_NAME,
        message: MSG_HOTEL_DETAILS_SAVED,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      state.newSettingsAdded = true;
    });
    builder.addCase(createHotelNew.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });

  },
});

// Action creators are generated for each case reducer function
export const { setHotelSettingsForm, setNewSettingsAdded } =
hotelSettingsFormDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectHotelSettingsFormDataSlice = (state: RootState) => state.hotelSettingsFromData

export const hotelSettingsFormDataReducer = hotelSettingsFormDataSlice.reducer;
