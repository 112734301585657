import { get, getWithoutAuth, post, put, postFile, getFile, patch, getPdfFile, postWithoutAuth } from './apiService';
import { useAppDispatch, useAppSelector } from '../helpers/hook';
import { IHotelAssociateListRequestData,IHotelAssociateCreate, IHotelAssociateModify, IAdminAssociateUsername } from '../models/hotelAssociateInterfaces';
import { IGenericResponse } from './interfaces';
import { createSlice, createSelector, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { IHotelForm, IHotelId } from '../models/hotelsInterfaces';
import { Store } from 'react-notifications-component';

export const fetchHotelAssociates = createAsyncThunk(
  'hotels/fetchHotelAssociates',
  async (assocHotel: IHotelAssociateListRequestData, thunkAPI: any) => {
    try {
      let url = 'hotels/v1/users-list/'+assocHotel.hotelId;  
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createHotelAssociate = createAsyncThunk(
  'hotels/createHotelAssociate',
  async (newAssoc: IHotelAssociateCreate, thunkAPI: any) => {
    try {
      const url = 'hotel-users/v1/user/create/';
      const response = post(url, newAssoc);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const modifyHotelAssociate = createAsyncThunk(
  'hotels/modifyHotelAssociate',  
  async (assocData: IHotelAssociateModify, thunkAPI: any) => {
    try {
      const url = 'hotel-users/v1/user/update/'+assocData.userId+'/';
      const response = post(url, assocData);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const AdminAssociateUsernameSearch = createAsyncThunk(
  'associates/AdminAssociateUsernameSearch',
  async (newAssoc: IAdminAssociateUsername, thunkAPI: any) => {
    try {
      let url = 'hotel-users/v1/hotel-user/details/?username=' + newAssoc.username;
      const response = get(url);
      console.log("data", response);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const statusUpdateHotelAssociate = createAsyncThunk(
  'hotels/statusUpdateHotelAssociate',  
  async (assocId: number, thunkAPI: any) => {
    try {
      const url = 'hotel-users/v1/user-status-update/'+assocId+'/';
      const response = post(url, {});
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resetUserPassword = createAsyncThunk(
  'associates/resetUserPassword',
  async (username: String, thunkAPI: any) => {
    try {
      const url = 'hotels/v1/password/reset/?username=' + username; 
      const response = post(url, "");
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 

export const resetUserPasswordNoAuth = createAsyncThunk(
  'associates/resetUserPassword',
  async (username: String, thunkAPI: any) => {
    try {
      const url = 'hotels/v1/password/reset/?username=' + username; 
      const response = postWithoutAuth(url, "");
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);