import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotelNameId } from '../../models/hotelsInterfaces';
import { fetchHotelsNameId } from '../../services/maidPaymentsService';
import { IAutocompleteSelectOptionType } from '../../models/maidsInterfaces';
import { getUpdateURLParameter } from '../../helpers/url';
import { LBL_VAR_MAID_PAY_HOTEL } from '../../config/common_labels';

export interface FilterState {
  curValue: string;
  curValueTwo: string;
  hotelNameIds: IHotelNameId[];
  curHotelOPt: IAutocompleteSelectOptionType;
  curHotelOPtTwo: IAutocompleteSelectOptionType;
  allHotelOpts: IAutocompleteSelectOptionType[];
  allHotelOptsWithAll: IAutocompleteSelectOptionType[];
  isHotelActive: boolean;
}

const initialState: FilterState = {
  curValue: '0',
  curValueTwo: '0',
  hotelNameIds: [],
  curHotelOPt: {} as IAutocompleteSelectOptionType,
  curHotelOPtTwo: {} as IAutocompleteSelectOptionType,
  allHotelOpts: [],
  allHotelOptsWithAll: [],
  isHotelActive: true,
};

export const maidPaymentsHotelFilterSlice = createSlice({
  name: 'maidPaymentsHotelFilter',
  initialState,
  reducers: {
    changeHotelFilter: (state, action: PayloadAction<any>) => {
      state.curValue = action.payload;
      if (action.payload != 0) {
        window.history.replaceState(
          '',
          '',
          getUpdateURLParameter(window.location.href, LBL_VAR_MAID_PAY_HOTEL, action.payload)
        );
      }
      let arrHotels = action.payload != 0 ? state.allHotelOpts : state.allHotelOptsWithAll; 
      let op = arrHotels.find((option) => option.value === action.payload);
      if (op !== undefined) {
        state.curHotelOPt = op;
        let opVal = parseInt(op.value);
        if (opVal != undefined) {
          let hotel = state.hotelNameIds.find((option) => option.id === opVal);
          if (hotel !== undefined) {
            state.isHotelActive = hotel.isActive;
          } else {
            state.isHotelActive = true;
          }
        }
      }
    },
    changeHotelFilterTwo: (state, action: PayloadAction<any>) => {
      state.curValueTwo = action.payload;
      let arrHotels = action.payload != 0 ? state.allHotelOpts : state.allHotelOptsWithAll; 
      let op = arrHotels.find((option) => option.value === action.payload);
      if (op !== undefined) {
        state.curHotelOPtTwo = op;        
      }
    },
  },
  
  extraReducers: (builder) => {
    builder.addCase(fetchHotelsNameId.pending, (state) => {
      state.hotelNameIds = [];
    });
    builder.addCase(fetchHotelsNameId.fulfilled, (state, action) => {
      const hotels = action.payload as IHotelNameId[];
      state.hotelNameIds = hotels;
      let opts: IAutocompleteSelectOptionType[] = [];
      let optsAll: IAutocompleteSelectOptionType[] = [];
      optsAll.push({ value: '0', label: 'All Hotels' ,active: true});
      hotels.map((hotelNameId) => {
        opts.push({ value: hotelNameId.id.toString(), label: hotelNameId.hotelName ,active: hotelNameId.isActive ? true : false});
        optsAll.push({ value: hotelNameId.id.toString(), label: hotelNameId.hotelName ,active: hotelNameId.isActive ? true : false});
      });
      state.allHotelOpts = opts;
      state.allHotelOptsWithAll = optsAll;

      
      // if (opts.length > 0) {
      //   if (state.curValue == 0) {
      //     let opt = opts.find((option) => option.value !== undefined);
      //     if(!opt || opt == undefined) {
      //       opt = opts[0]; 
      //     }
      //     if (opt != undefined) {
      //       state.curHotelOPt = opt;
      //       state.curHotelOPtTwo = opt;
      //       state.curValue = parseInt(opt.value);
      //       state.curValueTwo = parseInt(opt.value);
      //       let opVal = parseInt(opt.value);
      //       if (opVal != undefined) {
      //         let hotel = state.hotelNameIds.find((option) => option.id === opVal);
      //         if (hotel !== undefined) {
      //           state.isHotelActive = hotel.isActive;
      //         } else {
      //           state.isHotelActive = true;
      //         }
      //       }
      //     }
      //   } else {
      //     let opt = opts.find((option) => parseInt(option.value) == state.curValue);
      //     if(!opt || opt == undefined) {
      //       opt = opts[0]; 
      //     }
      //     if (opt != undefined) {
      //       state.curHotelOPt = opt;
      //       state.curHotelOPtTwo = opt;
      //       state.curValue = parseInt(opt.value);
      //       state.curValueTwo = parseInt(opt.value);
      //       let opVal = parseInt(opt.value);
      //       if (opVal != undefined) {
      //         let hotel = state.hotelNameIds.find((option) => option.id === opVal);
      //         if (hotel !== undefined) {
      //           state.isHotelActive = hotel.isActive;
      //         } else {
      //           state.isHotelActive = true;
      //         }
      //       }
      //     }
      //   }
      // }
    });
    builder.addCase(fetchHotelsNameId.rejected, (state, { payload }: any) => {
      //   state.loading = "error";
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { changeHotelFilter, changeHotelFilterTwo } = maidPaymentsHotelFilterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidPaymentsHotelFilterSlice = (state: RootState) =>
  state.maidPaymentsHotelFilter;

export const maidPaymentsHotelFilterReducer = maidPaymentsHotelFilterSlice.reducer;
