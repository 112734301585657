import { get, post, put, deleteApi, getFile, postFile } from './apiService';
import { env_var } from '../config/env';
import { IMaidsRequestData, IMaidsExportRequestData } from '../models/maidsInterfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getHotelImage = (hotel: string): any => {
  try {
    let url = 'hotels/v1/hotel/get-hotel-tip-details/' + hotel + '/';
    return get(url);
  } catch (error) {
    throw error;
  }
};
