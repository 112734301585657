import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { getMaidPhone, removeMaidTokens } from "../../services/localStorage";
import { isMaidAuthenticated } from "../../services/maidAuthenticationService";

import logo from './../../assets/img/tova_stack.png';
import { authenticateMaid, verifyOTP } from './maidAuthenticationSlice';
import { LBL_URL_MAIDS } from '../../config/common_labels';

export const MaidOTP = () => {
    const history  = useHistory();
    const location = useLocation();
    const maidPhone = getMaidPhone(); 
    const phoneOTP = localStorage.getItem('phoneOTP'); 
    // const { maidPhone } = useSelector((state: any) => state.maidAuthentication); 

    console.log('maidPhone from MaidOTP: ',maidPhone); 
    console.log('phoneOTP  from MaidOTP: ',phoneOTP); 

    if (isMaidAuthenticated()) {
        history.push('/'+LBL_URL_MAIDS+'/profile'); 
    }

    const [otp, setOtp] = useState('');

     const dispatch = useAppDispatch();

    useEffect(() => {
        if(maidPhone && maidPhone.length > 1 && phoneOTP && phoneOTP.length > 1 ) {
            setOtp(phoneOTP); 
            dispatch(verifyOTP(maidPhone,phoneOTP));
            localStorage.removeItem('phoneOTP'); 
            
            setTimeout(() => {
                if (isMaidAuthenticated()) {
                    history.push('/'+LBL_URL_MAIDS+'/profile'); 
                }
            },1500); 
        }
    }, []);

     const onResendOTP = (e: any, maidPhone:any) => {
        e.preventDefault();
        dispatch(authenticateMaid(maidPhone));
    }

     const submitHandler = (e: any,maidPhone:any) => {
        e.preventDefault();
        localStorage.setItem('phoneOTP', otp); 
        dispatch(verifyOTP(maidPhone,otp));

        setTimeout(() => {
            if (isMaidAuthenticated()) {
                history.push('/'+LBL_URL_MAIDS+'/profile'); 
            }
        },1750); 
    }

    

    return (
        <div className="min-h-full bg-white">
            <div className="align-center-common">
                <div className="mt-33 max-width-500 ">
                    <ToastContainer autoClose={5000} />
                    <form autoComplete="off" className="box border" onSubmit={(e) => submitHandler(e,maidPhone)} >
                        <div className="align-center-common flex-col padding-top20 mb-4">
                            <img src={logo} alt='maideasy' className="maid-login-logo mb-4" />
                            <p className="">Verification code is sent to</p>
                            <p className="">{maidPhone}</p>
                            <p className="">
                                <div className="text-center">
                                    <label className='div label font-bold'>Didn&apos;t receive code? </label> &nbsp;
                                    <label className="div-label cursor-pointer">
                                    <Link className="div-label font-bold" to='#' onClick={(e) => onResendOTP(e,maidPhone)}>Resend.</Link>
                                    </label>
                                </div>
                            </p>

                            {/* <p className="">Didn&apos;t &nbsp; receive code? &nbsp;<Link className="div-label font-bold" to='#' onClick={(e) => onResendOTP(e,maidPhone)}>Resend.</Link></p> */}
                        </div>
                        <div className="align-center-common px-20 flex-col mt-33">
                            <label htmlFor="otp" className="div-label font-bold mb-2">One Time Password.</label>
                            <input
                                type={'number'}
                                id="otp"
                                required
                                name="otp"
                                value={otp}
                                autoFocus
                                onChange={(e) => setOtp(e.target.value)}
                                className="w-100px mb-4"
                                placeholder="Enter OTP here"
                            />
                            <button type="submit" className="primary w-100px">Verify OTP</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}