import React, { useEffect, useState } from 'react';

interface IProps {
    currentSearch: string;
    onSearch: (search: any) => void;
    place_holder: string;
    searchButton: boolean;
    onSearchButton: (search: any) => void;
    searchDisabled?: boolean;
}

const SearchBar: React.FC<IProps> = (props) => {
    return (
        <div className="">
            <form autoComplete="off" onSubmit={props.onSearchButton} >
            <input
                type="search"
                name="searchbar"
                value={props.currentSearch}
                onChange={props.onSearch}
                placeholder={props.place_holder}
                className="input-search"
                disabled={props.searchDisabled ? true : false}
            />
            {props.searchButton && <button type="button" className="primary margin-left-15px" onClick={props.onSearchButton} >
                Search
            </button>}
            </form>
        </div>
    );
};

export default SearchBar;
