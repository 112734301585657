import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/img/tova.png';
import logo2 from '../../assets/img/tova_stack.png';
import success from '../../assets/img/success_tick.svg';
import { checkIos } from '../../helpers/ios'; 

import getSymbolFromCurrency from 'currency-symbol-map';
import { getHotelImage } from '../../services/tipService';
import { APP_NAME, COPYRIGHT_TEXT } from '../../config/env';

import './style.css'; 
import '../Tip/style.css'; 
import logoFB from '../../assets/img/facebook.svg';
import logoInsta from '../../assets/img/instagram.svg';
import logoTwitter from '../../assets/img/twitter.svg';
import logoYoutube from '../../assets/img/youtube.svg';
import logoGoogle from '../../assets/img/google.svg';
import logoTripadvisor from '../../assets/img/tripadvisor-logo.svg';
import logoApplePay from '../../assets/img/apple-pay.svg';
import { LBL_MAIDS } from '../../config/common_labels';

interface CustomizedState {
  amount: string
  hotel_logo: string,
  hotel_data: string,
  tipValue: string,
}

export const Success = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as CustomizedState; // Type Casting, then you can get the params passed via router
  const { amount, hotel_logo, hotel_data, tipValue } = state; //reading state params
  const [currency, setCurrency] = useState<string>('');
  const [totalTipValue, setTotalTipValue] = useState<string>('');
  const [hotelLogo, setHotelLogo] = useState<string>(hotel_logo); //seting hotel logo
  const [hoteldata, setHoteldata] = useState<any>(hotel_data) //setting hotel data 

  const [hotelJSONData, setHotelJSONData] = useState<any>()

  React.useEffect(() => {
    window.scrollTo(0, 0)
    const url: URL = new URL(window.location.href);
    const params: URLSearchParams = url.searchParams;
    console.log('amount: ',JSON.stringify(amount));   
    console.log('hotel_logo: ',JSON.stringify(hotel_logo));
    setHotelJSONData(JSON.parse(JSON.stringify(hotel_data))); 
    const curFromUrl = params.get('currency');
    let currencySymbol = '';
    if (curFromUrl !== undefined) {
      currencySymbol = getSymbolFromCurrency(curFromUrl || '') || '';
    }

    let amountPaid = amount;
    if (amountPaid != undefined) {
      let amountPaidFloat = parseFloat(amountPaid);
      setCurrency(`${String(currencySymbol)}` + `${amountPaidFloat.toFixed(2)}`);
    }

    let tipValuePaid = tipValue;
    if (tipValuePaid != undefined) {
      let tipValuePaidFloat = parseFloat(tipValuePaid);
      setTotalTipValue(`${String(currencySymbol)}` + `${tipValuePaidFloat.toFixed(2)}`);
    }

    let hotel_id = params.get('hotel_id');
    if (hotel_id != undefined || hotel_id != null) {
      getHotelImage(hotel_id).then((res: any) => {
        if (res['status'] === 200) {
          if (res.data['url'] != null) {
            setHotelLogo(res.data['url']);
          }
        }
      });
    }
    console.log('hotelJSONData: ',hotelJSONData);
  }, []);

  return (
    <>
      <div className='max-width-500 div-center-margin-auto '>
        <div className='bg-white' >
          <div>
            <div>
              <div className="header-tip justify-space-between">
                <div className="justify-left">
                  <div className="div-left">
                    <div className="div-left">
                      <img className="logo_maid_tip" src={logo2} alt={APP_NAME} />
                    </div>
                  </div>
                </div>
                <div className="div-right mb-rem04 ">
                  {hotelLogo === 'null' && <span className="hotel_name">{hoteldata.name}</span>}
                  {hotelLogo !== 'null' && <img className="hotel_logo" src={hotelLogo} alt={hoteldata.name} />}
                </div>
              </div>
            </div>
          </div>
          <div className="content_container tipbg margin-top40 ">
            <div className="justify-center pt-5">
              <img src={success} alt="success" />
            </div>
            <div className="justify-center mt-3">
              <label className="didact-30">Payment Successful</label>
            </div>
          
            <div className="box-bl margin-top35">
              <div className='box-b2' >
                <div className="justify-center">
                  <label> <span className='amount-paid'>Amount Tipped</span></label>
                </div>
                <div className="justify-center">
                  <label className="didact-30">${currency}</label>
                </div>
                <div className="justify-center">
                  <label> <span className='amount-paid'>Amount Charged: ${totalTipValue}</span></label>
                </div>
                
              </div> 
            </div>

            <div className="mt-5 justify-center ">
              <div className="helv-nue-24">Thank you for tipping the staff at {hoteldata.name}!</div>
            </div>
            {/* <div className="margin-top15 text-center helv-reg-16 div-center-85">
              {/* Your tip goes directly to the {LBL_MAIDS} who clean this room.
              {/* Thank you for using {APP_NAME}!  
              Thank you for tipping the staff at {hoteldata.name}!
            </div> */}
            {/* <div className="margin-top40 text-center div-center-90">
                <button className="btnbg padding-tb w-90perc mb-1 btn-text  div-center-85">
                Leave us a review 
                {checkIos() != true && <span onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.usetova','_blank')}}><img src={logoGoogle} alt="Google" className="margin-left-20px"/></span> }
                {checkIos() == true && <span onClick={() => { window.open('https://apps.apple.com/us/app/maid-easy/id1610376065','_blank')}}><img src={logoApplePay} alt="Apple Pay" className="margin-left-20px"/></span> }
                </button> 
            </div> */}

            {hotelJSONData?.googleReviewLink && hotelJSONData?.googleReviewLink.trim().length > 2 && <div className="margin-top40 text-center div-center-95"><button className="btnbg padding-tb w-98perc mb-1 btn-text review-button " onClick={() => { window.open(hotelJSONData.googleReviewLink,'_blank')}} title="Google"> Review us on Google <span ><img src={logoGoogle} alt="Google" className="margin-left-20px"/></span></button> </div> }

            {hotelJSONData?.tripAdvisorLink && hotelJSONData?.tripAdvisorLink.trim().length > 2 && <div className="margin-top40 text-center div-center-95"><button className="btnbg padding-tb w-98perc mb-1 btn-text review-button" onClick={() => { window.open(hotelJSONData.tripAdvisorLink,'_blank')}} title="Tripadvisor" > Review us on Tripadvisor <span ><img src={logoTripadvisor} alt="Tripadvisor" className="margin-left-5px" height={'40'}/></span></button> </div> }

            <div className="margin-top20 text-center div-center-90">&nbsp;</div>
            <div className='socialIconsdiv margin-top60'>
                {/* <a href={hoteldata.facebookLink} target="_blank" rel="noreferrer" ><img src={logoFB} alt="FB" className="sm-icons"/></a>
                <a href={hoteldata.instagramLink} target="_blank" rel="noreferrer" ><img src={logoInsta} alt="Instagram" className="sm-icons"/></a>
                <a href={hoteldata.twitterLink} target="_blank" rel="noreferrer" ><img src={logoTwitter} alt="Twitter" className="sm-icons"/></a>
                <a href={hoteldata.youtubeLink} target="_blank" rel="noreferrer" ><img src={logoYoutube} alt="Youtube" className="sm-icons"/></a> */}

                <p className='m-0 ms-3 fontz margin-top8'>POWERED BY</p>
                <img className="logo margin-top8" src={logo} alt={APP_NAME} />
                <p className='m-0 fontz margin-top20'>&nbsp;</p>
            </div>

            {/* <div className='socialIconsdiv mt-4'>
              <div className='socialIconsinnerDiv'>
              <a href={hoteldata.facebookLink} target="_blank" rel="noreferrer" className="fa fa-facebook"></a>
              <a href={hoteldata.instagramLink} target="_blank" rel="noreferrer" className="fa fa-instagram"></a>
              <a href={hoteldata.twitterLink} target="_blank" rel="noreferrer" className="fa fa-twitter"></a>
              <a href={hoteldata.youtubeLink} target="_blank" rel="noreferrer" className="fa fa-youtube-play" ></a>
              </div>
          
              <p className='m-0  fontz'>POWERED BY</p>
              <img className="logo" src={logo} alt={APP_NAME} />
            </div> */}

          </div>
        </div>
      </div>
    </>
  );
};
