import { get, post, put, deleteApi, getFile } from './apiService';
import { useAppDispatch, useAppSelector } from '../helpers/hook';
import { setHotelList } from '../pages/HotelList/hotelListDataSlice';
import {
  IHotelPayment,
  IHotelPaymentsRequestData,
  IHotelPaymentFilterRequest,
} from '../models/hotelPaymentsInterfaces';
import { IGenericResponse } from './interfaces';
import { createSlice, createSelector, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

export const fetchPayments = createAsyncThunk(
  'hotels/fetchPayments',
  async ({ page, search, start, end, type }: IHotelPaymentsRequestData, thunkAPI: any) => {
    try {
      let url = 'payments/v1/payments/?page=' + page;
      if (search != '') {
        url = url + '&search=' + search?.trim();
      }
      if (start != '') {
        url = url + '&start=' + start;
      }
      if (end != '') {
        url = url + '&end=' + end;
      }
      if (type != '') {
        url = url + '&type=' + type;
      }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchPaymentsFilterData = createAsyncThunk(
  'hotels/fetchPaymentsFilter',
  async ({ search, start_date, end_date, type }: IHotelPaymentFilterRequest, thunkAPI: any) => {
    try {
      let url =
        'payments/v1/maid-payments/filter/?start_date=' +
        start_date +
        '&end_date=' +
        end_date +
        '&type=' +
        type;
      if (search != '') {
        url = url + '&search=' + search?.trim();
      }  
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const exportHotelPayments = ({
  search,
  start,
  end,
  type,
}: IHotelPaymentsRequestData): void => {
  try {
    let url = 'payments/v1/payments/csv-download/?csv=true';
    if (search != '') {
      url = url + '&search=' + search?.trim();
    }
    if (start != '') {
      url = url + '&start=' + start;
    }
    if (end != '') {
      url = url + '&end=' + end;
    }
    url = url + '&type=' + type;
    getFile(url, 'Hotel_Payments_Exported');
  } catch (error) {
  }
};
