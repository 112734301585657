import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { emaillogs, } from '../../models/hotelsInterfaces';
import { fetchemailLog, fetchPaymentLog } from '../../services/hotelService';
import { IGenericResponse, IPagination } from '../../services/interfaces';

export interface DataState {
  emailLogs: emaillogs[];
  logTotalRows:number;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  totalSize: number;
}

const initialState: DataState = {
  emailLogs: [],
  logTotalRows:0,
  loading: 'idle',
  error: '',
  totalSize: 0,
};

export const emailListDataSlice = createSlice({
  name: 'emaillogListData',
  initialState,
  reducers: {
    setEmailList: (state, action: PayloadAction<any>) => {
      state.emailLogs = action.payload;
    },
    setDataLoading: (state) => {
      state.loading = 'loading';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchemailLog.pending, (state) => {
      state.emailLogs = [];      
      state.loading = 'loading';
    });
    builder.addCase(fetchemailLog.fulfilled, (state, action) => {  
      const m = action.payload as IPagination<emaillogs[]>;    
      state.logTotalRows=m.count;
      state.emailLogs = m.results;
      state.loading = 'succeeded';
    });
    builder.addCase(fetchemailLog.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setEmailList, setDataLoading } = emailListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectEmailListDataSlice = (state: RootState) => state.emailListData;

export const emailListDataReducer = emailListDataSlice.reducer;
