import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { selectTipSlice, TipState } from '../Tip/tipSlice';
import { fetchStripeToken } from '../../services/guestPaymentService';

export const StripeWrapper = (props: any) => {
  const dispatch = useAppDispatch();

  const { tipValue, hotelValue } = useAppSelector(selectTipSlice);

  React.useEffect(() => {
    const req: TipState = {
      tipValue: tipValue,
      hotelValue: hotelValue,
      tipValueWithoutCommission:tipValue,
    };
    dispatch(fetchStripeToken(req));
  }, []);

  return <></>;
};
