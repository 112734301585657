import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import InfiniteScroll from 'react-infinite-scroller';
import Table from '../../components/Table';
import { EmailLogFields, PaymentsLogFields } from '../../config/tables';

import { changeSearch, selectHotelListSearchSlice } from '../HotelList/hotelListSearchSlice';
import logo from './../../assets/img/tova_stack.png';
import { fetchemailLog } from '../../services/hotelService';
import { selectEmailListDataSlice, setDataLoading } from './emailListDataSlice';
import { PAGINATION_NUM_ROWS } from '../../config/env';
import { IEmailLogListRequestData } from '../../models/hotelsInterfaces';

export const EmailLog = (props: any) => {
  const { emailLogs, logTotalRows, loading } = useAppSelector(selectEmailListDataSlice);
  const [listFetchHotelPromise, setListFetchHotelPromise] = useState<Array<any>>([]);
  const [emailLogsList, setEmailLogsList] = useState<any>([]);
  const [nextPage, setNextPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const [displaySearch, setDisplaySearch] = useState<string>('');
  const [appLogo, setAppLogo] = useState<boolean>(true);
  const [smallHeader, setSmallHeader] = useState<boolean>(false);
  const [contentTitle, setContentTitle] = useState<string>('Email Log');
  const dispatch = useAppDispatch();
  const [applySearch, setApplySearch] = useState(0);
  const { searchValue } = useAppSelector(selectHotelListSearchSlice);
  // const { currentPage } = useAppSelector(selectHotelListPaginationSlice);

  const fetchall = (): void => {
    let sVal = searchValue;

    sVal = displaySearch;
    if (hasMore || nextPage == 1) {
      const req: IEmailLogListRequestData = {
        page: nextPage,
        search: sVal,
      };
      dispatch(fetchemailLog(req));
    }
  };

  const loadNextPage = (e: any): void => {
    fetchall();
    // setEmailLogsList([...emailLogsList, ...emailLogs]);
  };

  React.useEffect(() => {
    fetchall();
  }, []); // displaySearch

  React.useEffect(() => {
    if (loading == 'succeeded') {
      setEmailLogsList([...emailLogsList, ...emailLogs]);
      if (logTotalRows > nextPage * PAGINATION_NUM_ROWS) {
        setNextPage(nextPage + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [loading]); // displaySearch

  return (
    <div className="content-box table-wrapper">
      <div className="content_header">
        <div className="justify-space-between">
          <div className="justify-left">
            {appLogo && (
              <a className="text-white hotel-logo-right" href="#">
                <img src={logo} alt="logo" className="small headerSmall" />
              </a>
            )}
            <div>
              <div>
                {!smallHeader && <h1 className="font-bold color-blue">{contentTitle}</h1>}
                {smallHeader && (
                  <h3 className="font-bold color-blue margin-top10 margin-left-15px ">
                    {contentTitle}
                  </h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='m-5'>
      <InfiniteScroll pageStart={1} loadMore={loadNextPage} hasMore={hasMore} threshold={2}>
        <Table
          titles={EmailLogFields}
          data={emailLogsList}
          isLoading={loading === `loading` ? true : false}
          currentPage={0}
        />
        <div className='m-3'>&nbsp;</div>
        <div className='m-3'>&nbsp;</div>
        <div className='m-3'>&nbsp;</div>
        <div className='m-3'>&nbsp;</div>
      </InfiniteScroll>
      </div>
    </div>
  );
};
