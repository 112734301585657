
import { LBL_HOTELS, LBL_HOTEL_PAYMENTS, LBL_MAIDS, LBL_MAID_PAYMENTS, LBL_TIPS, LBL_SETTINGS, LBL_ASSOCIATES, LBL_URL_MAID_PAYMENTS, LBL_URL_MAIDS, LBL_TERMS, LBL_HOTEL_ASSOCIATES } from '../../config/common_labels';

export const navBarList = [
  {
    name: LBL_HOTELS,
    path: '/v1/hotels',
    imgSrc: '/assets/images/dashboard.svg',
    imgSrcActive: '/assets/images/dashboard-active.svg',
  },
  {
    name: LBL_MAIDS,
    path: '/v1/' + LBL_URL_MAIDS,
    imgSrc: '/assets/images/tickets.svg',
    imgSrcActive: '/assets/images/tickets-white-icon.svg',
  },
  {
    name: LBL_TIPS,
    path: '/v1/guest-payments',
    imgSrc: '/assets/images/tickets.svg',
    imgSrcActive: '/assets/images/tickets-white-icon.svg',
  },
  {
    name: LBL_HOTEL_PAYMENTS,
    path: '/v1/hotel-payments',
    imgSrc: '/assets/images/application-icon.svg',
    imgSrcActive: '/assets/images/application-white-icon.svg',
  },
  {
    name: LBL_MAID_PAYMENTS,
    path: '/v1/' + LBL_URL_MAID_PAYMENTS,
    imgSrc: '/assets/images/ticket-status-blue-icon.svg',
    imgSrcActive: '/assets/images/ticket-status-icon.svg',
  },
  {
    name: LBL_HOTEL_ASSOCIATES,
    path: '/v1/hotel-users',
    imgSrc: '/assets/images/tickets.svg',
    imgSrcActive: '/assets/images/tickets-white-icon.svg',
  },
  // {
  //   name: LBL_ASSOCIATES,
  //   path: '/v1/admin-associates',
  //   imgSrc: '/assets/images/tickets.svg',
  //   imgSrcActive: '/assets/images/tickets-white-icon.svg',
  // },
  // {
  //   name: LBL_SETTINGS,
  //   path: '/v1/settings',
  //   imgSrc: '/assets/images/tickets.svg',
  //   imgSrcActive: '/assets/images/tickets-white-icon.svg',
  // },
  // {
  //   name: LBL_TERMS,
  //   path: '/v1/terms',
  //   imgSrc: '/assets/images/tickets.svg',
  //   imgSrcActive: '/assets/images/tickets-white-icon.svg',
  // }
];
