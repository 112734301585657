import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ITerms } from '../../models/termsInterfaces';
import { getTerms, saveTerms } from '../../services/termsServices';
import { Store } from 'react-notifications-component';
import { APP_NAME, notification_shown_time_success } from '../../config/env';
import { MSG_TERMS_SAVED, MSG_TERMS_SAVE_FAILED } from '../../config/messages';

export interface DataState {
  terms: ITerms;
  termsLoading: 'idle' | 'loading' | 'succeeded' | 'error';
  termsError: string | undefined;
}

const initialState: DataState = {
  terms: {} as ITerms,
  termsLoading: 'idle',
  termsError: '',
};

export const getTermsDataSlice = createSlice({
  name: 'getTermsdata',
  initialState,
  reducers: {
    setTermsData: (state, action: PayloadAction<any>) => {
      state.terms = action.payload;
      console.log('action.payload: ',action.payload); 
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTerms.pending, (state) => {
      state.terms = {} as ITerms;
      state.termsLoading = 'loading';
    });

    builder.addCase(getTerms.fulfilled, (state, action) => {
      const m = action.payload ? action.payload as ITerms : {group: 0,
        conditionText: '',
        currentTerms: false};
      console.log('action.payload: ',action.payload); 
      state.terms = m;
      state.termsLoading = 'succeeded';
    });

    builder.addCase(saveTerms.pending, (state) => {
      state.terms = {} as ITerms;
      state.termsLoading = 'loading';
    });
    
    builder.addCase(saveTerms.fulfilled, (state, action) => {
      const m = action.payload as ITerms;
      state.terms = m;
      state.termsLoading = 'succeeded';
      Store.addNotification({
        title: APP_NAME,
        message: MSG_TERMS_SAVED,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    });

    builder.addCase(saveTerms.rejected, (state, { payload }: any) => {
      state.termsLoading = 'error';
      Store.addNotification({
        title: APP_NAME,
        message: MSG_TERMS_SAVE_FAILED,
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      // state.error = action.error.message;
    });

  },
});

// Action creators are generated for each case reducer function
export const { setTermsData } = getTermsDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectGetTermsDataSlice = (state: RootState) => state.getTermsdata; 

export const termsDataReducer = getTermsDataSlice.reducer;
