import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';

import { getMaidPhone, removeMaidTokens } from "../../services/localStorage";
import { isMaidAuthenticated } from "../../services/maidAuthenticationService";
import { getTermsAndCondition, acceptTermsAndConditions } from '../../services/maidRegisterService';
import { selectMaidFormSlice } from '../MaidRegister/maidRegisterSlice';

import logo from './../../assets/img/tova_stack.png';
import { authenticateMaid, verifyOTP } from './maidAuthenticationSlice';
import { LBL_T_AND_C, LBL_URL_MAIDS } from '../../config/common_labels';
import { USER_GROUP_MAIDS } from "../../config/env";
import { IAcceptTerms } from '../../models/maidRegister'; 

export const StaffTerms = (props: any) => { 
    const history = useHistory();
    const location = useLocation();
    const maidPhone = getMaidPhone(); 
    const dispatch = useAppDispatch();
    const { termsAndC,loading, staffTermsAccepted } = useAppSelector(selectMaidFormSlice)
    const modules = {
        toolbar: false,
    };

  
    const [termValue, setTermvalue] = useState<any>();
    
    const handleChange = () => {
        setTermvalue(termValue)
    }

    useEffect(() => {
        dispatch(getTermsAndCondition(USER_GROUP_MAIDS));
        console.log('staffTermsAccepted[]: '+staffTermsAccepted); 
    }, []);

    useEffect(() => {
        if(staffTermsAccepted==='succeeded') {
            history.push('/'+LBL_URL_MAIDS+'/otp'); 
        }
    }, [staffTermsAccepted]);

    const handleClick =()=> {
        const acceptTerms: IAcceptTerms  = {
            username: maidPhone ? maidPhone : '',
            termsId: termsAndC.id ? Number(termsAndC.id) : 0
        }; 
        dispatch(acceptTermsAndConditions(acceptTerms)); 
    }

    return (
        <div className="min-h-full bg-white">
            <div className="align-center-common ">
                <div className="mt-33 max-width-500 ">
                    <form className="col-md-12 box border">
                        <div className="align-center-common flex-col">
                            <img src={logo} alt='maideasy' className="maid-login-logo" />
                        </div>
                        <div>
                            <div className="terms-content">
                                <div className="mb-2">{LBL_T_AND_C}</div>
                                <ReactQuill theme="snow"
                                    value={termsAndC.conditionText} 
                                    onChange={handleChange}
                                    modules={modules}
                                    readOnly={true}
                                />
                            </div>
                            <div>&nbsp;</div>
                            <div>
                                <div className='float-right mr-10px'>
                                    <button type='button' className="primary margin-left-15px mb-3" 
                                    onClick={handleClick} 
                                    >
                                    I Accept
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div>{localStorage.getItem('phoneOTP')}</div>
                        <div>{termsAndC.id}</div> */}
                        <div>&nbsp;</div>
                    </form>
                    <div>&nbsp;</div>
                </div>
            </div>
        </div>
    )
}

