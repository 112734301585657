import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { useHistory } from 'react-router-dom';
import Select, { Options } from 'react-select';
import ReactLoading from 'react-loading';

import { fetchHotelsNameId } from '../../services/maidPaymentsService';
import { setSelectedHotelId, getSelectedHotelId } from '../../services/localStorage';
import { processFailedTransactions, processDirectHotelPayments } from '../../services/maidPaymentsService';

import { Store } from 'react-notifications-component';
import { LBL_MAID,  LBL_MAIDS, LBL_PROCESS_PAYMENTS } from '../../config/common_labels';
import { USER_GROUP_MAIDS, USER_GROUP_GUESTS, APP_NAME, notification_shown_time_success, notification_shown_time_failed } from '../../config/env';
import {
  changeHotelFilter,
  changeHotelFilterTwo,
  selectMaidPaymentsHotelFilterSlice,
} from '../MaidPayments/maidPaymentHotelFilterSlice';
import { MSG_NO_RECORDS_AVAILABLE, MSG_STARTED_PROCESS_FAILED_TRANS, MSG_STARTED_PROCESS_HOTEL_PAYOUT, MSG_CONFIRM_ALL_REPROCESS, MSG_CONFIRM_HOTEL_REPROCESS, MSG_CONFIRM_DIRECT_HOTEL_PAY, MSG_CONFIRM_DIRECT_HOTEL_PAY_ALL } from '../../config/messages';

export const ProcessPayments = (props: any) => {
  const history = useHistory(); 

  const [localStorageHotelId, setLocalStorageHotelId] = useState(getSelectedHotelId());
  const [localStorageHotelIdTwo, setLocalStorageHotelIdTwo] = useState(getSelectedHotelId());
  const { hotelNameIds, curValue, curValueTwo, allHotelOptsWithAll, curHotelOPt, curHotelOPtTwo, isHotelActive } = useAppSelector(
    selectMaidPaymentsHotelFilterSlice
  );

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchHotelsNameId(''));
  }, []);

  function selectBoxOnFocus() {
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-grey');
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-light-grey');
  }
  function selectBoxOnBlur() {
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.remove('color-grey');
    document.getElementsByClassName('react-select-maids__single-value')[0].classList.add('color-light-grey');
  }

  const handleHotelFilterChange = (e: any): void => {
      if(e.value!=0) {
        setSelectedHotelId(e.value);
      }
      setLocalStorageHotelId(e.value);
      dispatch(changeHotelFilter(e.value));
  };

  const handleHotelFilterChangeTwo = (e: any): void => {
    if(e.value!=0) {
      setSelectedHotelId(e.value);
    }
    setLocalStorageHotelIdTwo(e.value);
    dispatch(changeHotelFilterTwo(e.value));
};


  const reprocessFailed = (actionString: any): void => {
        let uploadStatus = null;
        let confirmMsg = ''; 
        if (actionString === 'directHotelPayments') {
          confirmMsg = MSG_CONFIRM_DIRECT_HOTEL_PAY_ALL.replace("Hotel", (curHotelOPt.value.toString()=='0' ? curHotelOPt.label.toLowerCase() : curHotelOPt.label)); 
        }
        else {
          confirmMsg = MSG_CONFIRM_HOTEL_REPROCESS.replace("hotel", (curHotelOPtTwo.value.toString()=='0' ? curHotelOPtTwo.label.toLowerCase():curHotelOPtTwo.label));          
        }

        Store.addNotification({
          title: 'Payment Process',
          id: 'pay-process-modal',
          content: (
              <div className="refund-modal">
                  {/* <div className="refund-flex-center page-heading">
                      <h4 >Confirm Payment Process</h4> 
                  </div> */}
                  <div className="refund-flex-center mt-2">
                      {confirmMsg}
                  </div>
                  <div className="refund-margin"></div>
                  <div className="refund-flex-center">
                      <button
                          type="button"
                          className="primary"
                          onClick={() => Store.removeNotification('pay-process-modal')}
                      >
                          Cancel
                      </button>
                      <button
                          type="button"
                          className="primary margin-left-15px"
                          onClick={() => {
                              Store.removeNotification('pay-process-modal');
                              callReprocessFailed(actionString); 
                            }
                          }
                      >
                          Process
                      </button>
                  </div>
              </div>
            ),
            type: 'default',
            container: 'center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 1000000000,
                click: false,
                touch: false,
            },
        });
  };

  const callReprocessFailed = (actionString: any): void => {
    (async () => {
      let uploadStatus = null;
      let msgString = MSG_STARTED_PROCESS_FAILED_TRANS; 

      if (actionString === 'directHotelPayments') {
        msgString = MSG_STARTED_PROCESS_HOTEL_PAYOUT; 
        uploadStatus = await processDirectHotelPayments('hotel',curValue,'','');
      }
      else {
        msgString = MSG_STARTED_PROCESS_FAILED_TRANS; 
        uploadStatus = await processFailedTransactions(curValueTwo,'','');
      }

      if (uploadStatus?.status === 200) {
        Store.addNotification({
            title: APP_NAME,
            message: msgString,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: notification_shown_time_success,
                onScreen: true,
                pauseOnHover: true,
            },
        });
        } if (uploadStatus.response.status === 400) {
            Store.addNotification({
                title: APP_NAME,
                message: MSG_NO_RECORDS_AVAILABLE,
                type: 'danger',
                insert: 'top',
                container: 'top-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: notification_shown_time_failed,
                    onScreen: true,
                    click: false,
                    showIcon: false
                }
            });
        }

    })();
  }

  const colorStyles = {
      option: (styles: any, data: any) => ({
          ...styles,
          color: data.data.active ? '' : 'red'
      })
  }

  return (
    <>
      <div className="header-content-container">
        <div className="box">
          <div className="row">
            <div className="col maid-list-container">
              <div className="hotel-select-bar">
                <h4 className="page-heading div-left maid-list-title padding-top6">{LBL_PROCESS_PAYMENTS}  </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content_container">
        <div className="box pb-5">
          <form autoComplete="off">
            <div className="row padding-20-30px">
              {/* <div className="col-12 mb-30px">
                <div className="row mb-10px">
                  <div className="col-7 div-left ">
                        <h5> Reprocess failed transactions - All Hotels</h5>
                  </div>
                  <div className="col-5 div-left ">
                    <button type="button" className="primary div-left" onClick={(e) => reprocessFailed('all')}>Process
                      </button>
                  </div>
                </div>
              </div> */}

              <div className="col-12 mb-30px">
                <div className="row mb-10px">
                  <div className="col-7 div-left ">
                    <div className="div-left padding-top9"><h5>Process direct hotel payments of </h5></div>
                    <div className="margin-left-15px div-left">
                      <Select
                          onChange={handleHotelFilterChange}
                          name="hotelName"
                          className=""
                          onFocus={selectBoxOnFocus}
                          onBlur={selectBoxOnBlur}
                          classNamePrefix="react-select-maids"
                          options={allHotelOptsWithAll}
                          value={curHotelOPt}
                          isSearchable={true}
                          styles={colorStyles}
                      />
                    </div>
                  </div>
                  <div className="col-5 div-left ">
                    <button type="button" className="primary div-left" onClick={(e) => reprocessFailed('directHotelPayments')}>Process
                      </button>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-30px">
                <div className="row mb-10px">
                  <div className="col-7 div-left">
                    <div className="div-left padding-top9"><h5>Reprocess failed transactions of </h5></div>
                    <div className="margin-left-15px div-left">
                      <Select
                          onChange={handleHotelFilterChangeTwo}
                          name="hotelName"
                          className=""
                          onFocus={selectBoxOnFocus}
                          onBlur={selectBoxOnBlur}
                          classNamePrefix="react-select-maids"
                          options={allHotelOptsWithAll}
                          value={curHotelOPtTwo}
                          isSearchable={true}
                          styles={colorStyles}
                      />
                    </div>
                  </div>
                  <div className="col-5 div-left ">
                    <button type="button" className="primary div-left" onClick={(e) => reprocessFailed('')}>Process
                      </button>
                  </div>
                </div>
              </div>


              



              <div className="col-12">
                <div className="col-8 div-left flex">
                  
                </div>
                <div className="col-4 div-right">
                  
                </div>
              </div>

            </div>

          </form>
        </div>
      </div>
    </>
  )}
    
