import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { selectTipSlice, setTip, setHotel, TipState, setTipWithoutCommission } from './tipSlice';
import { fetchStripeToken, getStripeSecret } from '../../services/guestPaymentService';
import { useHistory, Link } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { getTermsAndCondition } from '../../services/maidRegisterService';
import { selectGetTermsDataSlice } from '../Terms/termsSlice';
import { selectMaidFormSlice } from '../MaidRegister/maidRegisterSlice';

import { ITerms } from '../../models/termsInterfaces';

import { selectHotelFormDataSlice } from '../HotelForm/hotelFormDataSlice';
import { getHotelImage } from '../../services/tipService';
import { ITip } from '../../models/tipInterfaces';
import DiscreteSlider from '../../components/Slider/DiscreetSlideBar';
import { loadStripe } from "@stripe/stripe-js";
import { env_var, stripe_token_var, APP_NAME, USER_GROUP_GUESTS, STRIPE_COMMISION } from '../../config/env';

import logo from '../../assets/img/tova.png';
import logo2 from '../../assets/img/tova_stack.png';
import logoFB from '../../assets/img/facebook.svg';
import logoInsta from '../../assets/img/instagram.svg';
import logoTwitter from '../../assets/img/twitter.svg';
import logoYoutube from '../../assets/img/youtube.svg';

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement
} from "@stripe/react-stripe-js";

import { Grid, Slider, TextField, Typography } from "@mui/material"
import { makeStyles } from '@material-ui/core/styles';
import { Box } from "@mui/material";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import './style.css'; 

import useResponsiveFontSize from "./useresponsive";
import { selectstripeTokenSlice } from '../Stripe/stripeTokenSlice';
import axios from 'axios';
import { LBL_MAID, LBL_MAIDS, LBL_T_AND_C } from '../../config/common_labels';


//styling options for card element
const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};


declare const stripe: {
  loadStripe: typeof loadStripe;
};

let stripePromise = loadStripe(stripe_token_var);

export const TipForm = (props: any) => {
  const refz = useRef<any>(null);
  const history = useHistory();

  const { termsAndC } = useAppSelector(selectMaidFormSlice)

  const { tipValue, hotelValue, tipValueWithoutCommission } = useAppSelector(selectTipSlice);

  const { tokenData, loading } = useAppSelector(selectstripeTokenSlice)
  const dispatch = useAppDispatch();
  const [hotelLogo, setHotelLogo] = useState<string>('null');
  const [secret, setClentSecret] = useState<string>('null');
  const [tipfactors, setTipFactors] = useState<any>([]);
  const [slider, setSlider] = useState<number>(0);
  const [hoteldata, setHoteldata] = useState<any>({})
  const [isCardError, setisCardError] = useState<boolean>(false);
  const [cstomtipval, setCstomTipval] = useState<number>();
  const [carderrormsg, setCardErrorMsg] = useState<any>('');

  const [modalopen, setModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);


  const [value, setValue] = useState<any>(2);
  const [pstage, setPstage] = useState<any>(0);
  const [isAllowed, setIsAllowed] = useState<'loading' | true | false>('loading');
  const [isCustomTip, setCustomTip] = useState<boolean>(false);
  const [isPrivacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const [isRoomVisible, setRoomvisible] = useState<boolean>(true);
  const [isCard, setisCard] = useState<boolean>(false);
  const [istripe, setisStripe] = useState<boolean>(false);
  const [privacyerr, setPrivacyerr] = useState<boolean>(false);
  const [tipzero, setLessZero] = useState<boolean>(true);
  const [room_number, setRoomnumber] = useState<string>(''); 
  const [nameOnCardError, setNameOnCardError] = useState<boolean>(false); 
  const [amountChanged, setAmountChanged] = useState<boolean>(false); 

  // const [roomnumberph, setRoomnumberph] = useState<string>('Room number (Optional)');
  // const [roomnameph, setRoomnameph] = useState<string>('Room name (Optional)');

  const numericPattern = /^[0-9\b]+$/;

  const [paymentRequest, setPaymentRequest] = useState<any>(null); 
  // NOW PROCESSING FEE SHOULD TAKEN BY DEFAULT - SO USING THIS VARIABLE TO HANDLE IT NO MATTER WHAT VALUE COMES FROM THE API RESPONSE 

  // const stripe = useStripe();
  // const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if(amountChanged) {
      setPstage(0); 
    }
  }, [amountChanged]);

  const handleClickOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleTermsClose = () => {
    setTermsModalOpen(false);
  };
  
  const addProcessFee = () => {
    dispatch(setTipWithoutCommission(Number(tipValue.toFixed(2)))); 
    // dispatch(setTip(Number(((tipValue/(1-(hoteldata.paymentCommission/100)))+STRIPE_COMMISION).toFixed(2)))); 
    // CHANGED CALCULATION ON 06Jan2023
    dispatch(setTip(Number(((tipValue+(tipValue*(hoteldata.paymentCommission/100)))+STRIPE_COMMISION).toFixed(2)))); 
    setAmountChanged(false); 
  };
  
  // React.useEffect(() => {
  //   if(amountChanged && !isCard && pstage > 0) {
  //     setModalOpen(true); 
  //   }
  // }, [amountChanged]);


  React.useEffect(() => {
    if (props.match.params.id !== undefined) {
      // ios fix
      let urlId = props.match.params.id;
      let cleanedUrlId = urlId.replace('\\', '');
      dispatch(setHotel(cleanedUrlId));
      setValue(2)

      getHotelImage(cleanedUrlId).then((res: any) => {

        if (res['status'] === 200) {
          console.log(res.data)
          if (res.data['url'] != null) {

            console.log('value--', res)
            setHotelLogo(res.data['url']);
          }
          setIsAllowed(res.data['isActive']);
          setHoteldata(res.data);
          setTipFactors([{
            'tip_value': res.data.tip_1,
            'tip_facts': res.data.tipFacts.fact1
          },
          {
            'tip_value': res.data.tip_2,
            'tip_facts': res.data.tipFacts.fact2
          },
          {
            'tip_value': res.data.tip_3,
            'tip_facts': res.data.tipFacts.fact3
          },
          {
            'tip_value': res.data.tip_4,
            'tip_facts': res.data.tipFacts.fact4
          },
          {
            'tip_value': res.data.tip_5,
            'tip_facts': res.data.tipFacts.fact5
          }])

          dispatch(setTip(parseFloat(res.data.tip_3)));
          dispatch(setTipWithoutCommission(parseFloat(res.data.tip_3)));
          setAmountChanged(true); 
        }
      });
    }

  }, []);


  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
  };

  function valuetext(value: number) {
    console.log(value)
    return `${value}°C`;
  }

  function dummyFunction (field:any) {
    return(''); 
  }

  const handleSliderChange = (event: Event, newValue: any) => {
    event.preventDefault();
    setValue(newValue);
    dispatch(setTip(parseFloat(tipfactors[newValue]?.tip_value)));
    dispatch(setTipWithoutCommission(parseFloat(tipfactors[newValue]?.tip_value)));
    setAmountChanged(true); 
  };


  const handleSubmit = (stripe: any, elements: any) => async (e: any) => {
    e.preventDefault();
    const cardElement = elements.getElement(CardElement);

    const req: TipState = {
      tipValue: tipValue,
      hotelValue: hotelValue,
      tipValueWithoutCommission: tipValueWithoutCommission,
    };
    dispatch(getStripeSecret(req))

    if (loading == 'succeeded') {

      const paymentResult = await stripe.confirmCardPayment(JSON.parse(tokenData.token).clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: "Yusuff Faruq",
          },
        },
      });

      if (paymentResult.error) {
        alert(paymentResult.error.message);
        console.log(paymentResult.error.message);
      } else {
        if (paymentResult.paymentIntent.status === "succeeded") {
          console.log(paymentResult);
        }
      }

    }

    // var secret = axios.get('https://api.npms.io/v2/invalid-url')
    //   .then(response => { console.log(response); return response})
    //   .catch(error => {

    //       console.error('There was an error!', error);
    //   });

    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardElement,
    // });

    // const paymentResult = await stripe.confirmCardPayment('123', {
    //   payment_method: {
    //     card: elements.getElement(CardElement),
    //     billing_details: {
    //       name: "Yusuff Faruq",
    //     },
    //   },
    // });

    // if (error) {
    //   console.log('[error]', error);
    // } else {
    //   console.log('[PaymentMethod]', paymentMethod);
    //   // ... SEND to your API server to process payment intent
    // }
  };

  const submitTip = async () => {
    // const cardElement = elements.getElement(CardElement);
    // e.preventDefault();

    // if (!isNaN(tipValue)) {
    //   history.push('/pay');
    // }

    const req: TipState = {
      tipValue: tipValue,
      hotelValue: hotelValue,
      tipValueWithoutCommission: tipValueWithoutCommission,
    };

    // console.log(dispatch(fetchStripeToken(req)));

    dispatch(getStripeSecret(req))

    // if(loading =='succeeded'){
    //   const paymentResult = await stripe.confirmCardPayment(tokenData['clientSecret'], {
    //     payment_method: {
    //       card: elements.getElement(CardElement),
    //       billing_details: {
    //         name: "Yusuff Faruq",
    //       },
    //     },
    //   });

    //   if (paymentResult.error) {
    //     console.log(paymentResult.error.message);
    //   } else {
    //     if (paymentResult.paymentIntent.status === "succeeded") {
    //       console.log(paymentResult);
    //     }
    //   }

    // }

  };

  const onInpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (numericPattern.test(event.target.value) || event.target.value == '') {
      dispatch(setTip(parseFloat(event.target.value)));
      dispatch(setTipWithoutCommission(parseFloat(event.target.value))); 
      setAmountChanged(true); 
    }
  };

  const modules = {
      toolbar: false,
  };

  const handleTermsChange = () => {
    return; 
  }

  return (

    <>
    <div className='max-width-500 div-center-margin-auto '>
      {isAllowed === true && (
        <div className='bg-white' >
          <div id="main">
            <div>
              <div className="header-tip justify-space-between">
                <div className="justify-left">
                  <div className="div-left">
                    <div className="div-left">
                      {pstage != 0 ?
                        <a href="#" className="fa fa-angle-left  backbtn div-left" onClick={() => { setPstage(0); setisCard(false) }}></a> : null
                      }
                      <img className="logo_maid_tip" src={logo2} alt={APP_NAME} />
                    </div>
                  </div>
                </div>
                <div className="div-right mb-rem04 ">
                  {hotelLogo === 'null' && <div className="hotel_name">{hoteldata.name}</div>}
                  {hotelLogo !== 'null' && <img className="hotel_logo" src={hotelLogo} alt={hoteldata.name} />}
                </div>
              </div>
            </div>
          </div>


          <Dialog
            open={modalopen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title">
              <div className='tip-processfee-header'>{"Transaction charges."}</div>
            </DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className='tip-processfee-text'>
                  {/* Would you like to cover the platform fee so that 100% of your tip reaches the staff? You will be charged  <b>${(tipValue+(tipValue/(1-.05))).toFixed(2) */}
                  {/* Would you like to cover the platform fee so that 100% of your tip reaches the {LBL_MAID.toLowerCase()}? You will be charged  <b>${((tipValue/(1-(hoteldata.paymentCommission/100)))+STRIPE_COMMISION).toFixed(2)}</b> */}
                  {/* There is an additional platform fee to cover transaction charges. You will be charged  <b>${((tipValue/(1-(hoteldata.paymentCommission/100)))+STRIPE_COMMISION).toFixed(2)}</b> for this tip payment. */}
                  {/* There is a platform fee to cover the cost of this transaction.  You will be charged <b>${((tipValue/(1-(hoteldata.paymentCommission/100)))+STRIPE_COMMISION).toFixed(2)}</b> so that the staff will receive 100% of your <b>${(tipValue).toFixed(2)}</b> tip. */}
                  {/* // CHANGED CALCULATION ON 06Jan2023 */}
                  There is a platform fee to cover the cost of this transaction.  You will be charged <b>${((tipValue+(tipValue*(hoteldata.paymentCommission/100)))+STRIPE_COMMISION).toFixed(2)}</b> so that the staff will receive 100% of your <b>${(tipValue).toFixed(2)}</b> tip.

                  
                </div>
              </DialogContentText>
            </DialogContent>
            <div className='justify-center mb-10px'>
              <button className='tip-processfee-button btnbg btn-text mb-10px' onClick={()=>{handleClose();setPstage(1); addProcessFee(); }} autoFocus>
                OK
              </button>
            </div>
            {/* <DialogActions>
              <Button className='tip-processfee-button btnbg btn-text mb-10px display-center' onClick={()=>{handleClose();setPstage(1); addProcessFee(); }} autoFocus>
                OK
              </Button>
            </DialogActions> */}
          </Dialog>

          <Dialog
            open={termsModalOpen}
            onClose={handleTermsClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title">
              {"Would you like to cover the platform fee?"}
            </DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="mb-10px">
                  {/* <div className="mb-2">{LBL_T_AND_C}</div> */}
                  <ReactQuill theme="snow"
                    value={termsAndC.conditionText} 
                    onChange={handleTermsChange}
                      modules={modules}
                      readOnly={true}
                  />
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className='mr-35px mb-10px'>
                {/* <Button className='primary' onClick={()=>{handleTermsClose();}}>I Accept</Button> */}
                <button type='button' className="primary" 
                  onClick={()=>{handleTermsClose();}} 
                  >
                  I Accept
                </button>
              </div>
            </DialogActions>
          </Dialog>


          <div className="content_container pt-4 tipbg">
            {/* card view */}
            <div className="card-backGround div-width460">
              <div className='tip-factor-header'>{tipfactors[value]?.tip_facts?.header}</div>
              <p className="tip-factor-text">{tipfactors[value]?.tip_facts?.body}</p>
            </div>

            {/* slider */}
            <div className="slider-parent">
              <div className='div-center-72'>
                {!isCustomTip ?
                  <Box sx={{ width: '100%' }}>
                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                      {/* <span className='bold'>${tipfactors[0]?.tip_value}</span> */}
                      <Slider 
                        aria-label="Always visible"
                        defaultValue={value}
                        onChange={handleSliderChange} 
                        getAriaValueText={valuetext}
                        //onChangeCommitted={(e,value)=>console.log(JSON.stringify(e.target))}
                        onChangeCommitted={(e,value)=>dummyFunction(e.target)}
                        valueLabelDisplay="on"
                        valueLabelFormat={(value) => <div className='value-box'>
                          ${tipfactors[value]?.tip_value}
                        </div>}
                        step={1}
                        marks
                        min={0}
                        max={4}
                        sx={{
                          '& .css-7drnjp': {
                                zIndex: 1
                          },
                          '& .css-1t2bqnt': {
                            height: 'auto'
                          }
                        }}
                      />
                      {/* <span className='bold'>${tipfactors[4]?.tip_value}</span> */}
                    </Stack>
                  </Box>
                  : null}
              </div>

              {
                !isCustomTip ?
                  <p className='custom_v' onClick={() => {
                    if(cstomtipval && cstomtipval > 0) {
                      dispatch(setTip(cstomtipval)); 
                      dispatch(setTipWithoutCommission(cstomtipval)); 
                      setAmountChanged(true); 
                    }
                    setCustomTip(!isCustomTip);
                    refz.current.focus();
                  }}>Enter Custom Tip</p> : null
              }

              {
                isCustomTip ?
                  <div className='input-icons div-center-75'>
                    <div className=''>
                      <p style={{ float: 'left', marginBottom: 0, fontWeight: 'bold' }} className='mb0 helv-regular'>Tip Amount</p>
                      <p className='custom_v' style={{ float: 'right', margin: 0 }} onClick={() => { setCustomTip(false); dispatch(setTip(parseFloat(tipfactors[value]?.tip_value))); setAmountChanged(true); dispatch(setTipWithoutCommission(parseFloat(tipfactors[value]?.tip_value))); }}>Back to default</p>
                    </div>

                    <label id="search1">
                      <i className="fa fa-dollar"></i>
                      <input ref={refz} autoFocus id="search-icon1" className={`primary tipinpbg  tipinp p-3 w-100 ${tipzero ? 'redbrdr' : ''}`} type='number' pattern="\d*" min="1" step="1" value={cstomtipval} onChange={(e: any) => {
                        e.preventDefault();
                        if (parseFloat(e.target.value) < 1 || e.target.value == '') {
                          setLessZero(true)
                          setCstomTipval(e.target.value);
                        }
                        else {
                          setLessZero(false)
                          setCstomTipval(e.target.value);
                          dispatch(setTip(parseFloat(e.target.value)));
                          dispatch(setTipWithoutCommission(parseFloat(e.target.value)));
                          setAmountChanged(true); 
                        }
                      }} />
                    </label>
                  </div> : null
              }  

              {
                isRoomVisible ?
                  <>
                    <div className='margin-top25'>
                      {
                      hoteldata.roomLabel==1 ? <>
                        <input type={'number'} 
                          className={"tipinpbg p-3 roomNum-width tip-input"+(hoteldata.roomLabelMandatory && room_number.trim().length==0 ? " input-error " : "" ) } 
                          placeholder={hoteldata.roomLabelMandatory?"Room Number*":"Room Number (Optional)"} 
                          value={room_number} 
                          onChange={(e: any) => { e.preventDefault(); setRoomnumber(e.target.value); }} /> </>
                        : <>
                          <input type={'text'} 
                            className={"tipinpbg p-3 roomNum-width tip-input"+(hoteldata.roomLabelMandatory && room_number.trim().length==0 ? " input-error " : "" )} 
                            placeholder={hoteldata.roomLabelMandatory?"Room Name*":"Room Name (Optional)"} 
                            value={room_number} 
                            onChange={(e: any) => { e.preventDefault(); setRoomnumber(e.target.value); }} /> </> 
                      }

                    </div> 
                    
                    </> : null
              }
            </div>

            <Elements stripe={stripePromise}>
              <div className="mt-2 text-center">
                <ApplePayForm />
                {
                  isCard ?
                    <div className="product">
                      {pstage > 0 && <CheckoutForm />}
                    </div> : null
                }
              </div>
            </Elements>
            <div className="margin-top12 text-center helv-regular div-center-85 ">
              {/* Your tip goes directly to the {LBL_MAIDS.toLowerCase()} who clean this room. */}
              {/* Thank you for tipping the staff at {hoteldata.name}! */}
            </div>
            <div className='socialIconsdiv margin-top45'>
              {/* <a href={hoteldata.facebookLink} target="_blank" rel="noreferrer" className="fa fa-facebook"></a>
              <a href={hoteldata.youtubeLink} target="_blank" rel="noreferrer" className="fa fa-youtube"></a>
              <a href={hoteldata.twitterLink} target="_blank" rel="noreferrer" className="fa fa-twitter"></a>
              <a href={hoteldata.instagramLink} target="_blank" rel="noreferrer" className="fa fa-instagram"></a> */}
{/* 
              <a href={hoteldata.facebookLink} target="_blank" rel="noreferrer" ><img src={logoFB} alt="FB" className="sm-icons"/></a>
              <a href={hoteldata.instagramLink} target="_blank" rel="noreferrer" ><img src={logoInsta} alt="Instagram" className="sm-icons"/></a>
              <a href={hoteldata.twitterLink} target="_blank" rel="noreferrer" ><img src={logoTwitter} alt="Twitter" className="sm-icons"/></a>
              <a href={hoteldata.youtubeLink} target="_blank" rel="noreferrer" ><img src={logoYoutube} alt="Youtube" className="sm-icons"/></a> */}

              <p className='m-0 ms-3 fontz margin-top8'>POWERED BY</p>
              <img className="logo margin-top8" src={logo} alt={APP_NAME} />
              <p className='m-0 fontz margin-top20'>&nbsp;</p>
            </div>
          </div>
        </div>
      )}
      {isAllowed === false && (
        <div  className='bg-white'>
          <div id="main tipbg">
            <div>
              <div className="header-tip justify-space-between">
                <div className="justify-left">
                  <div className="div-left">
                    <div>
                      <img className="small" src={logo} alt={APP_NAME} />
                    </div>
                  </div>
                </div>
                <div className="div-right hotel-logo-right">
                  {hotelLogo === 'null' && <span className="none">
                    <img className="small" src={logo} alt={APP_NAME} />
                  </span>}
                  {hotelLogo !== 'null' && <img className="small" src={hotelLogo} alt={APP_NAME} />}
                </div>
              </div>
            </div>
          </div>
          <div className="content_container">
            <div className="rounded-20px inactive-hotel margin-top20">
              Invalid request. Please contact {APP_NAME} admin for more details.
            </div>
          </div>
        </div>
      )}
      </div>
    </>
  );


  //Card element
  function CheckoutForm() {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [cardname, setCardname] = useState<string>('');
    const [paymentRequest, setPaymentRequest] = useState<any>(null);
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const onCardNameChange = (e:any) => {
      e.preventDefault(); 
      setCardname(e.target.value)
      if(e.target.value.trim().length==0) {
        // setNameOnCardError(true); 
        document.getElementById('txt-cardname')?.classList.add("input-error");
      }
      else {
        // setNameOnCardError(false); 
        document.getElementById('txt-cardname')?.classList.remove("input-error");
      }
    };

    useEffect(() => {
      if (!stripe || !elements) {
        return;
      }
      let amountToStrip = parseInt((tipValue*100).toFixed(0), 10);
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: amountToStrip,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (e) => {
        let tipAmount = tipValueWithoutCommission; 
        if(!hoteldata.tipIncludeProcessFee) { 
          tipAmount = Number(((tipValue-STRIPE_COMMISION)-((tipValue-STRIPE_COMMISION)*(hoteldata.paymentCommission/100))).toFixed(2)); 
        }
        axios.get(`${env_var.API_URL}payments/v1/token/?amount=${tipValue}&hotel=${hotelValue}&room=${room_number}&tip_amount=${tipAmount}&commission=${hoteldata.paymentCommission}`)
          .then(res => {
            const persons = res.data;
            // console.log(persons.)

            const paymentResult = stripe.confirmCardPayment(persons.token.client_secret, {
              payment_method: e.paymentMethod.id,
            }, { handleActions: false }).then(paymentResult => {

              const dataz = paymentResult.paymentIntent;

              if (dataz && dataz.status === "succeeded") {
                setPaymentLoading(false);
                setPstage(0); setisCard(false)
                //success redirection
                history.push({
                  pathname: '/success',
                  state: { amount: tipValueWithoutCommission, hotel_logo: hotelLogo, hotel_data: hoteldata }
                })
              }

            })

          })

      });


    }, [stripe, elements]);


    // card payment initiation
    const payMoney = async (e: any) => {
      e.preventDefault();
      if(!isPrivacyChecked || (hoteldata.roomLabelMandatory && room_number.trim().length==0)) {
        if(!isPrivacyChecked) {
           setPrivacyerr(true);
        }
        return; 
      }

      if(hoteldata.tipIncludeProcessFee && amountChanged) { 
        setModalOpen(true);
        return; 
      }
      
      if(cardname.trim().length==0) {
        // setNameOnCardError(true);
        document.getElementById('txt-cardname')?.classList.add("input-error"); 
        return; 
      }

      //checking is stripe is loaded for card element
      if (!stripe || !elements) {
        return;
      }
      //checking card name is entered
      if (cardname == '') {
        return;
      }
      setPaymentLoading(true);
      setisCardError(false);

      const req: TipState = {
        tipValue: tipValue,
        hotelValue: hotelValue,
        tipValueWithoutCommission: tipValueWithoutCommission
      };

      //requesting stripe payment intent 
      let tipAmount = tipValueWithoutCommission; 
      if(!hoteldata.tipIncludeProcessFee) { 
        tipAmount = Number(((tipValue-STRIPE_COMMISION)-((tipValue-STRIPE_COMMISION)*(hoteldata.paymentCommission/100))).toFixed(2)); 
      }
      axios.get(`${env_var.API_URL}payments/v1/token/?amount=${tipValue}&hotel=${hotelValue}&room=${room_number}&tip_amount=${tipAmount}&commission=${hoteldata.paymentCommission}`)
        .then(res => {
          const persons = res.data;

          //confirming payment intent
          const paymentResult = stripe.confirmCardPayment(persons.token.client_secret, {
            payment_method: {
              card: elements.getElement(CardNumberElement)!,
              billing_details: {
                name: cardname,
              },
            },
          }).then(paymentResult => {

            const dataz = paymentResult.paymentIntent;

            if (dataz && dataz.status === "succeeded") {

              setPaymentLoading(false);
              setPstage(0); setisCard(false)

              //success redirection
              history.push({
                pathname: '/success',
                state: { amount: tipValueWithoutCommission, hotel_logo: hotelLogo, hotel_data: hoteldata, tipValue: tipValue }
              })
            }
            else {
              //error handling
              setCardErrorMsg(paymentResult?.error?.message)
              setPaymentLoading(false);
              setisCardError(true);

            }
          }).catch(err => alert(err.message))

        })

    };


    return (
      <div
        style={{
          padding: "2rem",
          backgroundColor: 'white'
        }}
      >
        <div
          style={{
            maxWidth: "500px",
            margin: "0 auto",
          }}
        >
          {isPrivacyChecked==true && <form
            style={{
              display: "block",
              width: "100%",
            }}
            onSubmit={e => payMoney(e)}
          >

            <p className="m0 text-left">Card information</p>
            <div className="Demo">

              <label className="topCardnumber">

                <CardNumberElement
                  options={options}
                  onReady={() => {
                    console.log("CardNumberElement [ready]");
                  }}
                  onChange={event => {
                    console.log("CardNumberElement [change]", event);
                  }}
                  onBlur={() => {
                    console.log("CardNumberElement [blur]");
                  }}
                  onFocus={() => {
                    console.log("CardNumberElement [focus]");
                  }}
                />
              </label>

              <label style={{ display: 'flex' }}>
                <CardExpiryElement

                  options={options}
                  onReady={() => {
                    console.log("CardNumberElement [ready]");
                  }}
                  onChange={event => {
                    console.log("CardNumberElement [change]", event);
                  }}
                  onBlur={() => {
                    console.log("CardNumberElement [blur]");
                  }}
                  onFocus={() => {
                    console.log("CardNumberElement [focus]");
                  }}
                />

                <CardCvcElement
                  options={options}
                  onReady={() => {
                    console.log("CardNumberElement [ready]");
                  }}
                  onChange={event => {
                    console.log("CardNumberElement [change]", event);
                  }}
                  onBlur={() => {
                    console.log("CardNumberElement [blur]");
                  }}
                  onFocus={() => {
                    console.log("CardNumberElement [focus]");
                  }}
                />
              </label>

            </div>

            <p className="m0 text-left">Name on Card</p>
            <div className="Demo b0">
              {/* <input className="primary tipinpbg p-3 w-100" value={cardname} onChange={(e: any) => { e.preventDefault; setCardname(e.target.value) }} />  +(cardname.length==0 ? " input-error " : "") */}
              <input type="text" id="txt-cardname" className={"primary tipinpbg p-3 w-100"} value={cardname} onChange={(e: any) =>  { e.preventDefault(); onCardNameChange(e); } } />

            </div>

            {isCardError ?
              <p className="m0 text-center text-danger">{carderrormsg}</p> : null
            }

            <button disabled={isPaymentLoading} type="submit" className="btnbg w-100 mb-1 btn-text padding-tb">
              {isPaymentLoading ? "Processing..." : "Pay"}
            </button>

          </form>}
        </div>
      </div>
    );
  }

  function showTerms() {
    dispatch(getTermsAndCondition(USER_GROUP_GUESTS)); 
    setTermsModalOpen(true);  
  }

  // apple pay and google pay element component
  function ApplePayForm() {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [cardname, setCardname] = useState<string>('');
    const [paymentRequest, setPaymentRequest] = useState<any>('');
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    useEffect(() => {

      // checking is stripe is loaded
      if (!stripe || !elements) {
        return;
      }

      // Creating payment request for applepay and google pay
      let amountToStrip = parseInt((tipValue*100).toFixed(0), 10);
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: APP_NAME,
          amount: amountToStrip,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (e) => {
        //requesting stripe payment intent 
        let tipAmount = tipValueWithoutCommission; 
        if(!hoteldata.tipIncludeProcessFee) { 
          tipAmount = Number(((tipValue-STRIPE_COMMISION)-((tipValue-STRIPE_COMMISION)*(hoteldata.paymentCommission/100))).toFixed(2)); 
        }
        // making payment intent in stripe and made easy server
        axios.get(`${env_var.API_URL}payments/v1/token/?amount=${tipValue}&hotel=${hotelValue}&room=${room_number ? room_number : ''}&tip_amount=${tipAmount}&commission=${hoteldata.paymentCommission}`)
          .then(res => {
            const persons = res.data;

            const paymentResult = stripe.confirmCardPayment(persons.token.client_secret, {
              payment_method: e.paymentMethod.id,
            }, { handleActions: false }).then(paymentResult => {

              const dataz = paymentResult.paymentIntent;

              if (dataz && dataz.status === "succeeded") {
                e.complete('success');
                setPaymentLoading(false);
                setPstage(0);
                setisCard(false)

                //sucess page redirection
                console.log(paymentResult);
                history.push({
                  pathname: '/success',
                  state: { amount: tipValueWithoutCommission, hotel_logo: hotelLogo, hotel_data: hoteldata, tipValue: tipValue }
                })
              }
              else {
                //fail handling
                e.complete('fail');
              }
            })

          })

      });
    }, [stripe, elements, tipValue]);

    return (
      <>
      <div className="checkbox-wrapper flex">
        {/* style={ privacyerr ? { color:'red'} : {color:'black'}} */}
        <input name="chk_terms" id="chk_terms" type="checkbox" style={{height:20,width:20}} checked={isPrivacyChecked}
          onChange={() => {
              setPrivacyerr(isPrivacyChecked);
              setPrivacyChecked(!isPrivacyChecked)
          }} 
          className="mt-5px red-text"
        />
        <label htmlFor='chk_terms' className={'tip-terms-checkbox padding-bottom-5px mt-10px'+(privacyerr ? ' red-text' : '')} ><span className={(privacyerr ? ' red-text' : '')}>I agree to the <Link to="#" onClick={() => showTerms()}><span className={(privacyerr ? ' red-text' : '')}> terms & conditions</span> </Link></span></label>
      </div>
      
        {
          paymentRequest != null ?
          pstage == 0?
            <button onClick={() => {
              if(hoteldata.roomLabelMandatory && room_number.trim().length==0) {
                  return false; 
              }
              if(isPrivacyChecked){
                if(hoteldata.tipIncludeProcessFee) { 
                  setModalOpen(true);
                }
                else {
                  setPstage(1)
                }
              }
              else{
                setPrivacyerr(true);
              }
               }} disabled={(tipzero && isCustomTip) ? true : false} className="btnbg padding-tb w-90perc mb-1 btn-text">
              Continue
            </button> : null :
              pstage == 0 && !isCard?
              <button onClick={() => {
                setisCard(true); 
                if(hoteldata.tipIncludeProcessFee && amountChanged) { 
                  setModalOpen(true);
                }
              }
              } type="button" className="btnbg padding-tb w-90perc mb-1 btn-text margin-top8">
                Pay with Card
              </button>:null
        }

        {/* {
          paymentRequest == null && pstage == 0 && !isCard ?
            <button onClick={() => setisCard(true)} type="submit" className="btnbg padding-tb w-90perc mb-1 btn-text margin-top8">
              Pay with Card
            </button> : null

        } */}

        {pstage == 1 && !isCard  && isPrivacyChecked==true ?
          <>
            {paymentRequest && <>

              <PaymentRequestButtonElement options={{ paymentRequest }} />
              
              <p className='m0 text-center helvfont' >OR</p>
            </>}

            <button onClick={() => {
                setisCard(true); 
                if(hoteldata.tipIncludeProcessFee && amountChanged) { 
                  setModalOpen(true);
                } 
              } } type="button" className="btnbg padding-tb w-90perc mb-1 btn-text margin-top8">
              Pay with Card
            </button>
          </> : null
        }

      </>

    );
  }

};

