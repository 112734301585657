import { maidGet, maidPut, maidPost, maidPatch, maidDelete } from './apiService';
// import { get, post, put, deleteApi, getFile, postFile, getAuthImg, patch, maidGet, maidPut } from './apiService';
import { env_var, notification_shown_time_failed } from '../config/env';
// import { IMaidsRequestData, IMaidsExportRequestData, IMaidEdit } from '../models/maidsInterfaces';
import { IMaidProfile, IMaidProfileEdit, IMaidProfileW9Form, IMaidProfileIdProofCreate, IMaidProfileIdProofDelete } from '../models/maidProfileInterfaces'; 
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Store } from 'react-notifications-component';

// STAFF SERVICES FOR STAFF LOGIN 01AUG2022
export const fetchMaidProfile = createAsyncThunk(
    'maid/fetchMaidProfile',
    async (id: number, thunkAPI: any) => {
        try {
            let url = 'users/v1/maid-details/' + id + '/';  
            const response = maidGet(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
); 

export const maidProfileUpdate = createAsyncThunk(
    'maid/maidProfileUpdate',
    async (maidEditData: IMaidProfileEdit, thunkAPI: any) => {
        try {
            let url = 'users/v1/maid-details/' + maidEditData.id + '/';
            const response = maidPut(url, maidEditData);
            return (await response).data;
        } catch (error) {
            console.log('maidProfileUpdate = createAsyncThunk: ', error); 
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetchMaidW9Form = async (id: number): Promise<any> => {
    try {
        let url = 'users/v1/maids/generate-tax-form-mobile/' + id + '/?fromWeb=true';
        const resp = maidGet(url);
        return await resp;
    } catch (error) {
        return new Promise((resolve) => {
            resolve(500);
        });
    }
};

export const createW9Form = createAsyncThunk(
    'maid/createW9Form',
    async (params: IMaidProfileW9Form, thunkAPI: any) => {
        try {
            let id = params.id; 
            let w9data = {ssn: params.ssn, signature: params.signature} 
            let url = 'users/v1/maids/save-tax-form/' + id + '/?fromWeb=true&timezone='+Intl.DateTimeFormat().resolvedOptions().timeZone; 
            const response = maidPost(url,w9data);
            return (await response).data;
        } catch (error) {
            console.log('createW9Form error: '); 
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetchMaidIDProof = async (id: number): Promise<any> => {
    try {
        let url = 'users/v1/maids/upload-id-proof/' + id + '/';
        const resp = maidGet(url);
        return await resp;
    } catch (error) {
        return new Promise((resolve) => {
            resolve(500);
        });
    }
};


export const uploadMaidIDProof = createAsyncThunk(
    'maid/uploadMaidIDProof',
    async (params: IMaidProfileIdProofCreate, thunkAPI: any) => {
        try {
            let id = params.id; 
            // let files = {files: [{uri: params.uri, name: params.name, fileName:params.fileName, type: params.type}]}; 
            // THE IMAGE PICKER FIVES BASE64 DATA, SO CONVERT IT TO BINARY OBJECT AND THEN INTO FILE OBJECT 
            const formData = new FormData();
            const blob = await fetch(params.uri).then((res) => res.blob());

            const buf = await blob.arrayBuffer();
            let files =  new File([buf], params.fileName, { type: params.type });

            formData.append('verification_document', files); // 'files' 
            formData.append('document_type_id', params.docId); // 'order'

            // let files = {files: [params.uri]}; 
            let url = 'users/v1/maids/upload-id-proof/' + id + '/';  
            if(params.idProofFilesLength===0) {
                const response = maidPost(url,formData);
                return (await response).data;
            }
            else {
                const response = maidPatch(url,formData);
                return (await response).data;
            }
            
        } catch (error) {
            console.log('uploadMaidIDProof error: '); 
            return thunkAPI.rejectWithValue(error);
        }
    }
); // 

export const deleteMaidIDProof = createAsyncThunk(
    'maid/deleteMaidIDProof',
    async (params: IMaidProfileIdProofDelete, thunkAPI: any) => {
        try {
            let id = params.id;
            let order = params.order;
            let url = 'users/v1/maids/upload-id-proof/' + id + '/?order='+order;  
            const response = maidDelete(url);
            return (await response).data;
        } catch (error) {
            console.log('deleteMaidIDProof error: '); 
            return thunkAPI.rejectWithValue(error);
        }
    }
);



// export const OTP_LOGIN          = HOST + 'users/v1/otp/';
// export const VERIFY_OTP         = HOST + 'users/v1/token/';
// export const STAFF_DETAILS       = HOST + 'users/v1/maid-details/';
// export const UPLOAD_IDPROOF     = HOST + 'users/v1/maids/upload-id-proof/';
// export const GENERATE_W9        = HOST + 'users/v1/maids/save-tax-form/';
// export const W9_FORM_PDF        = HOST + 'users/v1/maids/generate-tax-form-mobile/'; 


// **********************************************************************

// export const fetchMaids = createAsyncThunk(
//     'maids/fetchMaids',
//     async ({ page, search, hotel }: IMaidsRequestData, thunkAPI: any) => {
//         try {
//             let url = 'users/v1/maids/?page=' + page;
//             if (search != '') {
//                 url = url + '&search=' + search?.trim();
//             }
//             url = url + '&hotel=' + hotel;
//             const response = get(url);
//             return (await response).data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error);
//         }
//     }
// );

// export const fetchMaidDetails = createAsyncThunk(
//     'maids/fetchMaidDetails',
//     async (id: number, thunkAPI: any) => {
//         try {
//             let url = 'users/v1/maid-details/' + id + '/';
//             const response = get(url);
//             return (await response).data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error);
//         }
//     }
// );

// export const approveMaid = createAsyncThunk(
//     'maids/approveMaid',
//     async (id: number, thunkAPI: any) => {
//         try {
//             let url = 'users/v1/maid-approve/' + id + '/';
//             const response = post(url, null);
//             return (await response).data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error);
//         }
//     }
// );

// export const adminUpdateMaid = createAsyncThunk(
//     'maids/adminUpdateMaid',
//     async (maidEditData: IMaidEdit, thunkAPI: any) => {
//         try {
//             let url = 'users/v1/maid-admin-update/' + maidEditData.id + '/';
//             const response = patch(url, maidEditData);
//             return (await response).data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error);
//         }
//     }
// );

// export const getImageId = createAsyncThunk(
//     'maids/getImageId',
//     async (id: number, thunkAPI: any) => {
//         try {
//             let url = 'users/v1/maids/upload-id-proof/' + id + '/';
//             const response = get(url);
//             return (await response).data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error);
//         }
//     }
// );

// export const exportMaids = ({ search, hotel }: IMaidsExportRequestData): void => {
//     try {
//         let url = 'users/v1/maids/download-csv/?hotel=' + hotel;
//         if (search != '') {
//             url = url + '&search=' + search?.trim();
//         }
//         getFile(url, 'Maids_List_Exported');
//     } catch (error) {
//     }
// };

// export const exportW9 = async (id: number): Promise<any> => {
//     try {
//         let url = 'users/v1/maids/generate-tax-form/' + id + '/';
//         const resp = get(url);
//         return await resp;
//     } catch (error) {
//         return new Promise((resolve) => {
//             resolve(500);
//             Store.removeNotification('w9');
//             Store.addNotification({
//                 title: APP_NAME+' - Error',
//                 message: 'Failed to gnerate W9 Form.',
//                 type: 'danger',
//                 insert: 'top',
//                 container: 'top-right',
//                 animationIn: ['animate__animated', 'animate__fadeIn'],
//                 animationOut: ['animate__animated', 'animate__fadeOut'],
//                 dismiss: {
//                     duration: notification_shown_time_failed,
//                     onScreen: true,
//                 },
//             });
//         });
//     }
// };

// export const importMaids = async (hotel: number, file: any): Promise<any> => {
//     const url = 'users/v1/maids/upload-csv/?hotel=' + hotel;
//     let resp = await postFile(url, file);
//     // console.log('importMaids resp: ',resp); 
//     return (resp);

//     // try {
//     //     const url = 'users/v1/maids/upload-csv/?hotel=' + hotel;
//     //     const resp = postFile(url, file);
//     //     return (await resp)?.status;
//     // } catch (error) {        
//     //     // return new Promise((resolve) => {
//     //     //     resolve(500);
//     //     // });
//     //     Store.addNotification({
//     //         title: 'File Format - Error',
//     //         message: 'Invalid file format.',
//     //         type: 'danger',
//     //         insert: 'top',
//     //         container: 'top-center',
//     //         animationIn: ['animate__animated', 'animate__fadeIn'],
//     //         animationOut: ['animate__animated', 'animate__fadeOut'],
//     //         dismiss: {
//     //             duration: notification_shown_time_failed,
//     //             onScreen: true,
//     //         },
//     //     });
//     // }
// };

// export const getSampleMaidImport = (): void => {
//     try {
//         let url = 'users/v1/csv/maidimport/';
//         getFile(url, 'Maids_List_Import');
//     } catch (error) {
//     }
// };
