import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Store } from 'react-notifications-component';
import jwt_decode from 'jwt-decode'; 

import { APP_NAME, notification_shown_time_failed, notification_shown_time_success } from '../../config/env';
import { IMaidRegisterForm, IOtpForm, ITerms } from '../../models/maidRegister';
import { checkMaid, maidOtpRequest, maidRegister, getTermsAndCondition } from '../../services/maidRegisterService';
import { RootState } from '../../store';
import { setMaidTokens, setMaidId } from '../../services/localStorage';
import { acceptTermsAndConditions } from '../../services/maidRegisterService';
import { MSG_OTP_SENDING_FAILED, MSG_OTP_SENT, MSG_RESENT_OTP, MSG_TERMS_ACCEPT_FAILED } from '../../config/messages';

export interface DataState {
  maidRegisterForm: IMaidRegisterForm;
  termsAndC : ITerms;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  screen: 'register' | 'otp' | 'otp-success' | 'maid-profile';
  otpForm: IOtpForm;
  toastMsgType: '' | 'OTPSentSuccess' | 'OTPSentFailed';
  staffTermsAccepted: 'idle' | 'loading' | 'succeeded' | 'error';
}

const initialState: DataState = {
  maidRegisterForm: {} as IMaidRegisterForm,
  termsAndC : {} as ITerms,
  loading: 'idle',
  screen: 'register',
  otpForm: {} as IOtpForm,
  toastMsgType: '',
  staffTermsAccepted: 'idle',
};

export const maidFormSlice = createSlice({
  name: 'maidFormSlice',
  initialState,
  reducers: {
    setScreen: (state, action: PayloadAction<'register' | 'otp' | 'otp-success'>) => {
      state.screen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(maidRegister.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(maidRegister.fulfilled, (state, action) => {
      if(action.payload.access) {
        let dataAccess = action.payload.access;
        let decoded = jwt_decode(dataAccess);

        setMaidId(decoded);
        setMaidTokens(action.payload);
        state.screen = 'maid-profile';
      }
      else {
        state.loading = 'succeeded';
        state.screen = 'otp-success';
      }
      
    });
    builder.addCase(maidRegister.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });
    builder.addCase(checkMaid.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(checkMaid.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.screen = 'otp';
    });
    builder.addCase(checkMaid.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });
    builder.addCase(maidOtpRequest.fulfilled, (state, action) => {
      state.toastMsgType = 'OTPSentSuccess'
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: 'OTP has been sent.',
      //   type: 'success',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
    });
    builder.addCase(maidOtpRequest.rejected, (state, action) => {
      state.toastMsgType = 'OTPSentFailed'; 
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: 'Failed to send OTP.',
      //   type: 'danger',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
    });
    builder.addCase(getTermsAndCondition.pending, (state) => {
      state.loading = 'loading';
      state.staffTermsAccepted = 'loading'; 
    });
    builder.addCase(getTermsAndCondition.fulfilled, (state, action) => {
      console.log("pppp",action.payload);
      state.loading = 'succeeded';
      let data = action.payload as ITerms
      state.termsAndC = data;
      state.staffTermsAccepted = 'loading'; 
    });
    builder.addCase(getTermsAndCondition.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.staffTermsAccepted = 'loading'; 
    });

    builder.addCase(acceptTermsAndConditions.pending, (state) => {
      state.staffTermsAccepted = 'loading';
    });
    builder.addCase(acceptTermsAndConditions.fulfilled, (state, action) => {
      state.staffTermsAccepted = 'succeeded';
    });
    builder.addCase(acceptTermsAndConditions.rejected, (state, { payload }: any) => {
      state.staffTermsAccepted = 'error'; 
      Store.addNotification({
        title: APP_NAME,
        message: MSG_TERMS_ACCEPT_FAILED,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: notification_shown_time_failed,
            onScreen: true,
            pauseOnHover: true,
        },
      });
    });
  },
});

// Action creators are generated for each case reducer function
export const { setScreen } = maidFormSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidFormSlice = (state: RootState) => state.maidFormData;

export const maidregisterReducer = maidFormSlice.reducer;
