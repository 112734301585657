import dayjs from 'dayjs';
import { getUpdateURLParameter } from './url';

export const GetStartDate = (pageTitle: string): string => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let datePresent = params.get(pageTitle + 'start');
  if (datePresent != undefined && datePresent != null) {
    return decodeURIComponent(datePresent);
  } else {
    const date = new Date();
    if (date.getDate() > 15) {
      // payperiod is 1 to 15
      const sixteenthDay = new Date(date.getFullYear(), date.getMonth(), 16);
      let sd = dayjs(sixteenthDay).format(`YYYY-MM-DDT00:00:00ZZ`);
      return sd;
    }
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let sd = dayjs(firstDay).format(`YYYY-MM-DDT00:00:00ZZ`);
     return sd;
  }
};

export const GetEndDateDate = (pageTitle: string): string => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let datePresent = params.get(pageTitle + 'end');
  if (datePresent && datePresent) {
    return decodeURIComponent(datePresent);
  } else {
    const date = new Date();
    if (date.getDate() > 15) {
      // payperiod is 1 to 15
      const lastDay = new Date(date.getFullYear(), date.getMonth()+1, 0);
      let ed = dayjs(lastDay).format(`YYYY-MM-DDT23:59:59ZZ`);
      return ed;
    }
    const sixteenthDay = new Date(date.getFullYear(), date.getMonth(), 15);
    let ed = dayjs(sixteenthDay).format(`YYYY-MM-DDT23:59:59ZZ`);
    return ed;
    
  }
};


export const GetStartDateBKUP = (pageTitle: string): string => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let datePresent = params.get(pageTitle + 'start');
  if (datePresent != undefined && datePresent != null) {
    return decodeURIComponent(datePresent);
  } else {
    const date = new Date();
    if (date.getDate() > 15) {
      // payperiod is 1 to 15
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let sd = dayjs(firstDay).format(`YYYY-MM-DDT00:00:00ZZ`);
      return sd;
    }
    const sixteenthDay = new Date(date.getFullYear(), date.getMonth() - 1, 16);
    let sd = dayjs(sixteenthDay).format(`YYYY-MM-DDT00:00:00ZZ`);
    return sd;
  }
};

export const GetEndDateDateBKUP = (pageTitle: string): string => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let datePresent = params.get(pageTitle + 'end');
  if (datePresent && datePresent) {
    return decodeURIComponent(datePresent);
  } else {
    const date = new Date();
    if (date.getDate() > 15) {
      // payperiod is 1 to 15
      const sixteenthDay = new Date(date.getFullYear(), date.getMonth(), 15);
      let ed = dayjs(sixteenthDay).format(`YYYY-MM-DDT23:59:59ZZ`);
      return ed;
    }
    const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    let ed = dayjs(lastDay).format(`YYYY-MM-DDT23:59:59ZZ`);
    return ed;
  }
};

export const getLowerDateRange = (
  filterFrom: string,
  filterTo: string,
  pageTitle: string
): [string, string] => {
  let fromDate = dayjs(filterFrom).toDate();
  let toDate = dayjs(filterTo).toDate();
  if (fromDate.getDate() === 16) {
    fromDate.setDate(1);
    toDate.setDate(15);
  } else {
    fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, 16);
    toDate = new Date(toDate.getFullYear(), toDate.getMonth(), 0);
  }
  let lower = dayjs(fromDate).format(`YYYY-MM-DDT00:00:00ZZ`);
  let upper = dayjs(toDate).format(`YYYY-MM-DDT23:59:59ZZ`);
  window.history.replaceState(
    '',
    '',
    getUpdateURLParameter(window.location.href, pageTitle + 'start', encodeURIComponent(lower))
  );
  window.history.replaceState(
    '',
    '',
    getUpdateURLParameter(window.location.href, pageTitle + 'end', encodeURIComponent(upper))
  );
  return [lower, upper];
};

export const getNextDateRange = (
  filterFrom: string,
  filterTo: string,
  pageTitle: string
): [string, string] => {
  let fromDate = dayjs(filterFrom).toDate();
  let toDate = dayjs(filterTo).toDate();
  if (fromDate.getDate() === 16) {
    fromDate.setDate(toDate.getDate() + 1);
    toDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 15);
  } else {
    fromDate.setDate(16);
    toDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0);
  }
  let lower = dayjs(fromDate).format(`YYYY-MM-DDT00:00:00ZZ`);
  let upper = dayjs(toDate).format(`YYYY-MM-DDT23:59:59ZZ`);
  window.history.replaceState(
    '',
    '',
    getUpdateURLParameter(window.location.href, pageTitle + 'start', encodeURIComponent(lower))
  );
  window.history.replaceState(
    '',
    '',
    getUpdateURLParameter(window.location.href, pageTitle + 'end', encodeURIComponent(upper))
  );
  return [lower, upper];
};
