import { postWithoutAuth } from './apiService';
import { getMaidAccessToken, getMaidPhone } from './localStorage';

export interface AuthPayload {
  phone_number: string;
}

export interface AuthPayloadOTP {
  mobile_number: string;
  otp: string; 
}

export const authenticateMaidPhone = async (phone_number: string) => {
  const auth: AuthPayload = { phone_number };
  return await (
    await postWithoutAuth('users/v1/otp/', auth)
  ).data;
};

export const verifyMaidPhoneOTP = async (mobile_number: string, otp: string) => {
  const auth: AuthPayloadOTP = { mobile_number, otp };
  return await (
    await postWithoutAuth('users/v1/token/', auth)
  ).data;
};

export const isMaidAuthenticated = (): boolean => {
  return getMaidAccessToken() ? true : false;
};

export const isMaidOTPSent = (): boolean => {
  return getMaidPhone() ? true : false;
};
