import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IMaidProfile, IMaidProfileW9FormGet, IMaidProfileW9Form, IMaidProfileIdProofCreate, IMaidProfileW9FormCreate } from '../../models/maidProfileInterfaces';
import { fetchMaidProfile, maidProfileUpdate, createW9Form, fetchMaidIDProof, uploadMaidIDProof, deleteMaidIDProof } from '../../services/maidProfileService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Store } from 'react-notifications-component';
import { notification_shown_time_success } from '../../config/env';
import { MSG_ID_PROOF_REMOVAL_FAILED, MSG_ID_PROOF_REMOVED, MSG_ID_PROOF_UPLOADED, MSG_ID_PROOF_UPLOAD_FAILED, MSG_MAID_DETAILS_UPDATED, MSG_MAID_DETAILS_UPDATE_FAILED, MSG_W9_FORM_CREATED, MSG_W9_FORM_CREATION_FAILED } from '../../config/messages';

export interface DataState {
  maidProfile: IMaidProfile;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  maidProfileStatus: 'idle' | 'loading' | 'succeeded' | 'error';
  idProofRemovetatus: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  maidProfileW9Form: IMaidProfileW9Form;
  maidProfileW9FormStatus: 'idle' | 'loading' | 'succeeded' | 'error';
  maidProfileIdProofUploadStatus: 'idle' | 'loading' | 'succeeded' | 'error';
  reloadMaidProfileForIDProof: boolean;
  reloadMaidProfileForW9Form: boolean;
  maidProfileW9FormCreate: IMaidProfileW9FormCreate
  // idProofs: IMaidIdProofList;
  // idProofStatus: 'idle' | 'loading' | 'succeeded' | 'error';
}

const initialState: DataState = {
  maidProfile: {} as IMaidProfile,
  loading: 'idle',
  maidProfileStatus: 'idle',
  error: '',
  maidProfileW9Form: {} as IMaidProfileW9Form,
  maidProfileW9FormCreate: {} as IMaidProfileW9FormCreate, 
  maidProfileW9FormStatus: 'idle', 
  maidProfileIdProofUploadStatus: 'idle',
  reloadMaidProfileForIDProof: false,
  reloadMaidProfileForW9Form: false,
  idProofRemovetatus: 'idle', 
  // idProofs: {} as IMaidIdProofList,
  // idProofStatus: 'idle',
};

export const maidProfileDataSlice = createSlice({
  name: 'maidData',
  initialState,
  reducers: {
    clearMaidProfile: (state) => {
      state.maidProfile = {} as IMaidProfile;
      state.maidProfileW9Form = {} as IMaidProfileW9Form;
    },
    setReloadMaidProfileForIDProof: (state, action: PayloadAction<boolean>) => {
      state.reloadMaidProfileForIDProof = action.payload;
    }, 
    setReloadMaidProfileForW9Form: (state, action: PayloadAction<boolean>) => {
      state.reloadMaidProfileForW9Form = action.payload;
    },
    setMaidProfileW9FormCreate: (state, action: PayloadAction<any>) => {
      state.maidProfileW9FormCreate = action.payload;
    }
  },
  extraReducers: (builder) => {

    builder.addCase(fetchMaidProfile.pending, (state) => {
      state.maidProfile = {} as IMaidProfile;
      state.maidProfileStatus = 'loading';
    });
    builder.addCase(fetchMaidProfile.fulfilled, (state, action) => {
      state.maidProfile = action.payload as IMaidProfile;
      state.maidProfileStatus = 'succeeded';
    });
    builder.addCase(fetchMaidProfile.rejected, (state, { payload }: any) => {
      state.maidProfileStatus = 'error';
      // state.error = action.error.message;
    });

    builder.addCase(maidProfileUpdate.pending, (state) => {
      state.maidProfileStatus = 'loading'; 
    });
    builder.addCase(maidProfileUpdate.fulfilled, (state, action) => {
      const m = action.payload as IMaidProfile;
      state.maidProfile = m;
      toast.success(MSG_MAID_DETAILS_UPDATED, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }); 
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: MSG_MAID_DETAILS_UPDATED,
      //   type: 'success',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
      state.maidProfileStatus = 'succeeded';
    });
    builder.addCase(maidProfileUpdate.rejected, (state, { payload }: any) => {
      console.log('maidProfileUpdate rejected', payload); 
      // state.error = action.error.message;
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: MSG_MAID_DETAILS_UPDATE_FAILED,
      //   type: 'danger',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
      state.maidProfileStatus = 'error';
    }); 

    // ***************************************************************************************
    // *********************************** createW9Form **************************************
    // ***************************************************************************************
    builder.addCase(createW9Form.pending, (state) => {
      state.maidProfileW9FormStatus = 'loading';
    });
    builder.addCase(createW9Form.fulfilled, (state, action) => {
      state.reloadMaidProfileForW9Form = true; 
      console.log('createW9Form.fulfilled:: ',action.payload); 
      state.maidProfileW9FormCreate = action.payload as IMaidProfileW9FormCreate;
      state.maidProfileW9FormStatus = 'succeeded';
      toast.success(MSG_W9_FORM_CREATED, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }); 
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: MSG_W9_FORM_CREATED,
      //   type: 'success',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
    });
    builder.addCase(createW9Form.rejected, (state, { payload }: any) => {
      // state.maidProfileW9Form = 'error';
      // state.error = action.error.message;
      state.maidProfileW9FormStatus = 'error';
      toast.error(MSG_W9_FORM_CREATION_FAILED, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }); 
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: MSG_W9_FORM_CREATION_FAILED,
      //   type: 'danger',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
    }); 

    // ***************************************************************************************
    // ******************************** uploadMaidIDProof ************************************
    // ***************************************************************************************
    // fetchMaidIDProof
    builder.addCase(uploadMaidIDProof.pending, (state) => {
      state.maidProfileIdProofUploadStatus = 'loading';
    });
    builder.addCase(uploadMaidIDProof.fulfilled, (state, action) => {
      state.reloadMaidProfileForIDProof = true;
      state.maidProfileIdProofUploadStatus = 'succeeded';
      toast.success(MSG_ID_PROOF_UPLOADED, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }); 
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: MSG_ID_PROOF_UPLOADED,
      //   type: 'success',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
    });
    builder.addCase(uploadMaidIDProof.rejected, (state, { payload }: any) => {
      // state.maidProfileW9Form = 'error';
      // state.error = action.error.message;
      state.maidProfileIdProofUploadStatus = 'error';
      toast.error(MSG_ID_PROOF_UPLOAD_FAILED, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }); 
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: MSG_ID_PROOF_UPLOAD_FAILED,
      //   type: 'danger',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
    }); // 

    // ***************************************************************************************
    // ******************************** deleteMaidIDProof ************************************
    // ***************************************************************************************
    builder.addCase(deleteMaidIDProof.pending, (state) => {
      state.idProofRemovetatus = 'loading';
    });
    builder.addCase(deleteMaidIDProof.fulfilled, (state, action) => {
      state.reloadMaidProfileForIDProof = true;
      state.idProofRemovetatus = 'succeeded';
      console.log('deleteMaidIDProof.fulfilled:: ',action.payload); 
      toast.success(MSG_ID_PROOF_REMOVED, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }); 
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: MSG_ID_PROOF_REMOVED,
      //   type: 'success',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
    });
    builder.addCase(deleteMaidIDProof.rejected, (state, { payload }: any) => {
      // state.maidProfileW9Form = 'error';
      // state.error = action.error.message;
      state.idProofRemovetatus = 'error';
      toast.error(MSG_ID_PROOF_REMOVAL_FAILED, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }); 
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: MSG_ID_PROOF_REMOVAL_FAILED,
      //   type: 'danger',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
    });

    // builder.addCase(getImageId.pending, (state) => {
    //   state.idProofStatus = 'loading';
    // });
    // builder.addCase(getImageId.fulfilled, (state, action) => {
    //   state.idProofs = action.payload as IMaidIdProofList;
    //   state.idProofStatus = 'succeeded';
    // });
    // builder.addCase(getImageId.rejected, (state, { payload }: any) => {
    //   state.idProofStatus = 'error';
    //   // state.error = action.error.message;
    // });


  },
});

// Action creators are generated for each case reducer function
export const { clearMaidProfile, setReloadMaidProfileForIDProof, setReloadMaidProfileForW9Form, setMaidProfileW9FormCreate } = maidProfileDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidProfileDataSlice = (state: RootState) => state.maidProfileData;

export const maidProfileReducer = maidProfileDataSlice.reducer;
