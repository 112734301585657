import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import AddPageHeader from '../../components/AddPageHeader';
import ImageBox from '../../components/ImageBox';
import { useForm, SubmitHandler, SubmitErrorHandler, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { IHotelForm, IHotelId, IHotelSettingsForm } from '../../models/hotelsInterfaces';
import {
  fetchHotel,
  getHotelsettings,
  fetchConfig,
  getHotelQrCode,
  addHotelsettings,
  fetchSettings,
} from '../../services/hotelService';
import {
  selectHotelSettingsFormDataSlice,
  setHotelSettingsForm,
  setNewSettingsAdded,
} from './AddSettings';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactLoading from 'react-loading';
import { notification_shown_time_success, server_url_var, TIP_AMT_MAX_VALUE, SMS_MAX_LENGTH, TIP_FACT_HEADER_MAX_LENGTH, TIP_FACT_BODY_MAX_LENGTH, DEFAULT_CURRENCY, DEFAULT_COUNTRY, COMPANY_DOC_TYPE_IRS_LETTER_147C_ID, COMPANY_DOC_TYPE_IRS_LETTER_147C, COMPANY_DOC_TYPE_IRS_SS4_LETTER_ID, COMPANY_DOC_TYPE_IRS_SS4_LETTER } from '../../config/env';
import dayjs from 'dayjs';
var QRCode = require('qrcode.react');
import copy from 'copy-to-clipboard';
import { Store } from 'react-notifications-component';
import { getPdfFile } from '../../services/apiService';
import { MSG_INVALID_TIP, MSG_INVALID_PHONE_NUMBER, MSG_INVALID_AMOUNT, MSG_STAFF_ID_PROOF_TYPE_ERROR } from '../../config/messages';
import { LBL_MAID } from '../../config/common_labels';

export const SettingsForm = (props: any) => {
  const history = useHistory();


  const [subTitle, setSubTitle] = useState<string>('Add Tips Settings');
  const [selectedImage, setSelectedImage] = useState<BinaryType | null | undefined>(null);
  const { hotelSettingsForm, loading, newSettingsAdded } =
    useAppSelector(selectHotelSettingsFormDataSlice);
  const [selectedMobile, setSelectedMobile] = useState('');
  const [selectedAlternative, setSelectedAlternative] = useState('');

  const [checkDisable, setCheckDisable] = useState(true);
  const [verificationDocumentImg, setVerificationDocumentImg] = useState('');

  const dispatch = useAppDispatch();
  

  useEffect(() => {

    dispatch(setHotelSettingsForm({} as IHotelSettingsForm));
    // reset form with user data


    if (props.match.params.id != undefined) {
      dispatch(getHotelsettings(1));
    } else {
      // dispatch(fetchConfig());
      dispatch(getHotelsettings(1));
    }
    return function cleanup() {
      dispatch(setHotelSettingsForm({} as IHotelSettingsForm));
      reset(hotelSettingsForm);
    };
  }, [dispatch]);

  // This effect is used to handle a redirect when a hotel is created successfully
  React.useEffect(() => {
    if (newSettingsAdded === true) {

      return function cleanup() {
        dispatch(setNewSettingsAdded(false));
      };
    }
  }, [newSettingsAdded]);

  const {
    setValue,
    getValues,
    clearErrors,
    setError,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IHotelSettingsForm>();

  // const { fields, append, remove } = useFieldArray({
  //   control,
  // });


  useEffect(() => {

    console.log(hotelSettingsForm)
    // if (props.match.params.id != undefined) {
    reset(hotelSettingsForm);
    setValue(`accountHolderName`, hotelSettingsForm?.bankDetails?.accountHolderName);
    setValue(`country`, hotelSettingsForm?.bankDetails?.country?hotelSettingsForm?.bankDetails?.country:DEFAULT_COUNTRY );
    setValue(`accountNumber`, hotelSettingsForm?.bankDetails?.accountNumber);
    setValue(`bankName`, hotelSettingsForm?.bankDetails?.bankName);
    setValue(`routingNumber`, hotelSettingsForm?.bankDetails?.routingNumber);
    setVerificationDocumentImg(hotelSettingsForm?.verificationDocument ? `data:image/png;base64,${hotelSettingsForm?.verificationDocument}`
    : '')
    setCheckDisable(hotelSettingsForm?.documentVerified ? hotelSettingsForm?.documentVerified : false);

    //   setSelectedImage(hotelForm?.image);
    //   if (hotelForm == undefined || hotelForm.contacts == undefined || hotelForm.contacts == null) {
    //     append({}, { shouldFocus: false });
    //   }
    //   setTitle(hotelForm?.hotelName);
    //   setSelectedMobile(hotelForm?.mobileNumber);
    //   setSelectedAlternative(hotelForm?.alternativeMobileNumber);
    //   setSubTitle(`Hotel List/${hotelForm?.hotelName}`);
    // } else {
    // setValue(`settingsform`, hotelSettingsForm);
    // }
  }, [hotelSettingsForm]);

  const onErrorSubmit: SubmitErrorHandler<IHotelSettingsForm> = (data) => {
    // if (selectedMobile === '') {
    //   setError('tip_1', {
    //     type: 'manual',
    //     message: MSG_INVALID_TIP, 
    //   });
    // }

    // if (selectedAlternative === '') {
    //   setError('alternativeMobileNumber', {
    //     type: 'manual',
    //     message: MSG_INVALID_PHONE_NUMBER,
    //   });
    // }
  };



  const onSubmit: SubmitHandler<IHotelSettingsForm> = (data) => {
    console.log('inside_submit', data)
    const idsToRemove: number[] = [];
    data.currency = hotelSettingsForm?.currency ? hotelSettingsForm.currency : DEFAULT_CURRENCY
    data.verificationDocument = verificationDocumentImg;    
    if (props.match.params.id === undefined) {
      dispatch(addHotelsettings(data));
    } else {
      data.id = props.match.params.id;
      dispatch(addHotelsettings(data));
    }
  };

  const onSaveSubmitted = (): void => {
    if (loading != 'loading') {
      handleSubmit(onSubmit, onErrorSubmit)();
    }
  };

  const onCancelSubmitted = (): void => {
    history.push('/v1/hotels');
  };

  const fileValidation = (e: any) => {
        setVerificationDocumentImg(e);
  };


  return ( 
    <div className="header-content-container">
      {loading != 'loading' && (
        <AddPageHeader
          contentTitle={"Settings"}
          subTitle={''}
          onSave={onSaveSubmitted}
          onCancel={onCancelSubmitted}
          appLogo={false}
          smallHeader={false}
        />
      )}
      {loading === 'loading' && (
        <div className="table-loader">
          <ReactLoading className="loader-center" color={'#2f427c'} type={'bars'} width={'3vw'} />
        </div>
      )}
      {loading != 'loading' && (



        <div className="box pb-5">
          <form autoComplete="off">
            <div className="row top">
              <div className="col-12">


                <div className="row  mb-10px">
                  <div className="col-6 form-group mb-10px">
                    <div className="mb-10px">
                      <label className="div-label font-bold">{LBL_MAID} Welcome Message (SMS)</label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('welcomeMessage', { required: 'Required.', maxLength: SMS_MAX_LENGTH })}
                        type="text"
                        name="welcomeMessage"
                        className="w-100px"
                        placeholder="Welcome Message"
                        maxLength={SMS_MAX_LENGTH}
                      />
                    </div>
                    {errors.welcomeMessage && <span className="danger">{errors.welcomeMessage.message}</span>}
                  </div>
                  <div className="col-6 form-group mb-10px">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Default Commission %</label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('defaultPaymentCommission', { required: 'Required.' })}
                        type="number"
                        name="defaultPaymentCommission"
                        className="w-100px"
                        placeholder="Default Commission"
                      />
                    </div>
                    {errors.defaultPaymentCommission && <span className="danger">{errors.defaultPaymentCommission.message}</span>}
                  </div>
                 
                </div>



                <br></br>
                <div className="mb-10px">
                  <h5 className="div-label font-bold">
                    <div className="heading">Tips</div>
                  </h5>
                </div>

                <div className="row mt-20px">
                  <div className="col-1 form-group min-width-125">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tip 1 </label>
                    </div>
                    <div className="mb-10px">
                      <div className='input-icons'>
                        <i className="fa fa-dollar hotel_icon"></i>
                        <input
                          {...register('tip_1', {
                            required: 'Required.', max: TIP_AMT_MAX_VALUE,
                            pattern: {
                              value: /^[.0-9]*$/,
                              message: MSG_INVALID_AMOUNT,
                            },
                          })}
                          type="number"
                          name="tip_1"
                          className="w-100px tipinp"
                          placeholder="Tip 1"
                          min={0}
                          max={TIP_AMT_MAX_VALUE}
                        />
                      </div>
                    </div>
                    {errors.tip_1 && <span className="danger">{errors.tip_1.message}</span>}
                  </div>


                  <div className="col-1 form-group ml-10px min-width-125">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tip 2 </label>
                    </div>
                    <div className="mb-10px">
                      <div className='input-icons'>
                        <i className="fa fa-dollar hotel_icon"></i>
                        <input
                          {...register('tip_2', {
                            required: 'Required.', max: TIP_AMT_MAX_VALUE,
                            pattern: {
                              value: /^[.0-9]*$/,
                              message: MSG_INVALID_AMOUNT,
                            },
                          })}
                          type="number"
                          name="tip_2"
                          className="w-100px tipinp"
                          placeholder="Tip 2"
                          min={0}
                          max={TIP_AMT_MAX_VALUE}
                        />
                      </div>
                      {errors.tip_2 && <span className="danger">{errors.tip_2.message}</span>}
                    </div>
                  </div>

                  <div className="col-1 form-group ml-10px min-width-125">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tip 3 </label>
                    </div>
                    <div className="mb-10px">
                      <div className='input-icons'>
                        <i className="fa fa-dollar hotel_icon"></i>
                        <input
                          {...register('tip_3', {
                            required: 'Required.', max: TIP_AMT_MAX_VALUE,
                            pattern: {
                              value: /^[.0-9]*$/,
                              message: MSG_INVALID_AMOUNT,
                            },
                          })}
                          type="number"
                          name="tip_3"
                          className="w-100px tipinp"
                          placeholder="Tip 3"
                          min={0}
                          max={TIP_AMT_MAX_VALUE}
                        />
                      </div>
                      {errors.tip_3 && <span className="danger">{errors.tip_3.message}</span>}
                    </div>
                  </div>

                  <div className="col-1 form-group ml-10px min-width-125">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tip 4 </label>
                    </div>
                    <div className="mb-10px">
                      <div className='input-icons'>
                        <i className="fa fa-dollar hotel_icon"></i>
                        <input
                          {...register('tip_4', {
                            required: 'Required.', max: TIP_AMT_MAX_VALUE,
                            pattern: {
                              value: /^[.0-9]*$/,
                              message: MSG_INVALID_AMOUNT,
                            },
                          })}
                          type="number"
                          name="tip_4"
                          className="w-100px tipinp"
                          placeholder="Tip 4"
                          min={0}
                          max={TIP_AMT_MAX_VALUE}
                        />
                      </div>
                      {errors.tip_4 && <span className="danger">{errors.tip_4.message}</span>}
                    </div>
                  </div>

                  <div className="col-1 form-group ml-10px min-width-125">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tip 5 </label>
                    </div>
                    <div className="mb-10px">
                      <div className='input-icons'>
                        <i className="fa fa-dollar hotel_icon"></i>
                        <input
                          {...register('tip_5', {
                            required: 'Required.', max: TIP_AMT_MAX_VALUE,
                            pattern: {
                              value: /^[.0-9]*$/,
                              message: MSG_INVALID_AMOUNT,
                            },
                          })}
                          type="number"
                          name="tip_5"
                          className="w-100px tipinp"
                          placeholder="Tip 5"
                          min={0}
                          max={TIP_AMT_MAX_VALUE}
                        />
                      </div>
                    </div>
                    {errors.tip_5 && <span className="danger">{errors.tip_5.message}</span>}
                  </div>
                </div>

                
                  <div className="row mt-20px">
                    <div className="col-3 ">
                      <div className="">
                        <h5 className="div-label font-bold">
                          <div className="heading">Bank Details</div>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Bank Account Payee Name</th>
                          <th>Country</th>
                          <th>Bank Account number</th>
                          <th>Bank Name</th>
                          <th>ABA/ ACH Routing Code</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <input
                              {...register('accountHolderName', { required: 'Required.' })}
                              name="accountHolderName"
                              type="text"
                              className={ "w-100px"}
                            ></input>
                            {errors.accountHolderName && <span className="danger">{errors.accountHolderName.message}</span>}
                          </td>
                          <td>
                            <input
                              {...register('country')}
                              name="country"
                              value={DEFAULT_COUNTRY}
                              type="text"
                              className={"w-100px"}
                              readOnly
                            ></input>
                            {errors.country && <span className="danger">{errors.country.message}</span>}

                          </td>
                          <td>
                            <input
                              {...register('accountNumber', { required: 'Required.' })}
                              name="accountNumber"
                              type="text"
                              className={"w-100px"}
                              maxLength={35}
                            ></input>
                            {errors.accountNumber && <span className="danger">{errors.accountNumber.message}</span>}
                          </td>
                          <td>
                            <input
                              {...register('bankName', { required: 'Required.'})}
                              name="bankName"
                              type="text"
                              className={"w-100px"}
                            ></input>
                            {errors.bankName && <span className="danger">{errors.bankName.message}</span>}

                          </td>
                          <td>
                            <input
                              {...register('routingNumber', { required: 'Required.' })}
                              name="routingNumber"
                              type="text"
                              className={"w-100px"}
                              maxLength={9}
                            ></input>
                            {errors.routingNumber && <span className="danger">{errors.routingNumber.message}</span>}
                          </td>


                        </tr>
                      </tbody>

                    </table>
                  </div>
                  
                  <div className="row mt-20px">
                    <div className="col-3 ">
                      <div className="">
                        <h5 className="div-label font-bold">
                          <div className="heading">ID Proof</div>
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4 ">
                      <div className="col-4">
                        <div className="mt-20px">
                          <label className="div-label font-bold">
                            <div className="">Document Type</div>
                          </label>
                        </div>
                        <div className="mb-10px ">
                          <select
                            id="documentTypeId"
                            className={'w-100px'}
                            {...register('documentTypeId')}
                            disabled={checkDisable}
                          >
                            <option value={0}>Select</option>
                            <option value={COMPANY_DOC_TYPE_IRS_LETTER_147C_ID}>
                              {COMPANY_DOC_TYPE_IRS_LETTER_147C}
                            </option>
                            <option value={COMPANY_DOC_TYPE_IRS_SS4_LETTER_ID}>
                              {COMPANY_DOC_TYPE_IRS_SS4_LETTER}{' '}
                            </option>
                          </select>
                        </div>
                        {errors.documentTypeId && (
                          <span className="danger">{errors.documentTypeId.message}</span>
                        )}
                      </div>
                      
                      <div className="col-4">
                        <div className="mt-20px">
                          <label className="div-label font-bold">
                            <div className="">Legal Company Name{/*<span className="danger">*</span>*/}</div>
                          </label>
                        </div>
                        <div className="mb-10px ">
                        <input
                          {...register('companyName', { required: 'Required.' })}
                          name="companyName"
                          type="text"
                          className={'w-100px'}
                        ></input>
                        </div>
                        {errors.companyName && (
                          <span className="danger">{errors.companyName.message}</span>
                        )}
                      </div>
                      <div className="col-4">
                        <div className="mt-20px">
                          <label className="div-label font-bold">
                            <div className="">Company Tax ID{/*<span className="danger">*</span>*/}</div>
                          </label>
                        </div>
                        <div className="mb-10px ">
                        <input
                          {...register('companyTaxId')}
                          name="companyTaxId"
                          type="text"
                          className={'w-100px'}
                        ></input>
                        </div>
                        {errors.documentTypeId && (
                          <span className="danger">{errors.documentTypeId.message}</span>
                        )}
                      </div>
                      
                  
                    </div>
                    <div className="row mb-4 ">
                      <div className="mt-10px">
                        <h5 className="div-label font-bold">
                          <div className="">Upload Document Image</div>
                        </h5>
                        <p className="div-label-small mb-0">{MSG_STAFF_ID_PROOF_TYPE_ERROR}</p>
                      </div>
                      <div className="mb-10px">
                        <ImageBox
                          label={''}
                          id={'verificationDocumentImg'}
                          url={''}
                          defaultImage={verificationDocumentImg}
                          onUpload={fileValidation}
                          checkDisable={checkDisable}
                        />
                      </div>
                      {errors.verificationDocument && (
                        <span className="danger">{errors.verificationDocument.message}</span>
                      )}
                    </div>

                <br></br>
                <div className="mb-10px">
                  <h5 className="div-label font-bold">
                    <div className="heading">Tipping Facts</div>
                  </h5>
                </div>

                <div className="row">
                  <div className="col-5 form-group  mb-43">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tipping Fact Header 1</label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('tippingFactHeader_1', { required: 'Required.', maxLength: TIP_FACT_HEADER_MAX_LENGTH })}
                        type="text"
                        name="tippingFactHeader_1"
                        className="w-100px"
                        placeholder="Tipping Fact Header 1"
                        maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                      />
                    </div>
                    {errors.tippingFactHeader_1 && <span className="danger">{errors.tippingFactHeader_1.message}</span>}
                  </div>

                  <div className="col-7 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tipping Fact body 1</label>
                    </div>
                    <div className="mb-10px">
                      <textarea
                        {...register('tippingFactBody_1', { required: 'Required.', maxLength: TIP_FACT_BODY_MAX_LENGTH })}
                        rows={2}
                        name="tippingFactBody_1"
                        className="w-100px"
                        placeholder="Tipping Fact Header 1"
                        maxLength={TIP_FACT_BODY_MAX_LENGTH}
                      />
                    </div>
                    {errors.tippingFactBody_1 && <span className="danger">{errors.tippingFactBody_1.message}</span>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-5 form-group mb-43">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tipping Fact Header 2</label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('tippingFactHeader_2', { required: 'Required.', maxLength: TIP_FACT_HEADER_MAX_LENGTH })}
                        type="text"
                        name="tippingFactHeader_2"
                        className="w-100px"
                        placeholder="Tipping Fact Header 2"
                        maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                      />
                    </div>
                    {errors.tippingFactHeader_2 && <span className="danger">{errors.tippingFactHeader_2.message}</span>}
                  </div>
                  <div className="col-7 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tipping Fact body 2</label>
                    </div>
                    <div className="mb-10px">
                      <textarea
                        {...register('tippingFactBody_2', { required: 'Required.', maxLength: TIP_FACT_BODY_MAX_LENGTH })}
                        rows={2}
                        name="tippingFactBody_2"
                        className="w-100px"
                        placeholder="Tipping Fact body  2"
                      />
                    </div>
                    {errors.tippingFactBody_2 && <span className="danger">{errors.tippingFactBody_2.message}</span>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-5 form-group mb-43">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tipping Fact Header 3</label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('tippingFactHeader_3', { required: 'Required.', maxLength: TIP_FACT_HEADER_MAX_LENGTH })}
                        type="text"
                        name="tippingFactHeader_3"
                        className="w-100px"
                        placeholder="Tipping Fact Header 3"
                        maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                      />
                    </div>
                    {errors.tippingFactHeader_3 && <span className="danger">{errors.tippingFactHeader_3.message}</span>}
                  </div>
                  <div className="col-7 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tipping Fact body 3</label>
                    </div>
                    <div className="mb-10px">
                      <textarea
                        {...register('tippingFactBody_3', { required: 'Required.', maxLength: TIP_FACT_BODY_MAX_LENGTH })}
                        rows={2}
                        name="tippingFactBody_3"
                        className="w-100px"
                        placeholder="Tipping Fact body 3"
                        maxLength={TIP_FACT_BODY_MAX_LENGTH}
                      />
                    </div>
                    {errors.tippingFactBody_3 && <span className="danger">{errors.tippingFactBody_3.message}</span>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-5 form-group mb-43">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tipping Fact Header 4</label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('tippingFactHeader_4', { required: 'Required.', maxLength: TIP_FACT_HEADER_MAX_LENGTH })}
                        type="text"
                        name="tippingFactHeader_4"
                        className="w-100px"
                        placeholder="Tipping Fact Header 4"
                        maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                      />
                    </div>
                    {errors.tippingFactHeader_4 && <span className="danger">{errors.tippingFactHeader_4.message}</span>}
                  </div>
                  <div className="col-7 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tipping Fact body 4</label>
                    </div>
                    <div className="mb-10px">
                      <textarea
                        {...register('tippingFactBody_4', { required: 'Required.', maxLength: TIP_FACT_BODY_MAX_LENGTH })}
                        rows={2}
                        name="tippingFactBody_4"
                        className="w-100px"
                        placeholder="Tipping Fact body 4"
                        maxLength={TIP_FACT_BODY_MAX_LENGTH}
                      />
                    </div>
                    {errors.tippingFactBody_4 && <span className="danger">{errors.tippingFactBody_4.message}</span>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-5 form-group mb-43">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tipping Fact Header 5</label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('tippingFactHeader_5', { required: 'Required.', maxLength: TIP_FACT_HEADER_MAX_LENGTH })}
                        type="text"
                        name="tippingFactHeader_5"
                        className="w-100px"
                        placeholder="Tipping Fact Header 5"
                        maxLength={TIP_FACT_HEADER_MAX_LENGTH}
                      />
                    </div>
                    {errors.tippingFactHeader_5 && <span className="danger">{errors.tippingFactHeader_5.message}</span>}
                  </div>
                  <div className="col-7 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Tipping Fact body 5</label>
                    </div>
                    <div className="mb-10px">
                      <textarea
                        {...register('tippingFactBody_5', { required: 'Required.', maxLength: TIP_FACT_BODY_MAX_LENGTH })}
                        rows={2}
                        name="tippingFactBody_5"
                        className="w-100px"
                        placeholder="Tipping Fact body 5"
                        maxLength={TIP_FACT_BODY_MAX_LENGTH}
                      />
                    </div>
                    {errors.tippingFactBody_5 && <span className="danger">{errors.tippingFactBody_5.message}</span>}
                  </div>
                </div>

                <div className="mb-10px">
                  <h5 className="div-label font-bold">
                    <div className="heading">Social Media Links</div>
                  </h5>
                </div>

                <div className="row">
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Facebook Link</label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('facebookLink', { required: 'Required.' })}
                        type="text"
                        name="facebookLink"
                        className="w-100px"
                        placeholder="Facebook Link"
                      />
                    </div>
                    {errors.facebookLink && <span className="danger">{errors.facebookLink.message}</span>}
                  </div>
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Youtube Link</label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('youtubeLink', { required: 'Required.' })}
                        type="text"
                        name="youtubeLink"
                        className="w-100px"
                        placeholder="Youtube Link"
                      />
                    </div>
                    {errors.youtubeLink && <span className="danger">{errors.youtubeLink.message}</span>}
                  </div>
                  
                </div>

                <div className="row">
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Twitter Link</label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('twitterLink', { required: 'Required.' })}
                        type="text"
                        name="twitterLink"
                        className="w-100px"
                        placeholder="Twitter Link"
                      />
                    </div>
                    {errors.twitterLink && <span className="danger">{errors.twitterLink.message}</span>}
                  </div>
                  <div className="col-6 form-group">
                    <div className="mb-10px">
                      <label className="div-label font-bold">Instagram Link</label>
                    </div>
                    <div className="mb-10px">
                      <input
                        {...register('instagramLink', { required: 'Required.' })}
                        type="text"
                        name="instagramLink"
                        className="w-100px"
                        placeholder="Instagram Link"
                      />
                    </div>
                    {errors.instagramLink && <span className="danger">{errors.instagramLink.message}</span>}
                  </div>
                </div>

                <div className="col-6 form-group">&nbsp;</div>


              </div>


            </div>

          </form>
        </div>
      )}
    </div>
  );
};
