import React from 'react';
import logo from './../../assets/img/tova_stack.png';

interface IProps {
  contentTitle: string;
  subTitle: string;
  onSave: () => void;
  onCancel: () => void;
  appLogo: boolean;
  smallHeader: boolean;
  saveButtonText?: string; 
}

const AddPageHeader: React.FC<IProps> = ({ contentTitle, subTitle, onSave, onCancel, appLogo, smallHeader, saveButtonText }) => {
  return (
    <div className="content_header">
      <div className="justify-space-between">

        <div className="justify-left">
          {appLogo &&(
                <a className="text-white hotel-logo-right" href="#">
                  <img src={logo} alt="logo" className="small headerSmall"/>
                </a>
              )
            }
          <div>
            <div>
              {!smallHeader && <h1 className="font-bold color-blue font-merri">{contentTitle}</h1>}
              {smallHeader && <h3 className="font-bold color-blue mt-25px margin-left-15px font-merri">{contentTitle}</h3>}
            </div>
          {subTitle && subTitle.length > 1 && <div>
            <label>{subTitle}</label>
          </div>}
          </div>
        </div>
        <div className='margin-top15'>
          <button type="button" className="primary" onClick={onSave}>
            {saveButtonText && saveButtonText.length > 0 ? saveButtonText : 'Save Details'}
          </button>
          {/* <button type="button" className="margin-left-15px" onClick={onCancel}>
            Cancel
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default AddPageHeader;
