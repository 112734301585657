import { get, post, put, postFile, getFile } from './apiService';
import {
  IMaidPaymentFilterData,
  IMaidPaymentFilterRequest,
  IMaidsPaymentsRequestData,
} from '../models/maidPaymentsInterface';
import { IHotelPaymentTimesheetRequest } from '../models/hotelPaymentsInterfaces';
import { IMaidTransacionDetailsRequest } from '../models/userDetailsInterfaces';

import { createSlice, createSelector, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Store } from 'react-notifications-component';
import { notification_shown_time_failed } from '../config/env';

export const fetchMaidPayments = createAsyncThunk(
  'maids/fetchMaidPaymentss',
  async (
    { page, search, start, end, hotel, type, additional }: IMaidsPaymentsRequestData,
    thunkAPI: any
  ) => {
    try {
      let url = 'payments/v1/maid-payments/?page=' + page + '&hotel=' + hotel;
      // if (search != '') {
      //   url = url + '&search=' + search?.trim();
      // }
      url = url + '&search=' + search?.trim();
      if (start != '') {
        url = url + '&start=' + start;
      }
      if (end != '') {
        url = url + '&end=' + end;
      }
      if (type != '') {
        url = url + '&type=' + type;
      }
      url = url + '&additional=' + additional;
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchMaidTimeSheets = createAsyncThunk(
  'maids/fetchMaidTimeSheets',
  async ({ start, end, hotel_id, maid_id }: IHotelPaymentTimesheetRequest, thunkAPI: any) => {
    try {
      let url =
        'hotels/v1/hotel/timesheet/' +
        hotel_id +
        '/' +
        maid_id +
        '/' +
        '?start=' +
        start +
        '&end=' +
        end;
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchMaidTansactions = createAsyncThunk(
  'maids/fetchMaidTansactions',
  async ({ start, end, hotel_id, maid_id }: IMaidTransacionDetailsRequest, thunkAPI: any) => {
    try {
      let url =
        'hotels/v1/hotel/transactions/' +
        maid_id +
        '/' +
        '?start=' +
        start +
        '&end=' +
        end +
        '&hotel_id=' +
        hotel_id;
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const exportMaidPayments = ({
  search,
  start,
  end,
  hotel,
  type,
  additional,
}: IMaidsPaymentsRequestData): void => {
  try {
    let url = 'payments/v1/maid-payments/csv-download/?csv=true' + '&hotel=' + hotel;
    if (search != '') {
      url = url + '&search=' + search?.trim();
    }
    if (start != '') {
      url = url + '&start=' + start;
    }
    if (end != '') {
      url = url + '&end=' + end;
    }
    if (type != '') {
      url = url + '&type=' + type;
    }
    url = url + '&additional=' + additional;
    getFile(url, 'Maid_Payments_Exported');
  } catch (error) {
  }
};

export const fetchHotelsNameId = createAsyncThunk(
  'hotels/fetchHotelsNameId',
  async (search: string, thunkAPI: any) => {
    try {
      let url = 'hotels/v1/hotel/list/';
      if (search != '') {
        url = url + '?search=' + search?.trim();
      }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const importTimesheet = async (hotel: string, file: any , startdate :any): Promise<any> => {

  let url = 'hotels/v1/hotel/timesheet/csv-upload/?hotel=' + hotel;
  if (startdate != '') {
    url = url + '&start_date=' + startdate;
  }
  let resp = await postFile(url, file);
  // console.log('importTimesheet resp: ',resp); 
  return (resp);

  // try {
  //   const url = 'hotels/v1/hotel/timesheet/csv-upload/?hotel=' + hotel;
  //   let resp = postFile(url, file);
  //   return (await resp);
  // } catch (error) {
  //   // return new Promise((resolve) => {
  //   //   resolve(500);
  //   // });
  //   Store.addNotification({
  //       title: 'File Format - Error',
  //       message: 'Invalid file format.',
  //       type: 'danger',
  //       insert: 'top',
  //       container: 'top-center',
  //       animationIn: ['animate__animated', 'animate__fadeIn'],
  //       animationOut: ['animate__animated', 'animate__fadeOut'],
  //       dismiss: {
  //           duration: notification_shown_time_failed,
  //           onScreen: true,
  //       },
  //   });
  // }
};

export const processFailedTransactions = async (id: string, start_date:string, end_date:string): Promise<any> => {
  try {
    let url = 'payments/v1/process-failed/';
    let sepVar = '?'; 
    if (id && id != '0') {
      url = url + sepVar+'hotel=' + id;
      sepVar = '&'
    }
    if (start_date != '' && start_date.trim().length > 3) {
      url = url + sepVar+'pay_period_start_date=' + start_date;
      sepVar = '&'
    }
    if (end_date != '' && end_date.trim().length > 3) {
      url = url + sepVar+'pay_period_end_date=' + end_date;
      sepVar = '&'
    }
    let resp = await post(url, null);
    return (resp);
  } catch (error) {
    return new Promise((resolve) => {
      resolve(400);
    });
  }
};

export const processDirectHotelPayments = async (processType:string, id: string, start_date:string, end_date:string): Promise<any> => {
  try {
    let url = processType=='staff' ? 'payments/v1/staff-payout/' : 'payments/v1/hotel-payout/';  // hotel-payout CHANGED ON 13Dec2022 
    let sepVar = '?'; 
    if (id != '' && id != '0' ) {
      url = url + sepVar+'hotel_id=' + id;
      sepVar = '&'
    }
    if (start_date != '' && start_date.trim().length > 3) {
      url = url + sepVar+'pay_period_start_date=' + start_date;
      sepVar = '&'
    }
    if (end_date != '' && end_date.trim().length > 3) {
      url = url + sepVar+'pay_period_end_date=' + end_date;
      sepVar = '&'
    }
    let resp = await get(url, null);
    return (resp);
  } catch (error) {
    return new Promise((resolve) => {
      resolve(400);
    });
  }
};

export const getSampleTimesheet = (): void => {
  try {
    let url = 'users/v1/csv/hoteltimesheet/';
    getFile(url, 'Timesheet_List_Import');
  } catch (error) {
  }
};
export const getApiTimesheetHourly = (): void => {
  try {
    let url = 'users/v1/csv/hourstimesheet/';
    getFile(url, 'Timesheet_List_Import');
  } catch (error) {
  }
};

export const fetchMaidPaymentsFilterData = createAsyncThunk(
  'maids/fetchPaymentsFilter',
  async ({ search, start_date, end_date, hotel, type }: IMaidPaymentFilterRequest, thunkAPI: any) => {
    try {
      let url =
        'payments/v1/maid-payments/filter/?start_date=' +
        start_date +
        '&end_date=' +
        end_date +
        '&hotel=' +
        hotel +
        '&type=' +
        type;
        if (search != '') {
          url = url + '&search=' + search?.trim();
        } 
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
