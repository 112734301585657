import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotelNameId } from '../../models/hotelsInterfaces';
import { fetchHotelsNameId } from './../../services/hotelService';
import { IAutocompleteSelectOptionType } from '../../models/maidsInterfaces';
import { stat } from 'fs';
import { getUpdateURLParameter } from '../../helpers/url';
import { getSelectedHotelId } from '../../services/localStorage';
import { PAYOUT_STAFF } from '../../config/env';

const localStorageHotelId  = getSelectedHotelId();

export interface FilterState {
  curHotelValue: number;
  hotelNameIds: IHotelNameId[];
  curHotelOPt: IAutocompleteSelectOptionType[];
  allHotelOpts: IAutocompleteSelectOptionType[];
  allHotelOptsInactive: IAutocompleteSelectOptionType[];
  isHotelActive: boolean;
}

const initialState: FilterState = {
  curHotelValue: 0,
  hotelNameIds: [],
  // curHotelOPt: {} as IAutocompleteSelectOptionType,  
  curHotelOPt: [], 
  allHotelOpts: [],
  allHotelOptsInactive: [],
  isHotelActive: true,
};

export const maidListHotelFilterSlice = createSlice({
  name: 'maidListHotelFilter',
  initialState,
  reducers: {
    changeHotelFilter: (state, action: PayloadAction<any>) => {
      state.curHotelValue = action.payload; 
      // if (action.payload != 0) {
      //   window.history.replaceState(
      //     '',
      //     '',
      //     getUpdateURLParameter(window.location.href, 'maid-list-hotel', action.payload)
      //   );
      // }
      let op = state.allHotelOpts.find((option) => option.value === action.payload);
      if (op !== undefined) {
        state.curHotelOPt = [op];
        let opVal = parseInt(op.value);
        if (opVal != undefined) {
          let hotel = state.hotelNameIds.find((option) => option.id === opVal);
          if (hotel !== undefined) {
            state.isHotelActive = hotel.isActive;
          } else {
            state.isHotelActive = true;
          }
        }
      }
    },
    changeHotelFilterToDefault: (state) => {
      let op = state.allHotelOpts.find((x) => x !== undefined);
      if (op !== undefined) {
        state.curHotelOPt = [op];
        state.curHotelValue = parseInt(op.value);
        let opVal = parseInt(op.value);
        if (opVal != undefined) {
          let hotel = state.hotelNameIds.find((option) => option.id === opVal);
          if (hotel !== undefined) {
            state.isHotelActive = hotel.isActive;
          } else {
            state.isHotelActive = true;
          }
        }
      }
    },

    changeCurValue: (state, action: PayloadAction<any>) => {
      state.curHotelValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHotelsNameId.pending, (state) => {
      state.hotelNameIds = [];
    });
    builder.addCase(fetchHotelsNameId.fulfilled, (state, action) => {
      const hotels = action.payload as IHotelNameId[];
      state.hotelNameIds = hotels;
      let opts: IAutocompleteSelectOptionType[] = [];
      let optsInactive: IAutocompleteSelectOptionType[] = [];
      hotels.map((hotelNameId) => {
        opts.push({ value: hotelNameId.id.toString(), label: hotelNameId.hotelName, active: hotelNameId.isActive ? true : false, payout:hotelNameId.payout});
        optsInactive.push({ value: hotelNameId.id.toString(), label: hotelNameId.isActive ? hotelNameId.hotelName : (hotelNameId.hotelName + ' (Inactive)'), active: hotelNameId.isActive ? true : false, payout:hotelNameId.payout });
      });
      state.allHotelOpts = [{value:"all",label:"All Hotels",active:true, payout:PAYOUT_STAFF},...opts];  
      state.allHotelOptsInactive = [{value:"all",label:"All Hotels",active:true, payout:PAYOUT_STAFF},...optsInactive];

      // if (opts.length > 0) {
      //   if (state.curHotelValue == 0) {
      //     let opt = opts.find((option) => option.active==true);
      //     if(!opt || opt == undefined) {
      //       opt = opts[0]; 
      //     }
      //     if (opt != undefined) {
      //       state.curHotelOPt = [opt];
      //       state.curHotelValue = parseInt(opt.value);
      //       let opVal = parseInt(opt.value);
      //       if (opVal != undefined) {
      //         let hotel = state.hotelNameIds.find((option) => option.id === opVal);
      //         if (hotel !== undefined) {
      //           state.isHotelActive = hotel.isActive;
      //         } else {
      //           state.isHotelActive = true;
      //         }
      //       }
      //     }
      //   } else {
      //     let opt = opts.find((option) => parseInt(option.value) == state.curHotelValue);
      //     if(!opt || opt == undefined) {
      //       opt = opts[0]; 
      //     }
      //     if (opt != undefined) {
      //       state.curHotelOPt = [opt];
      //       state.curHotelValue = parseInt(opt.value);
      //       let opVal = parseInt(opt.value);
      //       if (opVal != undefined) {
      //         let hotel = state.hotelNameIds.find((option) => option.id === opVal);
      //         if (hotel !== undefined) {
      //           state.isHotelActive = hotel.isActive;
      //         } else {
      //           state.isHotelActive = true;
      //         }
      //       }
      //     }
      //   }

      //   opts.find((option) => {
      //     if (parseInt(option.value) == state.curHotelValue) {
      //       state.curHotelOPt = [option];
      //     }
      //   });
      // }
      // if (state.propsCurValue !== 0) {
      //   state.curValue = state.propsCurValue;
      //   state.propsCurValue = 0;
      // }
    });
    builder.addCase(fetchHotelsNameId.rejected, (state, { payload }: any) => {
      //   state.loading = "error";
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { changeHotelFilter, changeCurValue, changeHotelFilterToDefault } =
  maidListHotelFilterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidListHotelFilterSlice = (state: RootState) => state.maidListHotelFilter;

export const maidListHotelFilterReducer = maidListHotelFilterSlice.reducer;
