import { loadStripe } from '@stripe/stripe-js';
import { stripe_token_var } from '../config/env';

declare const stripe: {
  loadStripe: typeof loadStripe;
};

let stripePromise = loadStripe(stripe_token_var);
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = stripe.loadStripe(stripe_token_var);
  }
  return stripePromise;
};

export default getStripe;
