import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IAdminAssociate, IAdminAssociateCreate } from '../../models/adminAssociateInterfaces';
import { AdminAssociateUsernameSearch, fetchAdminAssociates, fetchHotelUsers } from '../../services/adminAssociateService';
import { IGenericResponse, IPagination } from '../../services/interfaces';
import { IHotelAssociate } from '../../models/hotelAssociateInterfaces';

export interface DataState {
  associates: IHotelAssociate[];
  associatesUser: IAdminAssociateCreate;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  assocUserloading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  totalSize: number;
}

const initialState: DataState = {
  associates: [],
  associatesUser: {
    firstName: "",
    lastName: "",
    username: "",
    mobile: "",
    position: "",
    role: 0,
    hotelList: [],
    isActive: true,
  },
  loading: 'idle',
  assocUserloading: 'idle',
  error: '',
  totalSize: 0,
};

export const HotelUserListDataSlice = createSlice({
  name: 'adminAssociatesListData',
  initialState,
  reducers: {
    setHotelUserList: (state, action: PayloadAction<any>) => {
      state.associates = action.payload;
    },
    setDataLoading: (state) => {
      state.loading = 'loading';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHotelUsers.pending, (state) => {
      state.associates = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchHotelUsers.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IHotelAssociate[]>;
      state.associates =  m.results;
      state.loading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchHotelUsers.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
    builder.addCase(AdminAssociateUsernameSearch.pending, (state) => {
      // state.loading = 'loading';
      state.assocUserloading = 'loading';
    });
    builder.addCase(AdminAssociateUsernameSearch.fulfilled, (state, action) => {
      const m = action.payload as IAdminAssociateCreate;
      state.associatesUser = m;
      console.log(state.associatesUser,"fk");
      
      state.loading = 'succeeded';
      state.assocUserloading = 'succeeded';
    });
    builder.addCase(AdminAssociateUsernameSearch.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setHotelUserList, setDataLoading } = HotelUserListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectHotelUserListDataSlice = (state: RootState) => state.HotelUserListData;

export const HotelUserListDataReducer = HotelUserListDataSlice.reducer;
