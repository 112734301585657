import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import {
    APP_NAME,
    env_var,
    notification_shown_time_failed,
    notification_shown_time_failed4,
    notification_shown_time_success,
} from '../config/env';
import { LBL_ASSOCIATE, LBL_URL_MAIDS } from '../config/common_labels';
import { getFormDataHeader, getHeaderInfo } from '../helpers/tokenCreator';
import { getMaidFormDataHeader, getMaidHeaderInfo } from '../helpers/tokenCreator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { } from 'react-router';
// import { useHistory } from 'react-router-dom';

import { removeTokens, removeMaidTokens } from './localStorage';
import applyCaseMiddleware from 'axios-case-converter';
import { Store } from 'react-notifications-component';
import { config } from 'process';
import { url } from 'inspector';
import { MSG_FILE_FORMAT_ERROR, MSG_HOTEL_CODE_EXISTS, MSG_HOTEL_CREATION_FAILED, MSG_HOTEL_QR_CODE_FAILED, MSG_INVALID_CREDENTIALS, MSG_INVALID_EMAIL, MSG_INVALID_FILE_FORMAT, MSG_INVALID_OTP, MSG_INVALID_USER, MSG_LINK_EXPIRED, MSG_LOGIN_AGAIN, MSG_APPNAME_ERROR, MSG_MAID_APPROVAL_FAILED, MSG_MAID_DETAILS_UPDATE_FAILED, MSG_MOBILE_EXISTS, MSG_NEW_USER_CREATION_FAILED, MSG_NO_ACTIVE_ACCOUNT, MSG_OTP_SENDING_FAILED, MSG_PRIMARY_USERNAME_EXISTS, MSG_PWD_RESET_FAILED, MSG_SERVER_ERROR, MSG_SOMETHING_WENT_WRONG, MSG_UNAUTHORISED, MSG_USERNAME_EXISTS, MSG_USERNAME_SAVING_FAILED, MSG_USER_ALREADY_REGISTERED, MSG_USER_NOT_ELIGIBLE, MSG_USER_NOT_REGISTERED, MSG_USER_REG_FAILED, MSG_USER_EXISTS_IN_SYSTEM, MSG_USER_MODIFICATION_FAILED, MSG_TERMS_SAVE_ACCEPT, MSG_TERMS_FETCH_FAILED, MSG_INVALID_INPUT_FILE, MSG_TIMESHEET_IMPORT_DATES_INVALID, MSG_FILEIMPORT_INVALID_HEADER, MSG_APPROVE_USERS_BEFORE_IMPORT, MSG_INVALID_SESSION, MSG_HOTEL_IMAGE_SIZE_SIZE } from '../config/messages';

const client = applyCaseMiddleware(axios.create());

export const setIntercept = (history: any): void => {
    // const navigate = useHistory();
    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            console.log('::error.response::', error.response);
            if (error.response.status === 400) { // COMMON FOR ALL PAGES
                let reqURL = error?.response?.config?.url;

                console.log('reqURL: ', reqURL);
                console.log('error.response.data: ', error.response.data);
                
                if (reqURL && reqURL.search("/users/v1/otp/") > 0) { // ERROR FROM STAFF LOGIN
                    let errMsg = error?.response?.data ? (error.response.data=='user_not_registered' || error.response.data=='user_not_registered.') ? MSG_INVALID_USER : MSG_OTP_SENDING_FAILED : MSG_OTP_SENDING_FAILED;
                    toast.error(errMsg, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
                }
                else if (reqURL && reqURL.search("/check/") > 0) { // ERROR FROM STAFF REGISTRATION
                    // let errMsg = error?.response?.data ? error.response.data : MSG_USER_REG_FAILED;
                    let errMsg = (error?.response?.data && (error.response.data == "user_already_mapped" || error.response.data == "username_already_exists" || error.response.data == "username_already_exists" || error.response.data == "user_already_registered")) ? MSG_USER_ALREADY_REGISTERED : error.response.data; 
                    toast.error(errMsg, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
                }
                else if (reqURL && reqURL.search("/register/") > 0) {  // ERROR FROM STAFF REGISTRATION OTP
                    let errMsg = error?.response?.data ? error.response.data : MSG_OTP_SENDING_FAILED;
                    toast.error(errMsg, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
                }
                else if (reqURL && reqURL.search("/maid-details/") > 0) { // ERROR FROM STAFF PROFILE  
                    // return error; 
                    let errMsgStr = error?.response?.data?.error ? error.response.data.error : MSG_MAID_DETAILS_UPDATE_FAILED;
                    toast.error(errMsgStr, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
                }
                else if ((reqURL && reqURL.search("/generate-tax-form-mobile/") > 0) || (reqURL && reqURL.search("/upload-id-proof/") > 0) || (reqURL && reqURL.search("/save-tax-form/") > 0)) { // ERROR FROM STAFF PROFILE ID PROOF OR W9FORM   
                    // console.log('::error::',error.response); 
                    // return error; 
                } 
                else if ((reqURL && reqURL.search("/hotels/v1/hotel/timesheet/csv-upload/") > 0)) { // ERROR FROM TIMESHEET UPLOAD  
                    // {"row":2,"mobile_number":"+918086555780","message":"have_future_date"}
                    let msgTitle = MSG_INVALID_FILE_FORMAT; 
                    let msgText  = MSG_INVALID_INPUT_FILE; 
                    let dismisObj = {
                        duration: notification_shown_time_failed,
                        onScreen: true,
                        click: false,
                        showIcon: false
                    }
                    if(error?.response?.data?.message && error?.response?.data?.message === 'have_future_date') {
                        msgText  = MSG_TIMESHEET_IMPORT_DATES_INVALID+' Check row ' + error.response.data.row + ', mobile number ' + error.response.data.mobileNumber + '.'; 
                        dismisObj = {
                            duration: 0,
                            onScreen: true,
                            click: true,
                            showIcon: true
                        }
                    }
                    else if(error?.response?.data?.message && error?.response?.data.message === 'invalid_headings') {
                        msgText  = MSG_FILEIMPORT_INVALID_HEADER; 
                        dismisObj = {
                            duration: 0,
                            onScreen: true,
                            click: true,
                            showIcon: true
                        }
                    }
                    else if(error?.response?.data?.message && error?.response?.data.message === 'un_approved_maid') {
                        msgText  = MSG_APPROVE_USERS_BEFORE_IMPORT+" Check "
                        var i = 0 ;
                        while (i < error.response.data.details.length){
                            msgText += ' row ' + error.response.data.details[i]['row'];
                            msgText += ', mobile number ' + error.response.data.details[i]['mobileNumber'] + '.';
                            i++;
                        }; 
                        dismisObj = {
                            duration: 0,
                            onScreen: true,
                            click: true,
                            showIcon: true
                        }
                    }

                    Store.addNotification({
                        title: msgTitle,
                        message: msgText,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: dismisObj
                    });
                }
                else if ((reqURL && (reqURL.search("/hotels/v1/hotel/") > 0 || reqURL.search("/hotels/v1/hotel-admin/") > 0))) { // ERROR FROM HOTEL Creation OR INVALID SESSION
                    let errMsgStr = ((error?.response?.data && error.response.data == "username_already_exists") || (error?.response?.data?.message && error.response.data.message == "username_already_exists")) ? MSG_PRIMARY_USERNAME_EXISTS :
                        ((error?.response?.data && error.response.data == "hotel_code_already_exists") || (error?.response?.data?.message && error.response.data.message == "hotel_code_already_exists")) ? MSG_HOTEL_CODE_EXISTS : error.response?.data[0] && error.response?.data[0].length > 2 ? error.response.data[0]=='image_size_exceeded' ? MSG_HOTEL_IMAGE_SIZE_SIZE : error.response.data[0] : MSG_INVALID_SESSION;

                    if (error?.response?.data?.detail && (error.response.data.detail == "service_unavailable")) {
                        errMsgStr = MSG_HOTEL_QR_CODE_FAILED;
                    }

                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed4,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if ((reqURL && reqURL.search("/maid-approve/") > 0)) { // ERROR FROM HOTELS, STAFF PAGES
                    console.log('::error::', error.response);
                    let errMsgStr = error?.response?.data ? error.response.data == "pending_required_fields" ? MSG_USER_NOT_ELIGIBLE : MSG_MAID_APPROVAL_FAILED : MSG_MAID_APPROVAL_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed4,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if (reqURL && (reqURL.search("/hotels/v1/user/create/") > 0 || reqURL.search("/hotels/v1/user/update/") > 0)) { // ERROR FROM HOTELS USERNAME SAVE 
                    let errMsgStr = (error?.response?.data?.message && (error.response.data.message == "user_already_mapped" || error.response.data.message == "username_already_exists" || error.response.data.message == "username_already_exists" || error.response.data.message == "user_already_registered")) ? MSG_USER_ALREADY_REGISTERED :
                        (error.response.data.message == "need_user_merge") ? MSG_USER_EXISTS_IN_SYSTEM : MSG_USERNAME_SAVING_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                        },
                    });

                    console.log('::error::', error.response);
                    // return error;  
                }
                else if ((reqURL && reqURL.search("/hotels/v1/password/reset/submit") > 0)) { // RESET PASSWORD 
                    /*
                    invalid_token = if token invalid 
                    invalid_userid_expirytime = if user id or expiry time invalid
                    invalid_user = if invalid user
                    invalid_date = if invalid date
                    link_time_expired = if time expired
                    user_does_not_exist = if email id changed / email id not in db 
                    */
                    let errCode = error?.response?.data?.message ? error.response.data.message : '';
                    let errMsgStr = errCode == 'invalid_token' ? MSG_LINK_EXPIRED :
                        errCode == 'invalid_userid_expirytime' ? MSG_LINK_EXPIRED :
                            errCode == 'invalid_user' ? MSG_INVALID_USER :
                                errCode == 'user_does_not_exist' ? MSG_INVALID_USER :
                                    errCode == 'invalid_date' ? MSG_LINK_EXPIRED :
                                        errCode == 'link_time_expired' ? MSG_LINK_EXPIRED : MSG_PWD_RESET_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                        },
                    });
                }
                else if ((reqURL && reqURL.search("/hotels/") > 0) || (reqURL && reqURL.search("/users/") > 0) || (reqURL && reqURL.search("/payments/") > 0)) { // ERROR FROM HOTELS, STAFF, PAYMENTS PAGES
                    console.log('::error::', error.response);
                    return error;
                }
                else if (reqURL && reqURL.search("hotel-users/v1/user/update") > 0) { // ERROR FROM HOTEL USER EDIT  
                    // {"message":"username_already_exists"}
                    let errMsgStr = error?.response?.data?.message ?
                        error.response.data.message == 'username_already_exists' ? MSG_USERNAME_EXISTS :
                            error.response.data.message == 'phone_number_already_exists' ? MSG_MOBILE_EXISTS :
                                error.response.data.message == "need_user_merge" ? MSG_USER_EXISTS_IN_SYSTEM :
                                MSG_USER_MODIFICATION_FAILED : MSG_USER_MODIFICATION_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed4,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });

                }
                else if (reqURL && reqURL.search("hotel-users/v1/user/create") > 0) { // ERROR FROM HOTEL USER EDIT  
                    // {"message":"username_already_exists"}
                    let errMsgStr = error?.response?.data?.message ?
                        (error.response.data.message == 'username_already_exists' || error.response.data.message == 'user_already_added_in_current_hotel' || error.response.data.message == "user_already_registered") ? MSG_USERNAME_EXISTS :
                            error.response.data.message == 'phone_number_already_exists' ? MSG_MOBILE_EXISTS :
                                MSG_NEW_USER_CREATION_FAILED : MSG_NEW_USER_CREATION_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed4,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if (reqURL && reqURL.search("hotel-users/v1/maid-invite-link") > 0) { // ERROR FROM HOTEL USER 
                    Store.addNotification({
                        title: APP_NAME,
                        message: MSG_INVALID_EMAIL,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-center',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed4,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else {
                    Store.addNotification({
                        title: MSG_UNAUTHORISED,
                        message: MSG_USER_NOT_REGISTERED,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                        },
                    });
                    removeMaidTokens();
                    history.push('/'+LBL_URL_MAIDS);
                }
            }
            else if (error.response.status === 401) {
                // 'usetova.com/api/users/v1/maid-details/'
                // IF TOKEN NOT AUTHORISED, ERROR CODE 401 RETURNS FROM STAFF LOGIN PROFILE PAGE 'staff/profile', REDIRECT TO STAFF LOGIN 
                let reqURL = error?.response?.config?.url; 
                // IF FAILED TO FETCH T & C OF STAFF 
                if (reqURL && reqURL.search("/users/v1/terms-and-conditions/view/") > 0) { 
                    let errMsgStr = MSG_TERMS_FETCH_FAILED;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if (reqURL && reqURL.search("/users/v1/token/") > 0) { // ERROR FROM STAFF LOGIN - OTP REQUEST - TERMS AND CONDITION NOT ACCEPTED ERROR 
                    if(error?.response?.data?.detail && error.response.data.detail == "terms_and_conditions_not_accepted") { 
                        history.push('/'+LBL_URL_MAIDS+'/terms');
                        // let errMsgStr = MSG_TERMS_SAVE_ACCEPT;
                        // Store.addNotification({
                        //     title: APP_NAME,
                        //     message: errMsgStr,
                        //     type: 'danger',
                        //     insert: 'top',
                        //     container: 'top-right',
                        //     animationIn: ['animate__animated', 'animate__fadeIn'],
                        //     animationOut: ['animate__animated', 'animate__fadeOut'],
                        //     dismiss: {
                        //         duration: notification_shown_time_failed,
                        //         onScreen: true,
                        //         pauseOnHover: true,
                        //     },
                        // });
                        // history.push('/'+LBL_URL_MAIDS+'/terms');
                    }
                    else if (error.response?.data?.code == 'verify_failed' || error.response?.data?.code == 'invalid_otp') { // STAFF LOGIN OTP VERIFICATOIN FAILED 
                        Store.addNotification({
                            title: MSG_UNAUTHORISED,
                            message: MSG_INVALID_OTP,
                            type: 'danger',
                            insert: 'top',
                            container: 'top-right',
                            animationIn: ['animate__animated', 'animate__fadeIn'],
                            animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: notification_shown_time_failed,
                                onScreen: true,
                            },
                        });
                    }
                }
                else if (reqURL && reqURL.search("users/v1/admin-token/") > 0) { // ERROR FROM HOTEL LOGIN 
                    let errMsgStr = error?.response?.data?.detail ? (error.response.data.detail == "no_active_hotel" || error.response.data.detail == MSG_NO_ACTIVE_ACCOUNT) ? MSG_INVALID_CREDENTIALS : MSG_INVALID_CREDENTIALS : MSG_INVALID_CREDENTIALS;
                    Store.addNotification({
                        title: APP_NAME,
                        message: errMsgStr,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                            pauseOnHover: true,
                        },
                    });
                }
                else if (reqURL && reqURL.search("/maid-details/") > 0) {
                    removeMaidTokens();
                    history.push('/'+LBL_URL_MAIDS);
                }
                else if (error.response?.data?.code == 'verify_failed' || error.response?.data?.code == 'invalid_otp') { // STAFF LOGIN OTP VERIFICATOIN FAILED 
                    Store.addNotification({
                        title: MSG_UNAUTHORISED,
                        message: MSG_INVALID_OTP,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                        },
                    });
                }
                else {
                    Store.addNotification({
                        title: MSG_UNAUTHORISED,
                        message: MSG_LOGIN_AGAIN,
                        type: 'danger',
                        insert: 'top',
                        container: 'top-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: notification_shown_time_failed,
                            onScreen: true,
                        },
                    });
                    removeTokens();
                    history.push('/login');
                }

            }
            else if (error.response.status === 422) {
                Store.addNotification({
                    title: MSG_APPNAME_ERROR,
                    type: 'danger',
                    id: '422-error',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 1000000000,
                        onScreen: true,
                        click: false,
                        touch: false,
                    },
                    content: () => (
                        <div>
                            <div className="box rows-error">
                                <div className="row rows-error-col">
                                    <div className="col">
                                        <label className="h5">{MSG_APPNAME_ERROR}</label>
                                    </div>
                                    <div className="col">
                                        <div>
                                            {JSON.stringify(error.response.data)}
                                            <div className="text-center">
                                                <button
                                                    type="button"
                                                    className="primary"
                                                    onClick={() => Store.removeNotification('422-error')}
                                                >
                                                    Ok
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                });
            } else if (error.response.status === 500) {
                Store.addNotification({
                    title: MSG_SOMETHING_WENT_WRONG,
                    message: MSG_SERVER_ERROR,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: notification_shown_time_failed,
                        onScreen: true,
                        pauseOnHover: true,
                    },
                    content: () => <div></div>,
                });
            }
            else { // if (error.response.status === 400)
                return error;
                // console.log(error.response);
            }
        }
    );
};

export const get = async function (url: any, params: any = {}) {
    const header = await getHeaderInfo();
    const resp = await client.get(env_var.API_URL + url, { ...header, params });
    return resp;

};

export const getWithoutAuth = async function (url: any, params: any = {}) {
    const header = await getHeaderInfo();
    const resp = await client.get(env_var.API_URL + url, { ...params });
    return resp;
};

export const getAuthImg = async function (url: any, params: any = {}) {
    const header = await getHeaderInfo();
    const resp = await client.get(env_var.API_URL + url, {
        ...header,
        responseType: 'arraybuffer',
        params,
    });
    return resp;
};

export const post = async function (url: string, body: any) {
    const header = await getHeaderInfo();
    const resp = await client.post(env_var.API_URL + url, body, header);
    return resp;
};

export const postWithoutAuth = async function (url: string, body: any) {
    const resp = await client.post(env_var.API_URL + url, body);
    return resp;
};

export const patch = async function (url: string, body: any) {
    const header = await getHeaderInfo();
    const resp = await client.patch(env_var.API_URL + url, body, header);
    return resp;
};

export const put = async function (url: any, body: any) {
    const header = await getHeaderInfo();
    const resp = await client.put(env_var.API_URL + url, body, header);
    return resp;
};

export const deleteApi = async function (url: any) {
    const header = await getHeaderInfo();
    try {
        const resp = await client.delete(env_var.API_URL + url, header);
        return resp;
    } catch (err) {
        // error handling
        // throw err.response
        return { 'data': '' };
    }
};

export const postFile = async function (url: string, body: any) {
    const header = await getFormDataHeader();
    const formData = new FormData();
    formData.append('file', body);
    try {
        const resp = await client.post(env_var.API_URL + url, formData, header);
        // if (resp.status === 200) {
        //     Store.addNotification({
        //         title: APP_NAME,
        //         message: resp.data.importedCount > 0 ? (resp.data.importedCount + "/" + resp.data.totalCount + " records imported") +
        //             (resp.data.notImportedNumbers?.length > 0 ? "The following records not imported" +
        //                 resp.data.notImportedNumbers.map((item: any) => item)
        //                 : "") : "No records imported",
        //         type: resp.data.importedCount > 0 ? 'success' : 'danger',
        //         insert: 'top',
        //         container: 'top-right',
        //         animationIn: ['animate__animated', 'animate__fadeIn'],
        //         animationOut: ['animate__animated', 'animate__fadeOut'],
        //         dismiss: {
        //             duration: notification_shown_time_success,
        //             onScreen: true,
        //         },
        //     });
        // }
        return resp;
    } catch (err) {
        Store.addNotification({
            title: MSG_FILE_FORMAT_ERROR,
            message: MSG_INVALID_FILE_FORMAT,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: notification_shown_time_failed,
                onScreen: true,
            },
        });
        // error handling
        // throw err.response
    }
};

export const getFile = async function (url: string, name: string, params: any = {}) {
    const header = await getHeaderInfo();
    const response = await client.get(env_var.API_URL + url, { ...header, params });
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = urlBlob;
    if(name){
        link.setAttribute('download', name + '.csv');
    }else{
        link.setAttribute('download', response.headers["contentDisposition"] + '.csv');
    }
    document.body.appendChild(link);
    link.click();
};

export const getPnGFile = async function (url: string, name: string) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = name;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
};

export const getPdfFile = async function (url: string, name: string, params: any = {}) {
    const response = await client.get(url);
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = urlBlob;
    link.setAttribute('download', name + '.pdf');
    document.body.appendChild(link);
    link.click();
};

// *******************************************************************************
// *************************** SERVICES FOR STAFF LOGIN ***************************
// *******************************************************************************

export const maidGet = async function (url: any, params: any = {}) {
    const header = await getMaidHeaderInfo();
    const resp = await client.get(env_var.API_URL + url, { ...header, params });
    return resp;
};

export const maidPost = async function (url: string, body: any) {
    const header = await getMaidHeaderInfo();
    const resp = await client.post(env_var.API_URL + url, body, header);
    return resp;
};

export const maidPatch = async function (url: string, body: any) {
    const header = await getMaidHeaderInfo();
    const resp = await client.patch(env_var.API_URL + url, body, header);
    return resp;
};

export const maidPut = async function (url: any, body: any) {
    const header = await getMaidHeaderInfo();
    const resp = await client.put(env_var.API_URL + url, body, header);
    return resp;
};

export const maidDelete = async function (url: any) {
    const header = await getMaidHeaderInfo();
    const resp = await client.delete(env_var.API_URL + url, header);
    return resp;
    // try {
    //     const resp = await client.delete(env_var.API_URL + url, header);
    //     return resp;
    // } catch (err) {
    //     // error handling
    //     // throw err.response
    // }
};

