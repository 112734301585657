import { get, getWithoutAuth, post, put, postFile, getFile, patch, getPdfFile, postWithoutAuth, deleteApi } from './apiService';
import { useAppDispatch, useAppSelector } from '../helpers/hook';
import { IAdminAssociateListRequestData, IAdminAssociateCreate, IAdminAssociateModify, IAdminAssociateUsername } from '../models/adminAssociateInterfaces';
import { IGenericResponse } from './interfaces';
import { createSlice, createSelector, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { Store } from 'react-notifications-component';
import { IHotelUserListRequestData } from '../models/hotelUsersListInterfaces';

export const fetchAdminAssociates = createAsyncThunk(
  'associates/fetchAdminAssociates',
  async ({ }: IAdminAssociateListRequestData, thunkAPI: any) => {
    try {
      let url = 'hotels/v1/user-list/';
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);



export const fetchHotelUsers = createAsyncThunk(
  'hotels/fetchHotels',
  async ({ page, searchText, searchType }: IHotelUserListRequestData, thunkAPI: any) => {
    try {
      let url = 'hotels/v1/hotel-users-list/?page=' + page;
      if (searchType != "all") {
        url = url + '&' + searchType +"="+ searchText.trim();
      }
      const response = get(url);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createAdminAssociate = createAsyncThunk(
  'associates/createAdminAssociate',
  async (newAssoc: IAdminAssociateCreate, thunkAPI: any) => {
    try {
      const url = 'hotels/v1/user/create/';
      const response = post(url, newAssoc);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const modifyAdminAssociate = createAsyncThunk(
  'associates/modifyAdminAssociate',
  async (assocData: IAdminAssociateModify, thunkAPI: any) => {
    try {
      const url = 'hotels/v1/user/update/' + assocData.userId + '/';
      const response = post(url, assocData);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const AdminAssociateUsernameSearch = createAsyncThunk(
  'associates/AdminAssociateUsernameSearch',
  async (newAssoc: IAdminAssociateUsername, thunkAPI: any) => {
    try {
      let url = 'hotel-users/v1/hotel-user/details/?username=' + newAssoc.username;
      const response = get(url);
      console.log("data", response);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const statusUpdateAdminAssociate = createAsyncThunk(
  'associates/statusUpdateAdminAssociate',
  async (assocId: number, thunkAPI: any) => {
    try {
      const url = 'hotels/v1/user-status-change/' + assocId + '/';
      const response = post(url, {});
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resetAssocUserPassword = createAsyncThunk(
  'associates/resetAssocUserPassword',
  async (username: string, thunkAPI: any) => {
    try {
      //const url = 'hotels/v1/password/reset/?user_id=' + userId
      const url = 'hotels/v1/password/reset/?username=' + username;
      const response = post(url, "");
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resetHotelUserPassword = createAsyncThunk(
  'associates/resetHotelUserPassword',
  async (username: string, thunkAPI: any) => {
    try {
      //const url = 'hotels/v1/password/reset/?user_id=' + userId
      const url = 'hotel-users/v1/password/reset/?username=' + username;
      const response = post(url, "");
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteAdminAssociate = createAsyncThunk(
  'associates/deleteAdminAssociate',
  async (assocId: number, thunkAPI: any) => {
    try {
      const url = 'users/v1/delete-user/' + assocId + '/';
      const response = deleteApi(url);
      return (await response).data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);






