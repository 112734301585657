import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { changeSearch, selectHotelPaymentsSearchSlice } from './hotelPaymentsSearchSlice';
import {
  setCurrentPage,
  setTotalPage,
  setStartLoading,
  setStopLoading,
  selectHotelPaymentsPaginationSlice,
} from './hotelPaymentsPaginationSlice';
import SearchBar from '../../components/SearchBar';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { selectHotelPaymentsFilterDataSlice } from './hotelPaymentsFilterDataSlice';
import {
  selectHotelPaymentsDataSlice,
  setFilterState,
  setFilterFrom,
  setFilterTo,
} from './hotelPaymentsDataSlice';
import {
  IHotelPaymentsRequestData,
  IHotelPaymentFilterRequest,
} from '../../models/hotelPaymentsInterfaces';
import {
  fetchPayments,
  exportHotelPayments,
  fetchPaymentsFilterData,
} from '../../services/hotelPaymentService';
import { hotelPaymentsFields } from '../../config/tables';
import DateFilterComponent from '../../components/DateFilterComponent';
import dayjs from 'dayjs';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getLowerDateRange, getNextDateRange } from '../../helpers/dateOps';
import { Store } from 'react-notifications-component';
import { processFailedTransactions } from '../../services/maidPaymentsService';
import { APP_NAME, notification_shown_time_success } from '../../config/env';
import { LBL_HOTEL_PAYMENTS } from '../../config/common_labels';  
import { MSG_STARTED_PROCESS_FAILED_TRANS } from '../../config/messages';  

export const HotelPayments = () => {
  const { searchValue } = useAppSelector(selectHotelPaymentsSearchSlice);
  const { currentPage, isLoading } = useAppSelector(selectHotelPaymentsPaginationSlice);
  const { hotelPayments, filterState, filterFrom, filterTo, totalSize, loading } = useAppSelector(
    selectHotelPaymentsDataSlice
  );
  const { hotelPaymentsFilterData } = useAppSelector(selectHotelPaymentsFilterDataSlice);
  const dispatch = useAppDispatch();
  const [applySearch, setApplySearch] = useState(0);

  const fetchAll = (): void => {
    const req: IHotelPaymentsRequestData = {
      page: currentPage,
      search: searchValue.trim(), 
      start: encodeURIComponent(filterFrom),
      end: encodeURIComponent(filterTo),
      type: filterState,
    };
    dispatch(fetchPayments(req));
    const filterReq: IHotelPaymentFilterRequest = {
      search: searchValue.trim(), 
      start_date: encodeURIComponent(filterFrom),
      end_date: encodeURIComponent(filterTo),
      type: filterState,
    };
    dispatch(fetchPaymentsFilterData(filterReq));
  };

  React.useEffect(() => {
    fetchAll();
  }, [dispatch, applySearch, currentPage, filterState, filterFrom, filterTo]);

  const onSearchButton = (e:any) => {
    e.preventDefault(); 
    acceptPage(1); 
    setApplySearch(applySearch+1);
  };

  const acceptSearch = (e: any): void => {
    dispatch(changeSearch(e.target.value));
  };

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };

  const setStartDate = (e: Date): void => {
    dispatch(setFilterFrom(dayjs(e).format(`YYYY-MM-DDTHH:mm:ssZZ`)));
  };
  const setEndDate = (e: Date): void => {
    dispatch(setFilterTo(dayjs(e).format(`YYYY-MM-DDT23:59:59ZZ`)));
  };

  const setLess = (): void => {
    let date_dange = getLowerDateRange(filterFrom, filterTo, 'hp');
    dispatch(setFilterFrom(date_dange[0]));
    dispatch(setFilterTo(date_dange[1]));
  };

  const setMore = (): void => {
    let date_dange = getNextDateRange(filterFrom, filterTo, 'hp');
    dispatch(setFilterFrom(date_dange[0]));
    dispatch(setFilterTo(date_dange[1]));
  };

  const onExport = (): void => {
    const req: IHotelPaymentsRequestData = {
      page: currentPage,
      search: searchValue.trim(), 
      start: encodeURIComponent(filterFrom),
      end: encodeURIComponent(filterTo),
      type: filterState,
    };
    exportHotelPayments(req);
  };

  const reprocessFailed = (): void => {
    // (async () => {
    //   const uploadStatus = await processFailedTransactions(0);
    //   if (uploadStatus === 200) {
    //     fetchAll();
    //     Store.addNotification({
    //       title: APP_NAME,
    //       message: MSG_STARTED_PROCESS_FAILED_TRANS,
    //       type: 'success',
    //       insert: 'top',
    //       container: 'top-center',
    //       animationIn: ['animate__animated', 'animate__fadeIn'],
    //       animationOut: ['animate__animated', 'animate__fadeOut'],
    //       dismiss: {
    //         duration: notification_shown_time_success,
    //         onScreen: true,
    //         pauseOnHover: true,
    //       },
    //     });
    //   }
    // })();
  };

  const dateFilter = (): JSX.Element => {
    if (filterState === 'transDate') {
      return (
        <div className="div-left" id="transDate">
          <div className="div-left">
            <div className="div-left margin-left-15px pay-period">From</div>
          </div>
          <div className="div-left  margin-left-5px pointer-cursor">
            <DateFilterComponent
              defaultDate={dayjs(filterFrom).toDate()}
              setDate={setStartDate}
              format={`YYYY-MM-DDTHH:mm:ssZZ`}
              page="hp"
              type="start"
            />
          </div>
          <div className="div-left">
            <div className="div-left margin-left-15px pay-period">To</div>
          </div>
          <div className="div-left  margin-left-5px pointer-cursor">
            <DateFilterComponent
              defaultDate={dayjs(filterTo).toDate()}
              minDate={filterFrom}
              setDate={setEndDate}
              format={`YYYY-MM-DDTHH:mm:ssZZ`}
              page="hp"
              type="end"
            />
          </div>
        </div>
      );
    }
    return <span />;
  };

  const payPeriodFilter = (): JSX.Element => {
    if (filterState === 'payPeriod') {
      return (
        <div className="div-left" id="payPeriod">
          <div onClick={() => setLess()} className="box-data div-left pointer-cursor">
            &lt;
          </div>
          <div className="box-data box-border-blue div-left">
            {dayjs(filterFrom).format('MMM DD, YYYY')}
          </div>
          <div className="box-data box-border-blue div-left">
            {dayjs(filterTo).format('MMM DD, YYYY')}
          </div>
          <div onClick={() => setMore()} className="box-data div-left pointer-cursor">
            &gt;
          </div>
        </div>
      );
    }
    return <span />;
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    dispatch(setFilterState(e.target.value));
  };

  return (
    <>
      <div className="header-content-container">
        <div className="box">
          <div className="col-12">
            <h4 className="page-heading div-left padding-top3">{LBL_HOTEL_PAYMENTS}</h4>
            <div className="margin-left-15px div-left">
              {/* <label className="div-left page-heading m-1">Pay Period</label> */}
              <select
                  value={filterState}
                  name="payPeriod"
                  className="div-left box-data box-border-blue sel-box min-width-170"
                  onChange={(e) => handlePeriodChange(e)}
              >
                  <option value="payPeriod">Pay Period</option>
                  <option value="transDate">Transaction Date</option>
              </select>
                               
              {payPeriodFilter()}

              {dateFilter()}
            </div>
          </div>

          <div className="col-12 justify-space-between">
            <div className="col-12">
              <div className="page-sub-heading col-2 div-left">
                <div>Total Tip Received</div>
                <div className="page-sub-data">
                  <h4 className="div-left margin-right-5px">
                    {getSymbolFromCurrency(hotelPaymentsFilterData.currency || 'usd')}{' '}
                    {hotelPaymentsFilterData.totalTip.toFixed(2)}
                  </h4>{' '}
                </div>
              </div>
              <div className="page-sub-heading col-2 div-left margin-left-25px">
                <div>Total Amount Processed</div>
                <div className="page-sub-data">
                  <h4>
                    {getSymbolFromCurrency(hotelPaymentsFilterData.currency || 'usd')}{' '}
                    {hotelPaymentsFilterData.totalDistributed.toFixed(2)}
                  </h4>
                </div>
              </div>
              <div className="page-sub-heading col-2 div-left margin-left-25px">
                <div>Total Commission</div>
                <div className="page-sub-data">
                  <h4 className="div-left margin-right-5px">
                    {getSymbolFromCurrency(hotelPaymentsFilterData.currency || 'usd')}{' '}
                    {hotelPaymentsFilterData.totalCommission.toFixed(2)}
                  </h4>{' '}
                </div>
              </div>
              <div className="page-sub-heading col-2 div-left margin-left-25px">
                <div>Total Transactions</div>
                <div className="page-sub-data">
                  <h4 className="div-left margin-right-5px">
                    {hotelPaymentsFilterData.totalTransactions}{' '}
                  </h4>{' '}
                  <div className="margin-left-15px margin-top2">
                    {' '}
                    (
                    <span className="success" title="Success transactions">
                      {hotelPaymentsFilterData.successTransactions}
                    </span>
                    /
                    <span className="danger" title="Failed transactions">
                      {hotelPaymentsFilterData.failedTransactions}
                    </span>
                    )
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content_container">
        <div className="box content-box">
          <div className="justify-space-between">
            <SearchBar onSearch={acceptSearch} currentSearch={searchValue} place_holder="&#xf002; Search hotel name"  searchButton={true} onSearchButton={onSearchButton} />

            <div className="div-right">
            {hotelPayments && hotelPayments.length > 0 && <button type="button" className="primary margin-left-15px" onClick={() => onExport()}>
                Export {LBL_HOTEL_PAYMENTS}
              </button>}
            </div>
          </div>
          <div className=" table-wrapper">
            <Table
              titles={hotelPaymentsFields}
              data={hotelPayments}
              isLoading={loading === `loading` ? true : false}
              currentPage={currentPage}
            />
            
            <div className="justify-right">
              <div className="pagination">
                {hotelPayments && hotelPayments.length > 0 && <Pagination
                  isTableLoading={loading === `loading` ? true : false}
                  totalCount={totalSize}
                  currentPage={currentPage}
                  setCurrentPage={acceptPage}
                  pageSize={20}
                  siblingCount={2}
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
