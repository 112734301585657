import React, { useEffect, useState, useRef } from 'react';
import { navBarList } from './NavbarList';
import './pageLayout.css';
import { useHistory, useLocation } from 'react-router-dom';
import { removeTokens } from '../../services/localStorage';
import logo from './../../assets/img/tova_stack_white.png';
import settingsImage from './../../assets/img/settings.png';
import { APP_NAME } from '../../config/env';
import { checkPagePermission } from '../../config/permissions';
import { getAdminUserRole } from '../../services/localStorage';
import { LBL_ASSOCIATES, LBL_PROCESS_PAYMENTS, LBL_SETTINGS, LBL_TERMS } from '../../config/common_labels';

function Navbar() {
  const [click, setClick] = useState(false)
  let menuRef = React.useRef<HTMLDivElement>(null)

  const handleclk =()=>{
    setClick(!click)
  }
  let adminUserRole = getAdminUserRole();
  const history = useHistory();
  const path = useLocation().pathname;
  const loc = useLocation();
  const adminUserName: any = localStorage.getItem('adminUserName');

  useEffect(() => {
   let handle = (e:any)=>{
    if(!menuRef.current?.contains(e.target)){
      setClick(false)
      // console.log("donnnu",menuRef.current); 
    } 
   }
   document.addEventListener("mousedown",handle)

   return()=> {
    document.removeEventListener("mousedown",handle)
   }
  }, [])
  
  function checkIfActive(index: number) {
    let activeIndex = navBarList.findIndex((item) => item.path === path);
    if (activeIndex == -1) {
      const pathArr = path.split('/');
      if (pathArr.length > 2) {
        activeIndex = navBarList.findIndex(
          (item) => item.path === pathArr[0] + '/' + pathArr[1] + '/' + pathArr[2]
        );
      }
    }
    if (index == activeIndex) {
      return 'active';
    }
    return '';
  }

  function handleClick(path: string) {
    //history.push(path + window.location.search);
    history.push(path);
  }

  function sideImage(item: any) {
    if (item.path === path) {
      return item.imgSrcActive;
    } else {
      return item.imgSrc;
    }
  }
  const handleLogout = () => {
    removeTokens();
    history.push('/v1');
  };


  return (
    <>
      <div >
        <div className="header justify-space-between" style={{zIndex:'100'}}>
          <div className="justify-left margin-left-24px">
            <div className="div-left">
              <div>
                {/* <img className="small" style={{ height: 55, marginTop: 10 }} src={logo} alt={APP_NAME} /> */}
                <img className='nav_img' src={logo} alt={APP_NAME} />
              </div>
            </div>
            <div className="nav_links pointer-cursor">
              {navBarList.map((item, index) => {
                if (!checkPagePermission(adminUserRole, item.name)) {
                  return null;
                }
                else {
                  return (
                    <a
                      className={checkIfActive(index)}
                      key={index}
                      // style={{ paddingTop: 24, paddingBottom: 24 }}
                      onClick={() => handleClick(item.path)}
                    >
                      {item.name}
                    </a>
                  );
                }
              })}
            </div>
          </div>
          {/* <div className=' flex-col '>
         <div className="div-right user-select pointer-cursor">
            <div className="box-data box-border-grey div-right" onClick={handleLogout}>
              Logout
              <span className="margin-left-5px fa fa-sign-out"></span>            
            </div>
          </div>  
          <div className="div-right mt-5px">     
          {(adminUserName != "undefined" && adminUserName != "") ?(<p className='padding-right-27 small-text-12'>Logged in as {adminUserName}</p>):null}</div>   
         </div> */}


          <div className=' flex-col '>
            <div className="div-right user-select pointer-cursor" ref={menuRef}>
              <div
                className=" div-right margin-right-m1px" 
                onClick={handleclk}
              >
                {(adminUserName != "undefined" && adminUserName != "") ? (<button className='border-none mb-1 primary btnbg-bkup text-white'>{adminUserName}</button>) : null}
                <div className={click ? "NavDropdown" : 'hide'} >
                  <ul style={{zIndex:"10"}}>
                    {checkPagePermission(adminUserRole, LBL_ASSOCIATES) && <li onClick={() => handleClick('/v1/admin-associates')}>{LBL_ASSOCIATES}</li>}
                    {checkPagePermission(adminUserRole, LBL_PROCESS_PAYMENTS) && <li onClick={() => handleClick('/v1/process-payments')}>{LBL_PROCESS_PAYMENTS}</li>} 
                    {checkPagePermission(adminUserRole, LBL_SETTINGS) && <li onClick={() => handleClick('/v1/settings')}>{LBL_SETTINGS}</li>}
                    {checkPagePermission(adminUserRole, LBL_TERMS) && <li onClick={() => handleClick('/v1/terms')}>{LBL_TERMS}</li>}
                    <li onClick={handleLogout}>Logout</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
export default Navbar;
