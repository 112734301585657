import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotel } from '../../models/hotelsInterfaces';
import { fetchFilterHotels, fetchHotels } from '../../services/hotelService';
import { IGenericResponse, IPagination } from '../../services/interfaces';

export interface DataState {
  hotels: IHotel[];
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  totalSize: number;
}

const initialState: DataState = {
  hotels: [],
  loading: 'idle',
  error: '',
  totalSize: 0,
};

export const hotelListDataSlice = createSlice({
  name: 'hotelListData',
  initialState,
  reducers: {
    setHotelList: (state, action: PayloadAction<any>) => {
      state.hotels = action.payload;
    },
    setDataLoading: (state) => {
      state.loading = 'loading';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHotels.pending, (state) => {
      state.hotels = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchHotels.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IHotel[]>;
      state.hotels = m.results;
      state.loading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchHotels.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
    builder.addCase(fetchFilterHotels.pending, (state) => {
      state.hotels = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchFilterHotels.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IHotel[]>;
      state.hotels = m.results;
      state.loading = 'succeeded';
      state.totalSize = m.count;
    });
    builder.addCase(fetchFilterHotels.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setHotelList, setDataLoading } = hotelListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectHotelListDataSlice = (state: RootState) => state.hotelListData;

export const hotelListDataReducer = hotelListDataSlice.reducer;
