import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface PageState {
  currentPage: number;
  totalPage: number;
  isLoading: boolean;
}

const initialState: PageState = {
  currentPage: 1,
  totalPage: 10,
  isLoading: true,
};

export const adminAssociatesListPaginationSlice = createSlice({
  name: 'adminAssociatesListPagination',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setTotalPage: (state, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setStartLoading: (state) => {
      state.isLoading = true;
    },
    setStopLoading: (state) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentPage, setTotalPage, setStartLoading, setStopLoading } =
adminAssociatesListPaginationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAdminAssociatesListPaginationSlice = (state: RootState) => state.adminAssociatesListPaginationData;

export const adminAssociatesListPaginationReducer = adminAssociatesListPaginationSlice.reducer;
