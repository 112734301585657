import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotel, IHotelDefaultConfig, IHotelForm, IHotelIdUsername } from '../../models/hotelsInterfaces';
import {
  fetchHotel,
  fetchConfig,
  updateHotel,
  createHotelNew,
  activateHotel,
  deactivateHotel,
  modifyHotelUsername,
  resendEmail,
  inviteByEmail,
} from '../../services/hotelService';
import { IGenericResponse, IPagination } from '../../services/interfaces';
import { Store } from 'react-notifications-component';
import { APP_NAME, notification_shown_time_success } from '../../config/env';
import { MSG_HOTEL_DETAILS_UPDATED, MSG_HOTEL_DETAILS_UPDATE_FAILED, MSG_HOTEL_DETAILS_SAVED, MSG_INVITATION_SENT, MSG_HOTEL_USERNAME_SAVED, MSG_USER_VERIFY_EMAIL_RESENT, MSG_HOTEL_STATUS_UPDATED } from '../../config/messages';

export interface DataState {
  hotelForm: IHotelForm;
  defaultCommission: number;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  newHotelCreated: boolean;
  newHotelUserCreated: boolean;
}

const initialState: DataState = {
  hotelForm: {} as IHotelForm,
  loading: 'idle',
  error: '',
  newHotelCreated: false,
  defaultCommission: 10,
  newHotelUserCreated: false
};

export const hotelFormDataSlice = createSlice({
  name: 'hotelFormData',
  initialState,
  reducers: {
    setHotelForm: (state, action: PayloadAction<any>) => {
      state.hotelForm = action.payload;
    },
    setMobile: (state, action: PayloadAction<string>) => {
      state.hotelForm.mobileNumber = action.payload;
    },
    setAlternative: (state, action: PayloadAction<string>) => {
      state.hotelForm.alternativeMobileNumber = action.payload;
    },
    setNewHotelCreated: (state, action: PayloadAction<boolean>) => {
      state.newHotelCreated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHotel.pending, (state) => {
      state.hotelForm = {} as IHotelForm;
      state.loading = 'loading';
    });
    builder.addCase(fetchHotel.fulfilled, (state, action) => {
      const m = action.payload as IHotelForm;
      state.hotelForm = m;
      state.loading = 'succeeded';
    });
    builder.addCase(fetchHotel.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.hotelForm = {} as IHotelForm;
    });
    builder.addCase(fetchConfig.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      const m = action.payload as IHotelDefaultConfig;
      state.defaultCommission = m.defaultCommission;
      state.hotelForm.paymentCommission = m.defaultCommission;
      state.loading = 'succeeded';
    });
    builder.addCase(fetchConfig.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });
    builder.addCase(updateHotel.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(updateHotel.fulfilled, (state, action) => {
      const m = action.payload as IHotelForm;
      state.hotelForm = m;
      state.loading = 'succeeded';
      if (!action.payload) {
        Store.addNotification({
          title: APP_NAME,
          message: MSG_HOTEL_DETAILS_UPDATE_FAILED,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }
      else {
        Store.addNotification({
          title: APP_NAME,
          message: MSG_HOTEL_DETAILS_UPDATED,
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }

    });
    builder.addCase(updateHotel.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: MSG_HOTEL_DETAILS_UPDATE_FAILED,
      //   type: 'danger',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
      // state.error = action.error.message;
    });
    builder.addCase(createHotelNew.pending, (state, { payload }: any) => {
      state.loading = 'loading';
      // state.error = action.error.message;
    });
    builder.addCase(createHotelNew.fulfilled, (state, action) => {
      const m = action.payload as IHotelForm;
      state.hotelForm = m;
      state.loading = 'succeeded';
      // Store.addNotification({
      //   title: APP_NAME,
      //   message: MSG_HOTEL_DETAILS_SAVED,
      //   type: 'success',
      //   insert: 'top',
      //   container: 'top-center',
      //   animationIn: ['animate__animated', 'animate__fadeIn'],
      //   animationOut: ['animate__animated', 'animate__fadeOut'],
      //   dismiss: {
      //     duration: notification_shown_time_success,
      //     onScreen: true,
      //     pauseOnHover: true,
      //   },
      // });
      state.newHotelCreated = true;
    });
    builder.addCase(createHotelNew.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
    builder.addCase(inviteByEmail.pending, (state, { payload }: any) => {
      state.loading = 'loading';
      // state.error = action.error.message;
    });
    builder.addCase(inviteByEmail.fulfilled, (state, action) => {
      // const m = action.payload as IHotelForm;
      // state.hotelForm = m;
      state.loading = 'succeeded';
      Store.addNotification({
        title: APP_NAME,
        message: MSG_INVITATION_SENT,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      // state.newHotelCreated = true;
    });
    builder.addCase(inviteByEmail.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      
    });

    builder.addCase(modifyHotelUsername.pending, (state, { payload }: any) => {
      // state.loading = 'loading';
    });
    builder.addCase(modifyHotelUsername.fulfilled, (state, action) => {
      // const m = action.payload as IHotelIdUsername;
      // let m = state.hotelForm;
      // m.username = state.hotelUsername
      // console.log('modifyHotelUsername action'); 
      // console.log(action); 
      state.loading = 'succeeded';
      Store.addNotification({
        title: APP_NAME,
        message: MSG_HOTEL_USERNAME_SAVED,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      state.newHotelCreated = true;
      state.newHotelUserCreated = true; 
    });
    builder.addCase(modifyHotelUsername.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });


    builder.addCase(resendEmail.pending, (state, { payload }: any) => {
      // state.loading = 'loading';
    });
    builder.addCase(resendEmail.fulfilled, (state, action) => {
      
      state.loading = 'succeeded';
      Store.addNotification({
        title: APP_NAME,
        message: MSG_USER_VERIFY_EMAIL_RESENT,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    });
    builder.addCase(resendEmail.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });

    builder.addCase(activateHotel.fulfilled, (state, action) => {
      state.hotelForm.isActive = 'YES';
      state.loading = 'succeeded';
      Store.addNotification({
        title: APP_NAME,
        message: MSG_HOTEL_STATUS_UPDATED,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    });
    builder.addCase(activateHotel.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });
    builder.addCase(deactivateHotel.fulfilled, (state, action) => {
      state.hotelForm.isActive = 'NO';
      state.loading = 'succeeded';
      Store.addNotification({
        title: APP_NAME,
        message: MSG_HOTEL_STATUS_UPDATED,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    });
    builder.addCase(deactivateHotel.rejected, (state, { payload }: any) => {
      state.loading = 'error';
    });
  },
});

// Action creators are generated for each case reducer function
export const { setHotelForm, setMobile, setAlternative, setNewHotelCreated } =
  hotelFormDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectHotelFormDataSlice = (state: RootState) => state.hotelFormData;

export const hotelFormDataReducer = hotelFormDataSlice.reducer;
