import { configureStore } from '@reduxjs/toolkit';
import { authenticationReducer } from './pages/LoginPage/authenticationSlice';
import { alertReducer } from './components/Alert/alertSlice';
import { hotelListSearchReducer } from './pages/HotelList/hotelListSearchSlice';
import { hotelListPaginationReducer } from './pages/HotelList/hotelListPaginationSlice';
import { hotelListDataReducer } from './pages/HotelList/hotelListDataSlice';
// hotel payments
import { hotelPaymentsSearchReducer } from './pages/HotelPayments/hotelPaymentsSearchSlice';
import { hotelPaymentsPaginationReducer } from './pages/HotelPayments/hotelPaymentsPaginationSlice';
import { hotelPaymentsDataReducer } from './pages/HotelPayments/hotelPaymentsDataSlice';
import { hotelPaymentsFilterDataReducer } from './pages/HotelPayments/hotelPaymentsFilterDataSlice';
//guest
import { guestPaymentsSearchReducer } from './pages/GuestPayments/guestPaymentsSearchSlice';
import { guestPaymentsPaginationReducer } from './pages/GuestPayments/guestPaymentsPaginationSlice';
import { guestPaymentsDataReducer } from './pages/GuestPayments/guestPaymentsDataSlice';
import { guestPaymentsHotelFilterReducer } from './pages/GuestPayments/guestPaymentHotelFilterSlice';
import { selectGuestRefundReducer } from './pages/GuestPayments/guestRefundSlice';
// staff list
import { maidListSearchReducer } from './pages/MaidList/maidListSearchSlice';
import { maidListPaginationReducer } from './pages/MaidList/maidListPaginationSlice';
import { maidListDataReducer } from './pages/MaidList/maidListDataSlice';
import { maidAdminEditReducer } from './pages/MaidList/maidAdminEditSlice';
// form data
import { hotelFormDataReducer } from './pages/HotelForm/hotelFormDataSlice';
// hotel fetch name and id
import { maidListHotelFilterReducer } from './pages/MaidList/maidListHotelFilterSlice';

import { maidPaymentsSearchReducer } from './pages/MaidPayments/maidPaymentsSearchSlice';
import { maidPaymentsPaginationReducer } from './pages/MaidPayments/maidPaymentsPaginationSlice';
import { maidPaymentsDataReducer } from './pages/MaidPayments/maidPaymentsDataSlice';
import { maidPaymentsHotelFilterReducer } from './pages/MaidPayments/maidPaymentHotelFilterSlice';
import { maidPaymentsTimesheetReducer } from './pages/MaidPayments/maidTimesheetSlice';
import { maidPaymentsTransactionReducer } from './pages/MaidPayments/maidTransactionsSlice';
import { maidPaymentsFilterDataReducer } from './pages/MaidPayments/maidPaymentsFilterDataSlice';
// staff details
import { maidDataReducer } from './pages/MaidList/maidDetailsSlice';

import { maidProfileReducer } from './pages/MaidLogin/maidProfileSlice';
import { maidProfileEditReducer } from './pages/MaidLogin/maidProfileEditSlice';

// tips
import { tipReducer } from './pages/Tip/tipSlice';
// stripe
import { maidregisterReducer } from './pages/MaidRegister/maidRegisterSlice';
import { stripeTokenSliceReducer } from './pages/Stripe/stripeTokenSlice';

// STAFF LOGIN 
import { maidAuthenticationReducer } from './pages/MaidLogin/maidAuthenticationSlice';
import { hotelSettingsFormDataReducer } from './pages/Settings/AddSettings';

// log
import { paymentListDataReducer } from './pages/PaymentLog/paymentListDataSlice';

import { resetPasswordReducer } from './pages/ResetPassword/resetPasswordSlice'

// HOTEL ASSOCIATES USERS 
import { hotelAssociatesListDataReducer } from './pages/HotelForm/hotelAssociatesListDataSlice';
import { hotelAssociatesListPaginationReducer } from './pages/HotelForm/hotelAssociatesListPaginationSlice';
import { hotelAssociatesAddEditReducer } from './pages/HotelForm/hotelAssociatesAddEditSlice';

// ADMIN ASSOCIATES USERS 
import { adminAssociatesListDataReducer } from './pages/AdminAssociates/adminAssociatesListDataSlice';
import { adminAssociatesListPaginationReducer } from './pages/AdminAssociates/adminAssociatesListPaginationSlice';
import { adminAssociatesAddEditReducer } from './pages/AdminAssociates/adminAssociatesAddEditSlice';
import { HotelUserListDataReducer } from './pages/HotelUserList/hotelUsersListDataSlice';
import { HotelUserListListPaginationReducer } from './pages/HotelUserList/hotelUsersListPaginationSlice';
import { hotelUserSearchReducer } from './pages/HotelUserList/hotelUsersListSearchSlice';

// TERMS AND CONTIDIONS 
import { termsDataReducer } from './pages/Terms/termsSlice'; 
import { multiSelectSliceReducer } from './components/MultiSelect/MultiSelectSlice';
import { emailListDataReducer } from './pages/EmailLog/emailListDataSlice';

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    maidAuthentication: maidAuthenticationReducer,
    alert: alertReducer,
    hotelListSearch: hotelListSearchReducer,
    hotelListPagination: hotelListPaginationReducer,
    hotelListData: hotelListDataReducer,
    // hotel payments
    hotelPaymentsSearch: hotelPaymentsSearchReducer,
    hotelPaymentsPagination: hotelPaymentsPaginationReducer,
    hotelPaymentsData: hotelPaymentsDataReducer,
    hotelPaymentsFilterData: hotelPaymentsFilterDataReducer,
    // guest payments
    guestPaymentsSearch: guestPaymentsSearchReducer,
    guestPaymentsPagination: guestPaymentsPaginationReducer,
    guestPaymentsData: guestPaymentsDataReducer,
    guestPaymentsHotelFilter: guestPaymentsHotelFilterReducer,
    guestRefund: selectGuestRefundReducer,
    // multiselect hotellist
    multiSelectHotelList: multiSelectSliceReducer,
    // staff list
    maidListSearch: maidListSearchReducer,
    maidListPagination: maidListPaginationReducer,
    maidListData: maidListDataReducer,
    maidDetailsAdminEdit: maidAdminEditReducer,
    // hotel-form
    hotelFormData: hotelFormDataReducer,

    hotelSettingsFromData: hotelSettingsFormDataReducer,

    maidListHotelFilter: maidListHotelFilterReducer,

    // staffPayments
    maidPaymentsSearch: maidPaymentsSearchReducer,
    maidPaymentsPagination: maidPaymentsPaginationReducer,
    maidPaymentsData: maidPaymentsDataReducer,
    maidPaymentsHotelFilter: maidPaymentsHotelFilterReducer,
    maidPaymentsTimesheet: maidPaymentsTimesheetReducer,
    maidPaymentsTransaction: maidPaymentsTransactionReducer,
    maidPaymentsFilterData: maidPaymentsFilterDataReducer,
    //staff-details
    maidDetailsData: maidDataReducer,
    //tips
    tipData: tipReducer,
    //
    maidFormData: maidregisterReducer,
    // stripe
    stripeTokenData: stripeTokenSliceReducer,

    //MAID LOGIN - PROFILE 
    maidProfileData: maidProfileReducer,
    maidProfileEdit: maidProfileEditReducer,

    //log
    paymentListData: paymentListDataReducer,
    //Email-log
    emailListData: emailListDataReducer,
    
    //RESET PASSWORD 
    resetPassword: resetPasswordReducer,

    // HOTEL ASSOCIATES USERS
    hotelAssociatesListData: hotelAssociatesListDataReducer,
    hotelAssociatesListPaginationData: hotelAssociatesListPaginationReducer, 
    hotelAssociatesAddEditData: hotelAssociatesAddEditReducer, 

    // HOTEL  USERS
    HotelUserListData: HotelUserListDataReducer,
    HotelUserListListPaginationData: HotelUserListListPaginationReducer, 
    hotelUserSearch: hotelUserSearchReducer, 

    // ADMIN ASSOCIATES USERS
    adminAssociatesListData: adminAssociatesListDataReducer,
    adminAssociatesListPaginationData: adminAssociatesListPaginationReducer, 
    adminAssociatesAddEditData: adminAssociatesAddEditReducer,

    // TERMS AND CONDITIONS 
    getTermsdata: termsDataReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
