import { getAccessToken, getMaidAccessToken } from '../services/localStorage';

export const getHeaderInfo = async function () {
  const token = await getAccessToken();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getFormDataHeader = async function () {
  const token = await getAccessToken();
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
};

// *******************************************************************************
// **************************** FOR STAFF OPERATIONS ******************************
// *******************************************************************************
export const getMaidHeaderInfo = async function () {
  const token = await getMaidAccessToken();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getMaidFormDataHeader = async function () {
  const token = await getMaidAccessToken();
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
};
