import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useAppDispatch } from '../../helpers/hook';
import { authenticateMaid } from './maidAuthenticationSlice';
import { isMaidAuthenticated, isMaidOTPSent } from '../../services/maidAuthenticationService';
import { getMaidPhone, removeMaidTokens } from '../../services/localStorage';

import logo from './../../assets/img/tova_stack.png';
import { APP_NAME } from '../../config/env';
import { LBL_URL_MAIDS } from '../../config/common_labels';

export const MaidLogin = () => {
  const history = useHistory();
  const [phone, setPhone] = useState('1');
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [validation, setValidation] = useState(false);

  if (isMaidAuthenticated()) {
    localStorage.removeItem('phoneOTP');
    history.push('/' + LBL_URL_MAIDS + '/profile');
  }

  // const [optSent, setOptSent] = useState(isMaidOTPSent());
  // useEffect(() => {
  //     if (optSent) {
  //         console.log('isMaidOTPSent Called')
  //         history.push('/'+LBL_URL_MAIDS+'/otp');
  //     }
  // },[isMaidOTPSent])
  // console.log('optSent optSent',JSON.stringify(optSent))

  const dispatch = useAppDispatch();

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (phoneValidation) {
      removeMaidTokens();
      localStorage.removeItem('phoneOTP');
      dispatch(authenticateMaid('+' + phone));

      setTimeout(() => {
        if (isMaidOTPSent()) {
          history.push({
            pathname: '/' + LBL_URL_MAIDS + '/otp',
            state: {
              phoneNumber: phone,
            },
          });
        }
      }, 1750);
    } else {
      setValidation(true);
    }
  };

  const onsetPhone = (code: any, data: any) => {

    setPhone(code);
    if (code.slice(data.dialCode.length).length == 10) {
      setValidation(false);
    } else if (code.slice(data.dialCode.length).length >= 1) {
      setPhoneValidation(true);
      setValidation(true);
    } else {
      setValidation(true);
      setPhoneValidation(false);
    }
  };

  return (
    <div className="bg-white min-h-full">
      <div className="align-center-common flex-col">
        <div className="box border max-width-500 mt-33">
          {phoneValidation && <ToastContainer autoClose={5000} />}
          <div className="align-center-common flex-col padding-top20 mb-4">
            <img src={logo} alt={APP_NAME} className="maid-login-logo" />
          </div>
          <form
            autoComplete="off"
            onSubmit={submitHandler}
            className="align-center-common min-h-half px-20 flex-col"
          >
            <div className="mb-4 max-width-500">
              <label htmlFor="phone" className=" div-label font-bold mb-2">
                Login with Mobile Number
              </label>
              <PhoneInput
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: false,
                }}
                isValid={true}
                containerClass="w-100px mb-4"
                inputClass={validation ? 'border-red tel-input' : ' tel-input'}
                onlyCountries={['us', 'in']}
                country={'us'}
                value={phone}
                onChange={(code, data) => onsetPhone(code, data)}
                placeholder="+1 Enter mobile number here"
              />
              <button type="submit" className="primary w-100px">
                Send OTP
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
