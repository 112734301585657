import { createAsyncThunk } from '@reduxjs/toolkit';
import { CheckMobileFormApi, IMaidRegisterAPI, IOtpForm, RequestOtp, IAcceptTerms } from '../models/maidRegister';
import { get, post } from './apiService';

export const maidRegister = createAsyncThunk(
  'maids/registerMaid',
  async (data: IMaidRegisterAPI, thunkAPI: any) => {
    try {
      let url = 'users/v1/maids/register/?hotel=' + data.hotel;
      const response = post(url, data.registerForm);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const maidOtpRequest = createAsyncThunk(
  'maids/maidOtpRequest',
  async (data: RequestOtp, thunkAPI: any) => {
    try {
      let url = 'users/v1/unregistered-otp/';
      const response = post(url, data);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const checkMaid = createAsyncThunk(
  'maids/checkMaid',
  async (data: CheckMobileFormApi, thunkAPI: any) => {
    try {
      let url = 'users/v1/maids/check/?hotel=' + data.hotel;
      const response = post(url, data.mobileForm);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTermsAndCondition = createAsyncThunk(
  'terms/getTermsAndCondition',
  async (group: number, thunkAPI: any) => {
    // console.log('tttt');
    try {
      let url = 'users/v1/terms-and-conditions/view/?group=' + group;
      const response = get(url);
      (await response).data
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const acceptTermsAndConditions = createAsyncThunk(
  'terms/acceptTermsAndConditions',
  async (data: IAcceptTerms, thunkAPI: any) => {
    // console.log('tttt');
    try {
      let url = 'users/v1/terms-and-conditions-log/';
      const response = post(url, data);
      (await response).data
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
