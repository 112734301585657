import React, { useEffect, useState, useMemo } from 'react';
import { PAGINATION_NUM_ROWS } from '../../config/env'; 

interface IProps {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  siblingCount: number;
  isTableLoading: boolean;
  setCurrentPage: (page: number) => void;
}

const Pagination: React.FC<IProps> = (props) => {
  const [pages, setPages] = useState<number[]>([]);

  const getRangeValues = (start: number, end: number): number[] => {
    if (start === end) {
      return Array.from([start]);
    }
    const inc = (end - start) / Math.abs(end - start);

    return Array.from(Array(Math.abs(end - start) + 1), (_, i) => start + i * inc);
  };

  const paginationRange = useMemo(() => {
    if (props.totalCount <= PAGINATION_NUM_ROWS) {
      // setPages(getRangeValues(1, 1));
      return;
    }
    const totalPageCount = Math.ceil(props.totalCount / props.pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = props.siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      setPages(getRangeValues(1, totalPageCount));
      return;
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(props.currentPage - props.siblingCount, 1);
    const rightSiblingIndex = Math.min(props.currentPage + props.siblingCount, totalPageCount);

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * props.siblingCount;
      let leftRange = getRangeValues(1, leftItemCount);
      setPages([...leftRange, totalPageCount]);
      return;
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * props.siblingCount;
      let rightRange = getRangeValues(totalPageCount - rightItemCount + 1, totalPageCount);
      setPages([firstPageIndex, ...rightRange]);
      return;
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = getRangeValues(leftSiblingIndex, rightSiblingIndex);
      setPages([firstPageIndex, ...middleRange, lastPageIndex]);
      return;
    }
  }, [props.currentPage, props.totalCount]);

  const onNextPage = (): void => {
    if (pages.length > 1) {
      const last = pages.slice(-1);
      if (props.currentPage < last[0]) {
        props.setCurrentPage(props.currentPage + 1);
      }
    }
  };

  const onPreviousPage = (): void => {
    if (props.currentPage > 1) {
      props.setCurrentPage(props.currentPage - 1);
    }
  };

  const PaginationButtons = (): JSX.Element[] => {
    const items: JSX.Element[] = [];
    pages.forEach((pageno) => {
      if (pageno === props.currentPage) {
        items.push(
          <a className="active noselect pointer-cursor" href="#" key={pageno}>
            {pageno}
          </a>
        );
      } else {
        items.push(
          <a
            className="noselect pointer-cursor"
            onClick={() => props.setCurrentPage(pageno)}
            key={pageno}
          >
            {pageno}
          </a>
        );
      }
    });
    return items;
  };

  return (
    <>
      {props.isTableLoading === false && props.totalCount > 0 && props.totalCount >  PAGINATION_NUM_ROWS && (
        <div className="pagination">
          <a className="noselect pointer-cursor" onClick={() => onPreviousPage()}>
            Previous
          </a>
          {PaginationButtons()}
          <a className="noselect pointer-cursor" onClick={() => onNextPage()}>
            Next
          </a>
        </div>
      )}
    </>
  );
};

export default Pagination;
