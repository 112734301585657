import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IGuestPayment, IGuestPaymentSummary } from '../../models/guestPaymentsInterfaces';
import { fetchPayments, fetchPaymentsSummary } from '../../services/guestPaymentService';
import { IGenericResponse, IPagination } from '../../services/interfaces';
import { GetStartDate, GetEndDateDate } from '../../helpers/dateOps';

export interface DataState {
  guestPayments: IGuestPayment[];
  guestPaymentsSummary: IGuestPaymentSummary; 
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  guestPaymentsLoading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  totalSize: number;
  filterState: 'transDate' | 'payPeriod';
  filterFrom: string;
  filterTo: string;
}

const initialState: DataState = {
  guestPayments: [],
  guestPaymentsSummary: { paymentCommission: '',
    total: 0,
    totalCommission: 0, 
    totalCommissionCurrency:'', 
    totalCurrency:''
  },
  loading: 'idle',
  guestPaymentsLoading: 'idle',
  error: '',
  totalSize: 0,
  filterState: 'payPeriod',
  filterFrom: GetStartDate('guest'),
  filterTo: GetEndDateDate('guest'),
};

export const guestPaymentsDataSlice = createSlice({
  name: 'guestPaymentsData',
  initialState,
  reducers: {
    setguestPayments: (state, action: PayloadAction<any>) => {
      state.guestPayments = action.payload;
    },
    setFilterState: (state, action: PayloadAction<any>) => {
      state.filterState = action.payload;
      // state.filterFrom = GetStartDate('guest');
      // state.filterTo = GetEndDateDate('guest');
      state.filterFrom = GetStartDate('null');
      state.filterTo = GetEndDateDate('null');
    },
    setFilterFrom: (state, action: PayloadAction<any>) => {
      state.filterFrom = action.payload;
    },
    setFilterTo: (state, action: PayloadAction<any>) => {
      state.filterTo = action.payload;
    },
    resetGuestPaymentsSummary: (state, action: PayloadAction<any>) => {
      state.guestPaymentsSummary = action.payload;
    },
    resetGuestPayments: (state, action: PayloadAction<any>) => {
      state.guestPayments = action.payload;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(fetchPayments.pending, (state) => {
      state.guestPayments = [];
      state.loading = 'loading'; 
      state.guestPaymentsLoading = 'loading'; 
    });
    builder.addCase(fetchPayments.fulfilled, (state, action) => {
      const m = action.payload as IPagination<IGuestPayment[]>;
      state.guestPayments = m.results;
      state.loading = 'succeeded';
      state.guestPaymentsLoading = 'succeeded'; 
      state.totalSize = m.count;
    });
    builder.addCase(fetchPayments.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      state.guestPaymentsLoading = 'error'; 
      // state.error = action.error.message;
    });

    builder.addCase(fetchPaymentsSummary.pending, (state) => {
      state.guestPaymentsSummary = { paymentCommission: '',
        total: 0,
        totalCommission: 0, 
        totalCommissionCurrency:'', 
        totalCurrency:''
      };
      state.loading = 'loading';
    });
    builder.addCase(fetchPaymentsSummary.fulfilled, (state, action) => {
      const m = action.payload as IGuestPaymentSummary;
      state.guestPaymentsSummary = m;
      state.loading = 'succeeded';
    });
    builder.addCase(fetchPaymentsSummary.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });

  },
});

// Action creators are generated for each case reducer function
export const { setguestPayments, setFilterState, setFilterFrom, setFilterTo, resetGuestPaymentsSummary, resetGuestPayments } =
  guestPaymentsDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectGuestPaymentsDataSlice = (state: RootState) => state.guestPaymentsData;

export const guestPaymentsDataReducer = guestPaymentsDataSlice.reducer;
