import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IAdminAssociate, IAdminAssociateCreate } from '../../models/adminAssociateInterfaces';
import { AdminAssociateUsernameSearch, fetchAdminAssociates } from '../../services/adminAssociateService';
import { IGenericResponse, IPagination } from '../../services/interfaces';

export interface DataState {
  associates: IAdminAssociate[];
  associatesUser: IAdminAssociateCreate;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  assocUserloading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
}

const initialState: DataState = {
  associates: [],
  associatesUser: {
    firstName: "",
    lastName: "",
    username: "",
    mobile: "",
    position: "",
    role: 0,
    hotelList: [],
    isActive: true,
  },
  loading: 'idle',
  assocUserloading: 'idle',
  error: '',
};

export const adminAssociatesListDataSlice = createSlice({
  name: 'adminAssociatesListData',
  initialState,
  reducers: {
    setAdminAssociatesList: (state, action: PayloadAction<any>) => {
      state.associates = action.payload;
    },
    setDataLoading: (state) => {
      state.loading = 'loading';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminAssociates.pending, (state) => {
      state.associates = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchAdminAssociates.fulfilled, (state, action) => {
      const m = action.payload as IAdminAssociate[];
      console.log('fetchAdminAssociates.fulfilled: ', m);
      state.associates = m;
      state.loading = 'succeeded';
      console.log('fetchAdminAssociates.fulfilled: state.associates: ', state.associates);
    });
    builder.addCase(fetchAdminAssociates.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
    builder.addCase(AdminAssociateUsernameSearch.pending, (state) => {
      // state.loading = 'loading';
      state.assocUserloading = 'loading';
    });
    builder.addCase(AdminAssociateUsernameSearch.fulfilled, (state, action) => {
      const m = action.payload as IAdminAssociateCreate;
      state.associatesUser = m;
      state.loading = 'succeeded';
      state.assocUserloading = 'succeeded';
    });
    builder.addCase(AdminAssociateUsernameSearch.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setAdminAssociatesList, setDataLoading } = adminAssociatesListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAdminAssociatesListDataSlice = (state: RootState) => state.adminAssociatesListData;

export const adminAssociatesListDataReducer = adminAssociatesListDataSlice.reducer;
