import { useDispatch } from 'react-redux';
import { USER_GROUP_HOTELS, USER_GROUP_MAIDS, USER_GROUP_SUPERADMIN } from '../../config/env';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getTermsAndCondition } from '../../services/maidRegisterService';
import { selectMaidFormSlice } from './maidRegisterSlice';
import {useHistory} from 'react-router-dom';
import logo from './../../assets/img/tova_stack.png';
import { LBL_T_AND_C } from '../../config/common_labels';

function MaidTerms() {
    const history = useHistory()
    const dispatch = useAppDispatch();
    const { termsAndC,loading } = useAppSelector(selectMaidFormSlice)
    
    const modules = {
        toolbar: false,
    };
  
    const [termValue, setTermvalue] = useState<any>();
    
    const handleChange = () => {
        setTermvalue(termValue)
    }
    
    const handleClick =()=> {
        let prevURL = localStorage.getItem('locationHref');
        localStorage.setItem('termsAccepted', 'yes');
        if(prevURL && prevURL.length > 3) {
            history.replace(prevURL);
            // history.push(prevURL); 
        }
        else {
            history.goBack();
        }
        console.log('handleClick22'); 
    }

    useEffect(() => {
        dispatch(getTermsAndCondition(USER_GROUP_MAIDS));
    }, [])
    
    return (
       
    <div className="align-center-register flex-col mb-4">
        <div className="box-p20-t10 border max-width-500 ">
            <div>
                    <form className="col-md-12">
                        <div className="align-center-common flex-col">
                            <img src={logo} alt='maideasy' className="maid-login-logo" />
                        </div>
                        <div>

                            <div className="terms-content">
                                {/* <div className="mb-2 div-label font-bold">{LBL_T_AND_C}</div> */}
                                <ReactQuill theme="snow"
                                 value={termsAndC.conditionText} 
                                 onChange={handleChange}
                                    modules={modules}
                                    readOnly={true}
                                />

                            </div>
                            <div>&nbsp;</div>
                            <div>
                                <div className='float-right mr-10px'>
                    
                                        <button type='button' className="primary margin-left-15px mb-3" 
                                        onClick={handleClick} 
                                        >
                                        I Accept
                                        </button>
                                </div>
                            </div>
                            {/* <div>&nbsp;</div>
                            <div>&nbsp;</div> */}
                        </div>
                    </form>
                </div>
          </div>

          
        </div>
      
    )
}

export default MaidTerms