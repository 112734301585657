import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IMaidDetails, IMaidApprove, IMaidIdProofList, IMaidIdProof } from '../../models/maidsInterfaces';
import { fetchMaidDetails, getImageId } from '../../services/maidService';
import { approveMaid } from '../../services/maidService';
import { Store } from 'react-notifications-component';
import { APP_NAME, notification_shown_time_success } from '../../config/env';
import { MSG_MAID_ACTIVATED } from '../../config/messages';

export interface DataState {
  maid: IMaidDetails;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
  idProofs: IMaidIdProof[];
  idProofStatus: 'idle' | 'loading' | 'succeeded' | 'error';
}

const initialState: DataState = {
  maid: {} as IMaidDetails,
  loading: 'idle',
  error: '',
  idProofs: [] as IMaidIdProof[],
  idProofStatus: 'idle',
};

export const maidDetailsDataSlice = createSlice({
  name: 'maidData',
  initialState,
  reducers: {
    clearMaidDetails: (state) => {
      state.maid = {} as IMaidDetails;
    },
    clearMaidIDProofs: (state) => {
      state.idProofs = [] as IMaidIdProof[]; 
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMaidDetails.pending, (state) => {
      state.maid = {} as IMaidDetails;
      state.loading = 'loading';
    });
    builder.addCase(fetchMaidDetails.fulfilled, (state, action) => {
      state.maid = action.payload as IMaidDetails;
      state.loading = 'succeeded';
    });
    builder.addCase(fetchMaidDetails.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
    builder.addCase(getImageId.pending, (state) => {
      state.idProofStatus = 'loading';
    });
    builder.addCase(getImageId.fulfilled, (state, action) => {
      console.log('getImageId: ', action.payload); 
      if(Array.isArray(action.payload.data)) {
        state.idProofs = action.payload.data as IMaidIdProof[];
        state.idProofStatus = 'succeeded';
      }
      else {
        state.idProofs = [] as IMaidIdProof[];
        state.idProofStatus = 'succeeded';
      }
    });
    builder.addCase(getImageId.rejected, (state, { payload }: any) => {
      state.idProofStatus = 'error';
      // state.error = action.error.message;
    });
    builder.addCase(approveMaid.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(approveMaid.fulfilled, (state, action) => {
      let adminApprove = action.payload as IMaidApprove;
      state.maid.isAdminApproved = adminApprove.isAdminApproved;
      state.loading = 'succeeded';
      Store.addNotification({
        title: APP_NAME,
        message: MSG_MAID_ACTIVATED,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    });
    builder.addCase(approveMaid.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { clearMaidDetails, clearMaidIDProofs } = maidDetailsDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidDetailsDataSlice = (state: RootState) => state.maidDetailsData;

export const maidDataReducer = maidDetailsDataSlice.reducer;
