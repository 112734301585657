import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import Table from '../../components/Table';
import { selectAdminAssociatesListDataSlice, setDataLoading } from './adminAssociatesListDataSlice';
import { selectAdminAssociatesAddEditSlice } from './adminAssociatesAddEditSlice';
import { IAdminAssociate, IAdminAssociateListRequestData, IAdminAssociateCreate, IAdminAssociateModify, IAdminAssociateUsername } from '../../models/adminAssociateInterfaces';
import {
  fetchAdminAssociates, createAdminAssociate, modifyAdminAssociate, statusUpdateAdminAssociate, resetAssocUserPassword, AdminAssociateUsernameSearch, deleteAdminAssociate 
} from '../../services/adminAssociateService';
import { adminAssociatesListFields } from '../../config/tables';
import { LBL_ASSOCIATES, LBL_ASSOCIATE, LBL_USER_ROLE_ADMIN_SUPERADMIN, LBL_USER_ROLE_ADMIN_ADMIN } from '../../config/common_labels';
import { getAdminUserRole } from '../../services/localStorage';

import { checkPagePermission, checkActionPermission, PERM_ADD_USER, PERM_EDIT_USER } from '../../config/permissions';

import logo from './../../assets/img/csv.png';
import { notification_shown_time_success, notification_shown_time_failed, USER_ROLE_HOTEL_USER, USER_ROLE_ADMIN_SUPERADMIN, USER_ROLE_ADMIN_ADMIN, notification_shown_time_failed4 } from '../../config/env';

import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { selectMaidListHotelFilterSlice } from '../MaidList/maidListHotelFilterSlice';
import { fetchHotelsNameId } from '../../services/hotelService';
import { MSG_CONFIRM_RESET_PASSWORD, MSG_USER_DELETED, MSG_USER_DELETION_FAILED  } from '../../config/messages';

export const AdminAssociatesList = () => {
  const { allHotelOpts, allHotelOptsInactive } = useAppSelector(selectMaidListHotelFilterSlice);
  let adminUserRole = getAdminUserRole();
  let userListFields = adminAssociatesListFields;
  // IF ADMIN USER - NO EDIT AND DELETE OPTIONS
  if (adminUserRole === USER_ROLE_ADMIN_ADMIN.toString()) {
    userListFields[0].clickable = false;
    userListFields = userListFields.filter(item =>item.type != 'delete_user_button'); 
  }
  const [listFetchAdminAssociatesPromise, setListFetchAdminAssociatesPromise] = useState<Array<any>>([]);
  const { associates, loading, assocUserloading, associatesUser } = useAppSelector(selectAdminAssociatesListDataSlice);
  const { addEditLoading, deleteUserLoading } = useAppSelector(selectAdminAssociatesAddEditSlice);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [editId, setEditId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [mobile, setMobile] = useState('');
  const [position, setPosition] = useState('');
  const [hotelListObj, setHotelListObj] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [role, setRole] = useState(USER_ROLE_ADMIN_ADMIN);
  const [usernameError, setUsernameError] = useState('required');
  const [modalHeader, setModalHeader] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isPrimaryUser, setIsPrimaryUser] = useState(false);
  const [roleDisabled, setRoleDisabled] = useState(false);
  const [needUserMerge, setNeedUserMerge] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let adminUserId = localStorage.getItem('adminUserId');

  const resetAssociate = () => {
    setDeleteId(0); 
    setEditId(0);
    setFirstName('');
    setLastName('');
    setUsername('');
    setMobile('');
    setPosition('');
    setUsernameError('required');
    setEmailVerified(false);
    setIsActive(false);
    setRole(USER_ROLE_ADMIN_ADMIN);
    setHotelList([]);
    setHotelListObj([]);
    setIsPrimaryUser(false)
  }

  const fetchall = (): void => {
    listFetchAdminAssociatesPromise.forEach((p) => {
      p.abort();
    });
    const req: IAdminAssociateListRequestData = {
      page: 0,
      search: '',
    };
    const promise = dispatch(fetchAdminAssociates(req));
    setListFetchAdminAssociatesPromise([promise]);
  };

  React.useEffect(() => {
    if (addEditLoading == 'succeeded') {
      handleClose();
      fetchall();
    }
    else if (addEditLoading == 'statusUpdated') {
      fetchall();
    }
    else if (addEditLoading == 'statusUpdateFailed') {
      setIsActive(!isActive);
    }
  }, [addEditLoading]);

  React.useEffect(() => {
    if(deleteUserLoading=='deleted') {
      fetchall();
    }
  }, [deleteUserLoading]);

  React.useEffect(() => {
    fetchall();
    dispatch(fetchHotelsNameId('')); // FOR ADD/EDIT USERS  
  }, [dispatch]);

  React.useEffect(() => {
    if (username && username.length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        setUsernameError('');
      }
      else {
        setUsernameError('required');
      }
    }
    else {
      setUsernameError('required');
    }
  }, [username]);

  const {
    register,
    setValue,
    clearErrors,
    handleSubmit
  } = useForm<IAdminAssociateCreate>();

  const updateFieldMobile = (value: string) => {
    setMobile(value);
    setValue('mobile', value);
    clearErrors('mobile');
  };

  const onSaveSubmitted = () => {

    if(role==USER_ROLE_ADMIN_ADMIN && (!hotelListObj || !hotelListObj.length)) {
      return false; 
    }

    if (firstName && firstName.trim().length > 0 && lastName && lastName.trim().length > 0 && username && username.trim().length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        if (editId == 0 && hotelListObj && hotelListObj.length > 0) {
          let hList = role==USER_ROLE_ADMIN_ADMIN ? hotelList : [];
          var newAssoc: IAdminAssociateCreate = {
            firstName: firstName,
            lastName: lastName,
            mobile: mobile && mobile.trim().length > 0 ? mobile.search('\\+') < 0 ? '+' + mobile : mobile : '',
            username: username,
            position: position,
            isActive: true,
            role: role,
            hotelList: hList,
          };
          dispatch(createAdminAssociate(newAssoc));
        }
        else if (editId > 0) {
            let hList = role==USER_ROLE_ADMIN_ADMIN ? hotelList : [];
            var assocData: IAdminAssociateModify = {
              userId: editId,
              firstName: firstName,
              lastName: lastName,
              mobile: mobile && mobile.trim().length > 0 ? mobile.search('\\+') < 0 ? '+' + mobile : mobile : '',
              username: username,
              position: position,
              isActive: isActive,
              role: role,
              hotelList: hList,
            };
            dispatch(modifyAdminAssociate(assocData));
          
        }
      }
    }
    else {
      return false;
    }
  };

  const onAddAssociatebutton = () => {
    setModalHeader('Add ' + LBL_ASSOCIATE);
    resetAssociate();
    // dispatch(fetchHotelsNameId('')); // LIST OF HOTELS OF THIS USER CALLED ON PAGE LOADING ALREADY 
    handleOpen();
  }

  const deleteUser = (id:any) => {
    // console.log('delete'); 
    return;
  }

  const onClickable = (id: number, name: string): void => {
    let stringId = id.toString();
    const assocDet = associates.find(item => item.id == id);
    console.log('assocDet: ', assocDet);
    if(name=='deleteUser') {
      Store.addNotification({
        title: 'Refund',
        id: 'deleteUser-modal',
        content: (
            <div className="refund-modal">
                <div className="refund-flex-center page-heading">
                    <h4 >Delete {LBL_ASSOCIATE}</h4>
                </div>
                <div className="refund-flex-center">
                    Are you sure you want to delete this {LBL_ASSOCIATE.toLowerCase()}?
                </div>
                <div className="refund-margin"></div>
                <div className="refund-flex-center">
                    <button
                        type="button"
                        className="primary"
                        onClick={() => Store.removeNotification('deleteUser-modal')}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="rounded-btn danger margin-left-15px"
                        onClick={() => {
                            Store.removeNotification('deleteUser-modal');
                            dispatch(deleteAdminAssociate(id));
                          }
                        }
                    >
                        Delete
                    </button>
                </div>
            </div>
          ),
          type: 'default',
          container: 'center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
              duration: 1000000000,
              click: false,
              touch: false,
          },
      });
    }
    else if (((assocDet?.isPrimaryUser) == true && adminUserId != stringId) || (adminUserRole === USER_ROLE_ADMIN_ADMIN.toString())) {
      return;
    }
    else {
      setEditId(id);
      setFirstName(assocDet?.firstName ? assocDet.firstName : '');
      setLastName(assocDet?.lastName ? assocDet.lastName : '');
      setUsername(assocDet?.username ? assocDet.username : '');
      setMobile(assocDet?.mobile ? assocDet.mobile : '');
      setPosition(assocDet?.position ? assocDet.position : '');
      setRole(assocDet?.role ? assocDet.role : USER_ROLE_ADMIN_SUPERADMIN);
      setEmailVerified(assocDet?.emailVerified ? assocDet.emailVerified : false);
      setIsActive(assocDet?.isActive ? assocDet.isActive : false);
      setIsPrimaryUser(assocDet?.isPrimaryUser ? assocDet.isPrimaryUser : false);
      setUsernameError('');
      setModalHeader('Edit ' + LBL_ASSOCIATE);
      let arr: any = []
      let arr2: any = []
      console.log(allHotelOptsInactive); 
      allHotelOptsInactive.map((d: any) => { arr.push(d) })

      assocDet?.userHotelIds.map((data: any) => {
        arr.filter((d: any) => d.value == data ? arr2.push(d) : null)
      })
      
      setHotelListObj(arr2);
      setHotelList(arr2.map((item: any) => parseInt(item.value, 10)))
      handleOpen();
    }
  };

  React.useEffect(() => {
    setRoleDisabled(editId.toString() == adminUserId ? true : false);
  }, [editId]);

  const changeStatus = (isChecked: any) => {
    setIsActive(isChecked);
  }

  const onResendEmail = (username: string) => {
    if (username) {
      dispatch(resetAssocUserPassword(encodeURIComponent(username)));
    }
  };

  const onSetHotelList = (value: any) => {
    setHotelListObj(value)
    setHotelList(value.map((item: any) => parseInt(item.value, 10)))
  };



  const OnSetUsername = (value: any) => {
    setUsername(value)
    console.log("1");

    let pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (pattern.test(value)) {

      console.log("2");
      handleAssociateUsername(value)
    }
  };

  const handleAssociateUsername = (e: any) => {
    var newAssoc: IAdminAssociateUsername = {
      username: encodeURIComponent(e),
    };
    dispatch(AdminAssociateUsernameSearch(newAssoc));
  };


  useEffect(() => {

    if (assocUserloading == "succeeded" && associatesUser) {
      // if ((associatesUser?.firstName && associatesUser?.firstName.trim().length > 0) && (associatesUser?.lastName && associatesUser?.lastName.trim().length > 0)) {
      //   setNeedUserMerge(true)
      // }
      if (associatesUser?.firstName && associatesUser?.firstName.trim().length > 0) {
        setFirstName(associatesUser.firstName);
      }
      if (associatesUser?.lastName && associatesUser?.lastName.trim().length > 0) {
        setLastName(associatesUser.lastName);
      }
      if (associatesUser?.role && associatesUser?.role > 0) {
        setRole(associatesUser.role);
      }
      if (associatesUser?.mobile && associatesUser?.mobile.trim().length > 0) {
        setMobile(associatesUser.mobile);
      }
      if (associatesUser?.position && associatesUser?.position.trim().length > 0) {
        setPosition(associatesUser.position);
      }
    }

  }, [assocUserloading]);

  const onResetEmail = (username: string) => {
    if (username && username.length > 0) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (username.match(validRegex)) {
        Store.addNotification({
          title: 'Reset Password',
          id: 'resetpwd-modal',
          content: (
              <div className="resetpwd-modal">
                <div className="resetpwd-flex-center page-heading">
                    <h4>Confirm Reset Password</h4>
                </div>
                <div className="resetpwd-flex-center">
                    {MSG_CONFIRM_RESET_PASSWORD+' of '+username+'?'}
                </div>
                <div className="resetpwd-margin"></div>
                <div className="resetpwd-flex-center">
                    <button
                        type="button"
                        className="primary"
                        onClick={() => Store.removeNotification('resetpwd-modal')}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="primary margin-left-15px"
                        onClick={() => {
                          dispatch(resetAssocUserPassword(encodeURIComponent(username))) 
                          Store.removeNotification('resetpwd-modal') 
                          }
                        }
                    >
                        Reset Password
                    </button>
                </div>
            </div>
          ),
          type: 'default',
          container: 'center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 1000000000,
            click: false,
            touch: false,
          },
        });
      }
    }
  };


  // const downloadSample = (event: React.MouseEvent<HTMLElement>): void => {
  //   event.stopPropagation();
  //   getHotelSample();
  // };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    bgcolor: 'background.paper',
    border: '1px solid #d0d0d0',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className="header-content-container">
        <div className="box">
          <div className="justify-space-between">
            <div className="div-left margin-left-5px width-45-perc "><h4 className="page-heading div-left padding-top6">{LBL_ASSOCIATES}</h4></div>
            <div className="justify-end div-right">
              {checkActionPermission(adminUserRole, PERM_ADD_USER) && <button
                type="button"
                className="primary mb-3"
                onClick={() => onAddAssociatebutton()}
              >
                Add Admin {LBL_ASSOCIATE}
              </button>}
            </div>
          </div>
          <div className="content-box table-wrapper">
            <Table
              titles={userListFields}
              data={Array.isArray(associates) ? associates : []}
              isLoading={loading === `loading` ? true : false}
              onClickable={onClickable}
              currentPage={0}
              onResendEmail={onResendEmail}
            />

            <div className="justify-right">

            </div>

          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby={modalHeader}
        aria-describedby=""
      >
        <Box sx={style}>
          <div className="associate-modal div-center">
            {/* <div className="page-heading">
                    <h4>{modalHeader}</h4>
                </div> */}

            <div className='row col-12 margin-bottom20px'>
              <div className="row col-12 page-heading div-center">
                <div className="div-center"><h4>{modalHeader}</h4></div>
              </div>

              <div className='row margin-top5'>
                <div className='col-6'>
                  Username (Email)
                  <input
                    type="text"
                    name={'username'}
                    className={usernameError && usernameError.trim().length > 0 ? "w-100px input-error" : "w-100px"}
                    placeholder="Enter Username here"
                    onChange={(e) => OnSetUsername(e.target.value)}
                    required
                    value={username}
                  />
                  {emailVerified && <div className='div-right resetpwd-text' onClick={(e) => onResetEmail(username)}>Reset Password</div>}
                </div>
                <div className='col-6'>
                  Mobile
                  <PhoneInput
                    inputProps={{
                      name: `mobile`,
                      required: true,
                      autoFocus: false,
                    }}
                    inputClass="tel-input"
                    onlyCountries={['us', 'in']}
                    country={'us'}
                    value={mobile}
                    onChange={(code) => updateFieldMobile(code)}
                  />
                  {emailVerified && <div className='div-right resetpwd-text' >&nbsp;</div>}
                </div>

              </div>
              <div className='row margin-top20'>
                <div className='col-6'>
                  First Name
                  <input
                    type="text"
                    name={'firstName'}
                    className={firstName && firstName.trim().length > 0 ? "w-100px" : "w-100px input-error"}
                    placeholder="Enter First Name here"
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    value={firstName}
                  />
                </div>
                <div className='col-6'>
                  Last Name
                  <input
                    type="text"
                    name={'lastName'}
                    className={lastName && lastName.trim().length > 0 ? "w-100px" : "w-100px input-error"}
                    placeholder="Enter Last Name here"
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    value={lastName}
                  />
                </div>
              </div>
              <div className='row margin-top20'>
                <div className='col-6'>
                  Position
                  <input
                    type="text"
                    name={'position'}
                    className="w-100px"
                    placeholder="Enter Position here"
                    onChange={(e) => setPosition(e.target.value)}
                    value={position}
                  />
                </div>
                <div className='col-6 '>
                  Role
                  <select name="role" id="role" className={'w-100px'} value={role} defaultValue={USER_ROLE_ADMIN_ADMIN} onChange={(e: any) => setRole(e.target.value)} >
                    <option value={USER_ROLE_ADMIN_ADMIN}>{LBL_USER_ROLE_ADMIN_ADMIN} </option>
                    <option value={USER_ROLE_ADMIN_SUPERADMIN}>{LBL_USER_ROLE_ADMIN_SUPERADMIN}</option>
                  </select>
                </div>
              </div>

              <div className='row margin-top20'>
                {role==USER_ROLE_ADMIN_ADMIN && <div className='col-12'>
                  Hotel List
                  {/* HOTEL LIST IS NOT MANDATORY IN EDIT USER */}
                  <div className={hotelListObj && hotelListObj.length == 0 && editId === 0 ? "autocomplete input-error" : "autocomplete"}>
                    <Autocomplete
                      sx={{ height: 'auto' }}
                      multiple
                      limitTags={3}
                      disableCloseOnSelect={true}
                      id="multiple-limit-tags"
                      options={allHotelOptsInactive}
                      className="select_checkbox"
                      getOptionLabel={(option) => option.label}
                      // defaultValue={[allHotelOpts[0]]}
                      value={hotelListObj}
                      onChange={(e: any, newValue: any) => { onSetHotelList(newValue) }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} size="small" required />
                      )}
                    />
                  </div>
                </div>}
              </div>

              <div className='row margin-top30'>
                <div className='col-7 div-left'>
                  {emailVerified === true && editId > 0 && adminUserId != editId.toString() && <><label>
                    <div className='div-left'>
                      <input
                        type="checkbox"
                        checked={isActive}
                        onChange={e => changeStatus(e.target.checked)}
                        className="activeCheckbox"
                      /></div> <span > Active</span>
                  </label></>}
                </div>
                <div className='col-5 div-right'>
                  {adminUserId != editId.toString() ? <><button
                    type="button"
                    className="primary div-right margin-left-10px"
                    onClick={() => onSaveSubmitted()}
                  > &nbsp; Save &nbsp; </button>
                  <button
                    type="button"
                    className="primary div-right margin-right-15px"
                    onClick={() => handleClose()}
                  >Cancel</button></> : <button
                  type="button"
                  className="div-right"
                  onClick={() => handleClose()}
                >Cancel</button>}
                </div>
              </div>
              <div className='row margin-top120'>&nbsp;</div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
