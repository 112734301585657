import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IMaidPaymentFilterData } from '../../models/maidPaymentsInterface';
import { fetchMaidPaymentsFilterData } from '../../services/maidPaymentsService';
import { IGenericResponse, IPagination } from '../../services/interfaces';
import dayjs from 'dayjs';

export interface DataState {
  maidPaymentsFilterData: IMaidPaymentFilterData;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
}

const initialState: DataState = {
  maidPaymentsFilterData: {
    currency: '',
    totalDistributed: 0,
    totalDistributedFailed: 0,
    successTransactions: 0,
    failedTransactions: 0,
    pendingTransactions: 0,
    totalTransactions: 0,
    totalCommission: 0,
    totalPendingDistribution: 0,
    totalPendingCommission: 0,
  },
  loading: 'idle',
  error: '',
};

export const maidPaymentsFilterDataSlice = createSlice({
  name: 'maidPaymentsFilterData',
  initialState,
  reducers: {
    resetMaidPaymentsFilterData: (state) => {
      state.maidPaymentsFilterData =  {
        currency: '',
        totalDistributed: 0,
        totalDistributedFailed: 0,
        successTransactions: 0,
        failedTransactions: 0,
        pendingTransactions: 0,
        totalTransactions: 0,
        totalCommission: 0,
        totalPendingDistribution: 0,
        totalPendingCommission: 0,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMaidPaymentsFilterData.pending, (state) => {
      state.maidPaymentsFilterData = {
        currency: '',
        totalDistributed: 0,
        totalDistributedFailed: 0,
        successTransactions: 0,
        failedTransactions: 0,
        pendingTransactions: 0,
        totalTransactions: 0,
        totalCommission: 0,
        totalPendingDistribution: 0,
        totalPendingCommission: 0,
      };
      state.loading = 'loading';
    });
    builder.addCase(fetchMaidPaymentsFilterData.fulfilled, (state, action) => {
      const resp = action.payload as IMaidPaymentFilterData;
      state.maidPaymentsFilterData = {
        currency: resp.currency,
        totalDistributed: resp.totalDistributed,
        totalDistributedFailed: resp.totalDistributedFailed,
        successTransactions: resp.successTransactions,
        failedTransactions: resp.failedTransactions,
        pendingTransactions: resp.pendingTransactions,
        totalTransactions: resp.totalTransactions,
        totalCommission: resp.totalCommission,
        totalPendingDistribution: resp.totalPendingDistribution,
        totalPendingCommission: resp.totalPendingCommission,
      };
      state.loading = 'succeeded';
    });
    builder.addCase(fetchMaidPaymentsFilterData.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const {resetMaidPaymentsFilterData} = maidPaymentsFilterDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidPaymentsFilterDataSlice = (state: RootState) => state.maidPaymentsFilterData;

export const maidPaymentsFilterDataReducer = maidPaymentsFilterDataSlice.reducer;
