import { APP_NAME } from "./env";

export const LBL_HOTELS             = 'Hotels'; 
export const LBL_HOTEL_PAYMENTS     = 'Commission'; 
export const LBL_MAID               = 'Staff'; 
export const LBL_MAIDS              = 'Staff'; 
export const LBL_MAID_PAYMENTS      = 'Payments'; 
export const LBL_SETTINGS           = 'Settings'; 
export const LBL_TIPS               = 'Tips'; 
export const LBL_TIPS_OF            = 'Tips of'; 
export const LBL_EXPORT_TIPS        = 'Export Tips'; 
export const LBL_HOTEL_INACTIVE     = 'Inactive.'; // This hotel is inactive.
export const LBL_ASSOCIATES         = 'Users'; 
export const LBL_ASSOCIATE          = 'User';
export const LBL_HOTEL_ASSOCIATES   = 'Hotel Users'; 
export const LBL_HOTEL_ASSOCIATE    = 'Hotel User';

export const LBL_USER_ROLE_ADMIN_SUPERADMIN = 'Super Admin';
export const LBL_USER_ROLE_ADMIN_ADMIN      = 'Admin';
export const LBL_USER_ROLE_HOTEL_MANAGER    = 'Hotel Manager';
export const LBL_USER_ROLE_HOTEL_USER       = 'Hotel User';

export const LBL_URL_MAIDS           = 'staff'; 
export const LBL_URL_MAID_PAYMENTS   = 'staff-payments'; 
export const LBL_VAR_MAID_PAY_HOTEL  = 'staff-pay-hotel'; 

export const LBL_ROOM_NUMBER         = 'Room Number'; 
export const LBL_ROOM_NAME           = 'Room Name'; 

export const LBL_CSV_HOURLY          = 'Hourly'; 
export const LBL_CSV_DATE            = 'Date'; 

export const LBL_TERMS               = 'T & C'; 
export const LBL_T_AND_C             = 'Terms & Conditions'; 

export const LBL_PAYOUT_TOVA  = APP_NAME;
export const LBL_PAYOUT_HOTEL = 'Hotel';
export const LBL_PAYOUT_STAFF  = LBL_MAIDS;

export const LBL_TOVA_PAYOUT  = APP_NAME+' Payout';
export const LBL_HOTEL_PAYOUT = 'Hotel Payout';
export const LBL_STAFF_PAYOUT  = LBL_MAIDS+' Payout';

export const LBL_STAFF_PAYMENT_METHOD_BANK  = 'Bank';   // USED IN STAFF DETAILS SLIDER 
export const LBL_STAFF_PAYMENT_METHOD_CHECK = 'Check'; 

export const LBL_PROCESS_PAYMENTS = 'Process Payments'; 

export const LBL_INCLUDE_INACTIVE_HOTELS = 'Include inactive hotels.'; 
export const LBL_RESET_FILTER = 'Reset Filter'; 

export const LBL_COL_HEAD_DOCS_VERIFIED = 'ID Proof Verified?'; 
export const LBL_DOCS_VERIFIED_BY_STRIPE = 'ID Proof verified by Stripe'; 

export const LBL_INCLUDE_PAYOUTS = 'Include Hotel Payouts'; 

