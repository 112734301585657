import React, { useEffect, useState } from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { IAutocompleteSelectOptionType } from '../../models/maidsInterfaces';
import { getSelectedHotelId, setSelectedHotelId } from '../../services/localStorage';
import { PAYOUT_HOTEL, PAYOUT_STAFF } from '../../config/env';
import { fetchHotelsNameId } from '../../services/maidPaymentsService';
import { LBL_INCLUDE_INACTIVE_HOTELS, LBL_INCLUDE_PAYOUTS } from '../../config/common_labels';
import { changeMultiSelect, MultiSelectSlice } from './MultiSelectSlice';

interface IProps {
  // currentSearch: string;
  getHotellist: (hList:any) => void;
  getIncludeInactiveStatus: (includeInactive:boolean) => void;
  getIncludePayoutStatus: (includePayout:boolean) => void;
  // place_holder: string;
  includePayout: boolean;
  // onSearchButton: (search: any) => void;
  // searchDisabled?: boolean;
}

const MultiSelect: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const { allHotelList } = useAppSelector(MultiSelectSlice);

  const [multiSearchText, setMultiSearchText] = useState('');
  const [includeInactive, setIncludeInactive] = useState(false);
  const [includePayout, setIncludePayout] = useState(false);
  const [count, setCount] = useState(0);

  const [hotelListValue, setHotelListValue] = useState<IAutocompleteSelectOptionType[]>([]);
  const [hotelListObj, setHotelListObj] = useState<IAutocompleteSelectOptionType[]>([]);
  const [hotelList, setHotelList] = useState<string[]>([]); // useState<number[]>([]);

  const [localStorageHotelId, setLocalStorageHotelId] = useState(getSelectedHotelId());


  React.useEffect(() => {
      dispatch(fetchHotelsNameId(''));
  }, []);

  React.useEffect(() => {
    handleOption();
  }, [allHotelList,count]);

  const handleOption = () => {
    if (localStorageHotelId) {
      let arrHIds = localStorageHotelId.split(',');
      let arrHObj = [] as any;
      allHotelList.filter((item) => {
        if (arrHIds.includes(item.value)) {
          arrHObj.push(item);
        }
      });

      setHotelListValue(arrHObj);
      props.getHotellist(arrHIds);
      console.log('arrHObj2222',arrHObj); 
    } else {
      let hObj = allHotelList.filter(
        (item) => item.active == true && item.payout == PAYOUT_STAFF && item.value !== 'all'
      );
      if(hObj && Array.isArray(hObj) && hObj.length > 0) {
        setSelectedHotelId(hObj[0].value.toString());
        setLocalStorageHotelId(hObj[0].value.toString());

        let arrHIds = [hObj[0].value];
        let arrHObj = [] as any;
        allHotelList.filter((item) => {
          if (arrHIds.includes(item.value)) {
            arrHObj.push(item);
          }
        });

        setHotelListValue(arrHObj);
        props.getHotellist([hObj[0].value.toString()]);
      }
    }


    const hotelValueHasAll = hotelListValue.some((hotel) => hotel.value === 'all');
    if (includeInactive) {
      if (includePayout && props.includePayout) {
        setHotelListObj(allHotelList);
        if(hotelValueHasAll){
          setHotelListValue(allHotelList);
          let allHIds = allHotelList.map(item => item.value); 
          props.getHotellist(allHIds);
          setSelectedHotelId(allHIds.join(','));
          setLocalStorageHotelId(allHIds.join(','));
        }
        console.log('1', allHotelList.length);
      } else {
        if (props.includePayout) {
          let arr1: IAutocompleteSelectOptionType[] = [];
          arr1 = allHotelList.filter((item) => item.payout == PAYOUT_STAFF);
          setHotelListObj(arr1);
          if(hotelValueHasAll){
            setHotelListValue(arr1);
            let allHIds = arr1.map(item => item.value); 
            props.getHotellist(allHIds);
            setSelectedHotelId(allHIds.join(','));
            setLocalStorageHotelId(allHIds.join(','));
          }
          console.log('2', arr1.length);
        } else {
          setHotelListObj(allHotelList);
          if(hotelValueHasAll){
            setHotelListValue(allHotelList);
            let allHIds = allHotelList.map(item => item.value); 
            props.getHotellist(allHIds);
            setSelectedHotelId(allHIds.join(','));
            setLocalStorageHotelId(allHIds.join(','));
          }
          console.log('6', allHotelList.length);
        }
      }
    } else {
      if (includePayout && props.includePayout) {
        let arr2: IAutocompleteSelectOptionType[] = [];
        arr2 = allHotelList.filter((item) => item.active);
        setHotelListObj(arr2);
        if(hotelValueHasAll){
          setHotelListValue(arr2);
          let allHIds = arr2.map(item => item.value); 
          props.getHotellist(allHIds);
          setSelectedHotelId(allHIds.join(','));
          setLocalStorageHotelId(allHIds.join(','));
        }
        console.log('3', arr2.length);
      } else {
        if (props.includePayout) {
          let arr3: IAutocompleteSelectOptionType[] = [];
          arr3 = allHotelList.filter((item) => item.active && item.payout == PAYOUT_STAFF);
          setHotelListObj(arr3);
          if(hotelValueHasAll){
            setHotelListValue(arr3);
            let allHIds = arr3.map(item => item.value); 
            props.getHotellist(allHIds);
            setSelectedHotelId(allHIds.join(','));
            setLocalStorageHotelId(allHIds.join(','));
          }
          console.log('4', arr3.length);
        } else {
          let arr5: IAutocompleteSelectOptionType[] = [];
          arr5 = allHotelList.filter((item) => item.active==true);
          setHotelListObj(arr5);
          if(hotelValueHasAll){
            setHotelListValue(arr5); 
            console.log('arr5',arr5); 
            let allHIds = arr5.map(item => item.value); 
            props.getHotellist(allHIds);
            setSelectedHotelId(allHIds.join(','));
            setLocalStorageHotelId(allHIds.join(','));
          }
          console.log('5', arr5.length);
        }
      }
    }
  };

  const onSetMultiSearchText = (searchText: string) => {
    setMultiSearchText(searchText);
  };

  const onSetHotelList = (e: any, selectedHotels: IAutocompleteSelectOptionType[]) => {
    const hasAllOption = selectedHotels.some((hotel) => hotel.value === 'all');
    const selHasAllOption = hotelListValue.some((hotel) => hotel.value === 'all');
    let allOptions = hotelListObj;
    let allSelOptions: IAutocompleteSelectOptionType[] = [];

    if (!hasAllOption && !selHasAllOption && allOptions.length == selectedHotels.length + 1) {
      console.log('In if 000');
      allOptions.map((item) =>
        allSelOptions.push({ value: item.value, label: item.label, active: true })
      );
    } else if (hasAllOption && !selHasAllOption) {
      console.log('In if 001');
      allOptions.map((item) =>
        allSelOptions.push({ value: item.value, label: item.label, active: true })
      );
    } else if (hasAllOption && allOptions.length == selectedHotels.length + 1) {
      console.log('In if 111');
      allOptions = selectedHotels.filter((item) => item.value != 'all');
    } else if (hasAllOption && allOptions.length == selectedHotels.length) {
      console.log('In if 222');
      allOptions.map((item) =>
        allSelOptions.push({ value: item.value, label: item.label, active: true })
      );
    } else {
      console.log('In if 333');
      if (allOptions.length == selectedHotels.length + 1) {
        allOptions = [];
      } else {
        allOptions = selectedHotels;
      }
    }
    let allHIds = allOptions.map((item: any) => item.value);
    console.log('onSetHotelList', allOptions);
    setHotelListValue(allOptions);
    props.getHotellist(allHIds);
    setSelectedHotelId(allHIds.join(','));
    setLocalStorageHotelId(allHIds.join(','));
  };

  const onSetIsactiveHotel = (inactiveStatus: boolean) => {    
    setIncludeInactive(inactiveStatus);
    props.getIncludeInactiveStatus(inactiveStatus); 
    setCount((count+1)); 
  };
  
  const onSetIncludePayout = (payoutStatus: boolean) => {
    setIncludePayout(payoutStatus);
    props.getIncludePayoutStatus(payoutStatus); 
    setCount((count+1)); 
  };

  return (
    <div className="bg-white">
      <Autocomplete
        onBlur={(e) => onSetMultiSearchText('')}
        sx={{ height: 'auto' }}
        multiple
        limitTags={1}
        disableCloseOnSelect={true}
        id="multiple-limit-tags"
        options={hotelListObj}
        className="select_checkbox border-one"
        getOptionLabel={(option) => option.label}
        // getOptionDisabled={(option) =>
        //   option === activeHotel[0] || option === activeHotel[2]
        // }
        // defaultValue={[allHotelOpts[0]]}
        value={hotelListValue}
        inputValue={multiSearchText}
        onChange={(e: any, newValue: any) => {
          onSetHotelList(e, newValue);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            <span className={option.active ? '' : 'red-text'}>{option.label}</span>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            required
            onChange={(e) => onSetMultiSearchText(e.target.value)}
          />
        )}
      />
      <div className="my-2 gap-5 flex">
        <div>
          <input
            type="checkbox"
            id="chk"
            onChange={(e) => onSetIsactiveHotel(e.target.checked)}
            checked={includeInactive}
            className=""
          />{' '}
          <label className="div-label-small" htmlFor="chk">
            {LBL_INCLUDE_INACTIVE_HOTELS}
          </label>
        </div>
        {props.includePayout && (
          <div>
            <input
              type="checkbox"
              id="chkbox"
              onChange={(e) => onSetIncludePayout(e.target.checked)}
              checked={includePayout}
              className=""
            />{' '}
            <label className="div-label-small" htmlFor="chkbox">
              {LBL_INCLUDE_PAYOUTS}
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelect;
