import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import 'react-datepicker/dist/react-datepicker.css';
import { getUpdateURLParameter } from '../../helpers/url';

interface IProps {
  minDate?: string;
  format: string;
  defaultDate: Date;
  page: string;
  type: string;
  setDate: (e: Date) => void;
}

const DateFilterComponent: React.FC<IProps> = (props: IProps) => {
  const handleChange = (date: Date): void => {
    let formattedDate;
    if (props.type == 'start') {
      formattedDate = dayjs(date).format(`YYYY-MM-DDT00:00:00ZZ`);
    } else {
      formattedDate = dayjs(date).format(`YYYY-MM-DDT23:59:59ZZ`);
    }
    window.history.replaceState(
      '',
      '',
      getUpdateURLParameter(
        window.location.href,
        props.page + props.type,
        encodeURIComponent(formattedDate)
      )
    );
    props.setDate(date);
  };

  return (
    <><div style={{ position: "relative" }}>
        <label>
          <span className='div-right cursor-pointer' ><i className="fa fa-calendar calendar-icon" /></span>
          <DatePicker
            dateFormat="MMM dd, yyyy"
            minDate={props.minDate ? dayjs(props.minDate).toDate() : undefined}
            selected={props.defaultDate}
            onChange={handleChange}
            className="box-data box-border-blue"
          /> 
        </label>
      </div>
    </>
  );
};

export default DateFilterComponent;
