import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IMaidProfile, IMaidProfileEdit } from '../../models/maidProfileInterfaces';
import { adminUpdateMaid, fetchMaidDetails, getImageId } from '../../services/maidService';
import { Store } from 'react-notifications-component';
import { notification_shown_time_success, DEFAULT_CURRENCY, DEFAULT_COUNTRY } from '../../config/env';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface DataState {
  maidProfileEditData: IMaidProfileEdit;
  profileEditloading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
}

const initialState: DataState = {
  maidProfileEditData: {} as IMaidProfileEdit,
  profileEditloading: 'idle',
  error: '',
};

export const maidProfileEditDataSlice = createSlice({
  name: 'maidProfileEditData',
  initialState,
  reducers: {
    setMaidProfileProperties: (state, action: PayloadAction<IMaidProfile>) => {
      state.maidProfileEditData.id = action.payload.id;
      state.maidProfileEditData.mobileNumber = action.payload.mobileNumber;
      state.maidProfileEditData.alternativeMobileNumber = action.payload.alternativeMobileNumber;
      state.maidProfileEditData.email = action.payload.email;
      state.maidProfileEditData.firstName = action.payload.firstName;
      state.maidProfileEditData.lastName = action.payload.lastName;
      state.maidProfileEditData.middleName = action.payload.middleName;
      state.maidProfileEditData.addressLine_1 = action.payload.addressLine_1;
      state.maidProfileEditData.addressLine_2 = action.payload.addressLine_2;
      state.maidProfileEditData.city = action.payload.city;
      state.maidProfileEditData.state = action.payload.state;
      state.maidProfileEditData.zip = action.payload.zip;
      state.maidProfileEditData.accountHolderName = action.payload.accountHolderName;
      state.maidProfileEditData.dateOfBirth = action.payload.dateOfBirth;
      state.maidProfileEditData.country = action.payload.country ? action.payload.country : DEFAULT_COUNTRY;  
      state.maidProfileEditData.ssn = action.payload.ssn; 
      state.maidProfileEditData.accountNumber = action.payload.accountNumber; 
      state.maidProfileEditData.bankName = action.payload.bankName;
      state.maidProfileEditData.routingNumber = action.payload.routingNumber;
      state.maidProfileEditData.currency = action.payload.currency ? action.payload.currency : DEFAULT_CURRENCY;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminUpdateMaid.pending, (state) => {
      state.profileEditloading = 'loading';
    });
    builder.addCase(adminUpdateMaid.fulfilled, (state, action) => {
      state.profileEditloading = 'succeeded';
    });
    builder.addCase(adminUpdateMaid.rejected, (state, { payload }: any) => {
      state.profileEditloading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setMaidProfileProperties } = maidProfileEditDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMaidProfileEditSlice = (state: RootState) => state.maidProfileEdit;

export const maidProfileEditReducer = maidProfileEditDataSlice.reducer; 
