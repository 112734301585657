import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../helpers/hook';
import logo from './../../assets/img/tova.png';
import sideImageSmall from './../../assets/img/small_background.png';
import { APP_NAME, COPYRIGHT_TEXT } from '../../config/env';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// PAGE FOR 404 REDIRECTION 
function urlNotFound(props: any) {

  const history = useHistory();
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="">
        <div className='row bg-white' >
                <div className="justify-center mt-25px mb-50px">
                  <img className="medium" src={logo} alt={APP_NAME} />
                </div>
                <h3 className="justify-center mb-300px">
                  404 Not Found!
                </h3>
          <div className='col-3'>&nbsp;</div>
          </div>
      </div>
      <footer>{COPYRIGHT_TEXT}</footer>
    </>
  )
}

export default urlNotFound;