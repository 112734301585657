import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { Link, useHistory, useParams, useLocation  } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";

// import logo from '../../assets/img/tova.png';
import logo from '../../assets/img/tova_stack_white.png';
import { selectHotelFormDataSlice } from '../HotelForm/hotelFormDataSlice';
import { getHotelImage } from '../../services/tipService';
import { ITip } from '../../models/tipInterfaces';
import { selectMaidFormSlice, setScreen } from './maidRegisterSlice';
import {
  CheckMobileForm,
  CheckMobileFormApi,
  IMaidRegisterAPI,
  IMaidRegisterForm,
  IOtpForm,
  RequestOtp,
} from '../../models/maidRegister';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { checkMaid, maidOtpRequest, maidRegister } from '../../services/maidRegisterService';
import { load } from 'redux-localstorage-simple';
import PhoneInput from 'react-phone-input-2';
import appleLogo from './../../assets/img/app_store.png';
import googleLogo from './../../assets/img/google_play.png';
import { checkIos } from '../../helpers/ios';
import { MSG_OTP_SENDING_FAILED, MSG_OTP_SENT, MSG_RESENT_OTP } from '../../config/messages';
import { APP_NAME, GOOGLE_RECAPTCHA_KEY } from '../../config/env';
import { LBL_MAID, LBL_URL_MAIDS } from '../../config/common_labels';

export const MaidRegister = (props: any) => {
  const history = useHistory();
  const { maidRegisterForm, loading, screen, otpForm, toastMsgType } = useAppSelector(selectMaidFormSlice);
  const dispatch = useAppDispatch();
  const [hotelLogo, setHotelLogo] = useState<string>('null');
  const [hotelName, setHotelName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('null');
  const [lastName, setLastName] = useState<string>('null');
  const [middleName, setMiddleName] = useState<string>('null');
  const [mobileNumber, setMobileNumber] = useState<string>('null');
  const [isChecked, setIsChecked] = useState(false);
  const [otp, setOtp] = useState<string>('null');
  const [isAllowed, setIsAllowed] = useState<'loading' | true | false>('loading');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaValue, setCaptchaValue] = useState<string>('');
  let required = "This field is required."
  
  const numericPattern = /^[0-9\b]+$/;

  const location = useLocation()
  // console.log(location.state); 
  // const params = useParams()
  // let {id} = params;

  const {
    getValues,
    setValue,
    clearErrors,
    setError,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IMaidRegisterForm>();

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm<IOtpForm>();

  React.useEffect(() => {
    if (props.match.params.id !== undefined) {
      // ios fix
      let urlId = props.match.params.id;
      let cleanedUrlId = urlId.replace('\\', '');
      getHotelImage(cleanedUrlId).then((res: any) => {
        if (res['status'] === 200) {
          if (res.data['url'] != null) {
            setHotelLogo(res.data['url']);
          }
          setHotelName(res.data['name']);
          setIsAllowed(res.data['isActive']);
        }
      });
    }
    if ( localStorage.getItem('termsAccepted')=='yes') {
      setIsChecked(true)
      localStorage.setItem('termsAccepted','');
      let userData =  localStorage.getItem('userFormData');
      
      if(userData && userData.length > 3) {
        // firstName+'~#~'+lastName+'~#~'+middleName+'~#~'+mobileNumber;
        let arrUserData = userData.split('~#~'); 
        if(arrUserData[0]) { 
          setValue('firstName', arrUserData[0]);   
        }
        if(arrUserData[1]) { 
          setValue('lastName', arrUserData[1]);  
        }
        if(arrUserData[2]) { 
          setValue('middleName', arrUserData[2]);    
        }
        if(arrUserData[3]) { 
          setMobileNumber(arrUserData[3]); 
          setValue('mobileNumber', arrUserData[3]);  
        }
      }
      localStorage.setItem('userFormData','');
      localStorage.setItem('locationHref','');

    }
    
    // console.log('iiiii',props.match.params);
    
  }, []);

  React.useEffect(() => {
    if (toastMsgType === 'OTPSentSuccess') { //  toast.info/success/warning/error
      toast.success(MSG_OTP_SENT, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
    }
    else if (toastMsgType === 'OTPSentFailed') { // '' | 'OTPSentFailed' info, success, warning, error
      toast.error(MSG_OTP_SENDING_FAILED, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
    }
  }, [toastMsgType]);


  React.useEffect(() => {
    // setIsChecked(true)
    if (screen == 'maid-profile') { 
      history.push('/'+LBL_URL_MAIDS+'/profile');
    }
  }, [screen]);

  const onSubmit: SubmitHandler<IMaidRegisterForm> = (data) => {
    // var req: IMaidRegisterAPI = {
    //   hotel: props.match.params.id,
    //   registerForm: data,
    //   otp: '123'
    // };
    // dispatch(maidRegister(req));
  };

  // const onErrorSubmit: SubmitErrorHandler<IMaidRegisterForm> = (data) => {
  // };

  React.useEffect(() => {
    if (screen == 'otp') {
      sendOtp();
    }
  }, [screen]);

  const onSaveSubmitted = (data: IMaidRegisterForm): void => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setMiddleName(data.middleName);
    setMobileNumber(data.mobileNumber);
    var mobForm: CheckMobileForm = {
      mobileNumber: '+' + data.mobileNumber,
    };
    var checkForm: CheckMobileFormApi = {
      mobileForm: mobForm,
      hotel: props.match.params.id,
    };
    dispatch(checkMaid(checkForm));
    // dispatch(setScreen('otp'));
  };

  const onSaveSubmittedOtp = (data: IOtpForm): void => {
    setOtp(data.otp);
    var regForm: IMaidRegisterForm = {
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      mobileNumber: '+' + mobileNumber,
      otp: data.otp,
    };
    var req: IMaidRegisterAPI = {
      hotel: props.match.params.id,
      registerForm: regForm,
    };
    dispatch(maidRegister(req));
  };

  const updateFieldMobile = (value: string) => {
    setMobileNumber(value);
    setValue('mobileNumber', value);
    clearErrors('mobileNumber');
  };

  const sendOtp = () => {
    if(toastMsgType === 'OTPSentSuccess'){
      toast.success(MSG_RESENT_OTP, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
    }
    var reqOrp: RequestOtp = {
      phoneNumber: '+' + mobileNumber,
    };
    dispatch(maidOtpRequest(reqOrp));
  };

  const showTerms = () =>  {
    let formValues = getValues(); 
    let userFormData = formValues.firstName+'~#~'+formValues.lastName+'~#~'+formValues.middleName+'~#~'+formValues.mobileNumber; 
    localStorage.setItem('userFormData',userFormData);
    localStorage.setItem('locationHref',window.location.pathname);
    history.push('/'+LBL_URL_MAIDS+'-terms');
  }

  function onCaptchaChange(val:any) {
    setCaptchaValue(val); 
    setCaptchaVerified(true); 
  }

  return (
    <>
      {/* {isAllowed === true && (
        <div id="main">
          <div>
            <div className="header justify-space-between">
              <div className="justify-left">
                <div className="div-left">
                  <div>
                    <img className="small mr-10px" style={{height: 55,marginTop: 10}}  src={logo} alt={APP_NAME} />
                  </div>
                </div>
              </div>
              <div className="div-right hotel-logo-right mb-rem04 ">
                {hotelLogo === 'null' && <span className="none">{APP_NAME}</span>}
                {hotelLogo !== 'null' && <img className="small-logo" src={hotelLogo} alt={APP_NAME} />}
              </div>
            </div>
          </div>
        </div>
      )} */}

      {isAllowed === true && screen == 'register' && (
        <div className="align-center-register flex-col mb-4">
          <div className="box-p20-t10 border max-width-500 ">
            <ToastContainer autoClose={5000} />
            <div>
              <div className="header justify-space-between">
                <div className="justify-left">
                  <div className="div-left">
                    <div className="div-left">
                      <img className="logo_maid_tip" src={logo} alt={APP_NAME} />
                    </div>
                  </div>
                </div>
                <div className="div-right mb-rem04 ">
                  {hotelLogo === 'null' && <span className="none">{APP_NAME}</span>}
                  {hotelLogo !== 'null' && <img className="hotel_logo" src={hotelLogo} alt={APP_NAME} />}
                </div>
              </div>
            </div>

            <form autoComplete="off" onSubmit={handleSubmit(onSaveSubmitted)}>
              <div className="content_container-register margin-top25">
                <label className="font-merri  h3 pt-1 font-bold">{LBL_MAID} Registration</label>
                <div >
                  <div >
                    <div className=" mt-10px">
                      <label className='div-label font-bold'>First Name</label>
                    </div>
                    <div className="mt-10px">
                      <input
                        type="text"
                        className= {errors.firstName ? "input-error w-100" : "w-100"}
                        {...register('firstName', {required})}
                        name="firstName"
                        placeholder="First Name"
                      />
                    </div>
                    {errors.firstName && <div className="danger">{errors.firstName.message}</div>}
                  </div>
                  <div>
                    <div className=" mt-10px">
                      <label className='div-label font-bold'>Middle Name</label>
                    </div>
                    {/*  justify-center */}
                    <div className=" mt-10px">
                      <input
                        type="text"
                        className="w-100"
                        {...register('middleName')}
                        name="middleName"
                        placeholder="Middle Name"
                      />
                    </div>
                  </div>

                  <div>
                    <div className=" mt-10px">
                      <label className='div-label font-bold'>Last Name</label>
                    </div>
                    <div className="mt-10px">
                      <input
                        type="text"
                        className={errors.lastName ? "input-error w-100" : "w-100"}
                        {...register('lastName', {required })}
                        name="lastName"
                        placeholder="Last Name"
                      />
                    </div>
                    {errors.lastName && <div className="danger">{errors.lastName.message}</div>}
                  </div>

                  <div>
                    <div className=" mt-10px">
                      <label className='div-label font-bold'>Mobile Number</label>
                    </div>
                    <div className="justify-center mt-10px">
                      <input
                        type="number"
                        className="w-100 hide"
                        // className={errors.mobileNumber ? "input-error w-100 hide" : "w-100"}
                        {...register('mobileNumber', {required })}
                        name="mobileNumber"
                        value={mobileNumber}
                        placeholder="Mobile Number"
                      />
                      <PhoneInput
                        inputProps={{
                          name: 'mobileNumber',
                          required: true,
                          autoFocus: false,
                        }}
                        containerClass="w-100"
                        inputClass="tel-input"
                        onlyCountries={['us', 'in']}
                        country={'us'}
                        value={mobileNumber}
                        onChange={(code) => updateFieldMobile(code)}
                      />
                    </div>
                    {errors.mobileNumber && <div className="danger">{errors.mobileNumber.message}</div>}
                    <div className="custom-checkbox mt-4">
                      <input type="checkbox" id="chk" onChange={(e) => setIsChecked(e.target.checked)} checked={isChecked} /> <label className="checkbox div-label font-bold" htmlFor='chk'>I accept <Link to="#" onClick={e => showTerms()} className='color-blue'>Terms & Conditions</Link></label>
                    </div>
                    {/* {errors.mobileNumber && <div className="danger">{errors.mobileNumber.message}</div>} */}
                  </div>
                </div>

                <div className='mt-4 mb-5px div-center'> 
                  <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_KEY}
                    onChange={onCaptchaChange} 
                  />
                </div>
                <div className="mt-4">
                 {(captchaVerified===true && captchaValue && captchaValue.length > 0 && isChecked) ? <button type="submit" className="primary p-3 w-100">
                    Register Now
                  </button>: ''} 
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {isAllowed === true && screen == 'otp' && (
        <div className="align-center-register flex-col mb-4">
          <div className="box-p20-t10 border max-width-500">
            <ToastContainer autoClose={5000} />
            <div className='pt-10'>
              <div className="header justify-space-between">
                <div className="justify-left">
                  <div className="div-left">
                    <div className="div-left">
                      <img className="logo_maid_tip" src={logo} alt={APP_NAME} />
                    </div>
                  </div>
                </div>
                <div className="div-right mb-rem04 ">
                  {hotelLogo === 'null' && <span className="none">{APP_NAME}</span>}
                  {hotelLogo !== 'null' && <img className="hotel_logo" src={hotelLogo} alt={APP_NAME} />}
                </div>
              </div>
            </div>
              <form autoComplete="off" onSubmit={handleSubmit2(onSaveSubmittedOtp)}>
                <div>
                  <div className="text-center mt-30px">
                    <label className='div label font-bold'>A verification code has been sent to </label>
                  </div>
                  <div className="text-center">
                    <label className="div-label">+{mobileNumber} </label>
                  </div>
                  <div className="text-center mt-30px">
                    <label className='div label font-bold'>Didn&apos;t receive code? </label> &nbsp;
                    <label className="div-label cursor-pointer">
                      <u onClick={sendOtp}> Resend</u>
                    </label>
                  </div>
                </div>
                <div>
                  <div className="justify-center mt-30px">
                    <label className="div-label font-bold">One Time Password (OTP)</label>
                  </div>
                  <div className="justify-center mt-10px">
                    <input
                      type="number"
                      className="w-100"
                      {...register2('otp', {required })}
                      name="otp"
                      placeholder="Enter OTP here"
                    />
                  </div>
                  {errors2.otp && <div className="danger">{errors2.otp.message}</div>}
                </div>
                <div className="justify-center mb-30px mt-4">
                  <button type="submit" className="primary p-3 w-100">
                    Submit
                  </button>
                </div>
              </form> 
          </div>
        </div>
      )}
      {isAllowed === true && screen == 'otp-success' && (
        <div>
          <div className="content_container">
            <ToastContainer autoClose={5000} />
            <form autoComplete="off" action="otp.html">
              <div className="text-center mt-30px">
                <label>
                  You have successfully registered as a {LBL_MAID.toLowerCase()} at {hotelName}. Now you can start using
                  {APP_NAME} mobile app. If you have not download the app yet, download it from the{' '}
                  {checkIos() == true && <span>App Store.</span>}
                  {checkIos() == false && <span>Google Playstore.</span>}
                </label>
              </div>
              <div className="justify-center mt-10px">
                {checkIos() == false && (
                  <img src={googleLogo} className="store_button" alt="Google Playstore" />
                )}
                {checkIos() == true && (
                  <img src={appleLogo} className="store_button  ml-10px" alt="App Store" />
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      {isAllowed === false && (
        <div>
          <div id="main">
            <div>
              <ToastContainer autoClose={5000} />
              <div className="header justify-space-between">
                <div className="justify-left">
                  <div className="div-left">
                    <div>
                      <img className="small" src={logo} alt={APP_NAME} />
                    </div>
                  </div>
                </div>
                <div className="div-right hotel-logo-right">
                  {hotelLogo === 'null' && <span className="none">{APP_NAME} payments</span>}
                  {hotelLogo !== 'null' && <img className="small" src={hotelLogo} alt={APP_NAME} />}
                </div>
              </div>
            </div>
          </div>
          <div className="content_container">
            <div className="rounded-20px inactive-hotel margin-top20">
              Invalid request. Please contact {APP_NAME} admin for more details.
            </div>
          </div>
        </div>
      )}
    </>
  );
};
