import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IAdminAssociate } from '../../models/adminAssociateInterfaces';
import { createAdminAssociate, modifyAdminAssociate, statusUpdateAdminAssociate, resetAssocUserPassword, resetHotelUserPassword, deleteAdminAssociate } from '../../services/adminAssociateService';
import { Store } from 'react-notifications-component';
import { notification_shown_time_success } from '../../config/env';
import { LBL_ASSOCIATE, LBL_ASSOCIATES } from '../../config/common_labels';
import { MSG_NEW_USER_CREATION, MSG_USER_DELETED, MSG_USER_DELETION_FAILED, MSG_USER_DEL_FAILED_HOTEL_REL, MSG_USER_MODIFICATION, MSG_USER_STATUS_UPDATE, MSG_USER_STATUS_UPDATE_FAILED, MSG_USER_VERIFY_EMAIL_RESENT, MSG_USER_VERIFY_EMAIL_RESENT_FAILED } from '../../config/messages';
import { APP_NAME } from '../../config/env'; 

export interface AddEditState {
  addEditLoading: 'idle' | 'loading' | 'succeeded' | 'error' | 'statusUpdated' | 'statusUpdateFailed';
  deleteUserLoading: 'idle' | 'loading' | 'deleted' | 'error';
  error: string | undefined;
}

const initialState: AddEditState = {
  addEditLoading: 'idle',
  deleteUserLoading: 'idle', 
  error: '',
};

export const adminAssociatesAddEditSlice = createSlice({
  name: 'adminAssociatesAddEdit',
  initialState,
  reducers: {
    setDataLoading: (state) => {
      state.addEditLoading = 'loading';
    },
  },
  extraReducers: (builder) => {

    builder.addCase(createAdminAssociate.pending, (state) => { 
      state.addEditLoading = 'loading';
    });
    builder.addCase(createAdminAssociate.fulfilled, (state, action) => {
      // const m = action.payload as IAdminAssociate[];
      console.log('createAdminAssociate.fulfilled: ');  
      state.addEditLoading = 'succeeded';
      Store.addNotification({
        title: APP_NAME,
        message:  MSG_NEW_USER_CREATION,
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: notification_shown_time_success,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    });
    builder.addCase(createAdminAssociate.rejected, (state, { payload }: any) => {
      state.addEditLoading = 'error';
      // state.error = action.error.message;
      console.log('payload: ',payload); 
        //   let errStr = LBL_ASSOCIATE+'creation failed.'; 
        //   Store.addNotification({
        //     title: APP_NAME,
        //     message: LBL_ASSOCIATE+' creation failed.',
        //     type: 'danger',
        //     insert: 'top',
        //     container: 'top-center',
        //     animationIn: ['animate__animated', 'animate__fadeIn'],
        //     animationOut: ['animate__animated', 'animate__fadeOut'],
        //     dismiss: {
        //       duration: notification_shown_time_success,
        //       onScreen: true,
        //       pauseOnHover: true,
        //     },
        //   });
    });



    builder.addCase(modifyAdminAssociate.pending, (state) => { 
        state.addEditLoading = 'loading';
    });
    builder.addCase(modifyAdminAssociate.fulfilled, (state, action) => {
        // const m = action.payload as IAdminAssociate[];
        console.log('modifyAdminAssociate.fulfilled: ');  
        state.addEditLoading = 'succeeded';
        Store.addNotification({
            title: APP_NAME,
            message: MSG_USER_MODIFICATION,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
            },
        });
    });
    builder.addCase(modifyAdminAssociate.rejected, (state, { payload }: any) => {
        state.addEditLoading = 'error';
        // state.error = action.error.message;
        console.log('payload2: ',payload); 
        // Store.addNotification({
        //   title: APP_NAME,
        //   message: LBL_ASSOCIATE+' modification failed.',
        //   type: 'danger',
        //   insert: 'top',
        //   container: 'top-center',
        //   animationIn: ['animate__animated', 'animate__fadeIn'],
        //   animationOut: ['animate__animated', 'animate__fadeOut'],
        //   dismiss: {
        //     duration: notification_shown_time_success,
        //     onScreen: true,
        //     pauseOnHover: true,
        //   },
        // });
      });


    builder.addCase(statusUpdateAdminAssociate.pending, (state) => { 
        state.addEditLoading = 'loading';
    });
    builder.addCase(statusUpdateAdminAssociate.fulfilled, (state, action) => {
        // const m = action.payload as IAdminAssociate[];
        console.log('statusUpdateAdminAssociate.fulfilled: ');  
        state.addEditLoading = 'statusUpdated';
        Store.addNotification({
            title: APP_NAME,
            message: MSG_USER_STATUS_UPDATE,
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
            },
        });
    });
    builder.addCase(statusUpdateAdminAssociate.rejected, (state, { payload }: any) => {
        state.addEditLoading = 'statusUpdateFailed';
        // state.error = action.error.message;
        Store.addNotification({
          title: APP_NAME,
          message: MSG_USER_STATUS_UPDATE_FAILED,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      });


      builder.addCase(resetAssocUserPassword.pending, (state) => { 
        state.addEditLoading = 'loading';
      });
      builder.addCase(resetAssocUserPassword.fulfilled, (state, action) => {
        // const m = action.payload as IAdminAssociate[];
        console.log('resetAssocUserPassword.fulfilled: ');  
        state.addEditLoading = 'succeeded';
        Store.addNotification({
          title: APP_NAME,
          message: MSG_USER_VERIFY_EMAIL_RESENT,
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      });
      builder.addCase(resetAssocUserPassword.rejected, (state, { payload }: any) => {
        state.addEditLoading = 'error';
        // state.error = action.error.message;
        console.log('payload: ',payload); 
        Store.addNotification({
          title: APP_NAME,
          message: MSG_USER_VERIFY_EMAIL_RESENT_FAILED,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      });

      builder.addCase(resetHotelUserPassword.pending, (state) => { 
        state.addEditLoading = 'loading';
      });
      builder.addCase(resetHotelUserPassword.fulfilled, (state, action) => {
        // const m = action.payload as IAdminAssociate[];
        console.log('resetHotelUserPassword.fulfilled: ');  
        state.addEditLoading = 'succeeded';
        Store.addNotification({
          title: APP_NAME,
          message: MSG_USER_VERIFY_EMAIL_RESENT,
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      });
      builder.addCase(resetHotelUserPassword.rejected, (state, { payload }: any) => {
        state.addEditLoading = 'error';
        // state.error = action.error.message;
        console.log('payload: ',payload); 
        Store.addNotification({
          title: APP_NAME,
          message: MSG_USER_VERIFY_EMAIL_RESENT_FAILED,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      });


      builder.addCase(deleteAdminAssociate.pending, (state) => { 
        state.deleteUserLoading = 'loading';
      });
      builder.addCase(deleteAdminAssociate.fulfilled, (state, action) => {
          // const m = action.payload as IAdminAssociate[];
          // console.log('action:: ',action.payload.message); 
          // ''
          // if(action) {
          //   console.log('User Deletion Success:: '); 
          // }
          // else {
          //   console.log('User Deletion Failed:: '); 
          // }
          state.deleteUserLoading = action?.payload?.message ? 'error' : 'deleted';

          Store.addNotification({
              title: APP_NAME,
              message: action?.payload?.message=='primary_user_delete' ? MSG_USER_DEL_FAILED_HOTEL_REL:  MSG_USER_DELETED,
              type: action?.payload?.message=='primary_user_delete' ? 'danger' : 'success',
              insert: 'top',
              container: 'top-center',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
              duration: notification_shown_time_success,
              onScreen: true,
              pauseOnHover: true,
              },
          });
      });
      builder.addCase(deleteAdminAssociate.rejected, (state, { payload }: any) => {
        state.deleteUserLoading = 'error';
        // state.error = action.error.message;
        Store.addNotification({
          title: APP_NAME,
          message: MSG_USER_DELETION_FAILED,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: notification_shown_time_success,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      });

  },
});

// Action creators are generated for each case reducer function
export const { setDataLoading } = adminAssociatesAddEditSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAdminAssociatesAddEditSlice = (state: RootState) => state.adminAssociatesAddEditData;

export const adminAssociatesAddEditReducer = adminAssociatesAddEditSlice.reducer;
