import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import Select, { Options } from 'react-select';
import SearchBar from '../../components/SearchBar';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { Buffer } from 'buffer';
import { Store } from 'react-notifications-component';
import ReactLoading from 'react-loading';
import dayjs from 'dayjs';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { changeSearch, selectMaidListSearchSlice } from './maidListSearchSlice';
import { setCurrentPage, selectMaidListPaginationSlice } from './maidListPaginationSlice';
import { selectMaidListDataSlice, setLoading, setMaidList } from './maidListDataSlice';
import {
  selectMaidDetailsDataSlice,
  clearMaidDetails,
  clearMaidIDProofs,
} from './maidDetailsSlice';
import {
  changeHotelFilterToDefault,
  selectMaidListHotelFilterSlice,
} from './maidListHotelFilterSlice';
import { changeHotelFilter, changeCurValue } from './maidListHotelFilterSlice';
import {
  IMaidsRequestData,
  IMaidsExportRequestData,
  IAutocompleteSelectOptionType,
  IMaidDetails,
  IMaidEdit,
  IStaffHotel,
  IStaffHotelStatus,
} from '../../models/maidsInterfaces';
import {
  fetchMaids,
  fetchMaidDetails,
  exportMaids,
  importMaids,
  getSampleMaidImport,
  approveMaid,
  getImageId,
  exportW9,
  adminUpdateMaid,
  deleteMaid,
  changeStaffStatus,
} from '../../services/maidService';
import { fetchHotelsNameId } from '../../services/hotelService';
import { maidListFields } from '../../config/tables';
import logo from './../../assets/img/csv.png';
import logo1 from './../../assets/img/csv-black.png';
import editImage from './../../assets/img/edit.svg';
import {
  closeMaidEdit,
  openMaidEditState,
  selectMaidAdminEditSlice,
  setMaidProperties,
} from './maidAdminEditSlice';

import {
  notification_shown_time_success,
  notification_shown_time_failed,
  notification_shown_time_failed4,
  APP_NAME,
  STAFF_PAYMENT_METHOD_BANK,
  STAFF_PAYMENT_METHOD_CHECK,
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
  STAFF_DOC_TYPE_PASSOPORT_ID,
  STAFF_DOC_TYPE_PASSOPORT,
  STAFF_DOC_TYPE_PASSOPORT_CARD_ID,
  STAFF_DOC_TYPE_PASSOPORT_CARD,
  STAFF_DOC_TYPE_DRIVER_LICENSE_ID,
  STAFF_DOC_TYPE_DRIVER_LICENSE,
  STAFF_DOC_TYPE_STATE_ID_CARD_ID,
  STAFF_DOC_TYPE_STATE_ID_CARD,
  STAFF_DOC_TYPE_GREEN_CARD_ID,
  STAFF_DOC_TYPE_GREEN_CARD,
  STAFF_DOC_TYPE_BORDER_CROSSING_CARD_ID,
  STAFF_DOC_TYPE_BORDER_CROSSING_CARD,
  STAFF_DOC_TYPE_CHILD_ID_CARD_ID,
  STAFF_DOC_TYPE_CHILD_ID_CARD,
  STAFF_DOC_TYPE_NYC_CARD_ID,
  STAFF_DOC_TYPE_NYC_CARD,
  STAFF_DOC_TYPE_US_VISA_CARD_ID,
  STAFF_DOC_TYPE_US_VISA_CARD,
  PAYOUT_STAFF,
  PAYOUT_HOTEL,
} from '../../config/env';

import {
  MSG_FILEIMPORT_INVALID_HEADER,
  MSG_INVALID_FILE_FORMAT,
  MSG_INVALID_INPUT_FILE,
  MSG_MAID_DELETED,
  MSG_MAID_STATUS_UPDATED,
  MSG_NO_RECORDS_IMPORTED,
} from '../../config/messages';
import {
  LBL_DOCS_VERIFIED_BY_STRIPE,
  LBL_HOTEL_INACTIVE,
  LBL_INCLUDE_INACTIVE_HOTELS,
  LBL_INCLUDE_PAYOUTS,
  LBL_MAID,
  LBL_MAIDS,
  LBL_RESET_FILTER,
  LBL_STAFF_PAYMENT_METHOD_BANK,
  LBL_STAFF_PAYMENT_METHOD_CHECK,
} from '../../config/common_labels';
import { MSG_INVALID_PHONE_NUMBER, MSG_MAID_IMPORT_SUCCESS } from '../../config/messages';

import { setSelectedHotelId, getSelectedHotelId } from '../../services/localStorage';
import { IHotelIdMaid, IHotelNameId } from '../../models/hotelsInterfaces';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { selectGuestPaymentsHotelFilterSlice } from '../GuestPayments/guestPaymentHotelFilterSlice';
import MultiSelect from '../../components/MultiSelect';

export const MaidList = (props: any) => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [maidIdProof, setMaidIdProof] = useState<string | ArrayBuffer | null>('');
  const [maidDetailsWidth, setMaidDetailsWidth] = useState<number>(0);
  const [curMaid, setCurMaid] = useState<number>(0);
  const [curMaidActive, setCurMaidActive] = useState<boolean>(false);
  const [showStaffChangeStatus, setShowStaffChangeStatus] = useState<boolean>(false);
  const [curStaffHotels, setCurStaffHotels] = useState<IStaffHotel[]>([])
  const [idShowAllowed, setIdShowAllowed] = useState<boolean>(true);
  const { maidEditData, adminEditloading, isMaidEditShown } =
    useAppSelector(selectMaidAdminEditSlice);
  const { searchValue } = useAppSelector(selectMaidListSearchSlice);
  const { currentPage } = useAppSelector(selectMaidListPaginationSlice);
  const { maids, totalSize, loading, maidsLoading} = useAppSelector(selectMaidListDataSlice);
  const [currHotelIds, setCurrHotelIds] = useState<string>(''); 
  const [searchDetailsChanged, setSearchDetailsChanged] = useState(true); 

  const {
    hotelNameIds,
    curHotelValue,
    // curHotelOPt,
    // allHotelOpts,
    isHotelActive,
    // allHotelOptsInactive,
  } = useAppSelector(selectMaidListHotelFilterSlice);
  const { curHotelOPt, allHotelOpts, allHotelOptsInactive } = useAppSelector(
    selectGuestPaymentsHotelFilterSlice
  );
  const [selectedMobile, setSelectedMobile] = useState('');
  const [selectedAlternative, setSelectedAlternative] = useState('');
  const { maid, idProofStatus, idProofs } = useAppSelector(selectMaidDetailsDataSlice);
  const dispatch = useAppDispatch();
  const [applySearch, setApplySearch] = useState(0);
  const [localStorageHotelId, setLocalStorageHotelId] = useState(getSelectedHotelId());

  const [filterSideBar, setFilterSideBar] = useState(false);
  const [filterSearch, setFilterSearch] = useState('');
  const [documentVerified, setDocumentVerified] = useState('All');
  const [filterIsActive, setFilterIsActive] = useState('Yes'); 
  const [fromDate, setfromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [applyFilter, setApplyFilter] = useState(0);
  const [fetchWithFilter, setFetchWithFilter] = useState(false);
  const [displaySearch, setDisplaySearch] = useState<string>('');
  const [sortField, setSortField] = useState('first_name');
  const [logoState, setLogoState] = useState(true);

  const [multiSearchText, setMultiSearchText] = useState('');
  const [hotelListObj, setHotelListObj] = useState<IAutocompleteSelectOptionType[]>([]);
  const [hotelList, setHotelList] = useState<string[]>([]); // useState<number[]>([]);

  const [inactiveHotel, setInactiveHotel] = useState(false); 
  const [activeHotel, setactiveHotel] = useState<IAutocompleteSelectOptionType[]>([]);

  const [includePayout, setIncludePayout] = useState(false);

  const [maidListTableFields, setMaidListTableFields] = useState<any>(maidListFields);

  let required = 'This field is required.';

  const paymentOptionBank = {
    value: STAFF_PAYMENT_METHOD_BANK.toString(),
    label: LBL_STAFF_PAYMENT_METHOD_BANK,
  };
  const paymentOptionCheck = {
    value: STAFF_PAYMENT_METHOD_CHECK.toString(),
    label: LBL_STAFF_PAYMENT_METHOD_CHECK,
  };

  const [selectedPaymentOption, setSelectedPaymentOption] = useState(paymentOptionBank);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(STAFF_PAYMENT_METHOD_BANK);

  const allPaymentOptions = [paymentOptionBank, paymentOptionCheck];
  
  React.useEffect(() => {
    // dispatch(fetchHotelsNameId(''));
    return function cleanup() {
      // dispatch(changeHotelFilter(0));
      setCurMaid(0);
      Store.removeNotification('id-proof');
    };
  }, []);
  
  // React.useEffect(() => {
  //   if (localStorageHotelId) {
  //     dispatch(changeCurValue(localStorageHotelId)); 
  //     // dispatch(changeHotelFilter(localStorageHotelId));
  //     let arrHIds = localStorageHotelId.split(','); 
  //     let arrHObj = [] as any; 
  //     hotelNameIds.filter(item =>  {
  //       if(arrHIds.includes(item.id.toString())) {
  //         arrHObj.push({value: item.id.toString(), label: item.hotelName ? item.hotelName : '', active: item.isActive ? true : false, payout: Number(item.payout) ? Number(item.payout) : 0 }); 
  //       }
  //     }); 
  //     setHotelListObj(arrHObj);
  //     setHotelList(arrHIds);
  //   } else if(hotelNameIds.length > 0) {
  //     let hObj = hotelNameIds.filter(item => (item.isActive == true && item.payout==PAYOUT_STAFF) ); 
  //     setSelectedHotelId(hObj[0].id.toString());
  //     setLocalStorageHotelId(hObj[0].id.toString());

  //     // dispatch(changeCurValue(hObj[0]?.id ? hObj[0].id : 0 )); 
  //     // dispatch(changeHotelFilter(hObj[0].id));
  //     let arrHIds = [hObj[0].id]; 
  //     let arrHObj = [] as any; 
  //     hotelNameIds.filter(item =>  {
  //       if(arrHIds.includes(item.id)) {
  //         arrHObj.push({value: item.id.toString(), label: item.hotelName ? item.hotelName : '', active: item.isActive ? true : false, payout: Number(item.payout) ? Number(item.payout) : 0 }); 
  //       }
  //     }); 

  //     setHotelListObj(arrHObj);
  //     setHotelList([hObj[0].id.toString()]);  

  //   } 
  //   else {
  //     dispatch(changeHotelFilterToDefault());
  //   }
    
    
  //   // if (props.match.params.id !== undefined) {
  //   //   dispatch(changeHotelFilter(props.match.params.id));
  //   //   setSelectedHotelId(props.match.params.id);
  //   //   setLocalStorageHotelId(props.match.params.id);
  //   // } else {
  //   //   let search = window.location.search;
  //   //   let params = new URLSearchParams(search);
  //   //   if (params.get('maid-list-hotel') != undefined) {
  //   //     dispatch(changeHotelFilter(params.get('maid-list-hotel')));
  //   //     setSelectedHotelId(params.get('maid-list-hotel'));
  //   //     setLocalStorageHotelId(params.get('maid-list-hotel'));
  //   //   }
  //   // }
  // }, [hotelNameIds]);

  // React.useEffect(() => {
  //   console.log('curHotelOPt',curHotelOPt);
    
  //   if (localStorageHotelId) {
  //     dispatch(changeCurValue(localStorageHotelId)); 
  //     dispatch(changeHotelFilter(localStorageHotelId));
  //     let arrHIds = localStorageHotelId.split(','); 
  //     let arrHObj = [] as any; 
  //     hotelNameIds.filter(item =>  {
  //       if(arrHIds.includes(item.id.toString())) {
  //         arrHObj.push({value: Number(item.id) ? Number(item.id) : 0, label: item.hotelName ? item.hotelName : '', active: item.isActive ? true : false, payout: Number(item.payout) ? Number(item.payout) : 0 }); 
  //       }urHo
  //     }); 
  //     setHotelListObj(arrHObj);
  //     setHotelList(arrHIds);
  //   }
  //   else { 
  //     setHotelListObj(ctelOPt.filter(item => item.active==true));
  //     setHotelList(curHotelOPt.filter(item2 => item2.active==true).map((item: any) => item.value));
  //     // setHotelList(curHotelOPt.map((item: any) => item.value));
  //   }
  // }, [curHotelOPt]);

  React.useEffect(() => {
    if (hotelList.length == 1) {
      setMaidListTableFields(maidListFields.filter((item: any) => item.title != 'hotel'));
    } else {
      setMaidListTableFields(maidListFields);
    }
    if (hotelList.length == 0) {
      dispatch(setMaidList([]));
    }
    setSearchDetailsChanged(true); 
  }, [hotelList]);

  React.useEffect(() => {
    callFetchMaids(); 
  }, [curHotelValue, applySearch, currentPage, applyFilter, sortField, hotelList]); // searchValue

  React.useEffect(() => { 
    if(maidsLoading!='loading' && maidsLoading!='idle') {
      setSearchDetailsChanged(false); 
    }
  }, [maidsLoading]);  

  React.useEffect(() => {
    dispatch(closeMaidEdit());
  }, [curMaid]);

  React.useEffect(() => {
    if (adminEditloading == 'succeeded') {
      callFetchMaids(); 
      if (curMaid && curMaid !== 0) {
        dispatch(fetchMaidDetails(curMaid));
      }
    }
  }, [adminEditloading]);

  React.useEffect(() => {
    let arr: any = [];
    allHotelOpts.filter((d: any) => (d.active == true ? arr.push(d) : null));
    setactiveHotel(arr);
  }, [allHotelOpts]);

  const handlePaymentOptionChange = (e: any): void => {
    setSelectedPaymentMethod(e.value);
    setSelectedPaymentOption(
      e.value == STAFF_PAYMENT_METHOD_CHECK.toString() ? paymentOptionCheck : paymentOptionBank
    );
  };
  
  const handleFilter = () => {
    setFilterSideBar(!filterSideBar);
  };

  const closeFilterDetails = () => {
    setFilterSideBar(false);
  };

  const handleApplyFilter = (e: any) => {
    e.preventDefault();
    setSearchDetailsChanged(true); 
    setFetchWithFilter(true);
    setApplyFilter(applyFilter + 1);
    acceptPage(1);
    closeFilterDetails();
    setDisplaySearch(filterSearch);
  };

  const handleResetFilter = () => {
    setFilterSearch('');
    setDisplaySearch('');
    setDocumentVerified('Yes');
    setFilterIsActive('Yes'); 
    setfromDate('');
    setToDate('');
    setFetchWithFilter(false);
    setApplyFilter(applyFilter + 1);
    setSearchDetailsChanged(true); 
  };

  const callFetchMaids = () => {  
    let hIds = hotelList.join(',').replace('all,','');
    if (searchDetailsChanged || (hotelList && hotelList.length > 0 && currHotelIds!=hIds)) { // 
      const hasAllOption = hotelList.some((hotel) => hotel === 'all');
      let hotelsList =
        (hasAllOption && inactiveHotel && includePayout)
          ? 'all' 
          : (hasAllOption && !inactiveHotel && includePayout)
            ? 'active_all'
              : hIds;
      if(hotelsList && hotelsList.trim().length > 0) {
        let searchStr = fetchWithFilter ? filterSearch : displaySearch;    
        const req: IMaidsRequestData = {
          page: currentPage,
          //hotel: hotelList.length > 0 ? hotelList.join() : '0',
          hotel: hotelsList,
          search: searchStr.trim(),
          document_verified: documentVerified,
          active: filterIsActive,
          joining_from_date: fromDate,
          joining_to_date: toDate,
          sort: sortField,
        };
        dispatch(fetchMaids(req));
        setCurrHotelIds(hIds); 
      }    
    }
  }

  const onSearchButton = (e: any) => {
    e.preventDefault();
    setSearchDetailsChanged(true); 
    setFetchWithFilter(true);
    acceptPage(1);
    setApplySearch(applySearch + 1);
    setFilterSearch(displaySearch);
  };

  const {
    setValue,
    clearErrors,
    setError,
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IMaidEdit>();

  const handleSort = (sortField: string) => {
    setSortField(sortField);
  };

  React.useEffect(() => {
    reset({
      alternativeMobileNumber: maid.alternativeMobileNumber,
      mobileNumber: maid.mobileNumber,
      firstName: maid.firstName,
      lastName: maid.lastName,
      middleName: maid.middleName,
      email: maid.email,
      id: maid.id,
      accountHolderName: maid.accountHolderName,
      accountNumber: maid.accountNumber,
      bankName: maid.bankName,
      routingNumber: maid.routingNumber,
      paymentMethod: maid.paymentMethod,
      country: maid.country && maid.country.trim().length > 0 ? maid.country : DEFAULT_COUNTRY,
      currency: maid.currency && maid.currency.trim().length > 0 ? maid.currency : DEFAULT_CURRENCY,
    });
    setSelectedMobile(maid.mobileNumber);
    setSelectedAlternative(maid.alternativeMobileNumber);
    setSelectedPaymentMethod(maid.paymentMethod);
    setSelectedPaymentOption(
      maid.paymentMethod == STAFF_PAYMENT_METHOD_CHECK ? paymentOptionCheck : paymentOptionBank
    );
  }, [reset, maid]);

  const onErrorSubmit: SubmitErrorHandler<IMaidEdit> = (data) => {
    if (selectedMobile === '') {
      setError('mobileNumber', {
        type: 'manual',
        message: MSG_INVALID_PHONE_NUMBER,
      });
    }
    // if (selectedAlternative === '') {
    //   setError('alternativeMobileNumber', {
    //     type: 'manual',
    //     message: MSG_INVALID_PHONE_NUMBER,
    //   });
    // }
  };

  const updateFieldMobile = (value: string) => {
    setSelectedMobile(value);
    clearErrors('mobileNumber');
  };

  const updateFieldAltMobile = (value: string) => {
    setSelectedAlternative(value);
    clearErrors('alternativeMobileNumber');
  };

  const onSubmit: SubmitHandler<IMaidEdit> = (data) => {
    if (selectedMobile === '') {
      setError('mobileNumber', {
        type: 'manual',
        message: MSG_INVALID_PHONE_NUMBER,
      });
      return;
    }
    // if (selectedAlternative === '') {
    //   setError('alternativeMobileNumber', {
    //     type: 'manual',
    //     message: MSG_INVALID_PHONE_NUMBER,
    //   });
    //   return;
    // }
    if (selectedMobile.charAt(0) != '+') {
      data.mobileNumber = '+' + selectedMobile;
    } else {
      data.mobileNumber = selectedMobile;
    }

    if (selectedAlternative.trim().length == 0) {
      data.alternativeMobileNumber = '';
    } else if (selectedAlternative.charAt(0) != '+') {
      data.alternativeMobileNumber = '+' + selectedAlternative;
    } else {
      data.alternativeMobileNumber = selectedAlternative;
    }
    data.paymentMethod = selectedPaymentMethod;

    (data.country =
      maid.country && maid.country.trim().length > 0 ? maid.country : DEFAULT_COUNTRY),
      (data.currency =
        maid.currency && maid.currency.trim().length > 0 ? maid.currency : DEFAULT_CURRENCY),
      dispatch(adminUpdateMaid(data));
  };

  const onSaveSubmitted = (): void => {
    handleSubmit(onSubmit, onErrorSubmit)();
  };

  const onCancelSubmitted = (): void => {
    reset();
    dispatch(closeMaidEdit());
  };

  const openMaidDetails = (): void => {
    setMaidDetailsWidth(30);
  };

  const closeMaidDetails = (): void => {
    setMaidDetailsWidth(0);
    setMaidIdProof('');
  };

  const openMaidEdit = (): void => {
    dispatch(setMaidProperties(maid));
    dispatch(openMaidEditState());
  };

  const acceptSearch = (e: any): void => {
    // dispatch(changeSearch(e.target.value));
    setDisplaySearch(e.target.value);
    setSearchDetailsChanged(true); 
  };

  const acceptPage = (e: any): void => {
    dispatch(setCurrentPage(e));
  };

  const showIdProof = (image: string): void => {
    Store.removeNotification('id-proof');
    // remove notification doesnt immediately remove it.
    var interval = setInterval(function () {
      let idcheck = document.getElementById('id-dialogue');
      if (typeof idcheck != 'undefined' && idcheck != null) {
      } else {
        Store.addNotification({
          id: 'id-proof',
          container: 'center',
          animationIn: [],
          animationOut: [],
          dismiss: {
            duration: 1000000000,
            click: false,
            touch: false,
          },
          content: () => (
            <div id="id-dialogue" className="id-display-modal-main">
              <div className="box">
                <div className="row">
                  <div className="col id-proof-disp">
                    <label className="h5 page-heading">ID Proof</label>
                    <span
                      className="closebtn id-proof-image"
                      onClick={() => {
                        Store.removeNotification('id-proof');
                      }}
                    >
                      ×
                    </span>
                  </div>
                </div>
                <div className="row t-align-center">
                  <div className="col">
                    <div>
                      <img className="id-image" src={image} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),
        });
        clearInterval(interval);
      }
    }, 500);
  };

  // const handleapproveMaid = () => {
  //   var req: IHotelIdMaid = {
  //     id: maid.id,
  //     hotelId: curHotelOPt.value,
  //   };
  //   dispatch(approveMaid(req));
  // };
  // ---------multiSelect start--------------

  // const onSetMultiSearchText = (searchText: string) => {
  //   setMultiSearchText(searchText);
  // };

  // const onSetHotelList = (e: any, selectedHotels: IAutocompleteSelectOptionType[]) => {
  //   const hasAllOption = selectedHotels.some((hotel) => hotel.value === 'all');
  //   const selHasAllOption = hotelListObj.some((hotel) => hotel.value === 'all');
  //   let allOptions = inactiveHotel ? allHotelOptsInactive : activeHotel;
  //   let allSelOptions: IAutocompleteSelectOptionType[] = [];

  //   if (!hasAllOption && !selHasAllOption && allOptions.length == selectedHotels.length + 1) {
  //     console.log('In if 000');
  //     allOptions.map((item) =>
  //       allSelOptions.push({ value: item.value, label: item.label, active: true })
  //     );
  //   } else if (hasAllOption && !selHasAllOption) {
  //     console.log('In if 001');
  //     allOptions.map((item) =>
  //       allSelOptions.push({ value: item.value, label: item.label, active: true })
  //     );
  //   } else if (hasAllOption && allOptions.length == selectedHotels.length + 1) {
  //     console.log('In if 111');
  //     allOptions = selectedHotels.filter((item) => item.value != 'all');
  //   } else if (hasAllOption && allOptions.length == selectedHotels.length) {
  //     console.log('In if 222');
  //     allOptions.map((item) =>
  //       allSelOptions.push({ value: item.value, label: item.label, active: true })
  //     );
  //   } else {
  //     console.log('In if 333');
  //     if (allOptions.length == selectedHotels.length + 1) {
  //       allOptions = [];
  //     } else {
  //       allOptions = selectedHotels;
  //     }
  //   }
  //   let allHIds = allOptions.map((item: any) => item.value); 
  //   console.log('onSetHotelList',allOptions);
  //   setHotelListObj(allOptions);
  //   setHotelList(allHIds);
  //   setSelectedHotelId(allHIds.join(','));
  //   setLocalStorageHotelId(allHIds.join(','));
  //   closeMaidDetails(); 
  // };

  // React.useEffect(() => {
  //   let allOptions = inactiveHotel ? allHotelOptsInactive : activeHotel;
  //   let allSelOptions: IAutocompleteSelectOptionType[] = [];
  //   const selHasAllOption = hotelListObj.some((hotel) => hotel.value === 'all');
    
  //   allOptions.forEach((hItem) => {
  //     if (hotelListObj.some((hotel) => hotel.value === hItem.value)) {
  //       if((hItem.active && hItem.payout==PAYOUT_STAFF)) {
  //         if(selHasAllOption || hItem.value!='all') {
  //           allSelOptions.push(hItem);
  //         }
  //       }
  //       else if((inactiveHotel && includePayout)) {
  //         if(selHasAllOption || hItem.value!='all') {
  //           allSelOptions.push(hItem);
  //         }
  //       }
  //       else if(inactiveHotel && !hItem.active) {
  //         if(((includePayout) || (!includePayout && hItem.payout==PAYOUT_STAFF))) {
  //           if(selHasAllOption || hItem.value!='all') {
  //             allSelOptions.push(hItem);
  //           }
  //         }
  //       }
  //       else if(includePayout && hItem.payout==PAYOUT_HOTEL) {
  //         if(((inactiveHotel) || (!inactiveHotel && hItem.active))) {
  //           if(selHasAllOption || hItem.value!='all') {
  //             allSelOptions.push(hItem);
  //           }
  //         }
  //       }
  //     }
  //     else {
  //       if(inactiveHotel && !hItem.active) {
  //         if(((includePayout) || (!includePayout && hItem.payout==PAYOUT_STAFF))) {
  //           if(selHasAllOption || hItem.value!='all') {
  //             allSelOptions.push(hItem);
  //           }
  //         }
  //       }
  //       else if(includePayout && hItem.payout==PAYOUT_HOTEL) {
  //         console.log(hItem.label); 
  //         if(((inactiveHotel) || (!inactiveHotel && hItem.active))) {
  //           console.log(hItem.label); 
  //           if(selHasAllOption || hItem.value!='all') {
  //             console.log(hItem.label); 
  //             allSelOptions.push(hItem);
  //           }
  //         }
  //       }
  //     }
  //   });
  //   let allHIds = allSelOptions.map((item: any) => item.value); 
  //   setHotelListObj(allSelOptions);
  //   setHotelList(allHIds);
  //   setSelectedHotelId(allHIds.join(','));
  //   setLocalStorageHotelId(allHIds.join(','));
  // }, [inactiveHotel, includePayout]); 

  // const handleHotellist = () => {
  //   if (inactiveHotel) {
  //     if (includePayout) {
  //       // console.log(allHotelOptsInactive,"in");
  //       return allHotelOptsInactive;
  //     } else {
  //       let arr:IAutocompleteSelectOptionType[] = [];
  //       arr = allHotelOptsInactive.filter((item) => item.payout == PAYOUT_STAFF);
  //       // console.log(arr,"in1");

  //       return arr;
  //     }
  //   } else {
  //     if (includePayout) {
  //       // console.log(activeHotel,"nall");
  //       return activeHotel;
  //     } else {
  //       let arr:IAutocompleteSelectOptionType[] = [];
  //       arr = activeHotel.filter((item) => item.payout == PAYOUT_STAFF);
  //       // console.log(arr,"nall1");
  //       return arr;
  //     }
  //   }
  // };

  // ---------multiSelect--------------

  // const handleHotelFilterChange = (e: any): void => {
  //   acceptPage(1);
  //   // dispatch(changeHotelFilter(e.value));
  //   setSelectedHotelId(e.value);
  //   if (e.active) {
  //     setLocalStorageHotelId(e.value);
  //   }
  // };

  const onClickable = (id: number, name: string): void => {
    if (
      name === `maidName` ||
      name === `firstName` ||
      name === `lastName` ||
      name === `middleName`
    ) {
      setMaidIdProof('');
      clearMaidIDProofs();
      dispatch(getImageId(id));
      dispatch(fetchMaidDetails(id));
      setCurMaid(id);
      openMaidDetails();
      let maidDetails = maids.filter(item => item.id==id); 
      setCurStaffHotels(maidDetails[0]?.staffHotel ? maidDetails[0].staffHotel : []); 
      console.log('maidDetails: ',maidDetails[0].staffHotel); 
    }
  };

  const onDeleteVal = (id: number, name: string, maid: string): void => {
    if (name === 'deleteMaid') {
      onDeleteMaid(id, maid);
    }
  };

  const deleteMaidUser = (id: number): void => {
    (async () => {
      const delMaid = await dispatch(deleteMaid(id));
      Store.removeNotification('maid-modal');
      if (delMaid.meta.requestStatus === 'fulfilled') {

        callFetchMaids(); 

        let msgTitle = APP_NAME;
        let msgText = MSG_MAID_DELETED;
        let dismisObj = {
          duration: notification_shown_time_success,
          onScreen: true,
          click: true,
          showIcon: false,
        };
        Store.addNotification({
          title: msgTitle,
          message: msgText,
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 10000,
            onScreen: true,
            click: true,
            showIcon: true,
          },
        });
      }
    })();
  };
  const onDeleteMaid = (id: number, maid: string) => {
    Store.addNotification({
      title: 'Delete ' + LBL_MAID,
      id: 'maid-modal',
      content: (
        <div className="idproof-modal div-center">
          <div className="page-heading">
            <h4>Delete {LBL_MAID}</h4>
          </div>
          <div className="idproof-flex-center">
            Are you sure you want to delete {maid}?
            {/* from {' '} {curHotelOPt.map((item: any, index: any) => (index == 0 ? '' : ' , ') + item.label)} */}
            {/* {curHotelOPt.label} */} 
          </div>
          <div className="idproof-margin"></div>
          <div className="idproof-flex-center">
            <button
              type="button"
              className="primary margin-left-15px"
              onClick={() => Store.removeNotification('maid-modal')}
            >
              Cancel
            </button>
            <button
              type="button"
              className="danger margin-left-15px rounded-btn"
              onClick={() => deleteMaidUser(id)}
            >
              Remove
            </button>
          </div>
        </div>
      ),
      type: 'default',
      container: 'center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 1000000000,
        click: false,
        touch: false,
      },
    });
  };

  const onExport = (): void => {       
    let hIds = hotelList.join(',').replace('all,','');
    const hasAllOption = hotelList.some((hotel) => hotel === 'all');
    let hotelsList =
        (hasAllOption && inactiveHotel && includePayout)
          ? 'all' 
          : (hasAllOption && !inactiveHotel && includePayout)
            ? 'active_all'
              : hIds; 
    let searchStr = fetchWithFilter ? filterSearch : displaySearch;              
    const req: IMaidsRequestData = {
      hotel: hotelsList,
      page: currentPage,
      search: searchStr,
      document_verified: documentVerified,
      active: filterIsActive,
      joining_from_date: fromDate,
      joining_to_date: toDate,
      sort: sortField,
    };
    exportMaids(req);
  };

  const onW9Export = (): void => {
    if (curMaid != 0) {
      Store.addNotification({
        id: 'w9',
        content: (
          <div>
            <ReactLoading
              className="loader-center"
              color={'#2f427c'}
              type={'spokes'}
              width={'3vw'}
            />
          </div>
        ),
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 20000,
          onScreen: true,
        },
      });
      (async () => {
        const w9Data = await exportW9(curMaid);
        Store.removeNotification('w9');
        if (w9Data.status == 200) {
          let url = w9Data.data['w9'];
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'w9' + '.pdf');
          document.body.appendChild(link);
          link.click();
        }
      })();
    }
  };

  // ONLY CALLED WHEN ONLY ONE HOTEL IS SELECTED
  const postMaids = (e: any): void => {
    (async () => {
      let hId = hotelList.join(',').replace('all,','');
      const uploadStatus = await importMaids(hId, e.target.files[0]);
      // console.log('uploadStatus:: ',uploadStatus);
      if (uploadStatus?.status === 200) {

        callFetchMaids(); 

        let msgTitle = APP_NAME;
        let msgText = MSG_MAID_IMPORT_SUCCESS;
        let dismisObj = {
          duration: notification_shown_time_success,
          onScreen: true,
          click: true,
          showIcon: false,
        };

        if (
          uploadStatus.data.importedCount > 0 &&
          uploadStatus.data.importedCount === uploadStatus.data.totalCount
        ) {
          msgText =
            MSG_MAID_IMPORT_SUCCESS +
            ' ' +
            uploadStatus.data.importedCount +
            '/' +
            uploadStatus.data.totalCount +
            ' records imported. ';
          dismisObj = {
            duration: notification_shown_time_success,
            onScreen: true,
            click: false,
            showIcon: false,
          };
        } else if (
          uploadStatus.data.importedCount > 0 &&
          uploadStatus.data.notImportedNumbers.length > 0
        ) {
          let recText =
            uploadStatus.data.notImportedNumbers.length === 1 ? ' record ' : ' records ';
          msgText =
            uploadStatus.data.importedCount +
            '/' +
            uploadStatus.data.totalCount +
            ' records imported. The following ' +
            recText +
            ' not imported ' +
            uploadStatus.data.notImportedNumbers.map((item: any) => item).join(', ');
          dismisObj = {
            duration: 0,
            onScreen: true,
            click: true,
            showIcon: true,
          };
        } else if (uploadStatus.data.importedCount === 0) {
          msgText = MSG_NO_RECORDS_IMPORTED;
          dismisObj = {
            duration: notification_shown_time_success,
            onScreen: true,
            click: false,
            showIcon: false,
          };
        }

        Store.addNotification({
          title: msgTitle,
          message: msgText,
          type:
            uploadStatus.data.importedCount > 0 &&
            uploadStatus.data.importedCount === uploadStatus.data.totalCount
              ? 'success'
              : 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: dismisObj,
        });
      } else {
        if (uploadStatus.response.status === 400) {
          let msgTitle = MSG_INVALID_FILE_FORMAT;
          let msgText = MSG_INVALID_INPUT_FILE;
          let duration = notification_shown_time_failed;
          if (
            uploadStatus?.response?.data?.message &&
            uploadStatus?.response?.data?.message === 'invalid_headings'
          ) {
            msgText = MSG_FILEIMPORT_INVALID_HEADER;
          }

          Store.addNotification({
            title: msgTitle,
            message: msgText,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 0,
              onScreen: true,
              click: true,
              showIcon: true,
            },
          });
        }
      }
    })();
    inputFile!.current!.value = '';
  };

  const onImport = (): void => {
    inputFile?.current?.click();
  };

  const downloadSample = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    getSampleMaidImport();
  };

  // useEffect(() => {
  //   document
  //     .getElementsByClassName('react-select-maids__single-value')[0]
  //     .classList.add('color-light-grey');
  // }, []);

  function selectBoxOnFocus() {
    document
      .getElementsByClassName('react-select-maids__single-value')[0]
      .classList.add('color-grey');
    document
      .getElementsByClassName('react-select-maids__single-value')[0]
      .classList.remove('color-light-grey');
    console.log('allHotelOpts: ', allHotelOpts);
    console.log('curHotelOPt: ', curHotelOPt);
  }
  function selectBoxOnBlur() {
    document
      .getElementsByClassName('react-select-maids__single-value')[0]
      .classList.remove('color-grey');
    document
      .getElementsByClassName('react-select-maids__single-value')[0]
      .classList.add('color-light-grey');
  }

  const colorStyles = {
    option: (styles: any, data: any) => ({
      ...styles,
      color: data.data.active ? '' : 'red',
    }),
  };

  const checkDate = (dateValue: any, calledFrom: string) => {
    let arrTmp = dateValue.split('-');
    if (arrTmp.length == 3 && arrTmp[0].length > 4) {
      if (calledFrom == 'fromDate') {
        setfromDate(arrTmp[0].substring(0, 4) + '-' + arrTmp[1] + '-' + arrTmp[2]);
      } else if (calledFrom == 'toDate') {
        setToDate(arrTmp[0].substring(0, 4) + '-' + arrTmp[1] + '-' + arrTmp[2]);
      }
    } else {
      if (calledFrom == 'fromDate') {
        setfromDate(dateValue);
      } else if (calledFrom == 'toDate') {
        setToDate(dateValue);
      }
    }
  };

  const getIdProofDocName = (docTypeId: number) => {
    return docTypeId == STAFF_DOC_TYPE_PASSOPORT_ID
      ? STAFF_DOC_TYPE_PASSOPORT
      : docTypeId == STAFF_DOC_TYPE_PASSOPORT_CARD_ID
      ? STAFF_DOC_TYPE_PASSOPORT_CARD
      : docTypeId == STAFF_DOC_TYPE_DRIVER_LICENSE_ID
      ? STAFF_DOC_TYPE_DRIVER_LICENSE
      : docTypeId == STAFF_DOC_TYPE_STATE_ID_CARD_ID
      ? STAFF_DOC_TYPE_STATE_ID_CARD
      : docTypeId == STAFF_DOC_TYPE_GREEN_CARD_ID
      ? STAFF_DOC_TYPE_GREEN_CARD
      : docTypeId == STAFF_DOC_TYPE_BORDER_CROSSING_CARD_ID
      ? STAFF_DOC_TYPE_BORDER_CROSSING_CARD
      : docTypeId == STAFF_DOC_TYPE_CHILD_ID_CARD_ID
      ? STAFF_DOC_TYPE_CHILD_ID_CARD
      : docTypeId == STAFF_DOC_TYPE_NYC_CARD_ID
      ? STAFF_DOC_TYPE_NYC_CARD
      : docTypeId == STAFF_DOC_TYPE_US_VISA_CARD_ID
      ? STAFF_DOC_TYPE_US_VISA_CARD
      : docTypeId == null || docTypeId == undefined || !docTypeId
      ? null
      : docTypeId.toString();
  };

  React.useEffect(() => {
    if(curMaid > 0) { 
      if(curStaffHotels && curStaffHotels.length > 0) {
        let sHotel = curStaffHotels.filter(item => item.id==Number(hotelList[0])); 
        if(sHotel && sHotel.length > 0) {
          setShowStaffChangeStatus(true);  
          setCurMaidActive(sHotel[0].isActive); 
        }
        else {
          setShowStaffChangeStatus(false);  
          setCurMaidActive(false); 
        }
      }
      else {
        setShowStaffChangeStatus(false);  
        setCurMaidActive(false); 
      }
    }
    else { 
      setShowStaffChangeStatus(false);  
      setCurMaidActive(false); 
    }
  }, [curMaid]);

 

  const changeStatus = (actString:string) => {
    if(curStaffHotels && curStaffHotels.length > 0) {
      let sHotel = curStaffHotels.filter(item => item.id==Number(hotelList[0])); 
      if(sHotel && sHotel.length > 0) {
        Store.addNotification({
          title: actString + ' ' + LBL_MAID,
          id: 'staff-status-modal',
          content: (
            <div className="idproof-modal div-center">
              <div className="page-heading">
                <h4>{actString +' '+LBL_MAID}</h4>
              </div>
              <div className="idproof-flex-center">
                {actString=='Deactivate' ? ('Are you sure you want to disconnet '+LBL_MAID.toLowerCase()+' from '+sHotel[0].hotelName) : ('Are you sure you want to connet '+LBL_MAID.toLowerCase()+' with '+sHotel[0].hotelName) }
              </div>
              <div className="idproof-margin"></div>
              <div className="idproof-flex-center">
                <button
                  type="button"
                  className="primary margin-left-15px"
                  onClick={() => Store.removeNotification('staff-status-modal')}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={actString=='Deactivate' ? "danger margin-left-15px rounded-btn" : "bg-success  margin-left-15px rounded-btn"}
                  onClick={() => {
                    Store.removeNotification('staff-status-modal');
                    onChangeStatus({staffId:curMaid, hotelId:Number(hotelList[0])}) 
                  }
                  }
                >
                  {actString}
                </button>
              </div>
            </div>
          ),
          type: 'default',
          container: 'center',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 1000000000,
            click: false,
            touch: false,
          },
        });
      }
      else {
        return false; 
      }
    }
  }

  const onChangeStatus = (staffHotel:IStaffHotelStatus) => {
    dispatch(changeStaffStatus(staffHotel)); 
    closeMaidDetails(); 
    let msgTitle = APP_NAME;
    let msgText = MSG_MAID_STATUS_UPDATED;
    let dismisObj = {
      duration: notification_shown_time_success,
      onScreen: true,
      click: true,
      showIcon: false,
    };
    Store.addNotification({
      title: msgTitle,
      message: msgText,
      type: 'success',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 10000,
        onScreen: true,
        click: true,
        showIcon: true,
      },
    });
    setTimeout(() => {
      callFetchMaids(); 
    }, 400);
  }

  return (
    <>
      <div className="header-content-container">
        <div className="box">
          <div className="row">
            <div className="col-12 maid-list-container ">
              <div className="hotel-select-bar  col-8">
                <h4 className="page-heading div-left padding-top6">{LBL_MAIDS} list of</h4>
                {curHotelOPt !== ({} as IAutocompleteSelectOptionType[]) && (
                  <>
                    <div className="div-left col-6 ml-10px ">
                      {/* <Autocomplete
                        onBlur={(e) => onSetMultiSearchText('')}
                        sx={{ height: 'auto' }}
                        multiple
                        limitTags={1}
                        disableCloseOnSelect={true}
                        id="multiple-limit-tags"
                        options={handleHotellist()}
                        // options={inactiveHotel ? allHotelOptsInactive : activeHotel}
                        className="select_checkbox border-one"
                        getOptionLabel={(option) => option.label}
                        // getOptionDisabled={(option) =>
                        //   option === activeHotel[0] || option === activeHotel[2]
                        // }
                        // defaultValue={[allHotelOpts[0]]}
                        value={hotelListObj}
                        inputValue={multiSearchText}
                        onChange={(e: any, newValue: any) => {
                          onSetHotelList(e, newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox style={{ marginRight: 8 }} checked={selected} />
                            {option.label} 
                          </li>
                        )}
                        renderInput={(params) =>
                          props.editId > 0 ? (
                            <TextField
                              {...params}
                              size="small"
                              onChange={(e) => onSetMultiSearchText(e.target.value)}
                            />
                          ) : (
                            <TextField
                              {...params}
                              size="small"
                              required
                              onChange={(e) => onSetMultiSearchText(e.target.value)}
                            />
                          )
                        }
                      /> */}

                      <MultiSelect 
                        getHotellist={setHotelList} 
                        getIncludeInactiveStatus={setInactiveHotel} 
                        getIncludePayoutStatus={setIncludePayout} 
                        includePayout={true} 
                      />
{/* 
                      <div className="my-2 gap-5 flex">
                        <div>
                          <input
                            type="checkbox"
                            id="chk"
                            onChange={(e) => setInactiveHotel(e.target.checked)}
                            checked={inactiveHotel}
                            className=""
                          />{' '}
                          <label className="div-label-small" htmlFor="chk">
                            {LBL_INCLUDE_INACTIVE_HOTELS}
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="chkbox"
                            onChange={(e) => setIncludePayout(e.target.checked)}
                            checked={includePayout}
                            className=""
                          />{' '}
                          <label className="div-label-small" htmlFor="chkbox">
                            {LBL_INCLUDE_PAYOUTS}
                          </label>
                        </div>
                      </div> */}
                    </div>
                    {/* <Select
                      onChange={handleHotelFilterChange}
                      name="hotelName"
                      onFocus={selectBoxOnFocus}
                      onBlur={selectBoxOnBlur}
                      className="margin-left-15px div-left"
                      classNamePrefix="react-select-maids"
                      options={inactiveHotel ? allHotelOpts : activeHotel}
                      value={curHotelOPt}
                      isSearchable={true}
                      styles={colorStyles}
                    /> */}
                  </>
                )}
              </div>

              {/* {!isHotelActive && <div className="div-right warning-text">{LBL_HOTEL_INACTIVE}</div>} */}

            </div>
          </div>
        </div>
      </div>

      <div className="content_container">
        <div className="box content-box">
          <div className="justify-space-between">
            <div className="justify-left">
              <div>
                <SearchBar
                  onSearch={acceptSearch}
                  currentSearch={displaySearch}
                  place_holder="&#xf002; Search name or mobile number"
                  searchButton={true}
                  onSearchButton={onSearchButton}
                />
              </div>
              <div className="circlediv">
                <div
                  className={`circle pointer ${fetchWithFilter ? 'bluebackGround' : null}`}
                  onClick={handleFilter}
                >
                  <i className="fa  fa-filter"></i>
                </div>
              </div>
              <span className="mt-10px ml-9px link-resent-m" onClick={handleResetFilter}>
                {LBL_RESET_FILTER}
              </span>
            </div>

            <div className="justify-end">
              <input
                type="file"
                onChange={postMaids}
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
              />
              <button
                type="button"
                className="primary csv-button"
                onMouseEnter={() => setLogoState(false)}
                onMouseLeave={() => setLogoState(true)}
                onClick={downloadSample}
                title={'CSV template for importing ' + LBL_MAIDS + '.'}
              >
                <img
                  src={logoState ? logo : logo1}
                  className="info-sample invert-filter "
                  title={'CSV template for importing ' + LBL_MAIDS + '.'}
                ></img>
              </button>

              {hotelList && hotelList.length==1 && <button type="button" className="primary margin-left-15px" onClick={onImport}>
                Import {LBL_MAIDS}
              </button>}

              <button type="button" className="primary margin-left-15px" onClick={onExport}>
                Export {LBL_MAIDS}
              </button>
            </div>
          </div>
          <div className=" table-wrapper">
            <Table
              handleSort={handleSort}
              titles={maidListTableFields}
              data={maids}
              isLoading={loading === `loading` ? true : false}
              onClickable={onClickable}
              currentPage={0}
              onDeleteVal={onDeleteVal}
              sortedFieldName={sortField}
            />
            <div className="justify-right">
              { maids.length >0 &&
                <div className="pagination">
                <Pagination
                  isTableLoading={loading === `loading` ? true : false}
                  totalCount={totalSize}
                  currentPage={currentPage}
                  setCurrentPage={acceptPage}
                  pageSize={20}
                  siblingCount={2}
                />
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      {/* <div id="sliderTransactions" className="sidenav" style={{ width: '0%' }}>
        <div className="box">
          <div className="row">
            <div className="col">
              <label className="h5 page-heading">James Smith</label>
              <span className="closebtn">×</span>
            </div>
          </div>
          <div className="content_header">
            <label className="div-label  font-bold">Transaction Details</label>
          </div>
          <div className="box border">
            <label className="color-blue"></label>
            <div className="mb-2">
              <label className="color-blue font-bold"></label>
            </div>
            <label className="color-blue"></label>
            <div className="mb-2">
              <label className="color-blue font-bold"></label>
            </div>
            <label className="color-blue"></label>
            <div className="mb-2">
              <label className="color-blue font-bold"></label>
            </div>
          </div>
        </div>
      </div> */}

      <div id="sliderMaidDetails" className="sidenav" style={{ width: maidDetailsWidth + '%' }}>
        <div className="box">
          <div className="row">
            <div className="col">
              <label className="h5 page-heading">
                {maid.firstName && maid.firstName != 'undefined'
                  ? `${maid.firstName} ${maid.middleName} ${maid.lastName} `
                  : ''}
              </label>
              <div className="closebtn" onClick={closeMaidDetails}>
                ×
              </div>
            </div>
          </div>
          <div className="content_header">
            <div className="downoad-qr">
              <label className="div-label  font-bold">Contact Details</label>
              <img
                className="edit-icon pointer-cursor"
                src={editImage}
                onClick={() => openMaidEdit()}
              />
            </div>
          </div>
          {isMaidEditShown && (
            <form autoComplete="off">
              <div className="box border">
                <label className="div-label  ">First Name</label>
                <div className="mb-2">
                  <input
                    {...register('firstName', { required })}
                    name="firstName"
                    className={errors.firstName ? 'input-error w-100px' : 'w-100px'}
                    type="text"
                  ></input>
                  {errors.firstName && <span className="danger">{errors.firstName.message}</span>}
                </div>
                <label className="div-label  ">Middle Name</label>
                <div className="mb-2">
                  <input
                    {...register('middleName')}
                    name="middleName"
                    className="w-100px"
                    type="text"
                  ></input>
                </div>
                <label className="div-label  ">Last Name</label>
                <div className="mb-2">
                  <input
                    {...register('lastName', { required })}
                    name="lastName"
                    className={errors.lastName ? 'input-error w-100px' : 'w-100px'}
                    type="text"
                  ></input>
                  {errors.lastName && <span className="danger">{errors.lastName.message}</span>}
                </div>

                <label className="div-label  ">Email Address</label>
                <div className="mb-2">
                  <input
                    {...register('email', { required })}
                    name="email"
                    className={errors.email ? 'input-error w-100px' : 'w-100px'}
                    type="email"
                  ></input>
                  {errors.email && <span className="danger">{errors.email.message}</span>}
                </div>
                <label className="div-label ">Mobile Number</label>
                <div className="mb-2">
                  <PhoneInput
                    inputProps={{
                      name: 'mobileNumberPhoneInput',
                      required: true,
                      autoFocus: false,
                    }}
                    containerClass="w-100px"
                    inputClass="tel-input"
                    onlyCountries={['us', 'in']}
                    country={'us'}
                    value={selectedMobile}
                    onChange={(code) => updateFieldMobile(code)}
                  />
                </div>
                {errors.mobileNumber && (
                  <span className="danger">{errors.mobileNumber.message}</span>
                )}
                <label className="div-label  ">Alternative Mobile Number</label>
                <div className="mb-2">
                  <PhoneInput
                    inputProps={{
                      name: 'alternativeMobileNumberPhoneInput',
                      required: false,
                      autoFocus: false,
                    }}
                    inputClass="tel-input"
                    onlyCountries={['us', 'in']}
                    country={'us'}
                    value={selectedAlternative}
                    onChange={(code) => updateFieldAltMobile(code)}
                  />
                  {/* {errors.alternativeMobileNumber && (
                    <span className="danger">{errors.alternativeMobileNumber.message}</span>
                  )} */}
                </div>
                <div>
                  <button type="button" className="primary" onClick={() => onSaveSubmitted()}>
                    Save Details
                  </button>
                  <button
                    type="button"
                    className="primary margin-left-15px"
                    onClick={() => onCancelSubmitted()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}

          {!isMaidEditShown && (
            <div className="box border">
              <label className="div-label ">Email Address</label>
              <div className="mb-2">
                <label className="font-bold">{maid.email}</label>
              </div>
              <label className="div-label ">Mobile Number</label>
              <div className="mb-2">
                <label className=" font-bold">
                  <PhoneInput
                    disabled={true}
                    buttonClass="hide"
                    containerClass="border-none tel-maid m8"
                    inputClass="border-none tel-maid"
                    value={maid.mobileNumber}
                  />
                </label>
              </div>
              <label className="div-label ">Alternative Mobile Number</label>
              <div className="mb-2">
                <label className="font-bold">
                  <PhoneInput
                    disabled={true}
                    buttonClass="hide"
                    containerClass="border-none tel-maid m8"
                    inputClass="border-none tel-maid"
                    value={maid.alternativeMobileNumber}
                    placeholder={' '}
                  />
                </label>
              </div>
            </div>
          )}

          <div className="content_header mt-4">
            <label className="div-label font-bold">Address</label>
          </div>
          <div className="box border">
            <label className="div-label ">Address</label>
            <div className="mb-2">
              <label className=" font-bold">{maid.address}</label>
            </div>
            <label className="div-label ">City</label>
            <div className="mb-2">
              <label className=" font-bold">{maid.city}</label>
            </div>
            <label className="div-label ">State</label>
            <div className="mb-2">
              <label className=" font-bold">{maid.state}</label>
            </div>
            <label className="div-label ">Country</label>
            <div className="mb-2">
              <label className=" font-bold">{maid.country}</label>
            </div>
            <label className="div-label ">Postal code</label>
            <div className="mb-2">
              <label className=" font-bold">{maid.zip}</label>
            </div>
          </div>

          <div className="content_header mt-4">
            <div className="downoad-qr">
              <label className="div-label  font-bold">Bank Details</label>
              <img
                className="edit-icon pointer-cursor"
                src={editImage}
                onClick={() => openMaidEdit()}
              />
            </div>
          </div>

          <div className="box border">
            {/* <label className="div-label ">Bank Account Payee Name</label>
            <div className="mb-2">
              <label className=" font-bold">{maid.accountHolderName}</label>
            </div>
            <label className="div-label ">Mobile Number</label>
            <div className="mb-2">
              <label className=" font-bold">
                <PhoneInput
                  disabled={true}
                  buttonClass='hide'
                  containerClass="border-none tel-maid m8"
                  inputClass="border-none tel-maid"
                  value={maid.mobileNumber}
                />
              </label>
            </div>
            <label className="div-label ">Bank Account Number</label>
            <div className="mb-2">
              <label className=" font-bold">{maid.accountNumber}</label>
            </div>
            <label className="div-label ">Bank Name</label>
            <div className="mb-2">
              <label className=" font-bold">{maid.bankName}</label>
            </div>
            <label className="div-label ">ABA/ ACH Routing Code</label> */}
            <div className="mb-2">
              {/* <label className=" font-bold">{maid.routingNumber}</label> */}

              {/* ***************** PAYMENT METHOD - START  ***********************/}

              {!isMaidEditShown && (
                <div>
                  <label className="">Payment Method</label>
                  <div className="mb-3">
                    <label className=" font-bold">
                      {maid.paymentMethod == STAFF_PAYMENT_METHOD_CHECK
                        ? LBL_STAFF_PAYMENT_METHOD_CHECK
                        : LBL_STAFF_PAYMENT_METHOD_BANK}
                    </label>
                  </div>
                  {selectedPaymentMethod != STAFF_PAYMENT_METHOD_CHECK && (
                    <div>
                      <label>Bank Account Payee Name</label>
                      <div className="mb-2">
                        <label className=" font-bold">{maid.accountHolderName}</label>
                      </div>
                      <label>Bank Account Number</label>
                      <div className="mb-2">
                        <label className=" font-bold">{maid.accountNumber}</label>
                      </div>
                      <label>Bank Name</label>
                      <div className="mb-2">
                        <label className=" font-bold">{maid.bankName}</label>
                      </div>
                      <label>ABA/ ACH Routing Code</label>
                      <div className="mb-2">
                        <label className=" font-bold">{maid.routingNumber}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {isMaidEditShown && (
                <div>
                  <label>Payment Method</label>
                  <div className="mb-3">
                    <Select
                      onChange={handlePaymentOptionChange}
                      name="paymentMethod"
                      onFocus={selectBoxOnFocus}
                      onBlur={selectBoxOnBlur}
                      className=""
                      classNamePrefix="react-select-maids"
                      options={allPaymentOptions}
                      value={selectedPaymentOption}
                      isSearchable={true}
                    />
                  </div>

                  {selectedPaymentMethod != STAFF_PAYMENT_METHOD_CHECK && (
                    <div>
                      <label>Bank Account Payee Name</label>
                      <div className="mb-2">
                        <input
                          {...register('accountHolderName', { required: 'Required.' })}
                          name="accountHolderName"
                          type="text"
                          className="min-width-20"
                        ></input>
                        {errors.accountHolderName && (
                          <span className="danger">{errors.accountHolderName.message}</span>
                        )}
                      </div>
                      <label className="">Bank Account Number</label>
                      <div className="mb-2">
                        <input
                          {...register('accountNumber', { required: 'Required.' })}
                          name="accountNumber"
                          type="text"
                          className="min-width-20"
                        ></input>
                        {errors.accountNumber && (
                          <span className="danger">{errors.accountNumber.message}</span>
                        )}
                      </div>
                      <label className="">Bank Name</label>
                      <div className="mb-2">
                        <input
                          {...register('bankName', { required: 'Required.' })}
                          name="bankName"
                          type="text"
                          className="min-width-20"
                        ></input>
                        {errors.bankName && (
                          <span className="danger">{errors.bankName.message}</span>
                        )}
                      </div>
                      <label className="">ABA/ ACH Routing Code</label>
                      <div className="mb-2">
                        <input
                          {...register('routingNumber', { required: 'Required.' })}
                          name="routingNumber"
                          type="text"
                          className="min-width-20"
                        ></input>
                        {errors.routingNumber && (
                          <span className="danger">{errors.routingNumber.message}</span>
                        )}
                      </div>
                      <div className="mb-1">&nbsp;</div>
                    </div>
                  )}

                  <div>
                    <button type="button" className="primary" onClick={() => onCancelSubmitted()}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="primary margin-left-15px"
                      onClick={() => onSaveSubmitted()}
                    >
                      Save Details
                    </button>
                  </div>
                </div>
              )}
              {/* ***************** PAYMENT METHOD - END  ***********************/}

              <div className="mt-4 mb-2">
                <label className="div-label font-bold font-bold">ID Proof</label>
              </div>
              {idProofStatus == 'succeeded' && idProofs != undefined && idProofs.length > 0 && (
                <>
                  <label>ID Proof Type</label>
                  <div className="mb-2">
                    <label className=" font-bold">{getIdProofDocName(maid.documentTypeId)}</label>
                  </div>
                </>
              )}

              <div className="mt-1 mb-2">
                {idProofStatus == 'succeeded' && idProofs != undefined && (
                  <>
                    {idProofs.map((imageData, i) => (
                      <>
                        <img
                          key={i}
                          className="pointer-cursor image-box-idproof"
                          onClick={() => showIdProof(imageData.image)}
                          id="maidIdProof"
                          src={`${imageData.image}`}
                          alt="Id proof"
                        />
                        <div className="mt-10px"></div>
                      </>
                    ))}
                    {idProofs.length == 0 && <p className="div-label "> Id proof not uploaded.</p>}
                  </>
                )}
                {idProofStatus == 'succeeded' && idProofs == undefined && (
                  <p className="div-label "> Id proof not uploaded.</p>
                )}

                {idProofStatus == 'error' && <p className="div-label "> Id proof not uploaded.</p>}
                {idProofStatus == 'idle' && <p className="div-label "> Id proof not uploaded.</p>}
                {idProofStatus == 'loading' && (
                  <p>
                    <ReactLoading className="" color={'#2f427c'} type={'bars'} width={'3vw'} />
                  </p>
                )}
              </div>

              {idProofStatus != 'error' && idProofs.length > 0 && (
                <div className="mb-2">
                  <div className="">
                    <label className="div-label ">{LBL_DOCS_VERIFIED_BY_STRIPE}</label>
                  </div>
                  <div className="mb-2">
                    <label className=" font-bold">{maid.documentVerified ? 'Yes' : 'No'}</label>
                  </div>
                </div>
              )}

              <div className="">
                {/* <div>
                  {!maid.isAdminApproved && (
                    <button onClick={handleapproveMaid} className="primary mt-2">
                      Approve
                    </button>
                  )}
                  {maid.isAdminApproved && <p className="slider-label padding-top-8">Approved</p>}
                </div> */}
                {/* <div className="justify-right">
                  {maid.isW9Generated && (
                    <div>
                      <a
                        onClick={onW9Export}
                        className="link row slider-label pointer-cursor padding-right-12"
                      >
                        Download W9 Form
                      </a>
                    </div>
                  )}
                  {!maid.isW9Generated && (
                    <div className="link slider-label row justify-right">
                      W-9 Form Not Generated
                    </div>
                  )}
                </div> */}
                <div className="">
                  {maid.isW9Generated && (
                    <div className="link row slider-label" onClick={onW9Export}>
                      <label className="text-link pointer-cursor">Download W9 Form</label>
                    </div>
                  )}
                  {!maid.isW9Generated && (
                    <div className="link slider-label row">
                      <label className="">W-9 Form Not Generated</label>
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                {maid.isW9Generated && (
                  <div className="row">
                    <label className="">
                      Last Updated {dayjs(maid.w9LastUpdate).format('MM/DD/YYYY HH:mm a')}
                    </label>
                  </div>
                )}
              </div>

              {/* <div className="row justify-right padding-right-12">
                {maid.isW9Generated && (
                  <div className="row justify-right padding-right-12">
                    Last Updated {dayjs(maid.w9LastUpdate).format('MM/DD/YYYY HH:mm a')}
                  </div>
                )}
              </div> */}

              <div className="row justify-right">

                {hotelList.length==1 && showStaffChangeStatus && <div className="col slider-close-button div-left">
                  <button type="button" className={curMaidActive ? "rounded-btn bg-danger" : "rounded-btn bg-success"} onClick={() => changeStatus(curMaidActive ? 'Deactivate' : 'Activate')}>
                    {curMaidActive ? 'Deactivate' : 'Activate'}
                  </button>
                </div>}

                <div className="col slider-close-button justify-right">
                  <button type="button" className="primary" onClick={() => closeMaidDetails()}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ******************************************************* */}
      {/* **************** FILTER SLIDER - START **************** */}
      <div id="filterHotelDetails" className={`sidenav ${filterSideBar ? 'show_sidenav' : 'hide'}`}>
        <div className="box">
          <div className="row">
            <div className="col">
              <label className="h5 page-heading">Filter {LBL_MAID} Details</label>
              <span className="closebtn" onClick={closeFilterDetails}>
                ×
              </span>
            </div>
          </div>

          <form autoComplete="off" onSubmit={(e) => handleApplyFilter(e)}>
            <div className="box border  mt-20px">
              <div className="">
                <label className="div-label font-bold mt-20px ">Name / Mobile number</label>
                <div className="mb-2">
                  <input
                    value={filterSearch}
                    name="filter_search_string"
                    type="text"
                    onChange={(e: any) => setFilterSearch(e.target.value)}
                    className="w-100px max-width-100"
                  ></input>
                </div>
              </div>

              <label htmlFor="activeDetail" className="div-label font-bold mt-20px ">
                Document Verified
              </label>
              <div className="mb-2">
                <select
                  name="active"
                  id="activeDetail"
                  className="w-100px"
                  value={documentVerified}
                  defaultValue={'Yes'}
                  onChange={(e: any) => setDocumentVerified(e.target.value)}
                >
                  <option value="All">All </option>
                  <option value="Yes">Yes </option>
                  <option value="No">No</option>
                </select>
              </div>

              <label htmlFor="filterIsActive" className="div-label font-bold mt-20px ">
                Active
              </label>
              <div className="mb-2">
                <select
                  name="filterIsActive"
                  id="filterIsActive"
                  className="w-100px"
                  value={filterIsActive}
                  defaultValue={'Yes'}
                  onChange={(e: any) => setFilterIsActive(e.target.value)}
                >
                  <option value="All">All </option>
                  <option value="Yes">Yes </option>
                  <option value="No">No</option>
                </select>
              </div>

              <div className="mb-20px flex">
                <div className="display-column w-100px">
                  <label className="div-label font-bold mt-20px ">Joining Date From</label>
                  <input
                    value={fromDate}
                    name="From_Date"
                    placeholder="From Date"
                    type="date"
                    className="w-100px"
                    onChange={(e) => checkDate(e.target.value, 'fromDate')}
                  ></input>
                </div>
                <div className="display-column w-100px ml-27px">
                  <label className="div-label font-bold mt-20px ">Date To</label>
                  <input
                    value={toDate}
                    min={fromDate}
                    placeholder="To Date"
                    className="w-100px"
                    name="To_Date"
                    type="date"
                    onChange={(e) => checkDate(e.target.value, 'toDate')}
                  ></input>
                </div>
              </div>

              <div className="mb-43 justify-right">
                <button
                  type="button"
                  className="primary mt-30px"
                  onClick={(e) => handleApplyFilter(e)}
                >
                  Apply
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* ***************** FILTER SLIDER - END ***************** */}
      {/* ******************************************************* */}
    </>
  );
};
