import { LBL_HOTELS, LBL_HOTEL_PAYMENTS, LBL_MAIDS, LBL_MAID_PAYMENTS, LBL_SETTINGS, LBL_TIPS, LBL_ASSOCIATES, LBL_MAID, LBL_PROCESS_PAYMENTS } from './common_labels';
import { USER_ROLE_ADMIN_SUPERADMIN, USER_ROLE_ADMIN_ADMIN } from './env'; 

// PERMISSIONS FOR ACCESS TO PAGES AND ACTIONS 

// ******************* PAGE ACCESS PERMISSIONS **************************
export const ARR_ADMIN_USER_PAGES = [LBL_HOTELS, LBL_HOTEL_PAYMENTS, LBL_MAIDS, LBL_MAID_PAYMENTS, LBL_SETTINGS, LBL_TIPS, LBL_ASSOCIATES, LBL_PROCESS_PAYMENTS];  

export const checkPagePermission = (role: any, pageName: string) => {
    if(role==USER_ROLE_ADMIN_SUPERADMIN.toString()) {
        return true; 
    }
    else if(role==USER_ROLE_ADMIN_ADMIN.toString()) {
        return ARR_ADMIN_USER_PAGES.includes(pageName); 
    }
    else {
        return false; 
    }
};

// ******************* ACTION PERMISSIONS **************************
// export const PERM_MAID_VIEW          = "View "+LBL_MAID+" Details"; 
// export const PERM_MAID_CSV           = "Download "+LBL_MAID+" CSV File"; 
// export const PERM_MAID_IMPORT        = "Import "+LBL_MAIDs; 
// export const PERM_MAID_EXPORT        = "Export "+LBL_MAIDs; 
// export const PERM_MAID_EDIT          = "Edit "+LBL_MAID+" Details"; 
// export const PERM_MAID_APPROVE       = "Approve "+LBL_MAID; 
// export const PERM_MAID_W9_DOWNLOAD   = "Download W9 From"; 
// export const PERM_MAID_DELETE        = "Delete "+LBL_MAID; 

// export const PERM_TIPS_EXPORT        = "Export Tips"; 
// export const PERM_TIPS_ACTION        = "Tips Action Menu"; 
export const PERM_TIPS_REFUND           = "Tip Refund"; 

// export const PERM_HOTEL_PAYMENTS_EXPORT = "Export Commission";  // COMMISSION 

// export const PERM_MAID_PAYMENTS_VIEW    = "View "+LBL_MAID+" Payments Details"; 
// export const PERM_MAID_PAYMENTS_CSV     = "Download "+LBL_MAID+" Payments CSV File"; 
// export const PERM_MAID_PAYMENTS_IMPORT  = "Import "+LBL_MAID+" Payments"; 
// export const PERM_MAID_PAYMENTS_EXPORT  = "Export "+LBL_MAID+" Payments"; 

export const PERM_VIEW_USERS   = "View Users"; 
export const PERM_ADD_USER     = "Add User"; 
export const PERM_EDIT_USER    = "Edit User"; 

export const PERM_EDIT_HOTEL_DETAILS = "Edit Hotel Details"; 
export const PERM_PROCESS_PAYMENTS = LBL_PROCESS_PAYMENTS; 

export const ARR_ADMIN_USER_ACTIONS = [PERM_VIEW_USERS, PERM_PROCESS_PAYMENTS];   

export const checkActionPermission = (role: any, permission: string) => {
    if(role==USER_ROLE_ADMIN_SUPERADMIN.toString()) {
        return true; 
    }
    else if(role==USER_ROLE_ADMIN_ADMIN.toString()) {
        return ARR_ADMIN_USER_ACTIONS.includes(permission); 
    }
    else {
        return false; 
    }
};
