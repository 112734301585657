import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { PaymentsLogFields } from '../../config/tables';
import { selectPaymentListDataSlice, setDataLoading } from './paymentListDataSlice';
import { IPaymentLogListRequestData } from '../../models/paymentLogInterfaces';
import {
  setCurrentPage,
  setTotalPage,
  setStartLoading,
  setStopLoading,
  selectHotelListPaginationSlice,
} from '../HotelList/hotelListPaginationSlice';
import { changeSearch, selectHotelListSearchSlice } from '../HotelList/hotelListSearchSlice';
import AddPageHeader from '../../components/AddPageHeader';
import logo from './../../assets/img/tova_stack.png';

import { fetchPaymentLog } from '../../services/hotelService';
import { PAGINATION_NUM_ROWS } from '../../config/env';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';

export const PaymentLog = (props: any) => {
  const { paymentlogs, totalSize, loading } = useAppSelector(selectPaymentListDataSlice);
  const [listFetchHotelPromise, setListFetchHotelPromise] = useState<Array<any>>([]);
  const [displaySearch, setDisplaySearch] = useState<string>('');
  const [appLogo, setAppLogo] = useState<boolean>(true);
  const [smallHeader, setSmallHeader] = useState<boolean>(false);
  const [contentTitle, setContentTitle] = useState<string>('Payment Log');
  const dispatch = useAppDispatch();
  const [applySearch, setApplySearch] = useState(0);
  const { searchValue } = useAppSelector(selectHotelListSearchSlice);
  const [paymentLogsList, setPaymentLogsList] = useState<any>([]);
  const [nextPage, setNextPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const scollToRef = useRef<HTMLDivElement>(null);

  const fetchall = (): void => {
    let sVal = searchValue;
    // if (displaySearch.length > 2) {
    //   dispatch(setDataLoading());
    //   changeSearch(displaySearch);
    // }
    sVal = displaySearch;
    // listFetchHotelPromise.forEach((p) => {
    //   p.abort();
    // });

    if (hasMore || nextPage == 1) {
      const req: IPaymentLogListRequestData = {
        page: nextPage,
        search: sVal,
      };
      dispatch(fetchPaymentLog(req));
      // const promise = dispatch(fetchPaymentLog(req));
      // setListFetchHotelPromise([promise]);
    }
  };

  const loadNextPage = (e: any): void => {
    fetchall();
    // setEmailLogsList([...emailLogsList, ...emailLogs]);
  };

  React.useEffect(() => {
    fetchall();
  }, []); // displaySearch

  React.useEffect(() => {
    if (loading == 'succeeded') {
      setPaymentLogsList([...paymentLogsList, ...paymentlogs]);     
      if (totalSize > nextPage * PAGINATION_NUM_ROWS) {
        setNextPage(nextPage + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [loading]);

  return (
    <div className="content-box table-wrapper">
      <div className="content_header">
        <div className="justify-space-between">
          <div className="justify-left">
            {appLogo && (
              <a className="text-white hotel-logo-right" href="#">
                <img src={logo} alt="logo" className="small headerSmall" />
              </a>
            )}
            <div>
              <div>
                {!smallHeader && <h1 className="font-bold color-blue">{contentTitle}</h1>}
                {smallHeader && (
                  <h3 className="font-bold color-blue margin-top10 margin-left-15px ">
                    {contentTitle}
                  </h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="m-5">
        <InfiniteScroll pageStart={1} loadMore={loadNextPage} hasMore={hasMore} threshold={2} >
          <Table
            titles={PaymentsLogFields}
            data={paymentLogsList}
            isLoading={loading === `loading` ? true : false}
            currentPage={0}
          />
      <div className="m-3">&nbsp;</div>
      <div className="m-3">&nbsp;</div>
      <div className="m-3">&nbsp;</div>
      <div className="m-3">&nbsp;</div>
       
        </InfiniteScroll>
      </div>
    </div>
  );
};
