import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHotelAssociate, IHotelAssociateCreate } from '../../models/hotelAssociateInterfaces';
import { AdminAssociateUsernameSearch, fetchHotelAssociates } from '../../services/hotelAssociateService';
import { IGenericResponse, IPagination } from '../../services/interfaces';

export interface DataState {
  associates: IHotelAssociate[];
  associatesUser: IHotelAssociateCreate;
  loading: 'idle' | 'loading' | 'succeeded' | 'error';
  assocUserloading: 'idle' | 'loading' | 'succeeded' | 'error';
  error: string | undefined;
}

const initialState: DataState = {
  associates: [],
  associatesUser: {
    hotelId: "", 
    firstName: "",
    lastName: "",
    username: "",
    mobile: "",
    position: "",
    role: 0,
    hotelList: [],
    isActive: true,
  },
  loading: 'idle',
  assocUserloading: 'idle',
  error: '',
};

export const hotelAssociatesListDataSlice = createSlice({
  name: 'hotelAssociatesListData',
  initialState,
  reducers: {
    setHotelAssociatesList: (state, action: PayloadAction<any>) => {
      state.associates = action.payload;
    },
    setDataLoading: (state) => {
      state.loading = 'loading';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHotelAssociates.pending, (state) => {
      state.associates = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchHotelAssociates.fulfilled, (state, action) => {
      const m = action.payload as IHotelAssociate[];
      console.log('fetchHotelAssociates.fulfilled: ',m); 
      state.associates = m;
      state.loading = 'succeeded';
      console.log('fetchHotelAssociates.fulfilled: state.associates: ',state.associates); 
    });
    builder.addCase(fetchHotelAssociates.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
    builder.addCase(AdminAssociateUsernameSearch.pending, (state) => {
      // state.loading = 'loading';
      state.assocUserloading = 'loading';
    });
    builder.addCase(AdminAssociateUsernameSearch.fulfilled, (state, action) => {
      const m = action.payload as IHotelAssociateCreate;
      state.associatesUser = m;
      state.loading = 'succeeded';
      state.assocUserloading = 'succeeded';
    });
    builder.addCase(AdminAssociateUsernameSearch.rejected, (state, { payload }: any) => {
      state.loading = 'error';
      // state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setHotelAssociatesList, setDataLoading } = hotelAssociatesListDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectHotelAssociatesListDataSlice = (state: RootState) => state.hotelAssociatesListData;

export const hotelAssociatesListDataReducer = hotelAssociatesListDataSlice.reducer;
