import { get, post, put } from './apiService';
import { env_var } from '../config/env';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getTerms = createAsyncThunk(
    'terms/getTerms',
    async (termsGroupId: string, thunkAPI: any) => {
        try {
            let url = 'users/v1/terms-and-conditions/?group=' + termsGroupId;
            const response = get(url);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const saveTerms = createAsyncThunk(
    'terms/saveTerms',
    async (data: any, thunkAPI: any) => {
        try {
            let url = 'users/v1/terms-and-conditions/'; 
            const response = post(url, data);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

